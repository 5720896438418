// @flow

import React, { PureComponent } from 'react';
import { AutoComplete } from 'antd';
import { Task, User } from '../../types';
import { mergeTaskData } from '../../helpers';
import withAllUsers from '../../hoc/withAllUsers';

type Props = {
    onChange: Function,
    task: Task,
    taskOwner: User,
    allUsers: Array<User>
}

type State = {
    userSearchValue: string,
}

class UserSelectionTask extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            userSearchValue: props.task.attributes.data.value,
        }
        this.onChange = this.onChange.bind(this);
    }

    static getFormattedValue = (task: Task) => {
        if (typeof task.attributes.data.fullName === 'undefined' || task.attributes.data.fullName.trim().length === 0) {
            return 'No user selected';
        }
        return task.attributes.data.fullName;
    }

    static getPreviewValue = (task: Task) => {
        return UserSelectionTask.getFormattedValue(task);
    }

    static isEmpty = (task: Task) => {
        return typeof task.attributes.data.fullName === 'undefined' || task.attributes.data.fullName.trim().length === 0;
    }

    onChange: Function
    onChange(selectedValue: any) {
        let selectedUser = this.props.allUsers.find(u => u.id === selectedValue);

        // If we didn't receive an ID matching a user then it's probably the user
        // filtering so we only update the user search value.
        if (!selectedUser) {
            this.setState({ userSearchValue: selectedValue })
            return;
        }

        this.setState({ userSearchValue: selectedUser.attributes.display_name })
        this.props.onChange(mergeTaskData(this.props.task, {
            value: selectedUser.id,
            // @TODO, saving the name leaves us open to stale data, in the future if our previews
            // move from a static method, we could pull in the live user object and up to date name.
            fullName: selectedUser.attributes.display_name,
        }));
    }

    render() {
        return (
            <AutoComplete
                style={{ width: '100%' }}
                onChange={this.onChange}
                value={this.state.userSearchValue}
                filterOption={(inputValue, option) => {
                    return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                }}
                placeholder="Select a collaborator"
            >
                {this.props.allUsers.map((u: any, i) =>
                    <AutoComplete.Option key={u.id} value={u.id}>
                        {u.attributes.display_name || 'N/A'}
                    </AutoComplete.Option>
                )}
            </AutoComplete>
        );
    }
}

let ConnectedUserSelectionTask = withAllUsers(UserSelectionTask);
ConnectedUserSelectionTask.displayName = 'User Selection';

// Copy required static methods across.
ConnectedUserSelectionTask.isEmpty = UserSelectionTask.isEmpty;
ConnectedUserSelectionTask.getFormattedValue = UserSelectionTask.getFormattedValue;
ConnectedUserSelectionTask.getPreviewValue = UserSelectionTask.getPreviewValue;

export default ConnectedUserSelectionTask;
export { UserSelectionTask as _UserSelectionTask }
