import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';

const CloseIcon = styled(Icon)`
    position: absolute;
    top: 50%;
    left: 50%
    margin-top: -11px;
    margin-left: -11px;
    font-size: 22px;
    cursor: pointer;
`

const Overlay = styled.div`
    background: rgba(0,0,0,0.3);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const Wrapper = styled.div`
    position: relative;
    cursor: pointer;
    display: inline-block;

    ${Overlay} {
        display: none;
    }
    &:hover {
        ${Overlay} {
            display: block;
        }
    }
`

const RemovableOverlay = ({ children, onClick, style = {} }) => {
    return (
        <Wrapper style={style}>
            <Overlay
                onClick={onClick}
            >
                <CloseIcon
                    type="close-circle"
                />
            </Overlay>
            {children}
        </Wrapper>
    );
};

export default RemovableOverlay;
