import getConfig from '../../instance-config'

let adminMenuItems = [
    {
        path: '/admin/task-templates',
        text: 'Task Workflows',
        key: 'task_workflows',
    },
    {
        path: '/admin/organisations',
        text: 'Organisations',
        key: 'organisations',
    },
    {
        path: '/admin/tags',
        text: 'Tags',
        key: 'tags',
    },
    {
        path: '/admin/metrics',
        text: 'Metrics',
        key: 'metrics',
    },
    {
        path: '/admin/content',
        text: 'Content',
        key: 'content',
    },
    {
        path: '/admin/help',
        text: 'Help',
        key: 'help',
    }
];

// remove organisations from the list if we are using a single organisation config
if (!getConfig().multiOrganisations) {
    adminMenuItems = adminMenuItems.filter(item => item.key !== 'organisations');
}

export default adminMenuItems;
