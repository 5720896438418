
// @flow

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from './Ant';

const Wrapper = styled.div`
    position: fixed;
    bottom: 20px;
    right: 120px;
    padding: 10px;
    border: 1px solid #DDD;
    border-radius: 4px;
    background: #FFF;
    z-index: 99;

    @media (max-width: 600px) {
        left: 0;
        right: 0;
        margin: 0 20px;
        bottom: 100px;
        display: flex;
        align-items: center;
    }
`

type Props = {
    clientNeedsRefresh: Boolean,
}

const NeedsRefreshIndicator = ({ clientNeedsRefresh }: Props) => {
    if (!clientNeedsRefresh) {
        return null;
    }

    return (
        <Wrapper>
            New version available. <Button
                type="primary"
                size="small"
                style={{ marginLeft: '16px' }}
                onClick={() => window.location.reload(true)}
            >Click to Refresh</Button>
        </Wrapper>
    );
};

const mapStateToProps = state => {
    return {
        clientNeedsRefresh: state.appState.clientNeedsRefresh,
    }
}

export default connect(mapStateToProps)(NeedsRefreshIndicator);
