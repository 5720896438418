import React from 'react';
import { TableCellName, TableCellText, Table } from '../Ant';
import TaskWorkflowLink from './TaskWorkflowLink';
import TaskTemplateLabel from '../TaskTemplate/TaskTemplateLabel';
import CollaboratorsTeaser from '../CollaboratorsTeaser';
import dayjs from 'dayjs';
import getConfig from '../../instance-config';
import OrganisationLogoFromId from '../Organisation/OrganisationLogoFromId';
import { Skeleton } from 'antd';

function getColumns() {
    let columns = [
        {
            title: 'Challenge', dataIndex: 'attributes.title', key: 'title', render: (value, workflow) => {
                if (workflow.isLoading) {
                    return <TableCellName>{value}</TableCellName>
                }
                return (
                    <TableCellName className="workflow-title-link">
                        <TaskWorkflowLink workflow={workflow}>{value}</TaskWorkflowLink>
                    </TableCellName>
                )
            }
        },
        {
            title: 'Type', dataIndex: 'type', key: 'type', render: (value, workflow) => {
                if (workflow.isLoading) {
                    return <Skeleton active title={false} paragraph={{ rows: 1, width: 150 }} />;
                }
                return <TaskTemplateLabel workflow={workflow}>
                    {(label) => <TableCellText>{label}</TableCellText>}
                </TaskTemplateLabel>
            }, className: 'priority-medium'
        },
        {
            title: 'Collaborators', dataIndex: 'collaborators', key: 'collaborators', render: (value, workflow) => {
                if (workflow.isLoading) {
                    return <Skeleton active title={false} paragraph={{ rows: 1, width: 150 }} />;
                }
                return <CollaboratorsTeaser taskWorkflow={workflow} size="small" />
            }, className: 'priority-low'
        },
        {
            title: 'Created', dataIndex: 'attributes.created', key: 'created', render: (value, workflow) => {
                if (typeof value === 'number') {
                    return <TableCellText>{dayjs.unix(value).format('MMM Do, YYYY')}</TableCellText>
                }
                return <TableCellText>{dayjs(value).format('MMM Do, YYYY')}</TableCellText>
            }, className: 'priority-low'
        },
    ];

    if (getConfig().multiOrganisations) {
        columns.unshift({
            title: 'Organisation', dataIndex: 'attributes.organisation_id', key: 'organisation', render: (value, workflow) => {
                return <OrganisationLogoFromId organisationId={value} />
            }, className: 'priority-medium'
        })
    }
    return columns;
}

const TaskWorkflowTableList = ({ workflows, className = '', extraProps = {} }) => {
    return (
        <Table
            dataSource={workflows}
            rowKey="id"
            columns={getColumns()}
            pagination={false}
            className={className}
            {...extraProps}
        />
    )
};

export default TaskWorkflowTableList;
