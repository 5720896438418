// @flow

import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import useJsonApiGet from '../../api/useJsonApiGet';
import { Select, Tag, Icon, Spin, Input, Button } from 'antd';
import { tokenise } from '../../helpers';
import qs from 'qs';
import { saveJsonApiResponse } from '../../api';

const { Group } = Input;

type PerCategoryProps = {
    render: Function,
    category: Object,
    defaultSelectedTagIds: Array<string>,
    onSelect: Function,
    onDeselect: Function,
    savedTags: Array<Object>,
    doSaveJsonApiResponse: Function,
}

const PerCategoryTagSelection = ({ render, category, defaultSelectedTagIds, onSelect, onDeselect, savedTags, doSaveJsonApiResponse }: PerCategoryProps) => {
    const { response } = useJsonApiGet(`/jsonapi/tag/${category.attributes.drupal_internal__id}`, doSaveJsonApiResponse)
    const tags = response.data.data || savedTags;
    const [selectVisible, setSelectVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSelect = useCallback((value, option) => {
        const selectedTagId = option.key;

        setIsLoading(true);
        onSelect(selectedTagId, category.attributes.drupal_internal__id, tags.find(tag => tag.id === selectedTagId).attributes.name)
            .then(() => {
                setSelectVisible(false);
                setIsLoading(false);
            });
    }, [category, onSelect, tags])

    const handleDeselect = useCallback(id => {
        setIsLoading(true);
        onDeselect(id, category.attributes.drupal_internal__id)
            .then(() => {
                setSelectVisible(false);
                setIsLoading(false);
            });
    }, [category, onDeselect])

    // If there are no tags then don't render anything.
    if (tags.length === 0) {
        return null;
    }

    const selectedTags = tags.filter(tag => defaultSelectedTagIds.includes(tag.id));
    const unselectedTags = tags.filter(tag => !defaultSelectedTagIds.includes(tag.id));

    return render(category.attributes.name, <div className={'category-' + tokenise(category.attributes.name)}>
        <Spin spinning={isLoading}>
            {selectedTags.map(tag =>
                <Tag
                    key={tag.id}
                    closable={true}
                    onClose={() => handleDeselect(tag.id)}
                    className={'tag-' + tokenise(tag.attributes.name)}
                    style={{ marginBottom: '8px' }}
                >
                    {tag.attributes.name}
                </Tag>
            )}
            {unselectedTags.length && !isLoading ?
                selectVisible ?
                    <Group>
                        <Select
                            mode="multiple"
                            style={{ width: 'calc(100% - 30px)' }}
                            placeholder={`Select a tag to add`}
                            onSelect={handleSelect}
                            className="select-tags"
                            size="small"
                        >
                            {unselectedTags.map(tag => <Select.Option className={'option-' + tokenise(tag.attributes.name)} key={tag.id} value={tag.attributes.name}>{tag.attributes.name}</Select.Option>)}
                        </Select>
                        <Button
                            size="small"
                            onClick={() => setSelectVisible(false)}
                            style={{ width: '30px' }}
                        >
                            <Icon type="close" />
                        </Button>
                    </Group>
                    :
                    <Tag
                        onClick={() => setSelectVisible(true)}
                        style={{ background: '#fff', borderStyle: 'dashed', cursor: 'pointer' }}
                        className={'add-tag add-tag-' + tokenise(category.attributes.name)}
                    >
                        <Icon type="plus" /> Add {category.attributes.name}
                    </Tag>
                : null}
        </Spin>
    </div>)
}

const mapStateToPropsPerCategory = (state, props) => {
    return {
        savedTags: typeof state.jsonApi['tag--' + props.category.attributes.drupal_internal__id] === 'undefined' ? [] : Object.values(state.jsonApi['tag--' + props.category.attributes.drupal_internal__id]),
    }
}

const mapDispatchToPropsPerCategory = dispatch => {
    return {
        doSaveJsonApiResponse: (...args) => dispatch(saveJsonApiResponse(...args)),
    }
}

const PerCategoryTagSelectionConnected = connect(mapStateToPropsPerCategory, mapDispatchToPropsPerCategory)(PerCategoryTagSelection);

type Props = {
    onTagSelect: Function,
    onTagDeselect: Function,
    defaultSelectedTagIds: Array<string>,
    children: Function,
    workflowTypeId: string,
    savedCategories: Array<Object>,
    doSaveJsonApiResponse: Function,
}

const TagSelection = ({ onTagSelect, onTagDeselect, workflowTypeId, defaultSelectedTagIds, children, savedCategories, doSaveJsonApiResponse }: Props) => {
    const query = {
        'sort[name][path]': 'name',
        'sort[name][direction]': 'ASC',
        'filter[workflows][condition][path]': 'enabled_workflow_types.*',
        'filter[workflows][condition][value]': workflowTypeId,
        'filter[workflows][condition][operator]': '=',
    };

    const { response } = useJsonApiGet('/jsonapi/tag_category/tag_category?' + qs.stringify(query), doSaveJsonApiResponse)
    const categories = response.data.data || savedCategories;

    if (categories.length === 0) {
        return null;
    }

    return (
        <div className="tag-selection">
            {categories.map(category => <PerCategoryTagSelectionConnected
                onSelect={onTagSelect}
                onDeselect={onTagDeselect}
                render={children}
                key={category.id}
                category={category}
                defaultSelectedTagIds={defaultSelectedTagIds}
            />)}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        savedCategories: typeof state.jsonApi['tag_category--tag_category'] === 'undefined' ? [] : Object.values(state.jsonApi['tag_category--tag_category']),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doSaveJsonApiResponse: (...args) => dispatch(saveJsonApiResponse(...args)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagSelection);
