import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ContentWrapper from '../../layouts/ContentWrapper';
import { Box } from '../../components/Common';
import EditContentBlockForm from '../../Forms/EditContentBlockForm';
import useJsonApiGet from '../../api/useJsonApiGet';
import { saveJsonApiResponse } from '../../api';
import setPageTitle from '../../hoc/setPageTitle';

const EditContentBlock = ({ match, history, doSaveJsonApiResponse }) => {
    const { response } = useJsonApiGet(`/jsonapi/content_block/content_block/${match.params.id}`, doSaveJsonApiResponse)
    const entity = response.data ? response.data.data : null;

    return (
        <ContentWrapper>
            <h1>Editing Content Block</h1>
            <Box style={{ maxWidth: '800px' }}>
                {entity ?
                    <EditContentBlockForm
                        entity={entity}
                        onAfterSave={() => history.push('/admin/content')}
                    />
                    : null}
            </Box>
        </ContentWrapper>
    );
};

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        doSaveJsonApiResponse: (response) => dispatch(saveJsonApiResponse(response)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(setPageTitle(EditContentBlock, 'Edit Content')));
