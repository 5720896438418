export default function getTaskWorkflowBundle(type) {
    return type.split('-')[2];
}

export function getEntityBundle(type) {
    return type.split('-')[2];
}

export function getEntityTypeId(type) {
    return type.split('-')[0];
}
