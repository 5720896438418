import React, { useCallback } from 'react';
import OpportunityActions from './OpportunityActions';
import BuildActions from './BuildActions';
import LiveActions from './LiveActions';
import { Menu } from 'antd';
import ShareToClipboard from '../ShareToClipboard';
import ArchivePlaybook from '../TaskWorkflow/ArchivePlaybook';
import usePlaybook from '../../api/usePlaybook';
import ManageChallengeCollaborators from '../TaskWorkflow/ManageChallengeCollaborators';
import styled from 'styled-components'

// Hides menu item if empty
const StyledMenu = styled(Menu)`
    .ant-dropdown-menu-item:empty {
        display: none;
    }
`

const PhaseActions = ({ workflow, playbookId, phase }) => {
    const { playbook, collaborators, update } = usePlaybook(playbookId);

    const getMenu = useCallback(() => {
        const menu = (
            <StyledMenu>
                <Menu.Item key="share">
                    <ShareToClipboard text={window.location.href}>
                        <span>share</span>
                    </ShareToClipboard>
                </Menu.Item>
                <Menu.Item key="archive">
                    <ArchivePlaybook
                        playbook={playbook}
                        update={update}
                    />
                </Menu.Item>
                <Menu.Item key="collaborators">
                    <ManageChallengeCollaborators
                        title={'Manage Collaborators'}
                        collaborators={collaborators}
                        taskWorkflow={playbook}
                        onSave={update}
                        toggleRender={(props, hasAccess) => {
                            if (hasAccess) {
                                return <span>collaborators</span>
                            }
                        }}
                    />
                </Menu.Item>
                <Menu.Item key="preview">
                    <a href={`/challenge/${playbookId}/preview`} target="_blank" rel="noopener noreferrer">preview</a>
                </Menu.Item>
            </StyledMenu>

        );
        return menu;
    }, [playbook, collaborators, update, playbookId]);

    if (phase === 'opportunity') {
        return <OpportunityActions
            playbookId={playbookId}
            challenge={workflow}
            menu={getMenu()}
        />
    }

    if (phase === 'build') {
        return <BuildActions
            competition={workflow}
            playbookId={playbookId}
            menu={getMenu()}
        />
    }

    if (phase === 'live' || phase === 'results') {
        return <LiveActions
            competition={workflow}
            playbookId={playbookId}
            menu={getMenu()}
        />
    }

    return null;
};

export default PhaseActions;
