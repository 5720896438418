import styled from 'styled-components';

const Box = styled.div`
    background: #FFF;
    border-radius: 4px;
    padding: 32px;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1);
    overflow-wrap: break-word;
    position: relative;
    height: 100%;

    @media (max-width: 600px) {
        padding: 16px;
    }

    border-left-color: ${props => props.theme.primaryColor};
    border-left-width: ${props => props.leftBorder ? '4px' : 0}
    border-left-style: solid;
`

export default Box;
