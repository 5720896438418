import React, { useCallback } from 'react';
import { mergeEntityAttributes } from '../../helpers';
import TagSelection from './TagSelection';

type Props = {
    entity: Object,
    update: Function,
    defaultSelectedTagIds: Array<string>,
}

const TagSelectionEdit = ({ entity, update, defaultSelectedTagIds, children }: Props) => {
    const onTagSelect = useCallback((tagId, categoryId) => {
        const updatedEntity = mergeEntityAttributes(entity, {});

        if (!Array.isArray(updatedEntity.relationships.tags.data)) {
            updatedEntity.relationships.tags.data = [];
        }
        updatedEntity.relationships.tags.data.push({
            type: 'tag--' + categoryId,
            id: tagId,
        });

        return update(updatedEntity);
    }, [entity, update])

    const onTagDeselect = useCallback((tagId, categoryId) => {
        const updatedEntity = mergeEntityAttributes(entity, {});

        updatedEntity.relationships.tags.data = updatedEntity.relationships.tags.data.filter(data => data.id !== tagId);
        return update(updatedEntity);
    }, [entity, update])

    return <TagSelection
        onTagSelect={onTagSelect}
        onTagDeselect={onTagDeselect}
        workflowTypeId={entity.relationships.task_workflow_type.data.id}
        defaultSelectedTagIds={defaultSelectedTagIds}
    >
        {children}
    </TagSelection>
};

export default TagSelectionEdit;
