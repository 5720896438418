// @flow

import React, { useEffect, useState } from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';
import { User } from '../../types';
import { http } from '../../actions';
import { get } from '../../helpers';

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px 0;
`

type StatProps = {
    icon: string,
    value: any,
    style?: Object,
}

const Stat = ({ icon, value, style = {} }: StatProps) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', ...style }}>
            <Icon
                className="icon-large primary-color"
                type={icon}
            />
            <span style={{ marginLeft: '4px', fontSize: '16px', fontWeight: 'bold' }}>{value}</span>
        </div>
    );
}

type Props = {
    user: User,
}

const UserStatsBar = ({ user }: Props) => {
    const [stats, setStats] = useState({})

    useEffect(() => {
        http()
            .get('/api/user-stats')
            .then(result => {
                setStats(result.data);
            })
    }, [])

    return (
        <IconWrapper>
            <Stat style={{ marginRight: '32px' }} icon="bulb" value={get(stats, 'challenge.count', 0)} />
            <Stat icon="flag" value={get(stats, 'competition.count', 0)} />
        </IconWrapper>
    );
};

export default UserStatsBar;
