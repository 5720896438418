// @flow

import React from 'react';
import setPageTitle from '../../hoc/setPageTitle';

import { Table } from '../../components/Ant';
import { Link, withRouter } from 'react-router-dom';
import { Box } from '../../components/Common';
import useJsonApiGet from '../../api/useJsonApiGet';
import CreateNewOrganisation from '../../components/Modals/CreateNewOrganisation';
import ContentWrapper from '../../layouts/ContentWrapper';

type Props = {
    loadOrganisations: Function,
    history: Object,
}

const columns = [{
    title: 'Name',
    key: 'name',
    render: record => <Link style={{ color: 'inherit' }} to={'/admin/organisations/edit/' + record.id}>{record.attributes.name}</Link>
},
{
    title: '',
    render: record => <Link to={'/admin/organisations/edit/' + record.id}>Edit</Link>
}];

const ManageOrganisations = ({ loadOrganisations, history }: Props) => {
    const { response } = useJsonApiGet('/jsonapi/organisation/organisation');
    const organisations = Array.isArray(response.data.data) ? response.data.data : [];

    return (
        <ContentWrapper>
            <h1>Manage Organisations</h1>
            <Box>
                <div style={{ textAlign: 'right', marginBottom: '16px' }}>
                    <CreateNewOrganisation
                        onAfterSave={organisation => {
                            history.push('/admin/organisations/edit/' + organisation.id);
                        }}
                    />
                </div>

                <Table
                    dataSource={organisations}
                    loading={response.isLoading}
                    rowKey="id"
                    columns={columns}
                    pagination={{
                        pageSize: 50,
                    }}
                />
            </Box>
        </ContentWrapper>
    );
};

export default setPageTitle(withRouter(ManageOrganisations), 'Manage Organisations');
