import qs from 'qs';
import { useEffect, useState, useContext } from "react";
import { extractErrorMessage } from '.';
import { ApiContext } from './ApiContext';

const useTagCategories = () => {
    const api = useContext(ApiContext);
    const [categories, setCategories] = useState([]);
    const [responseStatus, setResponseStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const query = {
            'sort[name][path]': 'name',
            'sort[name][direction]': 'ASC',
        };

        let url = `/jsonapi/tag_category/tag_category?${qs.stringify(query)}`;
        api
            .get(url)
            .then(result => {
                // Request was cancelled.
                if (!result) {
                    console.log('tag category cancelled');
                    return;
                }
                setResponseStatus('success');
                setCategories(result.data.data);
            })
            .catch(error => {
                setResponseStatus('failed');
                setErrorMessage(extractErrorMessage(error));
            });

        return () => {
            api.cancel(url);
        }

    }, [api]);

    return {
        isLoading: responseStatus === 'loading',
        isFailed: responseStatus === 'failed',
        errorMessage: errorMessage,
        categories: categories,
    }
}

export default useTagCategories;
