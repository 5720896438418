// @flow

import React, { Component } from 'react';
import { Input } from '../Ant';
import { Task, User } from '../../types';
import { mergeTaskData } from '../../helpers';

type Props = {
    task: Task,
    taskOwner: User,
    onChange: Function,
    size: string,
    isEditing: boolean,
    renderPreviewValue: Function,
    inputType: string,
    placeholder: string,
}

class TextfieldTask extends Component<Props> {

    static defaultProps = {
        inputType: 'text',
        placeholder: '',
        isEmpty: () => {},
    }

    static isEmpty(task: Task) {
        return task.attributes.data.value.trim().length === 0;
    }

    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
    }


    render() {
        let { task, placeholder, inputType } = this.props;
        return (
            <Input
                value={task.attributes.data.value}
                placeholder={placeholder}
                onChange={this.onChange}
                type={inputType}
            />
        )
    }

    onChange: Function
    onChange(el: Object) {
        this.props.onChange(mergeTaskData(this.props.task, { value: el.target.value }));
    }
}

TextfieldTask.displayName = 'Textfield';
export default TextfieldTask;
