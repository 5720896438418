// @flow

import React, { useState } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import { Modal } from '../Ant';
import EditMetricTypeForm from '../../Forms/EditMetricTypeForm';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    > * {
        margin: 0;
        cursor: pointer !important;
    }
`

type Props = {
    children: Node,
    metricType: Object,
    onAfterSave: Function,
}

const MetricTypeEditModal = ({ children, metricType, onAfterSave }: Props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Wrapper
                onClick={() => setIsModalVisible(true)}
            >
                {children}
            </Wrapper>
            <Modal
                title={'Edit Metric'}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                width={'100%'}
                footer={[]}
            >
                <EditMetricTypeForm
                    metricType={metricType}
                    onAfterSave={(closeModal) => {
                        onAfterSave();
                        setIsModalVisible(false)
                    }}
                />
            </Modal>
        </>
    );
};

export default MetricTypeEditModal;
