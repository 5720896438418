import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

const Wrapper = styled.div`
    opacity: ${props => props.opacity}
    border-radius: 4px;
    transform: translate(0, 0);
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1);
    transition: box-shadow 150ms ease-in-out;
    border-radius: 10px;

    &:hover {
        box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.3);
    }   
`
const canDrop = (columnId, item) => {
    return columnId === item.column;
}

const handleHover = (item, monitor, findItem, onHover, id) => {
    // If you can't drop here, ignore it.
    if (!monitor.canDrop()) {
        return;
    }

    if (typeof onHover !== 'function') {
        return;
    }

    // Find the index of the item we are dragging.
    let { index: draggedIndex } = findItem(item.id);

    // Find the index of the item we are hovering over.
    let { index: hoverIndex } = findItem(id);

    if (draggedIndex === hoverIndex) {
        return;
    }

    onHover(draggedIndex, hoverIndex, item);
}

const handleDrop = (item, monitor, findItem, onDrop, id) => {
    if (typeof onDrop !== 'function') {
        return;
    }

    // Find the index of the item we are dragging.
    let { index: draggedIndex } = findItem(item.id);

    // Find the index of the item we are hovering over.
    let { index: hoverIndex } = findItem(id);

    if (draggedIndex === hoverIndex) {
        return;
    }

    onDrop(draggedIndex, hoverIndex, item);
}

/**
 * This component handles any workflow tile/card that is dragged for the purpose
 * of re-ordering the workflows within a single list.
 */
const DraggableTile = ({ id, column, workflow, acceptType, children, onHover, onDrop, findItem }) => {
    // Let a card be dragged.
    const [{ isDragging }, drag] = useDrag({
        item: { type: acceptType, id, column, workflow },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    // This only handles re-ordering within the same column.
    const [{ isOver }, drop] = useDrop({
        accept: acceptType,
        collect: monitor => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver(),
        }),
        canDrop: (item, monitor) => {
            // Must be in the same column;
            return canDrop(column, item);
        },
        hover: (item, monitor) => {
            return handleHover(item, monitor, findItem, onHover, id);
        },
        drop: (item, monitor) => {
            return handleDrop(item, monitor, findItem, onDrop, id);
        }
    })

    return <Wrapper
        opacity={isDragging || isOver ? 0.5 : 1}
        ref={node => drag(drop(node))}
        className="draggable-tile"
    >
        {children}
    </Wrapper>
}

export default DraggableTile;
export { canDrop, handleHover, handleDrop }
