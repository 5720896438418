import React, { useCallback, useState } from 'react';
import { Form, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { Input, Button } from '../components/Ant';
import FormActions from '../components/Form/FormActions';
import qs from 'qs';
import { http } from '../actions';
import { extractErrorMessage } from '../api';
import { saveLoginInfo } from '../helpers/loginInfo';

const SetNewPasswordForm = ({ form, history }) => {
    const [isLoading, setIsLoading] = useState(false);

    const query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
    });

    const validatePasswordsAreSame = useCallback((rule: any, value: any, callback: Function) => {
        if (form.getFieldValue('password') !== form.getFieldValue('confirm_password')) {
            callback('Please ensure your passwords are the same');
            return;
        }

        callback();
    }, [form]);

    const onSubmit = useCallback(e => {
        e.preventDefault();

        form.validateFields()
            .then(result => {
                setIsLoading(true);

                http()
                    .get('/session/token', {
                        withCredentials: true,
                    })
                    .then(token => {
                        http()
                            .post('/user/password-update?_format=json', {
                                password: result.password,
                                'pass-reset-token': query['pass-reset-token'],
                                'uid': query['uid'],
                            }, {
                                    withCredentials: true,
                                    headers: {
                                        'X-CSRF-Token': token.data,
                                    }
                                })
                            .then(response => {
                                // If everything went OK, then redirect them to the homepage and they should
                                // be good to go.
                                saveLoginInfo(response.data.data);
                                setIsLoading(false);
                                message.success('Your password has been updated, please login');
                                window.location = query['redirect'] || '/';
                            })
                            .catch(error => {
                                console.log(error);
                                setIsLoading(false);
                                message.warning(extractErrorMessage(error));
                            })
                    })
            })
            .catch(() => message.warning('Please fix all errors'));


    }, [query, form]);

    if (!query['pass-reset-token']) {
        return <p>Invalid token</p>
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Item label="New Password">
                {form.getFieldDecorator('password', {
                    rules: [{
                        required: true, 
                        min: 8,
                        message: 'Please enter a password of at least 8 characters',
                    }],
                })(
                    <Input name="newPassword" type="password" autoComplete="new-password" />
                )}
            </Form.Item>

            <Form.Item label="Confirm Password">
                {form.getFieldDecorator('confirm_password', {
                    rules: [{
                        required: true,
                        min: 8,
                        message: 'Both passwords must match.',
                    }, {
                        validator: validatePasswordsAreSame,
                    }],
                })(
                    <Input name="confirmPassword" type="password" autoComplete="confirm-password" />
                )}
            </Form.Item>

            <FormActions>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                >Save</Button>
            </FormActions>
        </Form>
    );
};

export default withRouter(Form.create({ name: 'set_new_password' })(SetNewPasswordForm));
