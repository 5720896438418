import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSetPageTitle } from '../hoc/setPageTitle';
import { Spacer } from '../components/Common';
import PlaybookUpdateFeed from '../components/Playbook/PlaybookUpdateFeed';
import PlaybookNav from '../components/Playbook/PlaybookNav';
import { TwoCol7525 } from '../layouts';
import { Spin } from 'antd';
import usePlaybook from '../api/usePlaybook';
import CompetitionRunTabs from '../components/Competition/CompetitionRunTabs';
import PlaybookTitle from '../components/Playbook/PlaybookTitle';

const PlaybookUpdate = ({ match }) => {
    const playbookId = match.params.id;
    useSetPageTitle('Crowd Updates');
    const { isLoading, workflows } = usePlaybook(playbookId);
    const competition = workflows.find(w => w.type === 'task_workflow--competition');

    return (
        <div>
            {competition ?
                <Spin spinning={isLoading}>
                    <PlaybookNav
                        playbookId={playbookId}
                        title={<PlaybookTitle playbookId={playbookId} edit />}
                        activeWorkflowId={match.params.competitionId}
                        mode={competition.attributes.workflow_status === 'complete' ? 'results' : 'live'}
                        tabs={<CompetitionRunTabs
                            competition={competition}
                            activeTab={"updates"}
                            playbookId={playbookId}
                            workflowStatus={competition.attributes.workflow_status}
                        />}
                    />

                    <Spacer>
                        <TwoCol7525
                            left={
                                <PlaybookUpdateFeed
                                    playbookId={playbookId}
                                    workflowId={match.params.competitionId}
                                    activeUpdateId={match.params.activeUpdateId || 'all'}
                                    hideMilestones={true}
                                    title={(updates, pinnedUpdates) => {
                                        let count = updates.length + pinnedUpdates.length;
                                        if (count === 1) {
                                            return <h2>1 Update</h2>;
                                        }
                                        return <h2>{count} Updates</h2>;
                                    }}
                                />
                            }
                        >
                        </TwoCol7525>
                    </Spacer>
                </Spin>
                : null}
        </div>
    );
};

export default withRouter(PlaybookUpdate);
