import React from 'react';
import PlaybookTitle from '../components/Playbook/PlaybookTitle';
import PlaybookNav from '../components/Playbook/PlaybookNav';
import { Spin } from 'antd';
import usePlaybook from '../api/usePlaybook';
import { useSetPageTitle } from '../hoc/setPageTitle';
import { TwoCol7525 } from '../layouts';
import { Spacer, Box, GenericError } from '../components/Common';
import { withRouter } from 'react-router-dom';
import ContentWrapper from '../layouts/ContentWrapper';
import TaskViewList from '../components/TaskView/TaskViewList';
import useTaskWorkflow from '../api/useTaskWorkflow';
import { get } from '../helpers';

const ViewChallenge = ({ match }) => {
    const playbookId = match.params.id;
    const { isLoading, playbook, workflows, owner, isFailed, failedErrorMessage } = usePlaybook(playbookId);
    const challenge = workflows.find(w => w.type === 'task_workflow--challenge');
    const competition = workflows.find(w => w.type === 'task_workflow--competition');

    // Use this hook to load the tasks.
    const { tasks: challengeTasks } = useTaskWorkflow('challenge', challenge ? challenge.id : '')
    const { tasks: competitionTasks } = useTaskWorkflow('competition', competition ? competition.id : '')


    useSetPageTitle(isLoading ? 'Loading...' : 'Preview');

    if (isFailed) {
        return <ContentWrapper>
            <GenericError
                title={failedErrorMessage}
                message="This probably means the challenge no longer exists or the API is currently down."
            />
        </ContentWrapper>
    }

    const getChallengeDescription = () => {
        return get(playbook, 'attributes.description.value', '');
    }

    return (
        <Spin spinning={isLoading}>
            {challenge ?
                <PlaybookNav
                    playbookId={playbookId}
                    title={<PlaybookTitle playbookId={playbookId} />}
                    mode={'preview'}
                />
                : null}

            <Spacer>
                <TwoCol7525
                    isLoading={isLoading}
                    left={isLoading ? null : <>
                        {challengeTasks.length ?
                            <Spacer>
                                <h2>Opportunity</h2>
                                <TaskViewList
                                    tasks={challengeTasks}
                                    owner={owner}
                                />
                            </Spacer>
                            : null
                        }

                        {competitionTasks.length || getChallengeDescription() ?
                            <Spacer>
                                <h2>Build</h2>
                                {getChallengeDescription() ?
                                    <Box>
                                        <h2>Challenge Description</h2>
                                        <div dangerouslySetInnerHTML={{ __html: getChallengeDescription() }} />
                                    </Box>
                                    : null
                                }
                                <TaskViewList
                                    tasks={competitionTasks}
                                    owner={owner}
                                />
                            </Spacer>
                            : null
                        }
                    </>}
                />
            </Spacer>
        </Spin>
    );
};

export default withRouter(ViewChallenge);
