import React from 'react';
import { connectMenu } from 'react-instantsearch-dom';
import { Select } from 'antd';

const MenuSelect = connectMenu(({ items, currentRefinement, refine, emptyText, children, style = {} }) => {
    if (items.length === 0) {
        return null;
    }

    const select = <Select
            defaultValue={""}
            value={currentRefinement || ""}
            onChange={refine}
            style={style}
            dropdownMatchSelectWidth={false}
        >
            {!currentRefinement ?
                <Select.Option key="" value="">{emptyText}</Select.Option> :
                null
            }
            {items.map((item, i) => <Select.Option key={i} value={item.value}>{item.label}</Select.Option>)}
        </Select>

    if (typeof children === 'function') {
        return children(select);
    }

    return select;
})

export default MenuSelect;
