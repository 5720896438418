import { makeApiCall, makePatchApiCall, makePostApiCall, makeDeleteApiCall, withToken, http } from './api';
import getTaskComponent from '../helpers/getTaskComponent';
import { loadTaskWorkflow } from './workflows';
import { getTaskWorkflowBundle } from '../helpers';

export function updateTask(task, parentId) {
    return function (dispatch, getState) {
        // If the task is required and it's now empty then we set the
        // is_complete status back to false.
        if (task.attributes.data.is_required === '1') {
            let Component = getTaskComponent(task);
            if (Component.isEmpty(task)) {
                task.attributes.is_complete = false;
            }
        }

        let requestData = {
            data: task,
        }

        // We must remove these fields to avoid server errors as they will be
        // rejected. Comments are only rejected for non-admins but we still don't
        // want to send it back as it's a calculated field.
        delete requestData.data.attributes.changed;
        delete requestData.data.attributes.comments;

        return makePatchApiCall(dispatch, 'UPDATE_TASK', `/jsonapi/task/task/${task.id}`, requestData)
            .then(result => {
                if (parentId) {
                    dispatch(loadTaskWorkflow(getTaskWorkflowBundle(task.attributes.parent_type), parentId));
                }
                return Promise.resolve(result);
            });
    }
}

export function addTask(task) {
    return function (dispatch, getState) {
        let requestData = {
            data: task,
        }

        return makePostApiCall(dispatch, 'ADD_TASK', '/jsonapi/task/task', requestData);
    }
}

export function deleteTask(task, parentId) {
    return function (dispatch, getState) {
        return makeDeleteApiCall(dispatch, 'DELETE_TASK', `/jsonapi/task/task/${task.id}`)
            .then(result => {
                dispatch({ type: 'JSONAPI_DELETE', entity_type_id: 'task', id: task.id })
                return Promise.resolve(result);
            });
    }
}

export function updateDraftTask(parentId, taskId, data) {
    return {
        type: 'UPDATE_DRAFT',
        parentId: parentId,
        taskId: taskId,
        data: data,
    }
}

export function loadTaskTemplates() {
    return function (dispatch, getState) {
        return makeApiCall(dispatch, 'LOAD_TASK_TEMPLATES', '/jsonapi/task_template/task_template?sort=label');
    }
}

export function loadPublishedTaskTemplates() {
    return function (dispatch, getState) {
        return makeApiCall(dispatch, 'LOAD_TASK_TEMPLATES', '/jsonapi/task_template/task_template?sort=label&filter[published]=1');
    }
}

export function loadTaskTemplate(taskTemplateId) {
    return function (dispatch, getState) {
        return makeApiCall(dispatch, 'LOAD_TASK_TEMPLATE', '/jsonapi/task_template/task_template/' + taskTemplateId + '?include=default_tasks');
    }
}

export function deleteTaskTemplate(taskTemplateId) {
    return function (dispatch, getState) {
        return makeDeleteApiCall(dispatch, 'DELETE_TASK_TEMPLATE', '/jsonapi/task_template/task_template/' + taskTemplateId);
    }
}

export function addTaskTemplate(taskTemplate) {
    return function (dispatch, getState) {
        let requestData = {
            data: taskTemplate,
        }
        return makePostApiCall(dispatch, 'UPDATE_TASK_TEMPLATE', '/jsonapi/task_template/task_template', requestData);
    }
}

export function duplicateTaskTemplate(taskTemplate, newName) {
    return function (dispatch, getState) {
        let requestData = {
            newName: newName,
        }

        return withToken(token => {
            return http().post('/task-templates/duplicate/' + taskTemplate.id + '?_format=json', requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': token,
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        return dispatch({
                            type: 'API_SUCCESS',
                            key: 'DUPLICATE',
                            payload: { data: response.data },
                        })
                    }
                    return Promise.reject(response);
                })
        })
    }
}

export function updateTaskTemplate(taskTemplate) {
    return function (dispatch, getState) {
        let requestData = {
            data: taskTemplate,
        }
        return makePatchApiCall(dispatch, 'UPDATE_TASK_TEMPLATE', '/jsonapi/task_template/task_template/' + taskTemplate.id, requestData);
    }
}

export function addTaskToTaskTemplate(template, task) {
    return function (dispatch, getState) {

        return dispatch(addTask(task))
            .then(addedTaskResult => {
                template.relationships.default_tasks = template.relationships.default_tasks || { data: [] }
                template.relationships.default_tasks.data.push({
                    id: addedTaskResult.payload.data.id,
                    type: task.type,
                });


                let requestData = {
                    data: template,
                }
                return makePatchApiCall(dispatch, 'ADD_TASK_TO_TEMPLATE', `/jsonapi/task_template/task_template/${template.id}`, requestData);
            })
            .catch(addedTaskError => Promise.reject(addedTaskError));
    }
}

export function addNewTaskToTaskWorkflow(taskWorkflow, task) {
    return function (dispatch, getState) {

        return dispatch(addTask(task))
            .then(addedTaskResult => {
                taskWorkflow.relationships.tasks = taskWorkflow.relationships.tasks || { data: [] }
                taskWorkflow.relationships.tasks.data.push({
                    id: addedTaskResult.payload.data.id,
                    type: task.type,
                });

                let taskWorkflowTypeId = getTaskWorkflowBundle(taskWorkflow.type);
                let requestData = {
                    data: taskWorkflow,
                }
                return makePatchApiCall(dispatch, 'ADD_TASK_TO_WORKFLOW', `/jsonapi/task_workflow/${taskWorkflowTypeId}/${taskWorkflow.id}`, requestData)
                .then(result => {
                    if (taskWorkflow.id) {
                        dispatch(loadTaskWorkflow(taskWorkflowTypeId, taskWorkflow.id))
                        .then(() => {
                            return Promise.resolve(result)
                        })
                    } else {
                        return Promise.resolve(result);
                    }
                });

            });
    }
}

export function loadUserTasks(userId, workflowTypeId) {
    return function (dispatch, getState) {
        return makeApiCall(dispatch, 'LOAD_USER_TASKS', {
            url: `/jsonapi/${workflowTypeId}/${workflowTypeId}`,
            params: {
                include: 'tasks,task_template,collaborators',
                'filter[tasks.uid.id]': userId,
                'filter[tasks.is_complete]': false,
            }
        });
    }
}
