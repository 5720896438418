import { useEffect, useContext, useState } from "react";
import { ApiContext } from "./ApiContext";
import { extractErrorMessage } from ".";
import qs from 'qs';

function useOrganisations() {
    const api = useContext(ApiContext);
    const [organisations, setOrganisations] = useState([]);
    const [responseStatus, setResponseStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const query = {
            'sort[name][path]': 'name',
            'sort[name][direction]': 'ASC',
        };

        let url = `/jsonapi/organisation/organisation?${qs.stringify(query)}`;
        api
            .get(url)
            .then(result => {
                // Request was cancelled.
                if (!result) {
                    console.log('organisations cancelled');
                    return;
                }
                setResponseStatus('success');
                setOrganisations(result.data.data);
            })
            .catch(error => {
                setResponseStatus('failed');
                setErrorMessage(extractErrorMessage(error));
            });

        return () => {
            api.cancel(url);
        }

    }, [api]);

    return {
        isLoading: responseStatus === 'loading',
        isFailed: responseStatus === 'failed',
        errorMessage: errorMessage,
        organisations: organisations
    }
}

export default useOrganisations;
