import { get } from "./helpers";

const persistSettings = store => next => action => {
    let currentSettings = get(store.getState(), 'appState.persistentSettings');
    let nextAction = next(action);
    let updatedSettings = get(store.getState(), 'appState.persistentSettings');

    if (currentSettings !== updatedSettings) {
        localStorage.setItem('persistentSettings', JSON.stringify(updatedSettings))
        currentSettings = updatedSettings;
    }

    return nextAction;
}

export default persistSettings;
