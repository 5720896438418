import { useState, useEffect, useCallback } from 'react';
import normalize from 'json-api-normalizer';
import { extractErrorMessage } from '.';
import axios, { CancelToken } from 'axios';
import { get } from '../helpers';
import { jsonApi } from '../actions';

/**
 * Makes a GET call using our JSON API configured HTTP client and passes back a helpful response object.
 *
 * @param {string} url
 * @param {function} onRequestComplete
 *   Use this when you intent to store all the results into the redux store for later use.
 */
const useJsonApiGet = (url, onRequestComplete) => {
    const [response, setResponse] = useState(null);
    const [httpResponse, setHttpResponse] = useState({});
    const [responseStatus, setResponseStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');
    const [cacheId, setCacheId] = useState(1);
    const [link, setLink] = useState(null);
    const [initialUrl, setInitialUrl] = useState(url);

    const performRequest = useCallback(() => {
        setResponseStatus('loading');
        const source = CancelToken.source();

        // If the url has changed from the initial url then we need to invalidate
        // the link in case we've paged through the data.
        if (initialUrl !== url) {
            setInitialUrl(url);
            setLink(null);
        }

        jsonApi().get(link === null ? url : link, {
            cancelToken: source.token,
        })
            .then(httpResponse => {
                if (typeof onRequestComplete === 'function') {
                    onRequestComplete(httpResponse);
                }

                let normalized = { jsonApi: normalize(httpResponse.data, { camelizeKeys: false, camelizeTypeValues: false }) };
                setResponse(normalized);
                setHttpResponse(httpResponse);
                setResponseStatus('success');
            })
            .catch(error => {
                // Don't do anything if it was cancelled by the user.
                if (!axios.isCancel(error)) {
                    setResponseStatus('failed');
                    setErrorMessage(extractErrorMessage(error));
                }
            })

        return () => {
            source.cancel();
        };
    }, [url, onRequestComplete, link, initialUrl]);

    useEffect(performRequest, [performRequest, cacheId]);

    return {
        response: {
            status: responseStatus,
            // Check the response to handle a race condition where the normalized response hasn't been set
            // into state yet, so consider we're still loading.
            isLoading: responseStatus === 'loading' || response === null,
            isFailed: responseStatus === 'failed',
            errorMessage: errorMessage,
            data: typeof httpResponse.data !== 'undefined' ? httpResponse.data : {},
            httpResponse: httpResponse,
        },
        jsonapi: response,
        refresh: () => {
            setCacheId(v => v + 1)
        },
        requestLink: useCallback((linkName) => {
            if (typeof httpResponse.data !== 'undefined' && typeof httpResponse.data.links[linkName] !== 'undefined') {
                setLink(httpResponse.data.links[linkName].href);
            }
        }, [httpResponse]),
        hasLink: linkName => get(httpResponse, 'data.links.' + linkName, null) !== null,
    }
}

export default useJsonApiGet;
