// @flow

import * as React from 'react';
import { useCallback } from 'react';
import usePlaybookUpdates from '../../api/usePlaybookUpdates';
import { Spacer, Box } from '../Common';
import EditPlaybookUpdateModal from '../Modals/EditPlaybookUpdateModal';
import { Button, Spin, Dropdown, Menu, Icon } from 'antd';
import { VerticalTimeline } from '../Competition/VerticalTimeline';
import PlaybookUpdateItem from './PlaybookUpdateItem';
import { useEffect } from 'react';
import { HasPermission } from '../../helpers';

const Dot = ({ children }) => {
    return (
        <div style={{ height: '24px', width: '24px', borderRadius: '100%', background: `${props => props.theme.primaryColor}`, display: 'flex' }}>
            <div style={{ margin: '0 auto' }}>{children}</div>
        </div>
    );
}

const AddPlaybookUpdate = ({ playbookId, refreshAll }) => {
    const menu = <Menu>
        <Menu.Item key="update">
            <EditPlaybookUpdateModal
                playbookUpdate={{
                    attributes: { title: '' },
                }}
                playbookId={playbookId}
                onAfterUpdate={refreshAll}
            >
                Update
            </EditPlaybookUpdateModal>
        </Menu.Item>
        <Menu.Item key="milestone">
            <EditPlaybookUpdateModal
                playbookUpdate={{
                    attributes: { title: '', is_milestone: true },
                }}
                playbookId={playbookId}
                onAfterUpdate={refreshAll}
            >
                Milestone
            </EditPlaybookUpdateModal>
        </Menu.Item>
    </Menu>

    return (
        <Dropdown overlay={menu}>
            <Button>
                Add <Icon type="down" />
            </Button>
        </Dropdown>

    );
}

const playbookUpdatesExtra = {
    'filter[pinned][path]': 'pinned',
    'filter[pinned][value]': '0',
    'filter[pinned][condition]': '=',
};
const playbookUpdatesPinnedExtra = {
    'filter[pinned][path]': 'pinned',
    'filter[pinned][value]': '1',
    'filter[pinned][condition]': '=',
};

type Props = {
    playbookId: string,
    activeUpdateId?: string,
    workflowId: string,
    hideMilestones?: bool,
    title?: React.Node | Function,
}

const PlaybookUpdateFeed = ({ playbookId, workflowId, title = null, activeUpdateId = '', hideMilestones = false }: Props) => {
    const { updates, refresh, isLoading } = usePlaybookUpdates(playbookId, playbookUpdatesExtra);
    const { updates: pinnedUpdates, refresh: refreshPinned, isLoading: isLoadingPinned } = usePlaybookUpdates(playbookId, playbookUpdatesPinnedExtra);

    const refreshAll = useCallback(() => {
        refresh();
        refreshPinned();
    }, [refresh, refreshPinned]);

    useEffect(() => {
        // Quick hack to scroll to the selected update on the full page.
        if (!isLoading && activeUpdateId.length !== 0 && activeUpdateId !== 'all') {
            let element = document.getElementById('update-' + activeUpdateId);
            if (element) {
                element.scrollIntoView();
            }
        }
    });

    const pinnedUpdatesFiltered = pinnedUpdates.filter(u => hideMilestones === false || (hideMilestones && u.attributes.is_milestone === false));
    const updatedFiltered = updates.filter(u => hideMilestones === false || (hideMilestones && u.attributes.is_milestone === false));

    return (
        <Spacer>
            <Box style={{ padding: '16px 0' }}>
                <div className="playbook-update-new" style={{ padding: '16px 32px', display: 'flex', justifyContent: 'space-between' }}>
                    {isLoading
                        ? <span />
                        : typeof title === 'function' ? title(updatedFiltered, pinnedUpdatesFiltered) : title
                    }
                    <HasPermission permission="administer playbook_update">
                        <AddPlaybookUpdate
                            playbookId={playbookId}
                            refreshAll={refreshAll}
                        />
                    </HasPermission>

                </div>

                <Spin spinning={isLoadingPinned}>
                    {pinnedUpdatesFiltered
                        .map((update, i) => (
                            <div key={i}>
                                <VerticalTimeline className="featured">
                                    <PlaybookUpdateItem
                                        item={update}
                                        workflowId={workflowId}
                                        defaultActiveKey={activeUpdateId ? pinnedUpdatesFiltered.map(u => u.id) : [pinnedUpdatesFiltered[0].id]}
                                        dot={<Dot>
                                            <Icon type="info" style={{ color: 'white' }} />
                                        </Dot>}
                                        refresh={refreshAll}
                                        featured
                                        previewOnly={activeUpdateId === ''}
                                    />
                                </VerticalTimeline>
                                <br />
                            </div>
                        ))}
                </Spin>
                <br />
                <br />

                <Spin spinning={isLoading}>
                    <VerticalTimeline>
                        {updatedFiltered
                            .map((item, delta) => {
                                return (
                                    <PlaybookUpdateItem
                                        defaultActiveKey={activeUpdateId ? updatedFiltered.map(u => u.id) : [updatedFiltered[0].id]}
                                        key={item.id}
                                        workflowId={workflowId}
                                        item={item}
                                        dot={<Dot>
                                            {item.attributes.is_milestone ? null : <Icon type="info" style={{ color: 'white' }} />}
                                        </Dot>}
                                        refresh={refreshAll}
                                        previewOnly={activeUpdateId === ''}
                                    />
                                )
                            })}
                    </VerticalTimeline>
                </Spin>
            </Box>
        </Spacer>
    )
};


export default PlaybookUpdateFeed;
