import React, { useCallback, useState, useEffect } from 'react';
import PlaybookNav from '../components/Playbook/PlaybookNav';
import usePlaybook from '../api/usePlaybook';
import { withRouter } from 'react-router-dom';
import OrganisationSelect from '../components/Playbook/OrganisationSelect';
import { Spacer, GenericError } from '../components/Common';
import useManagedOpenState from '../components/TaskWorkflow/useManagedOpenState';
import KanbanOpportunityTile from '../components/Kanban/KanbanOpportunityTile';
import PhasePageLayout from '../components/Phase/PhasePageLayout';
import { Spin } from 'antd';
import TaskWorkflowTile from '../components/TaskWorkflow/TaskWorkflowTile';
import useTaskWorkflow from '../api/useTaskWorkflow';
import { mergeEntityAttributes, get } from '../helpers';
import TaskWorkflowTitleInput from '../components/TaskWorkflow/TaskWorkflowTitleInput';
import { useSetPageTitle } from '../hoc/setPageTitle';
import ContentWrapper from '../layouts/ContentWrapper';
import DescriptionInput from '../components/Playbook/DescriptionInput';
import PlaybookImageUpload from '../components/Playbook/PlaybookImageUpload';
import useOrganisations from '../api/useOrganisations';
import PhaseActions from '../components/Phase/PhaseActions';
import getConfig from '../instance-config';
import { PlaybookTitleFromWorkflows } from '../components/Playbook/PlaybookTitle';
import findTaskId from '../components/Phase/findTaskId'
import openTask from '../components/Phase/openTask'

const EditChallenge = ({ playbookId, taskWorkflow, refreshTaskWorkflow, updateTaskWorkflow, draftWorkflow, updateWorkflowFromDraft, updateDraftTitle, tasks }) => {
    const { playbook, organisation, update: updatePlaybook, refresh: refreshPlaybook, isLoading } = usePlaybook(playbookId);
    const { close, open, toggle, isOpen } = useManagedOpenState();
    const { organisations, isLoading: isLoadingOrgs } = useOrganisations();

    const hasMultipleOrgs = useCallback(() => {
        if (isLoadingOrgs) { return false }
        return getConfig().multiOrganisations && organisations.length > 1
    }, [isLoadingOrgs, organisations.length])

    let [taskId, isIdHash] = findTaskId(tasks, () => {
        if (get(taskWorkflow, 'attributes.title', '').trim().length === 0) {
            return 'title'
        } else if (hasMultipleOrgs && !organisation.id) {
            return 'organisation'
        }
    })

    const isLoaded = useCallback(() => {
        if (isLoading && isLoadingOrgs) {
            return true
        }
        return false
    }, [isLoading, isLoadingOrgs])
    

    useEffect(() => {
        if (!isLoaded) {
            return;
        }
        openTask(open, taskId, isIdHash)
    }, [isLoaded, open, taskId, isIdHash])

    const additionalTasks = <Spacer size="small">
        <Spacer size="small">
            <TaskWorkflowTitleInput
                delta={1}
                onToggle={() => toggle('title')}
                isOpen={() => isOpen('title')}
                workflowTitle={taskWorkflow.attributes.title.trim()}
                heading="Briefly describe the problem or opportunity."
                helpText={`For example, "I want to predict the failure of this component in order to plan maintenance and save money."`}
                onChange={updateDraftTitle}
                onSave={() => {
                    return updateWorkflowFromDraft()
                        .then(result => close('title'));
                }}
            />
        </Spacer>
        {hasMultipleOrgs() ?
            <OrganisationSelect
                isLoading={isLoading}
                delta={2}
                onToggle={() => toggle('organisation')}
                isOpen={() => isOpen('organisation')}
                heading="Organisation"
                selectedOrgId={organisation.id || ''}
                onSave={(orgId) => {
                    let updatedPlaybook = { ...playbook }
                    if (orgId) {
                        updatedPlaybook.relationships.organisation = { data: { type: 'organisation--organisation', id: orgId } }
                    }
                    else {
                        updatedPlaybook.relationships.organisation = { data: null }
                    }
                    updatePlaybook(updatedPlaybook)
                        .then(result => {
                            refreshPlaybook();
                            close('organisation');
                        });
                }}
            />
            : null}
    </Spacer>

    return <PhasePageLayout
        phase={'opportunity'}
        taskWorkflow={taskWorkflow}
        refreshTaskWorkflow={refreshTaskWorkflow}
        tasks={tasks}
        countOffset={hasMultipleOrgs ? 2 : 1}
        additionalTasks={additionalTasks}
        isOpen={isOpen}
        toggle={toggle}
        updateTaskWorkflow={updateTaskWorkflow}
        preview={
            <div>
                <Spacer size="small">
                    <KanbanOpportunityTile workflow={draftWorkflow} />
                </Spacer>
            </div>
        }
    />
}

const EditCompetition = ({ playbookId, taskWorkflow, refreshTaskWorkflow, updateTaskWorkflow, draftWorkflow, updateWorkflowFromDraft, updateDraftTitle, tasks }) => {
    const { playbook, image, isLoading } = usePlaybook(playbookId);
    const { open, close, toggle, isOpen } = useManagedOpenState();


    let [taskId, isIdHash] = findTaskId(tasks, () => {
        let description = get(playbook, 'attributes.description.value', '');
        if (taskWorkflow.attributes.title.trim().length === 0) {
            return 'title'
        } else if (!image) {
            return 'image'
        } else if (description.length === 0) {
            return 'description'
        }
    })
    
    useEffect(() => {
        if (!isLoading) {
            return;
        }
        openTask(open, taskId, isIdHash)
    }, [isLoading, open, taskId, isIdHash])


    const additionalTasks = <>
        <Spacer size="small">
            <TaskWorkflowTitleInput
                delta={1}
                onToggle={() => toggle('title')}
                isOpen={() => isOpen('title')}
                workflowTitle={taskWorkflow.attributes.title.trim()}
                heading="Add a Title"
                helpText={`For example, "I want to predict the failure of this component in order to plan maintenance and save money."`}
                onChange={updateDraftTitle}
                onSave={() => {
                    return updateWorkflowFromDraft()
                        .then(result => close('title'));
                }}
            />
        </Spacer>

        <Spacer size="small">
            <PlaybookImageUpload
                playbookId={playbookId}
                isOpen={() => isOpen('image')}
                close={() => close('image')}
                onToggle={() => toggle('image')}
                delta={2}
            />
        </Spacer>

        <Spacer size="small">
            <DescriptionInput
                playbookId={playbookId}
                isOpen={() => isOpen('description')}
                open={() => open('description')}
                onToggle={() => toggle('description')}
                delta={3}
            />
        </Spacer>
    </>

    return <PhasePageLayout
        phase={'build'}
        taskWorkflow={taskWorkflow}
        updateTaskWorkflow={updateTaskWorkflow}
        refreshTaskWorkflow={refreshTaskWorkflow}
        tasks={tasks}
        additionalTasks={additionalTasks}
        isOpen={isOpen}
        toggle={toggle}
        countOffset={3}
        preview={
            <div>
                <Spacer size="small">
                    <TaskWorkflowTile
                        size='large'
                        taskWorkflow={draftWorkflow}
                    />
                </Spacer>
            </div>
        }
    />
}

const PhaseEditPage = ({ match }) => {
    const phase = match.params.phase;
    const playbookId = match.params.id;
    const { workflows, isFailed: isFailedPlaybook, failedErrorMessage: failedErrorMessagePlaybook } = usePlaybook(playbookId);
    const activeWorkflow = workflows.find(w => phase === 'opportunity' ? w.type === 'task_workflow--challenge' : w.type === 'task_workflow--competition');
    const [draftTitle, setDraftTitle] = useState(null);
    const { taskWorkflow, refresh, tasks, isLoading, isFailed, update, failedErrorMessage } = useTaskWorkflow(phase === 'opportunity' ? 'challenge' : 'competition', activeWorkflow ? activeWorkflow.id : null);
    useSetPageTitle(isLoading || isFailed ? 'Loading...' : activeWorkflow.type === 'task_workflow--challenge' ? 'Creating your opportunity' : 'Building your competition');

    const getDraftWorkflow = useCallback(() => {
        if (!taskWorkflow) {
            return null;
        }
        let draft = mergeEntityAttributes(taskWorkflow, {
            title: draftTitle !== null ? draftTitle : taskWorkflow.attributes.title,
        })
        return draft;
    }, [taskWorkflow, draftTitle])

    const getWorkflowTitle = useCallback(() => {
        if (isLoading) {
            return 'Loading...';
        }

        return <PlaybookTitleFromWorkflows
            emptyText={'Creating your new opportunity'}
            challenge={activeWorkflow.type === 'task_workflow--challenge' ? getDraftWorkflow() : workflows.find(w => w.type === 'task_workflow--challenge')}
            competition={activeWorkflow.type === 'task_workflow--competition' ? getDraftWorkflow() : workflows.find(w => w.type === 'task_workflow--competition')}
        />

    }, [getDraftWorkflow, isLoading, workflows, activeWorkflow])

    const updateWorkflowFromDraft = () => {
        return update(getDraftWorkflow());
    }

    if (isFailed || isFailedPlaybook) {
        return <ContentWrapper>
            <GenericError
                title={failedErrorMessage + ' ' + failedErrorMessagePlaybook}
                message="This probably means the challenge no longer exists or the API is currently down."
            />
        </ContentWrapper>
    }

    return (
        <>
            <PlaybookNav
                playbookId={playbookId}
                mode={phase}
                title={getWorkflowTitle()}
                actions={activeWorkflow ?
                    <PhaseActions
                        playbookId={playbookId}
                        workflow={activeWorkflow}
                        phase={phase}
                    /> : null}
            />

            {isLoading ? <Spin spinning={true} /> :
                phase === 'build'
                    ? <EditCompetition
                        playbookId={playbookId}
                        taskWorkflow={taskWorkflow}
                        tasks={tasks}
                        draftWorkflow={getDraftWorkflow()}
                        updateDraftTitle={setDraftTitle}
                        updateWorkflowFromDraft={updateWorkflowFromDraft}
                        updateTaskWorkflow={update}
                        refreshTaskWorkflow={refresh}
                    />
                    : <EditChallenge
                        playbookId={playbookId}
                        taskWorkflow={taskWorkflow}
                        tasks={tasks}
                        draftWorkflow={getDraftWorkflow()}
                        updateDraftTitle={setDraftTitle}
                        updateWorkflowFromDraft={updateWorkflowFromDraft}
                        updateTaskWorkflow={update}
                        refreshTaskWorkflow={refresh}
                    />
            }
        </>
    );
}

export default withRouter(PhaseEditPage);
