// @flow

import React from 'react';
import { Checkbox } from 'antd';
import { mergeTaskData } from '../../helpers';
import { Task } from '../../types';

type Props = {
    task: Task,
    onChange: Function,
}

const CheckboxesTask = ({ task, onChange }: Props) => {

    const handleOnChange = selectedValue => {
        onChange(mergeTaskData(task, { value: selectedValue }));
    }

    return (
        <Checkbox.Group
            onChange={handleOnChange}
            value={task.attributes.data.value || []}
        >
            {task.attributes.data.options.map((option, i) => <Checkbox key={i} value={option}>{option}</Checkbox>)}
        </Checkbox.Group>
    )
}

CheckboxesTask.getFormattedValue = task => {
    if (!Array.isArray(task.attributes.data.value) || task.attributes.data.value.length === 0) {
        return 'None selected';
    }
    return task.attributes.data.value.join(', ');
}

CheckboxesTask.getPreviewValue = task => {
    return CheckboxesTask.getFormattedValue(task);
}

CheckboxesTask.isEmpty = task => task.attributes.data.value.length === 0;

CheckboxesTask.getSampleTask = () => {
    return {
        attributes: {
            data: {
                title: 'Sample Checkboxes',
                value: ['Option One'],
                options: [
                    'Option One',
                    'Option Two',
                    'Option Three',
                ],
            }
        }
    }
}

CheckboxesTask.displayName = 'Checkboxes';

export default CheckboxesTask;
