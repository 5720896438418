// @flow

import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';
import getConfig from '../instance-config';

type Props = {
    icon?: string,
    value: string,
    text: string,
}

const Wrapper = styled.div`
    text-align: center;
`

const StatValue = styled.span`
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
`

const StatWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`

const StatHeading = styled.h5`
    font-size: 15px;
    color: ${props => props.theme.greyBlack};
`

const NumericalStat = ({ text, icon = '', value }: Props) => {
    return (
        <Wrapper>
            <StatHeading>{text}</StatHeading>
            <StatWrapper>
                {icon === '' ? null : <Icon type={icon} style={{ fontSize: '28px', marginRight: '8px', color: getConfig().primaryColor }} />}
                <StatValue>{value}</StatValue>
            </StatWrapper>
        </Wrapper>
    );
};

export default NumericalStat;
