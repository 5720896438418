// @flow

import React from 'react';
import { List } from 'antd';
import { getBaseHost } from '../../actions';
import dayjs from 'dayjs';
import FileSize from './FileSize';
import FileIcon from './FileIcon';

type FileData = {
    fileName: string,
    fileUrl: string,
    size: number,
    date: string,
}

type Props = {
    attachments: Array<FileData>,
    getItemActions: Function,
    isLoading: bool,
}

const AttachmentList = ({ attachments, isLoading, getItemActions }: Props) => {
    return (
        <List
            itemLayout="horizontal"
            loading={isLoading}
            dataSource={attachments}
            renderItem={item => (
                <List.Item
                    actions={getItemActions(item)}
                >
                    <List.Item.Meta
                        title={<a href={item.fileUrl} target="_blank" rel="noopener noreferrer">{item.fileName}</a>}
                        description={`Uploaded on ${dayjs(item.date).format('MMM Do, YYYY')}`}
                    />
                    <div style={{ flexShrink: 0 }}><FileIcon fileName={item.fileName} /> <FileSize size={item.size} /></div>
                </List.Item>
            )}
        />
    )
}

type FromFilesProps = {
    attachments: Array<Object>,
    getItemActions: Function,
    isLoading: bool,
}
const AttachmentListFromFiles = ({ attachments, isLoading, getItemActions }: FromFilesProps) => {
    return (
        <AttachmentList
            attachments={attachments.map(a => {
                return {
                    fileName: a.attributes.filename,
                    size: a.attributes.filesize,
                    fileUrl: getBaseHost() + a.attributes.uri.url,
                    date: a.attributes.created,
                    // Lets other code access this in the remove handler.
                    file: a,
                }
            })}
            isLoading={isLoading}
            getItemActions={getItemActions}
        />
    );
}

export default AttachmentList;
export { AttachmentListFromFiles }
