// @flow

import React, { useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import useJsonApiGet from '../../api/useJsonApiGet';
import { Spin, List, Icon, message, Popconfirm } from 'antd';
import ContentWrapper from '../../layouts/ContentWrapper';
import { Box } from '../../components/Common';
import CreateNewHelpItem from '../../components/Modals/CreateNewHelpItem';
import setPageTitle from '../../hoc/setPageTitle';
import { get, mergeEntityAttributes, arrayMove } from '../../helpers';
import { saveJsonApiResponse, extractErrorMessage } from '../../api';
import PublishAction from '../../components/TaskTemplate/PublishAction';
import { updateHelpItem, deleteHelpItem } from '../../api/helpItems';
import { useDrag, useDrop } from 'react-dnd';
import { HelpItem } from '../../types'

type Props = {
    helpItems: Array<Object>,
    doSaveJsonApiResponse: Function,
    doDeleteHelpItem: Function,
}

const HelpItemList = ({ helpItems, doSaveJsonApiResponse, refresh, onDelete }) => {
    const onMove = useCallback((dragIndex, dropIndex) => {
        // Starting at the index we got dropped on, update every entity after it,
        const updatedHelpItems = [...helpItems];
        arrayMove(updatedHelpItems, dragIndex, dropIndex);

        let promises = [];
        for (let i = dropIndex; i < helpItems.length; i++) {
            const updatedDraggedEntity = mergeEntityAttributes(updatedHelpItems[i], {
                weight: i,
            })
            promises.push(updateHelpItem(updatedDraggedEntity));
        }

        Promise.all(promises)
            .then(results => {
                refresh();
            })
            .catch(error => {
                message.warning(extractErrorMessage(error));
                console.log(error);
            })
    }, [helpItems, refresh])

    return (
        <List
            itemLayout="horizontal"
            dataSource={helpItems}
            renderItem={(item, i) => (
                <HelpItemListItem
                    helpItem={item}
                    delta={i}
                    onMove={onMove}
                    doSaveJsonApiResponse={doSaveJsonApiResponse}
                    refresh={refresh}
                    onDelete={onDelete}
                />
            )}
        />
    );
}

const HelpItemListItem = ({ helpItem, delta, onMove, doSaveJsonApiResponse, refresh, onDelete }) => {
    const ref = useRef(null)

    const [collectedProps, drag] = useDrag({
        item: { type: 'HelpItemListItem', delta: delta },
        canDrag: () => true,
        collect: (monitor, props) => {
            return {
                isDragging: monitor.isDragging(),
                isOver: monitor.canDrag(),
            }
        }
    })

    const [{ isOver }, drop] = useDrop({
        accept: 'HelpItemListItem',
        collect: mon => ({
            isOver: !!mon.isOver(),
        }),
        drop: (item, monitor) => {
            onMove(item.delta, delta)
        },
    })
    drag(drop(ref));

    return (
        <div
            ref={ref}
            style={
                {
                    display: 'flex',
                    alignItems: 'center',
                    background: isOver ? '#fdfdb7' : 'none',
                    opacity: collectedProps.isDragging ? '0.5' : '1',
                    cursor: collectedProps.isOver ? 'move' : 'default',
                }
            }
        >
            <List.Item style={{ maxWidth: '100%', width: '500px', background: helpItem.attributes.is_published ? 'transparent' : '#fff4f4' }}>
                <List.Item.Meta
                    avatar={<Icon type="right-square" theme="filled" style={{ marginTop: '4px' }} className="icon-large" />}
                    title={helpItem.attributes.title}
                    description={helpItem.attributes.description}
                />
            </List.Item>
            <div style={{ marginLeft: '32px', display: 'flex', alignItems: 'center' }}>
                <PublishAction
                    entity={helpItem}
                    update={updatedEntity => updateHelpItem(updatedEntity)
                        .then(doSaveJsonApiResponse)
                        .catch(error => message.warning(extractErrorMessage(error)))
                    }
                />
                <div style={{ marginLeft: '16px', display: 'flex' }}>
                    <CreateNewHelpItem
                        onAfterSave={refresh}
                        helpItem={helpItem}
                        buttonRender={onClick => <span className="hoverable" onClick={onClick}>Edit</span>}
                    />
                    <Popconfirm
                        title={"Are you sure you want to delete this item?"}
                        onConfirm={() => onDelete(helpItem)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <span style={{ marginLeft: '8px' }} className="hoverable">Delete</span>
                    </Popconfirm>
                </div>
            </div>
        </div>
    );
}

const ManageHelp = ({ helpItems, doSaveJsonApiResponse, doDeleteHelpItem }: Props) => {
    const { response, refresh } = useJsonApiGet('/jsonapi/help_item/help_item?sort[weight][path]=weight&sort[weight][direction]=ASC', doSaveJsonApiResponse)
    const [isLoading, setIsLoading] = useState(false);

    const onDelete = useCallback((helpItem) => {
        setIsLoading(true);
        doDeleteHelpItem(helpItem)
            .then(result => {
                message.success('Help item deleted');
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                extractErrorMessage(error)
            });
    }, [doDeleteHelpItem])

    return (
        <Spin spinning={response.isLoading}>
            <ContentWrapper>
                <h1>Manage Help</h1>
                <Box>
                    <div style={{ textAlign: 'right', marginBottom: '16px' }}>
                        <CreateNewHelpItem
                            onAfterSave={refresh}
                        />
                    </div>

                    {helpItems.length === 0 && !response.isLoading ? <p>There are no help items</p> :
                        <Spin spinning={isLoading}>
                            <HelpItemList
                                helpItems={helpItems}
                                doSaveJsonApiResponse={doSaveJsonApiResponse}
                                refresh={refresh}
                                onDelete={onDelete}
                            />
                        </Spin>
                    }
                </Box>
            </ContentWrapper>
        </Spin>
    );
};

const mapStateToProps = state => {
    return {
        helpItems: Object.values(get(state, 'jsonApi.help_item--help_item', {}))
            .sort((a: HelpItem, b: HelpItem) => a.attributes.weight - b.attributes.weight)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doSaveJsonApiResponse: response => dispatch(saveJsonApiResponse(response)),
        doDeleteHelpItem: helpItem => dispatch(() => {
            return deleteHelpItem(helpItem)
                .then(result => {
                    dispatch({ type: 'JSONAPI_DELETE', 'entity_type_id': 'help_item', id: helpItem.id });
                    return Promise.resolve(result);
                });
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setPageTitle(ManageHelp, 'Manage Help'));
