// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { SearchInstanceUrlSynced as SearchInstance } from '../Search/SearchInstance';
import { Configure } from 'react-instantsearch-dom';
import SearchBox from '../Search/SearchBox';
import styled from 'styled-components';
import MenuSelect from '../AlgoliaFilters/MenuSelect';
import AlgoliaWorkflowHitsLoader from './AlgoliaWorkflowHitsLoader';
import { Icon, Popover, Switch } from 'antd';
import TaskWorkflowTableList from '../TaskWorkflow/TaskWorkflowTableList';
import useTagCategories from '../../api/useTagCategories';
import AlgoliaCurrentRefinements from './AlgoliaCurrentRefinements';
import withPermissions from '../../hoc/withPermissions';
import { hasPermission } from '../../helpers/HasPermission';
import useTaskWorkflowTypes from '../../api/useTaskWorkflowTypes';
import { TaskWorkflow } from '../../types';

import AlgoliaPagination from './AlgoliaPagination';

const FilterWrapper = styled.div`
    display: flex;
    margin-bottom: 24px;

    @media (max-width: 1025px) {
        flex-direction:column;
        & > div:not(:last-child) {
            margin-bottom: 16px
        }
    }
`

const FlexCenter = styled.div`
    display: flex;
    align-items: center;
`

const SearchRight = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: end;

    @media (max-width: 1025px) {
        justify-content: start;
    }
`

const FilterRow = styled.div`
    margin-bottom: 16px;
`

const Filter = styled.div`
    flex-direction: row;
    display: flex;
    color: ${props => props.theme.darkGrey};    

        &:hover {
            cursor: pointer;
            color: ${props => props.theme.primaryColor};
            transition: .2s ease-in;

            span {
                color: ${props => props.theme.darkGrey};
            }
        }
`

type Props = {
    workflowTypeId: string,
    isShowingGrid: bool,
    permissions: Array<string>,
    children?: Function,
    workflowsToHide?: Array<TaskWorkflow>,
    algoliaFilterExtra?: string,
}

const AlgoliaWorkflowList = ({ workflowTypeId, permissions, children, workflowsToHide = [], algoliaFilterExtra = '' }: Props) => {
    const [showArchived, setShowArchived] = useState(false);
    const { categories } = useTagCategories();
    const { workflowTypes } = useTaskWorkflowTypes();
    let activeWorkflowType = workflowTypes.find(workflowType => workflowType.attributes.drupal_internal__id === workflowTypeId);
    const filteredCategories = activeWorkflowType ? categories.filter(category => category.attributes.enabled_workflow_types.includes(activeWorkflowType.id)) : [];

    const filters = <div style={{ width: '300px' }}>
        <FilterRow>
            <h4>Template</h4>
            <MenuSelect
                attribute={'task_template_label'}
                emptyText={"Select"}
                key="task_template"
            />
        </FilterRow>

        {filteredCategories.map(category => (
            <MenuSelect
                // ComputedField for algolia index name?
                attribute={category.attributes.drupal_internal__id + '_name'}
                emptyText={`Select`}
                key={category.id}
            >
                {filter => filter === null ? null :
                    <FilterRow>
                        <h4>{category.attributes.name}</h4>
                        {filter}
                    </FilterRow>
                }
            </MenuSelect>
        ))}

        {hasPermission(permissions, 'administer task_workflow') ?
            <FilterRow key="archived">
                <h4>Show Archived</h4>
                <Switch
                    checkedChildren={'Archived'}
                    unCheckedChildren={'Not Archived'}
                    checked={showArchived}
                    onChange={e => setShowArchived(e => !e)}
                    className="archive-switch"
                />
            </FilterRow>
            : null
        }
    </div >

    return (
        <SearchInstance>
            <Configure
                filters={showArchived ? `type:${workflowTypeId} AND is_archived:1 ${algoliaFilterExtra}` : `type:${workflowTypeId} ${algoliaFilterExtra} AND NOT is_archived:1`}
                hitsPerPage="10"
            />

            <FilterWrapper>
                <FlexCenter>
                    <SearchBox
                        placeholder={`Search ${workflowTypeId}s`}
                    />
                    <Popover placement="rightTop" title={'Filters'} content={filters} trigger="click">
                        <div style={{ display: 'flex' }}>
                            <Filter>
                                <Icon className="icon-large" type="filter" style={{ marginLeft: '16px', marginRight: '8px' }} />
                                <span>Filter</span>
                            </Filter>
                        </div>
                    </Popover>

                    <div style={{ marginLeft: '16px' }}>
                        <AlgoliaCurrentRefinements
                            clearsQuery={true}
                            transformItems={items => {
                                items.forEach(item => {
                                    item.label = item.currentRefinement;
                                });
                                return items;
                            }}
                        />
                    </div>
                </FlexCenter>

                <SearchRight>
                    <FlexCenter>
                        <AlgoliaPagination hitsPerPage={10} />
                    </FlexCenter>
                </SearchRight>
            </FilterWrapper>

            <AlgoliaWorkflowHitsLoader
                workflowTypeId={workflowTypeId}
                workflowsToHide={workflowsToHide}
            >
                {workflows => {
                    if (typeof children === 'function') {
                        return children(workflows);
                    }
                    return <TaskWorkflowTableList className="TaskWorkflowTableList" workflows={workflows} />
                }}
            </AlgoliaWorkflowHitsLoader>
        </SearchInstance>
    );
}

const mapStateToProps = (state, props) => {
    return {
        tagCategories: [],
    }
}

export default connect(mapStateToProps)(withPermissions(AlgoliaWorkflowList));
