// @flow

import React, { useCallback, useState } from 'react';
import type { Node } from 'React'
import { Icon } from 'antd';
import styled from 'styled-components';

const CloseWrapper = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 50;

    &:hover {
        cursor: pointer;
    }
`

type Props = {
    name: string,
    children: Node,
    hash?: string,
}

const Dismissible = ({ name, children, hash = '' }: Props) => {
    const [isDismissed, setIsDismissed] = useState(localStorage.getItem('dismiss_' + name + hash));

    const cleanUp = useCallback(() => {
        // Clean-up anything with the same name previously.
        var keys = [];
        for (var i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            if (typeof key === 'string' && key.indexOf('dismiss_' + name) === 0) {
                keys.push(key);
            }
        }
        keys.forEach(k => localStorage.removeItem(k));
    }, [name]);

    const handleClose = useCallback(() => {
        cleanUp();

        // Stop rendering the component.
        localStorage.setItem('dismiss_' + name + hash, '1');
        setIsDismissed(true);

    }, [name, hash, cleanUp]);

    if (!!isDismissed) {
        return null;
    }

    return (
        <div style={{ position: 'relative' }}>
            <CloseWrapper onClick={handleClose}>
                <Icon type="close" />
            </CloseWrapper>
            {children}
        </div>
    );
};

export default Dismissible;
