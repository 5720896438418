// @flow

import React from 'react';
import styled from 'styled-components';
import { User, Task } from '../../types';
import { mergeTaskData } from '../../helpers';
import Ckeditor from '../Ckeditor';

const EditorWrapper = styled.div`
    .rdw-editor-wrapper {
        border: 1px solid #EEE;
    
    }
    .rdw-editor-toolbar {
        border-top: none;
        border-left: none;
        border-right: none;
    }
    .rdw-editor-main {
        padding: 0 24px;
        min-height: 250px;
    }
`
EditorWrapper.displayName = 'EditorWrapper';

type Props = {
    task: Task,
    taskOwner: User,
    onChange: Function
}


const TextareaTask = ({ task, onChange }: Props) => {
    return <Ckeditor
        data={task.attributes.data.value}
        onChange={event => {
            const html = event.editor.getData();

            onChange(mergeTaskData(task, { value: html }));
        }}
    />
}

TextareaTask.getPreviewValue = (task: Task) => {
    if (typeof task.attributes.data.value === 'undefined') {
        return 'Please complete this task';
    }

    let html = task.attributes.data.value.replace(/<\/p>/g, ' ');
    var div = document.createElement("div");
    div.innerHTML = html;

    if (div.textContent) {
        return div.textContent.trim();
    }

    if (div.innerText) {
        return div.innerText.trim();
    }

    return 'Please complete this task';
}

TextareaTask.getFormattedValue = (task: Task) => {
    if (typeof task.attributes.data.value === 'undefined') {
        return 'Please complete this task';
    }
    return task.attributes.data.value;
}

TextareaTask.isEmpty = task => task.attributes.data.value.trim().length === 0;

TextareaTask.displayName = 'Textarea';

export default TextareaTask;
