// @flow

import React from 'react';
import { connect } from 'react-redux';
import { getRelationship } from '../../selectors';
import { Card, Row, Col } from 'antd';
import Avatar from '../Avatar';
import { Task, User, TaskWorkflow } from '../../types';
import ViewDate from '../ViewDate';
import styled from 'styled-components';

const Bottom = styled.div`
`

const Title = styled.h2`
    font-size: 16px;
    margin-bottom: 16px;
`

type Props = {
    task: Task,
    taskOwner: User,
    taskWorkflow: TaskWorkflow,
}

const TaskCard = ({ task, taskOwner, taskWorkflow }: Props) => {
    return (
        <Card style={{ maxWidth: 760 }}>
            <Row gutter={8}>
                <Col span={4}>
                    <Avatar
                        user={taskOwner}
                        size={'default'}
                    />
                </Col>
                <Col span={20}>
                    <Title>{task.attributes.title}</Title>

                    <Bottom>
                        <div style={{ marginBottom: '16px' }}><em>{taskWorkflow.attributes.title}</em></div>
                        <ViewDate
                            date={task.attributes.due_date}
                            iconStyle={{ fontSize: '16px', marginRight: '8px' }}
                        />
                    </Bottom>
                </Col>
            </Row>

        </Card>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        taskOwner: getRelationship(state, 'uid', ownProps.task)
    }
}

export default connect(mapStateToProps)(TaskCard);
