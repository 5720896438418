// @flow

import React from 'react';
import { Dropdown, Icon } from 'antd';

type Props = {
    menu: React.Node,
}

const EllipsisMenu = ({ menu, ...props }: Props) => {
    return (
        <Dropdown
            overlay={menu}
            trigger={['click']}
            {...props}
        >
            <Icon
                type="ellipsis"
                style={{ fontSize: '30px' }}
                onClick={e => {
                    e.stopPropagation();
                }}
            />
        </Dropdown>
    );
};

export default EllipsisMenu;
