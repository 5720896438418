// @flow

import React from 'react';
import { Icon } from 'antd';
import { Button } from 'antd';

const wrapperStyles = {
    textAlign: 'center',
}

const iconWrapperStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

type Props = {
    title: string,
    message: string,
    hideRefresh?: bool,
}

const GenericError = ({ title, message, hideRefresh = false }: Props) => {
    return (
        <div style={wrapperStyles}>
            <div style={iconWrapperStyles}>
                <Icon type="thunderbolt" style={{ fontSize: '60px' }} />
                <h1>{title}</h1>
            </div>
            <br />
            <br />
            <p>{message}</p>
            <br />
            <br />
            {hideRefresh ? null : <Button type="primary" onClick={() => window.location.reload()}>Refresh Page</Button>}
        </div>
    );
};

export default GenericError;
