// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown, Menu, Icon, Popconfirm, message } from 'antd';
import { TaskWorkflow } from '../../types';
import DuplicateTaskTemplate from '../Modals/DuplicateTaskTemplate';
import { deleteTaskTemplate } from '../../actions';

type Props = {
    template: TaskWorkflow,
    doDeleteTaskTemplate: Function,
    history: Object,
}

const TaskTemplateActions = ({ template, doDeleteTaskTemplate, history }: Props) => {
    const menu = (
        <Menu onClick={() => { }}>
            <Menu.Item key="1">
                <DuplicateTaskTemplate template={template} />
            </Menu.Item>
            <Menu.Item key="2">
                <Icon type="delete" />
                <Popconfirm
                    title={"Are you sure you want to delete this template?"}
                    onConfirm={() => doDeleteTaskTemplate(template)}
                    okText="Yes"
                    cancelText="No"
                >
                    Delete
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown.Button onClick={() => history.push('/admin/task-templates/edit/' + template.id)} overlay={menu}>
            Edit
        </Dropdown.Button>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        doDeleteTaskTemplate: template => {
            return dispatch(deleteTaskTemplate(template.id))
                .then(result => {
                    if (result.type === 'API_SUCCESS') {
                        dispatch({ type: 'JSONAPI_DELETE', entity_type_id: 'task_template', id: template.id })
                        return Promise.resolve(result);
                    }

                    message.warning('Unable to delete the template, make sure it is not being used.');
                    return Promise.reject(result);
                })
                .catch(error => Promise.reject(error))
        },
    }
}

export default connect(null, mapDispatchToProps)(withRouter(TaskTemplateActions));
