import { useEffect, useContext, useState } from 'react';
import { ApiContext } from './ApiContext';
import { extractErrorMessage } from '.';

const useTaskWorkflowTypes = () => {
    const api = useContext(ApiContext);
    const [workflowTypes, setWorkflowTypes] = useState([]);
    const [responseStatus, setResponseStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        let url = `/jsonapi/task_workflow_type/task_workflow_type`;
        api
            .get(url)
            .then(result => {
                // Cancelled request.
                if (!result) {
                    return;
                }

                setResponseStatus('success');
                setWorkflowTypes(result.data.data);
            })
            .catch(error => {
                setResponseStatus('failed');
                setErrorMessage(extractErrorMessage(error));
            });

        return () => {
            api.cancel(url);
        }

    }, [api]);

    return {
        isLoading: responseStatus === 'loading',
        isFailed: responseStatus === 'failed',
        errorMessage: errorMessage,
        workflowTypes: workflowTypes,
    }
};

export default useTaskWorkflowTypes;
