// @flow

import React, { useCallback } from 'react';
import { Popconfirm, Icon, message } from 'antd';
import { Task } from '../../types';
import Media from 'react-media';
import styled from 'styled-components';
import { mergeEntityAttributes } from '../../helpers';
import { hasPermission } from '../../helpers/HasPermission';
import withPermissions from '../../hoc/withPermissions';
import { updateEntity } from '../../api/entity';
import { useDispatch } from 'react-redux';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`
Wrapper.displayName = 'TaskArchiveWrapper'

type Props = {
    task: Task,
    permissions: Array<string>,
    afterSave?: Function,
    children: any,
}

const ArchiveTask = ({ task, afterSave, permissions, children }: Props) => {
    const dispatch = useDispatch();

    const saveResult = useCallback(result => {
        dispatch({
            type: 'API_SUCCESS',
            payload: result.data,
        })
    }, [dispatch]);

    const onConfirm = useCallback(() => {
        let updatedTask = mergeEntityAttributes(task, {
            is_archived: !task.attributes.is_archived,
        })

        updateEntity(updatedTask)
            .then(response => {
                message.success(updatedTask.attributes.is_archived ? 'Task has been archived' : 'Task has been un-archived');
                saveResult(response);

                if (typeof afterSave === 'function') {
                    afterSave(updatedTask);
                }
            })
            .catch(error => message.error(error));
    }, [task, saveResult, afterSave])

    if (!hasPermission(permissions, 'can archive any task')) {
        return null;
    }

    let isArchived = task.attributes.is_archived;
    return (
        <Popconfirm
            title={isArchived ? "Unarchive this task?" : "Are you sure you want to archive this task?"}
            onConfirm={onConfirm}
            okText="Yes"
            cancelText="No"
        >
            <Wrapper>
                {typeof children === 'function' ? children(isArchived) :
                    <>
                        <Icon className="icon-large default-color" type="delete" />
                        <Media query="(min-width: 600px)">
                            <span style={{ marginLeft: '10px' }}>{isArchived ? 'Archived' : 'Archive'}</span>
                        </Media>
                    </>
                }
            </Wrapper>
        </Popconfirm>
    );
};

export default withPermissions(ArchiveTask);
export { ArchiveTask as _ArchiveTask };
