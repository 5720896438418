// @flow

import * as React from 'react';
import styled from 'styled-components';
import FullWidthContentBlock from '../FullWidthContentBlock';
import { StagedMenuResponsive } from '../Kanban/KanbanMenu';
import columnDefinitions from '../Kanban/columnDefinitions';
import { Spacer } from '../Common';
import Padding from '../Common/Padding';
import ArrowLink from '../Common/ArrowLink';
import usePlaybook from '../../api/usePlaybook';
import get from '../../helpers/get';

const HeaderWrapper = styled.div`
    background: #FFF;
`

const PlaybookHeadline = styled.span`
    font-size: 22px;
    font-weight: bold;
    width: 100%;
    
    @media (max-width: 700px) {
        text-align: center;
    }
`

const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 670px) {
        flex-direction: column;
        align-items: center;
    }
`

const ActionsWrapper = styled.div`
    flex-shrink: 0;
    @media (max-width: 670px) {
        margin-top: 16px;
    }
`

const FlexRight = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 8px;

    @media (max-width: 670px) {
        justify-content: center;
        padding-bottom: 16px;
    }
`

type Props = {
    playbookId: string,
    mode: string,
    title: React.Node,
    actions: React.Node,
    tabs?: React.Node,
}

const PlaybookNav = ({
    playbookId,
    mode,
    title,
    actions,
    tabs,
    workflowStatus,
}: Props) => {

    const { workflows } = usePlaybook(playbookId);
    const competition = workflows.find(w => w.type === 'task_workflow--competition');
    const competitionRemoteId = get(competition, 'attributes.remote_uuid', null);

    const shouldShowInnovatorPlatformLink = 
        (workflowStatus === 'complete' || workflowStatus === 'published')
        && competitionRemoteId
    return (
        <HeaderWrapper className="playbook-nav">
            <FullWidthContentBlock>
                <Padding size="small">
                    <FlexWrapper>
                        <div>
                            <StagedMenuResponsive
                                columns={columnDefinitions.map(definition => {
                                    return { ...definition, link: `/challenge/${playbookId}/${definition.id}` }
                                })}
                                activeColumn={mode}
                            />

                            <Spacer size="small" style={{ marginBottom: '0px' }}>
                                <PlaybookHeadline className="task-workflow-title">
                                    {title}
                                </PlaybookHeadline>
                            </Spacer>
                        </div>

                        <ActionsWrapper>
                            {actions}
                        </ActionsWrapper>
                    </FlexWrapper>
                </Padding>
                <FlexRight>
                    { shouldShowInnovatorPlatformLink ?
                        <ArrowLink absolute url={`https://unearthed.solutions//u/challenge-redirect/${competitionRemoteId}`}>
                            Innovator Platform
                        </ArrowLink>
                    : null }
                </FlexRight>
            </FullWidthContentBlock>            
            {tabs}
        </HeaderWrapper>
    );
};

export default PlaybookNav;
