// @flow

import React from 'react';
import NumericalStat from '../NumericalStat';
import useCompetitionMetadata from './useCompetitionMetadata';
import { Spin } from 'antd';
import { TaskWorkflow } from '../../types';

type Props = {
    competition: TaskWorkflow,
}

const SubmissionCountStat = ({ competition }: Props) => {
    const [isLoading, metadata] = useCompetitionMetadata(competition.attributes.remote_uuid);

    return <Spin spinning={isLoading}>
        <NumericalStat
            text={"Submissions"}
            icon="inbox"
            value={metadata.submissionCount}
        />
    </Spin>
};

export default SubmissionCountStat;
