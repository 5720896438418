import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useJsonApiGet from '../api/useJsonApiGet';
import { getTaskWorkflowUrl } from '../api/taskWorkflows';
import { saveJsonApiResponse, extractErrorMessage } from '../api';
import { GenericError, Box } from '../components/Common';
import setPageTitle from '../hoc/setPageTitle';
import { getTaskWorkflow, getRelationship } from '../selectors';
import PlaybookNav from '../components/Playbook/PlaybookNav';
import { Button, Spin, Form, message, InputNumber } from 'antd';
import { createMetric, updateMetric } from '../api/metrics';
import { mergeEntityAttributes } from '../helpers';
import ContentWrapper from '../layouts/ContentWrapper';

const MetricInput = ({ metricValue, metricType, form }) => {
    return (
        <Form.Item label={metricType.attributes.name}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {form.getFieldDecorator(metricType.id, {
                    initialValue: metricValue,
                })(
                    <InputNumber
                        style={{ marginRight: '8px', width: '200px' }}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                )}
                {metricType.attributes.unit}
            </div>
        </Form.Item>
    );
}

const TaskWorkflowMetrics = ({ taskWorkflowUrl, taskWorkflowType, doSaveJsonApiResponse, taskWorkflowId, taskWorkflow, form }) => {
    useJsonApiGet(taskWorkflowUrl, doSaveJsonApiResponse)
    const { response } = useJsonApiGet('/jsonapi/metric_type/metric_type', doSaveJsonApiResponse)
    const metricTypes = response.data.data || [];

    const { response: metricsResponse } = useJsonApiGet('/jsonapi/metric/metric?filter[workflow.id]=' + taskWorkflowId, doSaveJsonApiResponse)
    const workflowMetrics = metricsResponse.data.data || [];

    const onSave = useCallback(e => {
        e.preventDefault();

        form.validateFields()
            .then(result => {
                // Foreach each metric, we either create a new metric or update an existing one.
                const promises = [];
                Object.keys(result).forEach(metricTypeId => {
                    let value = result[metricTypeId];
                    if (!value) {
                        return;
                    }

                    const metric = workflowMetrics.find(metric => metric.relationships.metric_type.data.id === metricTypeId);
                    if (metric) {
                        // Update.
                        const updatedMetric = mergeEntityAttributes(metric, {
                            value: value,
                        })
                        promises.push(updateMetric(updatedMetric));
                    }
                    else {
                        // Create.
                        promises.push(createMetric(value, taskWorkflow, metricTypeId));
                    }
                });
                Promise.all(promises)
                    .then(result => {
                        message.success('Metrics saved');
                    })
                    .catch(error => {
                        message.error(extractErrorMessage(error));
                    })
            })

    }, [form, taskWorkflow, workflowMetrics]);

    const getMetricValue = useCallback(metricType => {
        let metric = workflowMetrics.find(metric => metric.relationships.metric_type.data.id === metricType.id)
        if (metric) {
            return metric.attributes.value;
        }
    }, [workflowMetrics])

    if (response.isFailed) {
        return (
            <GenericError title={response.errorMessage} message="This probably means the workflow no longer exists or the API is currently down." />
        )
    }

    return (
        <>
            {taskWorkflow && taskWorkflow.attributes && taskWorkflow.attributes.playbook_id ?
                <PlaybookNav
                    playbookId={taskWorkflow.attributes.playbook_id}
                    activeWorkflowId={taskWorkflow.id}
                    mode="metrics"
                /> :
                <span />
            }
            <Spin spinning={response.isLoading}>
                <ContentWrapper>
                    <Box>
                        <div>
                            <Form onSubmit={onSave} layout="vertical">
                                <Form.Item>
                                    <h1>Metrics</h1>
                                    {metricTypes.length === 0 && !response.isLoading ? <p>There are no metrics</p> : null}
                                </Form.Item>
                                {metricTypes.map(metricType => <MetricInput
                                    key={metricType.id}
                                    metricType={metricType}
                                    metricValue={getMetricValue(metricType)}
                                    form={form}
                                />)}
                                <Form.Item>
                                    {metricTypes.length === 0 ? null :
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >Save</Button>
                                    }
                                </Form.Item>
                            </Form>
                        </div>
                    </Box>
                </ContentWrapper>
            </Spin>
        </>
    );
};

const mapStateToProps = (state, props) => {
    let taskWorkflow = getTaskWorkflow(state, props.match.params.parentType, props.match.params.id);

    return {
        taskWorkflowUrl: getTaskWorkflowUrl(props.match.params.parentType, props.match.params.id),
        taskWorkflowId: props.match.params.id,
        taskWorkflow: taskWorkflow,
        taskWorkflowType: props.match.params.parentType,
        workflowMetrics: getRelationship(state, 'metrics', taskWorkflow),
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        doSaveJsonApiResponse: (response) => dispatch(saveJsonApiResponse(response)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(setPageTitle(Form.create({ name: 'metric_form' })(TaskWorkflowMetrics), 'Metrics')));
