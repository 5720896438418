import { useEffect, useContext, useState } from "react";
import { ApiContext } from "./ApiContext";
import { extractErrorMessage } from ".";
import qs from 'qs';

function useTaskTemplates(workflowTypeId = '') {
    const api = useContext(ApiContext);
    const [taskTemplates, setTaskTemplates] = useState([]);
    const [responseStatus, setResponseStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const query = {
            'sort[name][path]': 'label',
            'sort[name][direction]': 'ASC',
            'filter[published]': '1',
        };

        let url = `/jsonapi/task_template/task_template?${qs.stringify(query)}`;
        api
            .get(url)
            .then(result => {
                // Request was cancelled.
                if (!result) {
                    console.log('task templates cancelled');
                    return;
                }
                setResponseStatus('success');
                setTaskTemplates(result.data.data);
            })
            .catch(error => {
                setResponseStatus('failed');
                setErrorMessage(extractErrorMessage(error));
            });

        return () => {
            api.cancel(url);
        }

    }, [api]);

    return {
        isLoading: responseStatus === 'loading',
        isFailed: responseStatus === 'failed',
        errorMessage: errorMessage,
        taskTemplates: taskTemplates
            .filter((taskTemplate: Object) => {
                if (workflowTypeId) {
                    return taskTemplate.attributes.task_workflow_type === workflowTypeId;
                }
                // Include them all.
                return true;
            }),
    }
}

export default useTaskTemplates;
