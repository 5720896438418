import React, { useCallback, useState, useEffect } from 'react';
import { get, mergeEntityAttributes } from '../../helpers';
import { Spin, Button } from 'antd';
import usePlaybook from '../../api/usePlaybook';
import CkeditorAdmin from '../CkeditorAdmin';
import { Spacer } from '../Common';

const PlaybookEditableDescription = ({ playbookId, onAfterSave }) => {
    const [draftPlaybookDescription, setDraftPlaybookDescription] = useState('');
    const { playbook, update, isLoading } = usePlaybook(playbookId);

    useEffect(() => {
        if (isLoading) {
            return;
        }
        let description = get(playbook, 'attributes.description.value', '');
        setDraftPlaybookDescription(description);

    }, [isLoading, playbook]);

    const handleClick = useCallback(() => {
        const updatedPlaybook = mergeEntityAttributes(playbook, {
            description: {
                value: draftPlaybookDescription,
                format: 'basic_html',
            }
        })
        update(updatedPlaybook)
            .then(result => onAfterSave());
    }, [playbook, update, draftPlaybookDescription, onAfterSave])

    const isComplete = useCallback(() => {
        return !!get(playbook, 'attributes.description.value');
    }, [playbook])

    return (
        <Spin spinning={isLoading}>
            <CkeditorAdmin
                value={draftPlaybookDescription}
                onChange={setDraftPlaybookDescription}
                allowToggle={false}
            />

            <Spacer size="small" style={{ overflow: 'hidden' }}>
                <Button
                    style={{ float: 'right' }}
                    onClick={handleClick}
                >
                    {isComplete() ? 'Save Changes' : 'Done'}
                </Button>
            </Spacer>
        </Spin>
    );
};

export default PlaybookEditableDescription;
