// @flow

import React from 'react';
import { TaskWorkflow } from '../types';
import { Button } from './Ant';
import { Tooltip } from 'antd';

type Props = {
    taskWorkflow: TaskWorkflow,
    promptText: string,
    buttonText: string,
}

const CompleteBadge = ({ taskWorkflow, promptText, buttonText }: Props) => {
    return (
        <Tooltip title={promptText}>
            <Button type="primary" disabled>
                {buttonText}&nbsp;{taskWorkflow.attributes.completed_tasks_count}/{taskWorkflow.attributes.tasks_count}
            </Button>
        </Tooltip>
    );
};

export default CompleteBadge;
