import React from 'react';
import styled from 'styled-components';

const standardPadding = '32px';
const smallPadding = '16px;';

const PaddingStandard = styled.div`
    padding-top: ${props => props.direction !== 'bottom' ? standardPadding : '0px'};
    padding-bottom: ${props => props.direction !== 'top' ? standardPadding : '0px'};
    padding-left: ${props => props.horizontal ? standardPadding : '0px'};
    padding-right: ${props => props.horizontal ? standardPadding : '0px'};

    // Half the padding on mobile.
    @media (max-width: 600px) {
        padding-top: ${props => props.direction !== 'bottom' ? smallPadding : '0px'};
        padding-bottom: ${props => props.direction !== 'top' ? smallPadding : '0px'};
        padding-left: ${props => props.horizontal ? smallPadding : '0px'};
        padding-right: ${props => props.horizontal ? smallPadding : '0px'};
    }
`

const PaddingSmall = styled.div`
    padding-top: ${props => props.direction !== 'bottom' ? smallPadding : '0px'};
    padding-bottom: ${props => props.direction !== 'top' ? smallPadding : '0px'};
    padding-left: ${props => props.horizontal ? smallPadding : '0px'};
    padding-right: ${props => props.horizontal ? smallPadding : '0px'};

`

const Padding = ({ children, size = 'normal', direction = '', horizontal = false, ...props }) => {
    if (size === 'small') {
        return <PaddingSmall direction={direction} horizontal={horizontal} {...props}>
            {children}
        </PaddingSmall>
    }
    return (
        <PaddingStandard direction={direction} horizontal={horizontal} {...props}>
            {children}
        </PaddingStandard>
    );
};

export default Padding;
