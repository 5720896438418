// @flow

import React, { useState } from 'react';
import { Button, Modal } from '../Ant';
import EditTagCategoryForm from '../../Forms/EditTagCategoryForm';

type Props = {
    onAfterSave: Function,
}

const CreateNewCategory = ({ onAfterSave }: Props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <div>
            <Button type="primary" onClick={() => setIsModalVisible(!isModalVisible)}>Create category</Button>

            <Modal
                title="Create tag category"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <EditTagCategoryForm
                    category={{
                        type: 'metric_type--metric_type',
                        attributes: {
                            name: '',
                        }
                    }}
                    onAfterSave={() => {
                        onAfterSave();
                        setIsModalVisible(false)
                    }}
                />
            </Modal>
        </div>
    );
};

export default CreateNewCategory;
