// @flow

import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Input, Button } from 'antd';
import { textToHtml, htmlToText } from '../../helpers';
import Ckeditor from '../Ckeditor';

const { TextArea, Group } = Input;

const ActionsWrapper = styled.div`
    text-align: right;
`

type Props = {
    forceWysiwyg: bool,
    value: string,
    onChange: Function,
    showCancel: bool,
    onCancel: Function,
    showSubmit: bool,
    onSubmit: Function,
    submitText: string,
    placeholder: string,
}

const ToggleEditor = ({ forceWysiwyg, value, onChange, showCancel, onCancel, showSubmit, onSubmit, submitText, placeholder }: Props) => {
    const [useWysiwyg, setUseWysiwyg] = useState(false);

    const emitSubmit = useCallback((e) => {
        if (showSubmit && typeof onSubmit === 'function') {
            onSubmit(e);
        }
    }, [showSubmit, onSubmit]);

    const submitBtn = ( showSubmit ?
        <Button
            className="comment-submit"
            disabled={!htmlToText(value).trim()}
            style={!(useWysiwyg || forceWysiwyg)
                ? { width: '5em', height: '100%', position: 'absolute', top: 0, right: 0 }
                : { marginTop: '0.2em' }
            }
            onClick={emitSubmit}
        >
            {submitText || 'Submit'}
        </Button>
        : null);

    const toggleBtn = ( !forceWysiwyg ?
        <Button type="link" onClick={() => setUseWysiwyg(!useWysiwyg)} size="small" className="comment-wysiwyg-toggle">
            <small>{useWysiwyg ? 'Simple' : 'Full'} Editor</small>
        </Button>
        : null);
        
    const cancelBtn = ( showCancel ?
        <Button type="link" onClick={onCancel} size="small" className="comment-cancel">
            <small>Cancel</small>
        </Button>
        : null);

    if (useWysiwyg || forceWysiwyg) {
        return (
            <div>
                <div className="comment-wysiwyg-wrapper" style={{ width: '100%' }}>
                    <Ckeditor
                        data={value}
                        onChange={event => onChange(event.editor.getData().replace(/\n/g, ''))}
                    />
                </div>
                <ActionsWrapper>
                    {toggleBtn}
                    {cancelBtn}
                    {submitBtn}
                </ActionsWrapper>
            </div>
        );
    }

    return (
        <div>
            <Group compact className="comment-input-group">
                <TextArea
                    placeholder={placeholder}
                    style={{ width: showSubmit ? 'calc(100% - 5em)' : '100%' }}
                    autoSize
                    value={htmlToText(value)}
                    onInput={e => onChange(textToHtml(e.target.value))}
                    onPressEnter={emitSubmit}
                />
                {submitBtn}
            </Group>
            <ActionsWrapper>
                {toggleBtn}
                {cancelBtn}
            </ActionsWrapper>
        </div>
    );
};

export default ToggleEditor;