// @flow

import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Box from '../../components/Common/Box';
import setPageTitle from '../../hoc/setPageTitle';
import JsonApiTable from '../../components/JsonApiTable';
import { IMAGE_LARGE_CONSUMER_ID } from '../../consumers';
import ContentWrapper from '../../layouts/ContentWrapper';
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs';
import { Input, Checkbox } from 'antd';
import { get } from '../../helpers';
import styled from 'styled-components';
dayjs.extend(relativeTime)

const SearchWrapper = styled.div`
    display: flex;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`
const OptionWrapper = styled.div`
    flex: 1;

    .ant-checkbox-wrapper {
        min-width: max-content;
        margin-left: 2em;
        margin-top: 0.4em;
        
        @media (max-width: 600px) {
            margin-left: 0;
            margin-top: 0.75em;
            margin-bottom: 1em;
        }
    }
`
const columns = [{
    title: 'Name',
    dataIndex: 'attributes.display_name',
    key: 'name',
}, {
    title: 'Status',
    render: record => record.attributes.status === false ? 'Archived' : 'Active'
},
{
    title: 'Roles',
    render: (record, a, b) => {
        return record.included
            .filter(inc => inc.type === 'user_role--user_role')
            .filter(inc => get(record, 'relationships.roles.data', []).map(role => role.id).includes(inc.id))
            .map(inc => inc.attributes.label)
            .join(', ');
    }
},
{
    title: 'Last Access',
    key: 'last_access',
    render: (item => {
        if (item.attributes.access === '1970-01-01T00:00:00+00:00') {
            return 'Never logged in';
        }
        return dayjs(item.attributes.access).fromNow();
    }),
},
{
    title: 'Email',
    dataIndex: 'attributes.mail',
    key: 'age',
}, {
    title: '',
    render: record => <Link to={'/user/edit/' + record.id}>Edit</Link>
}];

const ManageUsers = () => {
    const [searchString, setSearchString] = useState('');
    const [archiveCheckState, setArchiveCheckState] = useState(false);

    const buildSearch = useCallback(() => {
        if (searchString.trim().length === '') {
            return {}
        }
        return {
            'filter[mail][condition][path]': 'mail',
            'filter[mail][condition][value]': searchString,
            'filter[mail][condition][operator]': 'CONTAINS',
        }
    }, [searchString]);

    const buildQueryString = useCallback(() => {
        let queryString = {
            'include': 'field_picture,roles',
            'consumerId': IMAGE_LARGE_CONSUMER_ID,
            'filter[anon][condition][path]': 'uid',
            'filter[anon][condition][value]': '0',
            'filter[anon][condition][operator]': '<>',
            'sort[name][path]': 'name',
            'sort[name][direction]': 'ASC',
            ...buildSearch(),
        }
        if (!archiveCheckState) { 
            queryString['filter[status]'] = 1
        } 

        return queryString;
    }, [buildSearch, archiveCheckState])


    return (
        <ContentWrapper>
            <h1>Manage Users</h1>
            <Box>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                    <SearchWrapper>
                        <Input.Search
                            enterButton
                            placeholder="Search by email"
                            onSearch={setSearchString}
                        />
                        <OptionWrapper>
                           <Checkbox onChange={(e) => setArchiveCheckState(e.target.checked)}>Archived</Checkbox>
                        </OptionWrapper>
                    </SearchWrapper>
                    <div className="actions">
                        <Link to="/admin/user/new" className="ant-btn ant-btn-primary">Add new</Link>
                    </div>
                </div>
                <JsonApiTable
                    url={'/jsonapi/user/user'}
                    columns={columns}
                    rowKey={"id"}
                    query={buildQueryString()}
                />
            </Box>
        </ContentWrapper>
    )
}

export default setPageTitle(ManageUsers, 'Manage Users');
