// @flow

import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spin, Button, Divider, Row, Col, Icon } from 'antd';
import { SortableTable, Modal } from '../../components/Ant';
import { loadTaskTemplate, updateTaskTemplate, deleteTaskTemplate } from '../../actions';
import Box from '../../components/Common/Box';
import { getRelationship } from '../../selectors';
import { Task } from '../../types';
import EditTaskForm from '../../Forms/EditTaskForm';
import EditTaskTemplateForm from '../../Forms/EditTaskTemplateForm';
import Spacer from '../../components/Common/Spacer';
import PreviewTaskTypes from '../../components/TaskTypes/PreviewTaskTypes';
import { arrayMove } from '../../helpers';
import setPageTitle from '../../hoc/setPageTitle';
import ContentWrapper from '../../layouts/ContentWrapper';

type Props = {
    form: Object,
    saveTaskTemplate: Function,
    template: Object,
    templateId: string,
    tasks: Array<Task>,
    loadTaskTemplate: Function,
    deleteTaskTemplate: Function,
    history: Object,
}

const columns = [{
    title: 'Task Title',
    dataIndex: 'attributes.title',
    key: 'title',
}, {
    title: 'Task Type',
    dataIndex: 'attributes.data.type',
    key: 'type',
}];

const EditTaskTemplate = ({ loadTaskTemplate, template, templateId, tasks, saveTaskTemplate, deleteTaskTemplate, history }: Props) => {
    const [isAddNewTaskFormVisible, setIsNewTaskFormVisible] = useState(false);
    const [isShowingPreviews, setIsShowingPreviews] = useState(false);
    const [isEditingTemplate, setIsEditingTemplate] = useState(false);

    useEffect(() => {
        loadTaskTemplate();
    }, [loadTaskTemplate, templateId]);

    const onMoveRow = useCallback((dragIndex, hoverIndex) => {
        arrayMove(template.relationships.default_tasks.data, dragIndex, hoverIndex);
        saveTaskTemplate(template);
    }, [template, saveTaskTemplate])

    return (
        <ContentWrapper>
            <Row gutter={16}>
                <Col sm={24} lg={14}>
                    {!template ? <Spin>Loading...</Spin> :
                        <div>
                            <Modal
                                title={'Edit Template'}
                                visible={isEditingTemplate}
                                onCancel={() => setIsEditingTemplate(false)}
                                width={'100%'}
                                footer={[]}
                            >
                                <Box style={{ marginBottom: '24px' }}>
                                    <h2>Task Workflow Details</h2>
                                    <EditTaskTemplateForm
                                        template={template}
                                        save={saveTaskTemplate}
                                        delete={deleteTaskTemplate}
                                        afterDelete={() => history.push('/admin/task-templates')}
                                    />
                                </Box>
                            </Modal>

                            <Box style={{ marginBottom: '24px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h2>{template.attributes.label} Task Workflow <Icon type="edit" onClick={() => setIsEditingTemplate(true)} /></h2>
                                    <Button onClick={() => setIsNewTaskFormVisible(!isAddNewTaskFormVisible)} className="add-task-toggle">
                                        {isAddNewTaskFormVisible ? 'Cancel' : 'Add Task'}
                                    </Button>
                                </div>

                                {isAddNewTaskFormVisible ? <div>
                                    <Divider />
                                    <EditTaskForm
                                        parent={template}
                                        afterSave={() => setIsNewTaskFormVisible(false)}
                                    />
                                </div> : null}

                                <Spacer>
                                    <SortableTable
                                        columns={columns}
                                        // We filter out null tasks because sometimes they take a while to
                                        // load and we have an array of unloaded tasks from the task workflow.
                                        dataSource={tasks.filter(task => task)}
                                        rowKey={'id'}
                                        locale={{ emptyText: 'No tasks' }}
                                        pagination={false}
                                        expandedRowRender={row => <EditTaskForm task={row} parent={template} />}
                                        onMoveRow={onMoveRow}
                                    />
                                </Spacer>

                            </Box>
                        </div>
                    }
                </Col>
                <Col sm={24} lg={10}>
                    <Box>
                        <h2><Icon className={"icon-large"} type="info-circle" /> Field Previews</h2>
                        <p><span className="link" onClick={() => setIsShowingPreviews(!isShowingPreviews)}>Click here</span> to view previews of each available task type.</p>
                        {isShowingPreviews ? <PreviewTaskTypes /> : null}
                    </Box>
                </Col>
            </Row>
        </ContentWrapper>
    )
}

const mapStateToProps = (state, props) => {
    let template = typeof state.jsonApi['task_template--task_template'] === 'undefined'
        ? null
        : state.jsonApi['task_template--task_template'][props.match.params.id]

    return {
        template: template,
        templateId: props.match.params.id,
        tasks: getRelationship(state, 'default_tasks', template) || [],
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        loadTaskTemplate: () => props.match.params.id ? dispatch(loadTaskTemplate(props.match.params.id)) : null,
        saveTaskTemplate: updatedTemplate => dispatch(updateTaskTemplate(updatedTemplate)),
        deleteTaskTemplate: template => {
            return dispatch(deleteTaskTemplate(template.id))
                .then(result => {
                    if (result.type === 'API_SUCCESS') {
                        dispatch({ type: 'JSONAPI_DELETE', entity_type_id: 'task_template', id: template.id })
                        return Promise.resolve(result);
                    }
                    return Promise.reject(result);
                })
                .catch(error => Promise.reject(error))
        },
    }
}

export default setPageTitle(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditTaskTemplate)), 'Manage Task Workflows');
