import React, { useState } from 'react';
import { Drawer } from '../Ant';
import Comments from '../Comments/Comments';
import { useCallback } from 'react';
import { get } from '../../helpers';

const TaskCommentDrawer = ({ task, children }) => {
    const [isDrawerOpen, setDrawerStatus] = useState(false);

    const open = useCallback(() => {
        setDrawerStatus(true);
    }, []);

    return (
        <>
            {typeof children === 'function' ?
                children(open) :
                <span className='open-task-comments' onClick={open}>comments ({get(task, 'attributes.comments.comment_count') || '0'})</span>
            }
            <Drawer
                onClose={() => setDrawerStatus(false)}
                visible={isDrawerOpen}
            >
                <Comments
                    id={task.id}
                    commentType="task_comments"
                    parentType="task--task"
                />
            </Drawer>
        </>
    );
};

export default TaskCommentDrawer;