import React from 'react'
import SignupActivity from './SignupActivity';
import SubmissionActivity from './SubmissionActivity';
import ForumActivity from './ForumActivity';
import ActivityFeed from './ActivityFeed';

const ActivityFeedRenderer = ({activities, jsonapi, limit, offset}) => {  
    const maxActivity = 18;
    const selector = {}

    if (limit) {
        selector.start = 0
        selector.end = limit
        
    } else if (offset) {
        selector.start = offset
        selector.end = maxActivity
    } else {
        selector.start = 0
        selector.end = maxActivity
    }

    return (
        <ActivityFeed
            items={activities.slice(selector.start, selector.end).map(activity => mapActivity(activity, jsonapi)
            ).filter(a => a !== null)}
        />
    )
}

const mapActivity = (activity, jsonapi) => {
    switch (activity.type) {
        case 'competition_participation--competition_participation':
            return SignupActivity.render(activity, jsonapi);
        case 'submission--data_science':
        case 'submission--hackathon':
            return SubmissionActivity.render(activity, jsonapi);
        case 'forum_question--forum_question':
            return ForumActivity.render(activity, jsonapi);
        default:
            return null;
    }
}

export default ActivityFeedRenderer
