import { useCallback, useState } from 'react';
import { uploadFile } from '../actions';

export default function useFileUpload(uploadUrl) {
    const [isUploadInProgress, setIsUploadInProgress] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleUpload = useCallback(async (files, urlOverride = '') => {
        let results = [];
        setIsUploadInProgress(true);
        for (let i = 0; i < files.length; i++) {
            results.push(await uploadFile(urlOverride.length !== 0 ? urlOverride : uploadUrl, files[i], setUploadProgress));
        }
        setIsUploadInProgress(false);

        return Promise.resolve(results);
    }, [uploadUrl]);

    return {
        handleUpload,
        isUploadInProgress,
        uploadProgress,
    }
}
