// @flow

import React, { useCallback, useState } from 'react';
import type { Node } from 'react'
import { Spin, Form, message, Button, Popconfirm } from 'antd';
import { extractErrorMessage } from '../api';
import FormActions from '../components/Form/FormActions';

type Props = {
    children: Node,
    form: Object,
    onAfterSave?: Function,
    handleSave: Function,
    handleDelete: Function,
    showDelete: bool,
    submitButtonText?: string,
}

const EditEntityForm = ({ children, form, onAfterSave, handleSave, handleDelete, showDelete = false, submitButtonText = 'Save' }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const errorHandler = useCallback(error => {
        setIsLoading(false);
        console.log(error);
        message.error(extractErrorMessage(error));
    }, []);

    const successHandler = useCallback((text, result) => {
        setIsLoading(false);
        message.success(text);

        if (typeof onAfterSave === 'function') {
            onAfterSave(result.data.data);
        }
    }, [onAfterSave])

    const onSave = useCallback(e => {
        e.preventDefault();

        form.validateFields()
            .then(result => {
                setIsLoading(true);
                handleSave(result)
                    .then(success => successHandler(success.text, success.result))
                    .catch(errorHandler);
            })
    }, [handleSave, errorHandler, successHandler, form]);

    const onDelete = useCallback(e => {
        e.preventDefault();
        setIsLoading(true);

        handleDelete()
            .then(success => successHandler(success.text, success.result))
            .catch(errorHandler);
    }, [handleDelete, errorHandler, successHandler])

    return <Spin spinning={isLoading}>
        <Form onSubmit={onSave}>
            {children}

            <FormActions>
                {showDelete ? <Popconfirm
                    title={"Are you sure?"}
                    onConfirm={onDelete}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button>Delete</Button>
                </Popconfirm> : null}
                <Button
                    type="primary"
                    htmlType="submit"
                >{submitButtonText}</Button>
            </FormActions>
        </Form>
    </Spin>;
};

export default EditEntityForm;
