// @flow

import React from 'react';
import { connect } from 'react-redux';
import { getRelationship } from '../../selectors';
import Image from '../Image';
import styled from 'styled-components';

const logoDimensions = {
    default: {
        maxWidth: '150px',
        maxHeight: '70px'
    },
    small: {
        maxWidth: '20px',
        maxHeight: '100px'
    },
    medium: {
        maxWidth: "75px",
        maxHeight: "50px"
    }
}

const LogoWrapper = styled.div`
    img {
        max-width: ${props => logoDimensions[props.cssSize].maxWidth};
        max-height: ${props => logoDimensions[props.cssSize].maxHeight};
    }
`

type Props = {
    organisation: Object,
    logo: Object,
    maxWidth?: string,
    maxHeight?: string,
    style?: Object,
}

const OrganisationLogo = ({ organisation, logo, logoSize = 'default' }: Props) => {
    if (!logo || !logo.links.thumbnail) {
        return null;
    }

    return (
        <LogoWrapper cssSize={logoSize}>
            <Image
                url={logo.links.thumbnail.href}
                alt={organisation.attributes.name}
            />
        </LogoWrapper>
    );
};

const mapStateToProps = (state, props) => {
    return {
        logo: getRelationship(state, 'logo', props.organisation),
    }
}

export default connect(mapStateToProps)(OrganisationLogo);
