// @flow

import React from 'react';
import CheckIcon from '../CheckIcon';
import { Task } from '../../types';
import Media from 'react-media';
import { message } from '../Ant';
import getTaskTypeComponent from '../../helpers/getTaskComponent';
import { trackEvent } from '../../helpers/trackEvent';
import { extractErrorMessage } from '../../api';

type Props = {
    task: Task,
    onClick?: Function,
    showText?: boolean,
    style?: Object,
    uiContext: string,
    parentId?: string,
}

const TaskCheck = ({ task, onClick, showText = false, style = {}, uiContext = '', parentId = '', iconOverride = null }: Props) => {
    let color = task.attributes.is_complete ? '#26e82f' : '#BBB';

    const isValid = (task) => {
        if (task.attributes.data.is_required === '1' && !task.attributes.is_complete) {
            let Component = getTaskTypeComponent(task);
            return !Component.isEmpty(task);
        }
        return true;
    }

    return (
        <span
            style={{ display: 'flex', ...style }}
            className={`task-check ${onClick ? "hoverable" : ""}`}
            onClick={() => {
                // Sometimes we're only showing the status and not actually letting them toggle it.
                if (!onClick) {
                    return;
                }

                if (!isValid(task)) {
                    message.warning('Please complete the task before marking complete');
                    return;
                }

                let updatedTask = JSON.parse(JSON.stringify(task));
                updatedTask.attributes.is_complete = !updatedTask.attributes.is_complete;
                onClick(updatedTask, parentId)
                    .then(result => {
                        if (result.type === 'API_FAILURE') {
                            message.warning(result.payload);
                        }
                        else {
                            if (updatedTask.attributes.is_complete) {
                                trackEvent(task.attributes.parent_type, 'Task marked complete', uiContext);
                                message.success('Task Complete');
                            }
                            else {
                                trackEvent(task.attributes.parent_type, 'Task marked incomplete', uiContext);
                            }
                        }
                    })
                    .catch(error => message.warning(extractErrorMessage(error)))

            }}
        >
            <CheckIcon
                color={color}
                iconOverride={iconOverride}
            />
            {showText ?
                <Media query="(min-width: 600px)">
                    <span style={{ marginLeft: '8px' }}>{task.attributes.is_complete ? 'Completed' : 'Mark Complete'}</span>
                </Media>
                : null}
        </span>
    );
};

export default TaskCheck;
