// @flow

import React from 'react';
import { getBaseHost } from '../../actions';
import FileSize from './FileSize';
import FileIcon from './FileIcon';

type Props = {
    fileName: string,
    fileUrl: string,
    size: number,
    linkToFile?: bool,
}

const FileName = ({ fileName, fileUrl, size, linkToFile = false }: Props) => {
    return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
            {linkToFile ?
                <a style={{ color: 'inherit' }} target="_blank" rel="noopener noreferrer" href={getBaseHost() + fileUrl}>
                    <span style={{ marginLeft: '8px' }}><FileIcon fileName={fileName} /> {fileName}</span>
                </a>
                : <span style={{ marginLeft: '8px' }}><FileIcon fileName={fileName} /> {fileName}</span>
            }

            <em style={{ marginLeft: '8px' }}><small><FileSize size={size} /></small></em>
        </span>
    );
};

type FromFileProps = {
    file: Object,
    linkToFile?: bool,
}
const FileNameFromFile = ({ file, linkToFile = true }: FromFileProps) => {

    return <FileName
        fileName={file.attributes.filename}
        fileUrl={file.attributes.uri.url}
        size={file.attributes.filesize}
        linkToFile={linkToFile}
    />
}

export default FileName;
export { FileNameFromFile }
