import React from 'react';
import { message } from 'antd';

const replaceFunction = (funcName) => {
  message[`${funcName}Original`] = message[funcName];
  message[funcName] = (content, duration, onClose) => {
    return message[`${funcName}Original`](<span className="message-dismiss" onClick={message.destroy}>{content}</span>, navigator.webdriver ? 5 : duration, onClose);
  }
};

replaceFunction('success');
replaceFunction('error');
replaceFunction('info');
replaceFunction('warning');
replaceFunction('warn');
replaceFunction('loading');

export default message;
