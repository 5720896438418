import React from 'react';
import { getTaskTypeComponentList } from '../../helpers/getTaskComponent';
import Spacer from '../Common/Spacer';

const PreviewTaskTypes = () => {
    let defaultTask = {
        attributes: {
            title: 'Sample Task',
            data: {
                value: 'sample value',
                values: ['Sample One', 'Sample Two'],
                options: ['Sample One', 'Sample Two'],
            }
        }
    }
    return (
        <div>
            {
                Object.values(getTaskTypeComponentList()).map((TaskComponent, i) => {
                    return (
                        <Spacer key={i}>
                            <h3>{TaskComponent.displayName}</h3>
                            <TaskComponent
                                task={typeof TaskComponent.getSampleTask === 'function' ? TaskComponent.getSampleTask() : defaultTask}
                            />
                        </Spacer>
                    )
                })
            }
        </div>
    );
};

export default PreviewTaskTypes;
