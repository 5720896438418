// @flow

import React, { useState, useEffect } from 'react';
import type { Node } from 'react';
import DraggableTile from './DraggableTile';
import moveItem from './moveItem';
import updateWorkflowOrder from '../../api/updateWorkflowOrder';
import { TaskWorkflow } from '../../types';

type Props = {
    column: Object,
    allWorkflows: Array<TaskWorkflow>,
}

const ReorderableList = ({ column, allWorkflows }: Props) => {
    // We must do this to take over the order of the workflows after initial render.
    const [workflows, setWorkflows] = useState(allWorkflows);

    useEffect(() => {
        setWorkflows(allWorkflows);
    }, [allWorkflows])

    return workflows.map<Node>((workflow: any): any => (
        <DraggableTile
            key={workflow.id}
            id={workflow.id}
            column={column.id}
            workflow={workflow}
            acceptType={`KanbanTile-${column.id}`}
            findItem={id => {
                let item = workflows.find(c => c.id === id);
                let index = workflows.indexOf(item);
                return { index, item };
            }}
            onHover={(draggedIndex, targetIndex) => {
                let newWorkflows = moveItem(workflows, draggedIndex, targetIndex);
                if (newWorkflows !== undefined) {
                    setWorkflows(newWorkflows);

                    updateWorkflowOrder(column.id, newWorkflows.map(w => w.id));
                }
            }}
        >
            {column.render(workflow)}
        </DraggableTile>
    ))
};

export default ReorderableList;
