import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import styled from 'styled-components';
import './VerticalTimeline.css';
import './VerticalTimelineElement.css';

const VerticalTimelineStyled = styled(VerticalTimeline)`
    && {
        width: 100% !important;
        max-width: none;

        &.featured:before {
            height: 70%;
            top: 15%;
        }

        &:before {
            width: 1px;
            background: #EEE;
            left: 170px;

            @media (max-width: 600px) {
                left: 30px;
            }
        }

        .vertical-timeline-element-content-arrow {
            display: none;
        }

        .vertical-timeline-element-date {
            padding: 34px 0 0 0;
            width: 170px;
            flex-shrink: 0;
        }

        .vertical-timeline-element-icon {
            position: absolute;
            left: 158px;
            border-radius: 50%;

            width: 24px;
            height: 24px;
            border: none;
            box-shadow: none;
            margin-top: 32px;

            @media (max-width: 600px) {
                left: 18px;
            }
        }

        .vertical-timeline-element {
            margin: 0;
            padding: 0 32px;

            &.featured {
                border: 1px solid #EEE;
                border-left: 4px solid ${props => props.theme.primaryColor};
                padding-left: 28px; // Reduced by 4px to account for border.
                box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.15), 0 1px 2px 0 rgba(0,0,0,0.05);

                .collapsible-timeline-item {
                    border: none;
                }

                .vertical-timeline-element-content {
                    border-bottom: none !important;
                }
            }
        }

        p {
            font-size: inherit;
        }
    }
`

const VerticalTimelineElementStyled = styled(VerticalTimelineElement)`
    .vertical-timeline-element-content {
        box-shadow: none;
        border-radius: 0;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding: 0;
        
        @media (max-width: 600px) {
            flex-direction: column-reverse;
            padding: 0 0 0 32px;
        }
    }
`

export {
    VerticalTimelineStyled as VerticalTimeline,
    VerticalTimelineElementStyled as VerticalTimelineElement
};
