// @flow

import React from 'react';
import { getBaseHost } from '../actions';

type Props = {
    url: ?string,
    alt: string,
    style?: Object,
}

const Image = ({ url, alt, style = {} }: Props) => {
    if (!url) {
        return null;
    }

    if (/^https?:\/\//i.test(url)) {
        return <img style={style} src={url} alt={alt} />
    }

    return <img style={style} src={getBaseHost() + url} alt={alt} />
};

export default Image;
