import { combineReducers } from 'redux';
import appState from './appState';
import jsonApi from './jsonApi';
import draftTasks from './draftTasks';

let appReducer = combineReducers({
    jsonApi,
    draftTasks,
    appState
});

const rootReducer = (state, action) => {
    // Lets us reset all state when a user logs out.
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }

    return appReducer(state, action)
}

export default rootReducer;
