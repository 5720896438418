// @flow

import React, { useCallback } from 'react';
import type { Node } from 'react';
import { Link } from 'react-router-dom';
import { createLinkFromTaskWorkflow } from './createLink';
import { get } from '../../helpers';
import withCurrentUser from '../../hoc/withCurrentUser';
import { User, TaskWorkflow } from '../../types';
import md5 from 'md5';

type Props = {
    currentUser: User,
    workflow: TaskWorkflow,
    children: Node,
    style: Object,
    extraProps?: Object,
}

const TaskWorkflowLink = ({ currentUser, workflow, children, style = {}, extraProps = {}}: Props) => {

    let noDrag = false
    if (workflow.isPinned) {
        noDrag = !!workflow.isPinned 
    }

    const getAction = useCallback(() => {
        let status = get(workflow, 'attributes.workflow_status');
        // If something has gone wrong, just default to the opportunity page.
        if (!status || status === 'draft') {
            return 'opportunity';
        }

        if (status === 'complete') {
            return 'results';
        }

        if (status === 'published' || status === 'ready_to_publish') {
            return 'live';
        }

        return 'build';
    }, [workflow]);

    return (
        <Link
            to={createLinkFromTaskWorkflow(workflow, getAction())}
            className={'workflow-tile-' + md5(get(workflow, 'attributes.title', ''))}
            style={{ ...style }}
            {...extraProps}
        >
                    {children}
        </Link>
    );
};

export default withCurrentUser(TaskWorkflowLink);
export { TaskWorkflowLink as _TaskWorkflowLink }
