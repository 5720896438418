import React from 'react';
import styled from 'styled-components';

const CharacterCountIndicator = styled.div`
   display: flex;
   justify-content: flex-end;
   font-size: 14px;
   margin-top: 4px;
`

const CharacterCount = ({ currentValue, maxLength }) => {
    return (
        <CharacterCountIndicator>{currentValue.length} / {maxLength} characters</CharacterCountIndicator>
    )
}

export default CharacterCount;