// @flow

import React from 'react';
import KanbanNewOpportunity from './KanbanNewOpportunity';
import KanbanOpportunityTile from './KanbanOpportunityTile';
import EmptyTileDropTargetBuildColumn from './EmptyTileDropTargetBuildColumn';
import TaskWorkflowTile from '../TaskWorkflow/TaskWorkflowTile';
import EmptyTileDropTargetLiveColumn from './EmptyTileDropTargetLiveColumn';
import TaskWorkflowLink from '../TaskWorkflow/TaskWorkflowLink';
import { TaskWorkflow } from '../../types';

export default [
    {
        id: 'opportunity',
        title: 'Opportunity',
        icon: 'alert',
        weight: 1,
        data: [],
        placeholderTop: <KanbanNewOpportunity />,
        render: (workflow: TaskWorkflow) => {
            return (
                <TaskWorkflowLink workflow={workflow} style={{ display: 'block'}}>
                    <KanbanOpportunityTile workflow={workflow} />
                </TaskWorkflowLink>
            );
        },
        single: true,
        width: 280,
        limit: 6,
        workflow_status: 'draft',
    },
    {
        id: 'build',
        title: "Build",
        icon: 'tool',
        weight: 2,
        limit: 5,
        workflow_status: 'build',
        data: [],
        placeholder: <EmptyTileDropTargetBuildColumn />,
        render: (workflow: TaskWorkflow) => {
            return (
                <TaskWorkflowLink workflow={workflow} style={{ display: 'block'}}>
                    <TaskWorkflowTile key={workflow.id} taskWorkflow={workflow} />
                </TaskWorkflowLink>
            );
        }

    },
    {
        id: 'live',
        title: 'Live',
        icon: 'notification',
        weight: 3,
        limit: 5,
        workflow_status: ['ready_to_publish', 'published'],
        data: [],
        placeholder: <EmptyTileDropTargetLiveColumn />,
        render: (workflow: TaskWorkflow) => {
            return (
                <TaskWorkflowLink workflow={workflow} style={{ display: 'block'}}>
                    <TaskWorkflowTile key={workflow.id} taskWorkflow={workflow} />
                </TaskWorkflowLink>
            );
        }
    },
    {
        id: 'results',
        title: 'Results',
        icon: 'check-circle',
        weight: 4,
        limit: 5,
        workflow_status: 'complete',
        data: [],
        render: (workflow: TaskWorkflow) => {
            return (
                <TaskWorkflowLink workflow={workflow} style={{ display: 'block'}}>
                    <TaskWorkflowTile key={workflow.id} taskWorkflow={workflow} />
                </TaskWorkflowLink>
            );
        }
    },
];
