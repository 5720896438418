class JsonApiRequestBuilder {
    constructor(type) {
        this.data = {
            type: type,
        }
    }

    id(idValue) {
        this.data.id = idValue;
        return this;
    }

    attribute(attributeName, attributeValue) {
        this.data.attributes = this.data.attributes || {};
        this.data.attributes[attributeName] = attributeValue;
        return this;
    }

    relationship(relationshipName, relationshipType, relationshipId, meta = {}) {
        this.data.relationships = this.data.relationships || {};
        this.data.relationships[relationshipName] = {
            data: {
                id: relationshipId,
                type: relationshipType,
            }
        }

        if (Object.keys(meta).length) {
            this.data.relationships[relationshipName].meta = meta;
        }

        return this;
    }

    relationshipArray(relationshipName, relationshipType, relationshipId, meta = {}) {
        this.data.relationships = this.data.relationships || { [relationshipName]: {data: []}};
        this.data.relationships[relationshipName].data.push({
                id: relationshipId,
                type: relationshipType,
        })

        if (Object.keys(meta).length) {
            this.data.relationships[relationshipName].meta = meta;
        }

        return this;
    }

    build() {
        return {
            data: this.data,
        }
    }
}

export default JsonApiRequestBuilder;
