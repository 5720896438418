import React from 'react';
import EmptyTileDropTarget from './EmptyTileDropTarget';
import { SolveChallengeModal } from '../Challenge/SolveChallenge';
import { HasPermission } from '../../helpers';
import { canBuildOpportunity } from '../Phase/OpportunityActions';
import styled from 'styled-components';
import { message } from 'antd';

const InvalidWrapper = styled.div`
    background: #fdc5c5;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const EmptyTileDropTargetBuildColumn = () => {
    return (
        <HasPermission
            permission={'forward incomplete task_workflow'}
        >
            <SolveChallengeModal>
                {showModal => (
                    <EmptyTileDropTarget
                        id={'build'}
                        onDrop={(item, monitor) => {
                            if (!canBuildOpportunity(item.workflow)) {
                                message.warn('Unable to Build It until all tasks are complete');
                                return;
                            }

                            // Pass the challenge we're creating a competition for.
                            showModal(item.workflow);
                        }}
                        isValid={(item) => canBuildOpportunity(item.workflow)}
                        acceptType={`KanbanTile-opportunity`}
                        handleCanDrop={(item, monitor) => item.column === 'opportunity'}
                        invalidDrop={<InvalidWrapper>
                            <p>Cannot build challenge until all tasks are complete.</p>
                        </InvalidWrapper>}
                    />
                )}
            </SolveChallengeModal>
        </HasPermission>
    );
};

export default EmptyTileDropTargetBuildColumn;
