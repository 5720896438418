import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducer';
import thunk from 'redux-thunk';
import persistSettings from './persistSettings';

export default function configureStore() {
    const createStoreWithMiddleware = applyMiddleware(thunk, persistSettings)(createStore)
    const store = createStoreWithMiddleware(
        rootReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )

    return store;
}
