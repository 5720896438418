import { jsonApi } from "../actions";

let cache = {};
export function loadTemplate(id) {
    if (!cache[id]) {
        cache[id] = jsonApi().get(`/jsonapi/task_template/task_template/${id}`)
    }

    return cache[id];
}
