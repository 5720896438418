import React, { useCallback } from 'react';
import EditPlaybookUpdateForm from '../../Forms/EditPlaybookUpdateForm';
import { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../Ant';
import HasPermission from '../../helpers/HasPermission';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    > * {
        margin: 0;
        cursor: pointer !important;
    }

`

const EditPlaybookUpdateModal = ({ children, playbookUpdate, playbookId, onAfterUpdate }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const getTitle = useCallback(() => {
        if (playbookUpdate.id) {
            return playbookUpdate.attributes.is_milestone ? 'Edit Milestone' : 'Edit Update';
        }

        return playbookUpdate.attributes.is_milestone ? 'Add Milestone' : 'Add Update';
    }, [playbookUpdate])
    
    return (
        <HasPermission permission="administer playbook_update">
            <Wrapper
                onClick={() => setIsModalVisible(true)}
            >
                {children}
            </Wrapper>
            <Modal
                className="playbook-update-modal"
                title={getTitle()}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                width={'100%'}
                footer={[]}
            >
                <EditPlaybookUpdateForm
                    entity={playbookUpdate}
                    playbookId={playbookId}
                    onAfterSave={(closeModal) => {
                        onAfterUpdate();
                        setIsModalVisible(false)
                    }}
                />
            </Modal>
        </HasPermission>
    );
};

export default EditPlaybookUpdateModal;
