// @flow

import * as React from 'react'
import { useCallback } from 'react';
import { Menu, Icon, Collapse, Spin, message, Popconfirm } from 'antd';
import EditPlaybookUpdateModal from '../Modals/EditPlaybookUpdateModal';
import { VerticalTimelineElement } from '../Competition/VerticalTimeline';
import styled from 'styled-components';
import ViewDate from '../ViewDate';
import { FileNameFromFile } from '../File/FileName';
import ArrowLink from '../Common/ArrowLink';
import { textTrim, get } from '../../helpers';
import HasPermission from '../../helpers/HasPermission';
import { deleteEntity } from '../../api/entity';
import { useState } from 'react';
import { extractErrorMessage } from '../../api';
import { Spacer } from '../Common';
import EllipsisMenu from '../Common/EllipsisMenu';
import AlignRight from '../Common/AlignRight';
import getConfig from '../../instance-config';

const NameAndDate = styled.div`
    max-width: 300px;
    white-space: nowrap;

    h5 {
        text-align: left;
        font-size: 12px;
    }

`

const ClockandDate = styled.div`
    flex: 0 0 auto;
    flex-direction: row;
    display: flex;
    align-items: center;
    font-size: 12px;
`

const CollapsibleTimelineItem = styled.div`
    width: 100%;
    border-bottom: ${props => props.featured ? 'none' : '2px solid #EEE'};
    padding: 32px 0;

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        top: 14px;
        right: 0px;

        svg {
            font-size: 20px
        }
    }

    .ant-collapse-extra {
        margin-right: 40px;
    }

    .ant-collapse,
    .ant-collapse-item,
    .ant-collapse-content {
        border: none !important;
    }

    .ant-collapse {
        background: transparent;
    }

    .ant-collapse-header {
        padding: 0 !important;
    }
    .ant-collapse-item-active .ant-collapse-header {
        margin-bottom: 16px;
    }

    .ant-collapse-content-box {
        padding: 0 !important;
    }

`

const ImageTextWrapper = styled.div`
    display: flex;
    align-items: flex-start;

    img {
        margin-right: 16px;
        max-width: 50%;
    }
    @media (max-width: 600px) {
        flex-direction: column;

        img {
            margin-right: 0;
            margin-bottom: 16px;
        }
    }
`

const getIconStyle = item => {
    return {
        background: new Date(item.attributes.date).getTime() > new Date().getTime() ? `${props => props.theme.lightGrey}` : `${props => props.theme.primaryColor}`,
    }
}

const CROP_LENGTH = 200;

type Props = {
    item: Object,
    dot: React.Node,
    refresh: Function,
    defaultActiveKey: Array<string>,
    previewOnly: bool,
    workflowId: string,
    featured?: bool,
}
const PlaybookUpdateItem = ({ item, dot, refresh, defaultActiveKey, previewOnly, workflowId, featured = false }: Props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const playbookId = item.relationships.playbook.data.id;

    const onDelete = useCallback(e => {
        e.stopPropagation();
        setIsDeleting(true);
        deleteEntity(item)
            .then(result => {
                refresh();
                setIsDeleting(false);
            })
            .catch(error => {
                setIsDeleting(false);
                message.warn(extractErrorMessage(error));
            });
    }, [item, refresh])

    const buildMenu = useCallback(() => {
        return <Menu onClick={e => e.domEvent.stopPropagation()}>
            <Menu.Item key="edit">
                <EditPlaybookUpdateModal
                    playbookUpdate={item}
                    playbookId={playbookId}
                    onAfterUpdate={refresh}
                >
                    Edit
                </EditPlaybookUpdateModal>
            </Menu.Item>
            <Menu.Item key="delete">
                <Popconfirm
                    title={"Are you sure?"}
                    onConfirm={e => {
                        onDelete(e);
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    Delete
                </Popconfirm>
            </Menu.Item>
        </Menu>
    }, [item, refresh, onDelete, playbookId]);

    const getProcessedText = useCallback(() => {
        return get(item, 'attributes.content.processed', '');
    }, [item]);

    const getTextToRender = useCallback(() => {
        let text = getProcessedText();
        return previewOnly ? textTrim(text, CROP_LENGTH) : text;
    }, [getProcessedText, previewOnly]);

    const isCropped = useCallback(() => {
        return getProcessedText().length > CROP_LENGTH;
    }, [getProcessedText]);

    return (
        <Spin spinning={isDeleting}>
            <VerticalTimelineElement
                className={featured ? 'featured playbook-update-item' : 'playbook-update-item'}
                dateStyle={{
                    width: 'auto',
                }}
                date={
                    <NameAndDate>
                        {item.author && !item.attributes.is_milestone ? <h5>{item.author.attributes.display_name}</h5> : null}
                        <ClockandDate>
                            <ViewDate
                                icon="clock-circle"
                                date={item.attributes.date}
                                iconStyle={{ fontSize: '14px', color: getConfig().darkGrey }}
                            />
                        </ClockandDate>
                    </NameAndDate>
                }
                iconStyle={featured ? {} : getIconStyle(item)}
                position="right"
                icon={dot}
            >
                <CollapsibleTimelineItem
                    featured={featured}
                >
                    <Collapse
                        defaultActiveKey={defaultActiveKey}
                        expandIconPosition={'right'}
                        accordion={false}
                    >
                        <Collapse.Panel
                            id={"update-" + item.id}
                            key={item.id}
                            header={<span
                                style={item.attributes.is_milestone ? {fontWeight: 'bold'} : {}}
                            >{item.attributes.title} {featured ? <Icon type="pushpin" style={{ color: getConfig().primaryColor }} /> : null}</span>}
                            showArrow={getProcessedText().length !== 0}
                            bordered={false}
                            extra={
                                <HasPermission permission="administer task_workflow">
                                    <EllipsisMenu 
                                        menu={buildMenu()}
                                        disabled={isDeleting}
                                    />
                                </HasPermission>
                            }
                        >
                            <ImageTextWrapper>
                                {item.image ? <img src={item.image.links.thumbnail_cropped.href} alt="Preview" /> : null}
                                <div dangerouslySetInnerHTML={{ __html: getTextToRender() }}></div>
                            </ImageTextWrapper>
                            {item.attachments ? item.attachments.map(attachment => (
                                <div key={attachment.id} style={{ marginTop: '16px' }}>
                                    <FileNameFromFile file={attachment} linkToFile={true} />
                                </div>
                            )) : null}

                            {previewOnly && isCropped() ?
                                <Spacer size="small" direction="top">
                                    <AlignRight>
                                        <ArrowLink url={`/challenge/${playbookId}/updates/${item.id}`}>See full update</ArrowLink>
                                    </AlignRight>
                                </Spacer>
                                : null
                            }
                        </Collapse.Panel>
                    </Collapse>
                </CollapsibleTimelineItem>
            </VerticalTimelineElement>
        </Spin>
    );
}


export default PlaybookUpdateItem;
