import React from 'react';
import TextfieldTask from './TextfieldTask';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
});

const NumberTask = props => <TextfieldTask
    inputType="number"
    placeholder="10000"
    {...props} 
/>

NumberTask.getFormattedValue = task => {
    return formatter.format(task.attributes.data.value);
}

NumberTask.getPreviewValue = task => {
    return formatter.format(task.attributes.data.value);
}

NumberTask.isEmpty = task => task.attributes.data.value.length === 0;

NumberTask.getSampleTask = () => {
    return {
        attributes: {
            data: {
                title: 'Sample Task',
                value: 2500,
            }
        }
    }
}

NumberTask.displayName = 'Number';

export default NumberTask;
