// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Icon, List, Popover } from 'antd';
import styled from 'styled-components';
import useJsonApiGet from '../../api/useJsonApiGet';
import { saveJsonApiResponse } from '../../api';
import { get } from '../../helpers';
import { HelpItem } from '../../types';

const StyledList = styled(List)`
    .ant-dropdown-menu-items {
        margin: 0;
        padding: 16px;
    }
    a {
        display: block;
        color: inherit;
        border-bottom: 1px solid #EEE;

        &:last-child {
            border: none;
        }
    }
`

const getHelpItems = (helpItems) => {
    return (
        <StyledList
            itemLayout="horizontal"
            dataSource={helpItems}
            renderItem={item => {
                const listItem = <List.Item>
                    <List.Item.Meta
                        avatar={<Icon type="right-square" theme="filled" style={{ marginTop: '4px' }} className="icon-large" />}
                        title={item.attributes.title}
                        description={item.attributes.description}
                    />
                </List.Item>

                if (!item.attributes.url || item.attributes.url.length === 0) {
                    return listItem
                }

                return (
                    <a target="_blank" rel="noopener noreferrer" href={item.attributes.url}>
                        {listItem}
                    </a>
                )
            }}
        />
    );
}

type Props = {
    helpItems: Array<Object>,
    doSaveJsonApiResponse: Function,
}

const HelpMenu = ({ helpItems, doSaveJsonApiResponse }: Props) => {
    useJsonApiGet('/jsonapi/help_item/help_item?sort[weight][path]=weight&sort[weight][direction]=ASC&filter[is_published]=1', doSaveJsonApiResponse)

    if (helpItems.length === 0) {
        return null;
    }

    return (
        <Popover placement="leftBottom" title={'What can we help with?'} content={getHelpItems(helpItems)} trigger="click">
            <span className="icon-large hoverable">
                <Icon type="question-circle" />
            </span>
        </Popover>
    );
};

const mapStateToProps = state => {
    return {
        // We must filter out unpublishd items on the client as well because if you
        // are on the help admin page then they'll all be loaded regardless.
        helpItems: Object.values(get(state, 'jsonApi.help_item--help_item', {}))
            .filter((item: Object) => !!item.attributes.is_published)
            .sort((a: HelpItem, b: HelpItem) => a.attributes.weight - b.attributes.weight)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doSaveJsonApiResponse: response => dispatch(saveJsonApiResponse(response)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpMenu);
