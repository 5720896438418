// @flow

import React from 'react';
import useTaskTemplate from '../../api/useTaskTemplate';
import { Spin } from 'antd';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';

const TaskLibraryTask = styled.div`
    background: #FFF;
    padding: 16px;
    border: 4px;
    height: 100%;
`

const DraggableTaskWrapper = styled.div`
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25), inset -1px 0px 4px rgba(0, 0, 0, 0.25);
    transition: box-shadow 150ms ease-in-out;
    width: 205px;

    &:hover {
        cursor: move;
        box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.3);
    } 
`

const DraggableTask = ({ id, task, children }) => {
    // Let a card be dragged.
    const [{ isDragging }, drag] = useDrag({
        item: { type: 'DraggableTask', id, task },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    return <DraggableTaskWrapper
        opacity={isDragging ? 0.5 : 1}
        ref={node => drag(node)}
        className="draggable-tile"
    >
        {children}
    </DraggableTaskWrapper>
}

const TaskLibraryWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    > div {
        margin-right: 8px;
        margin-bottom: 8px;
        word-break: break-word;
    }
`

type Props = {
    taskTemplateId: string,
}

const TaskLibrary = ({ taskTemplateId }: Props) => {
    const { tasks, isLoading } = useTaskTemplate(taskTemplateId);

    return (
        <Spin spinning={isLoading}>
            <TaskLibraryWrapper className="task-library">
                {tasks.map(t => (
                    <DraggableTask
                        key={t.id}
                        id={t.id}
                        task={t}
                    >
                        <TaskLibraryTask>
                            {t.attributes.title}
                        </TaskLibraryTask>
                    </DraggableTask>
                ))}
            </TaskLibraryWrapper>
        </Spin>
    );
};

const DropTargetWrapper = styled.div`
    background: ${props => props.canDrop ? '#EEE' : 'transparent'};
    border: 1px dashed #737373;
    height: 90px;
    border-radius: 10px;
`

type DropTargetProps = {
    onDrop: Function,
    isLoading: bool,
}
const NewTaskDropTarget = ({ onDrop, isLoading }: DropTargetProps) => {
    const [{ canDrop }, drop] = useDrop({
        accept: 'DraggableTask',
        collect: monitor => ({
            canDrop: monitor.canDrop(),
        }),
        canDrop: (item, monitor) => {
            return true;
        },
        drop: onDrop,
    })

    return <Spin spinning={isLoading}>
        <DropTargetWrapper className="new-task-drop" ref={drop} canDrop={canDrop} />
    </Spin>
};

export default TaskLibrary;
export { NewTaskDropTarget }

