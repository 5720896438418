// @flow

import React, { useState } from 'react';
import styled from 'styled-components';
import Avatar from './Avatar';
import { Checkbox } from 'antd';
import { User } from '../types';

const UserListWrapper = styled.div``

const UserListItem = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid ${props => props.theme.lightGrey};

    p {
        margin: 0 0 0 16px;
        line-height: 16px;
        font-size: 12px;
    }
    
    .ant-checkbox-wrapper span {
        font-size: 12px;
        margin-left: 0;
        padding-right: 0;
    }
`
UserListItem.displayName = 'UserListItem';

type Props = {
    collaborators: Array<User>,
    ownerId: string,
    onUserSelected?: Function,
    onRemoveCollaborator?: Function,
}

const UserList = ({ collaborators, ownerId, onUserSelected, onRemoveCollaborator }: Props) => {
    const [activeItemDelta, setActiveItemDelta] = useState(null);

    return (
        <UserListWrapper>
            {collaborators.map((collaborator, i) => (
                <UserListItem
                    key={i}
                    onMouseOver={() => setActiveItemDelta(i)}
                    onMouseLeave={() => setActiveItemDelta(null)}
                >
                    {collaborator.id !== ownerId && i === activeItemDelta && onRemoveCollaborator ?
                        <Avatar
                            user={{ attributes: { display_name: 'X' } }}
                            color="red"
                            className="hoverable"
                            onClick={() => onRemoveCollaborator(collaborator.id)}
                        />
                        : <Avatar user={collaborator} />
                    }

                    <p>{collaborator.attributes.display_name}</p>

                    {collaborator.id === ownerId ? <p style={{ marginLeft: 'auto' }}>Owner</p> : null}

                    {onUserSelected ?
                        <p style={{ marginLeft: 'auto' }}>
                            <Checkbox
                                onChange={(e) => {
                                    onUserSelected(e.target.checked, collaborator.id);
                                }}
                            ></Checkbox>
                        </p>
                        : null}
                </UserListItem>
            ))}
        </UserListWrapper>
    );
}

export default UserList;
