// @flow

import React, { useState, useCallback } from 'react';
import CollapsibleBox from '../Common/CollapsibleBox';
import CharacterCount from '../Common/CharacterCount';
import styled from 'styled-components';
import { Input, Button, Spin, message } from 'antd';
import { Spacer } from '../Common';
import { extractErrorMessage } from '../../api';
import CollapsibleBoxTaskListTitle from '../Task/CollapsibleBoxTaskListTitle';

const HelpText = styled.h4`
    font-style: italic;
    color: #737373;
`

type Props = {
    workflowTitle: string,
    title: React.Node,
    helpText: React.Node,
    onChange: Function,
    onSave: Function,
}

const TaskWorkflowTitleInput = ({ workflowTitle, heading, helpText, onChange, onSave, isOpen, onToggle, delta }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(workflowTitle);

    const handleClick = () => {

        if (title.trim().length === 0) {
            message.warn('Title is a required field');
            return;
        }

        setIsLoading(true);
        onSave()
            .then(result => {
                setIsLoading(false);
                onToggle();
            })
            .catch(error => {
                setIsLoading(false);
                message.warn(extractErrorMessage(error));
            })
    }

    const isComplete = useCallback(() => {
        return workflowTitle.length !== 0;
    }, [workflowTitle]);

    return (
        <CollapsibleBox
            className="task-workflow-title-task"
            isOpen={isOpen()}
            onToggle={onToggle}
            title={<CollapsibleBoxTaskListTitle
                heading={heading}
                isComplete={isComplete()}
                count={delta}
            />}
        >
            <Spin spinning={isLoading}>
                {helpText ? <HelpText>{helpText}</HelpText> : null}
                <Input
                    defaultValue={workflowTitle}
                    maxLength={200}
                    placeholder={'Title here'}
                    onChange={e => {
                        setTitle(e.target.value);
                        onChange(e.target.value);
                    }}
                />
            </Spin>

            <CharacterCount currentValue={title} maxLength={200} />

            <Spacer size="small" style={{ overflow: 'hidden' }}>
                <Button
                    style={{ float: 'right' }}
                    onClick={handleClick}
                >
                    {isComplete() ? 'Save Changes' : 'Done'}
                </Button>
            </Spacer>
        </CollapsibleBox>
    );
};

export default TaskWorkflowTitleInput;
