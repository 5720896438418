const moveItem = (data, moveIndex, targetIndex) => {
    if (moveIndex === targetIndex) {
        return;
    }

    // Prevent moving invalid items.
    if (moveIndex < 0 || moveIndex > data.length) {
        return;
    }

    // Prevent out of bound moves for target.
    if (targetIndex < 0 || targetIndex > data.length) {
        return;
    }

    // Find the element we're moving by index.
    const itemToMove = data.find((c, i) => i === moveIndex);

    // Remove the element that is being moved.
    let newData = data.filter((c, i) => i !== moveIndex);

    // Add into at the new position.
    newData.splice(targetIndex, 0, itemToMove);

    return newData;
}

export default moveItem;
