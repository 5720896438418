// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import setPageTitle from '../hoc/setPageTitle';
import TaskWorkflowTile from '../components/TaskWorkflow/TaskWorkflowTile';
import { saveJsonApiResponse } from '../api';
import TaskListPreview from '../components/Task/TaskListPreview';
import { getTaskWorkflowTasks, getRelationship, getPlaybookWorkflows, getCurrentUser, getPlaybook } from '../selectors';
import TaskPreview from '../components/Task/TaskPreview';
import GenericError from '../components/Common/GenericError';
import { IMAGE_LARGE_CONSUMER_ID } from '../consumers';
import useJsonApiGet from '../api/useJsonApiGet';
import CompleteSingleTaskModal from '../components/Task/CompleteSingleTaskModal';
import { User, Playbook, TaskWorkflow, Task } from '../types';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    max-width: 375px;
    margin: 100px auto;

    .task-preview-wrapper {
        max-width: 375px;
        width: 375px;
    }
`

const WrapperInner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

type Props = {
    playbookId: string,
    playbook: Playbook,
    workflowType: string,
    workflow: TaskWorkflow,
    tasks: Array<Task>,
    currentUser: User,
    saveResponse: Function,
}

const WorkflowCompleteTasksInline = ({ playbookId, playbook, workflowType, workflow, tasks, currentUser, saveResponse }: Props) => {
    const { response, jsonapi, refresh } = useJsonApiGet(`/jsonapi/playbook/playbook/${playbookId}?include=workflows,workflows.collaborators,workflows.uid,workflows.tasks,workflows.task_workflow_type,workflows.task_template,image,organisation,organisation.logo&consumerId=${IMAGE_LARGE_CONSUMER_ID}`, saveResponse)

    if (response.isFailed) {
        return <div style={{ marginTop: '60px' }}>
            <GenericError
                title="Something went wrong"
                message={response.errorMessage}
                hideRefresh={true}
            />
        </div>
    }

    return (
        <Wrapper>
            <Spin spinning={response.isLoading} style={{ width: '100%' }}>
                {!response.isLoading ?
                    <WrapperInner>
                        <TaskWorkflowTile
                            taskWorkflow={workflow}
                        />
                        <br />

                        <TaskListPreview
                            parentType={workflowType}
                            parentId={workflow.id}
                            tasks={tasks}
                        >
                            {task => (
                                <CompleteSingleTaskModal
                                    key={task.id}
                                    task={task}
                                    workflow={workflow}
                                    onAfterSave={refresh}
                                >
                                    <TaskPreview
                                        task={task}
                                        parentId={workflow.id}
                                        previewText={''}
                                        user={getRelationship(jsonapi, 'uid', task)}
                                        isLinked={false}
                                    />
                                </CompleteSingleTaskModal>
                            )}
                        </TaskListPreview>
                    </WrapperInner>
                    : null
                }
            </Spin>
        </Wrapper>
    );
};

const mapStateToProps = (state, props) => {
    const playbookId = props.match.params.playbookId;
    const workflowType = props.match.params.type;
    const playbook = getPlaybook(state, playbookId);
    const workflow = getPlaybookWorkflows(state, playbookId)
        .find(workflow => workflow.type === 'task_workflow--' + workflowType)
    const currentUser = getCurrentUser(state);

    return {
        playbookId: playbookId,
        workflowType: workflowType,
        currentUser: currentUser,
        playbook: playbook,
        workflow: workflow,
        tasks: !workflow ? [] : getTaskWorkflowTasks(state, workflowType, workflow.id)
            .filter(w => w.relationships.uid.data.id === currentUser.id),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveResponse: response => dispatch(saveJsonApiResponse(response))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(setPageTitle(WorkflowCompleteTasksInline, 'Complete Workflow Tasks')));
