// @flow

import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Checkbox, Icon } from 'antd';
import { get, mergeEntityAttributes } from '../helpers';
import { createEntity, updateEntity, deleteEntity } from '../api/entity';
import EditEntityForm from './EditEntityForm';
import { DatePicker } from '../components/Ant';
import DelayedUploadDropArea from '../components/DelayedUploadDropArea';
import useFileUpload from '../api/useFileUpload';
import ThumbnailCroppedImage from '../components/Common/ThumbnailCroppedImage';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { AttachmentListFromFiles } from '../components/File/AttachmentList';
import RemovableOverlay from '../components/Presentation/RemovableOverlay';
import { Spacer } from '../components/Common';
import CkeditorAdmin from '../components/CkeditorAdmin';
import moment from 'moment';

const DateFormItemWrapper = styled(Form.Item)`
    @media (min-width: 576px) {
        float: right;
    }
`

type Props = {
    form: Object,
    entity: Object,
    onAfterSave?: Function,
    playbookId: String,
}

const EditPlaybookUpdateForm = ({ form, entity, playbookId, onAfterSave }: Props) => {
    const [htmlContent, setHtmlContent] = useState(get(entity, 'attributes.content.value'));
    const { isUploadInProgress: isUploadInProgressImage, handleUpload: handleUploadImage, uploadProgress: uploadProgressImage } = useFileUpload('');
    const { isUploadInProgress: isUploadInProgressAttachments, handleUpload: handleUploadAttachments, uploadProgress: uploadProgressAttachments } = useFileUpload('');

    // Images comes from the upload widget and playbookImage is the exsting
    // image if any.
    const [images, setImages] = useState([]);
    const [playbookImage, setPlaybookImage] = useState(entity.image);

    // Attachments comes from the widget and playbook attachments are the already
    // upload files.
    const [attachments, setAttachments] = useState([]);
    const [playbookAttachments, setPlaybookAttachments] = useState([]);

    useEffect(() => {
        setPlaybookImage(entity.image);
        setPlaybookAttachments(entity.attachments || []);
        setHtmlContent(get(entity, 'attributes.content.value'));

        setImages([]);
    }, [entity]);

    const resetForm = useCallback(() => {
        form.resetFields();
        setAttachments([]);
        setPlaybookAttachments([]);
        setImages([]);
        setHtmlContent('');
        setPlaybookImage(null);
    }, [form])

    const handleDelete = useCallback(() => {
        return deleteEntity(entity)
            .then(result => Promise.resolve({ text: 'Deleted', result: result }));
    }, [entity]);

    const handleSave = useCallback(result => {
        const attributes = {
            ...entity.attributes,
            ...result,
            date: result.date,
            content: {
                format: 'basic_html',
                value: htmlContent,
            },
        }

        if (entity.id) {
            let updatedEntity = mergeEntityAttributes(entity, attributes);
            // They removed the playbookImage and didn't upload any more images
            // which would have handled overwriting the image for us so we null
            // the image out here.
            if (playbookImage === null && images.length === 0) {
                updatedEntity.relationships.image.data = [];
            }

            // Filter the attachments if some have been removed.
            const attachmentIds = playbookAttachments.map(a => a.id);
            updatedEntity.relationships.attachments.data = updatedEntity.relationships.attachments.data.filter(a => attachmentIds.includes(a.id))

            return updateEntity(updatedEntity)
                .then(result => {
                    return Promise.all([
                        handleUploadImage(images, `/jsonapi/playbook_update/playbook_update/${updatedEntity.id}/image`),
                        handleUploadAttachments(attachments, `/jsonapi/playbook_update/playbook_update/${updatedEntity.id}/attachments`),
                    ]).then(uploadResult => {
                        resetForm();
                        return Promise.resolve({
                            text: updatedEntity.attributes.is_milestone ? 'Updated Milestone' : 'Updated',
                            result: result
                        });
                    })
                });
        }

        let relationships = {
            playbook: { type: 'playbook--playbook', id: playbookId }
        }
        return createEntity('playbook_update', attributes, relationships)
            .then(result => {
                return Promise.all([
                    handleUploadImage(images, `/jsonapi/playbook_update/playbook_update/${result.data.data.id}/image`),
                    handleUploadAttachments(attachments, `/jsonapi/playbook_update/playbook_update/${result.data.data.id}/attachments`),
                ]).then(uploadResult => {
                    resetForm();
                    return Promise.resolve({ text: 'Update created', result: result });
                })
            });
    }, [entity, htmlContent, playbookId, handleUploadImage, images, attachments, handleUploadAttachments, resetForm, playbookImage, playbookAttachments])

    return (
        <EditEntityForm
            form={form}
            handleSave={handleSave}
            onAfterSave={onAfterSave}
            handleDelete={handleDelete}
            showDelete={entity.id}
            submitButtonText={entity.id ? 'Save' : 'Add'}
        >

            <Row type="flex" justify="space-between" align="middle">
                <Col sm={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item label="Title">
                        {form.getFieldDecorator('title', {
                            rules: [{ required: true, message: 'Please input the title.' }],
                            initialValue: entity.attributes.title,
                        })(
                            <Input name="title" autoFocus />
                        )}
                    </Form.Item>

                </Col>
                <Col sm={{ span: 8 }} xs={{ span: 24 }}>
                    <DateFormItemWrapper label="Date">
                        {form.getFieldDecorator('date', {
                            rules: [{ required: true, message: 'Please select a date.' }],
                            initialValue: entity.attributes.date || moment().unix(),
                        })(<DatePicker
                            format={"DD/MM/YYYY"}
                        />)}

                    </DateFormItemWrapper>
                </Col>
            </Row>

            {entity.attributes.is_milestone ? null :
                <>
                    <Form.Item label="Content">
                        <CkeditorAdmin
                            value={htmlContent}
                            onChange={setHtmlContent}
                            allowToggle={false}
                        />
                    </Form.Item>

                    <Row type="flex" justify="space-between" align="top">
                        <Col sm={{ span: 11 }} xs={{ span: 24 }} style={{ marginTop: '16px' }}>
                            <h2>Image Upload</h2>
                            <DelayedUploadDropArea
                                filesToPreview={images}
                                previewStyle={{ marginTop: '16px' }}
                                onDrop={images => {
                                    setPlaybookImage(null);
                                    setImages(images);
                                }}
                                isUploadInProgress={isUploadInProgressImage}
                                uploadProgress={uploadProgressImage}
                                uploadInstructions={"Supports single image upload. PNG or JPG supported under 2MB."}
                                options={{
                                    multiple: false,
                                }}
                            />
                            {playbookImage ?
                                <Spacer>
                                    <RemovableOverlay
                                        onClick={e => {
                                            setPlaybookImage(null);
                                        }}
                                    >
                                        <ThumbnailCroppedImage
                                            src={playbookImage.links.thumbnail_cropped.href}
                                            alt="preview"
                                        />
                                    </RemovableOverlay>
                                </Spacer>
                                : null
                            }
                        </Col>

                        <Col sm={{ span: 11, offset: 2 }} xs={{ span: 24 }} style={{ marginTop: '16px' }}>
                            <h2>Document Upload</h2>
                            <DelayedUploadDropArea
                                key={`upload-${get(entity, 'attributes.changed', 'new')}`}
                                filesToPreview={attachments}
                                onDrop={setAttachments}
                                isUploadInProgress={isUploadInProgressAttachments}
                                uploadProgress={uploadProgressAttachments}
                                uploadInstructions={"Uploading supporting documents including PDF, XLS, DOCX"}
                                preview="list"
                            />
                            {playbookAttachments.length ?
                                <div style={{ marginTop: '16px' }}>
                                    <AttachmentListFromFiles
                                        isLoading={false}
                                        attachments={playbookAttachments}
                                        getItemActions={item => {
                                            return [
                                                <Icon
                                                    type="close-circle"
                                                    className="hoverable"
                                                    onClick={() => {
                                                        setPlaybookAttachments(currentAttachments => currentAttachments.filter(a => a.id !== item.file.id));
                                                    }}
                                                />
                                            ];
                                        }}
                                    />
                                </div> : null}
                        </Col>
                    </Row>

                    <Form.Item>
                        {form.getFieldDecorator('pinned', {
                            initialValue: !!entity.attributes.pinned,
                            valuePropName: 'checked'
                        })(
                            <Checkbox
                                key="1"
                                name="pinned"
                                value="1"
                            >Pin to top of timeline</Checkbox>
                        )}
                    </Form.Item>
                </>
            }
        </EditEntityForm>
    );
};

export default Form.create({ name: 'edit_playbook_update_form' })(EditPlaybookUpdateForm);
export { EditPlaybookUpdateForm as _EditPlaybookUpdateForm }
