import React from 'react';
import { Button, Dropdown, Icon } from 'antd';

const DropButton = ({ isDisabled, buttonText, menu, icon = 'ellipsis', type="default"}) => {
    return (
        <Button.Group>
            <Button type={type} disabled={isDisabled}>
                {buttonText}
            </Button>
            <Dropdown overlay={menu}>
                <Button>
                    <Icon type={icon} />
                </Button>
            </Dropdown>
        </Button.Group>
    );
};

export default DropButton;
