// @flow

import React from 'react';
import Avatar from '../Avatar';
import styled from 'styled-components';
import Media from 'react-media';
import TaskCheck from './TaskCheck';
import { Task, User } from '../../types';
import { textTrim, tokenise, get } from '../../helpers';
import TaskLink from './TaskLink';
import CommentCount from '../Comments/CommentCount'

const TaskPreviewWrapper = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.15), 0 1px 2px 0 rgba(0,0,0,0.05);
    background: #FFF;
    border-radius: 4px;

    h4 {
        font-weight: 500;
        color: rgba(0,0,0,0.8);
        margin: 0;
    }

    .preview-text {
        color: rgba(0,0,0,0.8);
        font-size: 14px;
        margin: 0;
    }

    .task-title-wrapper {
        width: 220px;
        flex-shrink: 0;

        @media (max-width: 759px) {
            width: auto;
            max-width: 84%;
        }
    }

    &:hover {
        .delete-wrapper {
            display: block;
        }
    }
`

const TaskPreviewWrapperInfo = styled.div`
    padding: 20px 20px 20px 0;
    display: flex;
    align-items: center;
    width: 100%;
`

const Actions = styled.div`
    margin-left: auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
`

type Props = {
    task: Task,
    user: User,
    previewText: string,
    size?: string,
    parentId: string,
    saveTask?: Function,
    isLinked?: bool,
}

const TaskPreview = ({ task, user, previewText, size, parentId, saveTask, isLinked = true }: Props) => {

    const renderWithLink = (children, withLink) => {
        if (!withLink) {
            return children;
        }
        return (
            <TaskLink
                task={task}
                parentId={parentId}
                style={{ width: '100%' }}
            >
                {children}
            </TaskLink>
        )
    }

    return (
        <TaskPreviewWrapper
            className={`task-preview-wrapper ${tokenise(task.attributes.data.type)}`}
            style={{ ...(size === "small" ? { background: 'none', boxShadow: 'none', } : {}) }}
        >
            <TaskCheck
                task={task}
                style={{ paddingLeft: '20px' }}
                onClick={saveTask}
                uiContext="Preview Inline"
                parentId={parentId}
            />

            {renderWithLink(
                <TaskPreviewWrapperInfo isLinked={isLinked}>
                    <div className="task-title-wrapper">
                        <h4 style={{ marginLeft: '16px' }}>{textTrim(task.attributes.title, 70)}{task.attributes.data.is_required === '1' ? ' *' : ''}</h4>
                    </div>
                    <Media query="(min-width: 1000px)">
                        {size !== 'small' && previewText ? <p className="preview-text" style={{ marginLeft: '20px' }}>{previewText}</p> : null}
                    </Media>
                </TaskPreviewWrapperInfo>,
                isLinked
            )}

            <Actions>
                <CommentCount count={get(task, 'attributes.comments.comment_count', 0)} />
                {user ? <Avatar user={user} /> : null}
            </Actions>

        </TaskPreviewWrapper>
    );
};


export default TaskPreview;
