// @flow

import React from 'react';
import { Icon } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`
let defaultIconStyle = {
    marginRight: '10px',
    color: '#DDD',
    fontSize: '22px',
}

type Props = {
    date: string,
    iconStyle?: Object,
    onClick?: Function,
}

const ViewDate = ({ date, iconStyle = {}, onClick = null, icon="calendar" }: Props) => {
    if (!date) {
        return null;
    }
    return (
        <Wrapper onClick={onClick} className={onClick ? 'hoverable' : ''}>
            <Icon type={icon} style={{
                ...defaultIconStyle,
                ...iconStyle,
            }} />
            {dayjs(date).format('DD/MM/YY')}
        </Wrapper>
    );
};

export default ViewDate;
