import ReactGA from "react-ga";
import getConfig from '../instance-config';

let initialised = false;
export function initialiseGa() {
      if (getConfig().gaCode.length !== 0) {
        initialised = true;

        ReactGA.initialize([{
          trackingId: getConfig().gaCode,
          gaOptions: { name: 'instance' }
        }, {
          // Industry Platform (Global)
          trackingId: 'UA-43016849-20',
          gaOptions: { name: 'global' }
        }], {
          alwaysSendToDefaultTracker: false,
        });
      }

      return initialised;
}

/**
 * 
 * @param {string} category
 *   Context of where it happend. E.g. Competition or Challenge
 * @param {string} action
 *   The action such as "Added new collaborator" or "Created new challenge"
 * @param {string} label
 *   UI Context. E.g. Sidebar or Inline.
 */
export function trackEvent(category, action, label) {
    if (initialised === false) {
        return;
    }

    ReactGA.event({
        category: category, // Competition
        action: action, // Added collaborator
        label: label, // Sidebar or Inline
        dimension1: getConfig().siteName,
    }, ['instance', 'global']);
}
