// @flow

import React, { Component, useCallback } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Spin, Divider, Checkbox, Popconfirm } from 'antd';
import { message } from '../components/Ant';
import UploadDropArea from '../components/UploadDropArea';
import RemovableImage from '../components/RemovableImage';
import { getRelationship } from '../selectors';
import { User } from '../types';
import { mergeEntityAttributes, tokenise } from '../helpers';
import { setIsLoggedIn, loadUserRoles } from '../actions';
import { clearCurrentToken } from '../helpers/jwt';
import { hasPermission } from '../helpers/HasPermission';
import { getUserPermissions, getCurrentUser } from '../selectors';
import FormActions from '../components/Form/FormActions';
import getConfig from '../instance-config';
import useFileUpload from '../api/useFileUpload';

type Props = {
    user: User,
    currentUser: User,
    image?: Object,
    form: Object,
    save: Function,
    setIsLoggedIn: Function,
    doLoadAdminRoles: Function,
    allPermissions: Array<string>,
    adminRoles: Array<Object>,
    afterSave?: Function,
}

type State = {
    isLoading: boolean,
}

const UserImageUpload = ({ user, afterSave }) => {
    const { handleUpload, isUploadInProgress, uploadProgress } = useFileUpload(`/jsonapi/user/user/${user.id}/field_picture`)

    const doHandleUpload = useCallback(files => {
        handleUpload(files)
            .then(result => {
                message.success('Avatar has been uploaded');
                if (typeof afterSave === 'function') {
                    afterSave(true);
                }
            })
    }, [handleUpload, afterSave]);

    return (
        <UploadDropArea
            handleUpload={doHandleUpload}
            uploadInProgress={isUploadInProgress}
            uploadProgress={uploadProgress}
            uploadInstructions={"Supports single image upload. PNG or JPG supported under 2MB."}
            options={{ multiple: false }}
        />
    );
}

class EditUserForm extends Component<Props, State> {

    constructor() {
        super();
        this.state = {
            isLoading: false,
        }
        this.onSave = this.onSave.bind(this);
        this.onArchive = this.onArchive.bind(this);
        this.isArchived = this.isArchived.bind(this);
        this.requiresCurrentPassword = this.requiresCurrentPassword.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
    }

    componentDidMount() {
        if (hasPermission(this.props.allPermissions, 'administer permissions')) {
            this.props.doLoadAdminRoles();
        }
    }

    onSave: Function
    onSave(e: Object) {
        e.preventDefault();

        this.props.form.validateFields()
            .then(result => {
                // Merge new values into the user object and save it.
                let newUserValues = {
                    field_full_name: result.fullName,
                    name: result.email,
                    mail: result.email,
                    field_position: result.position,
                    pass: {}
                };

                // Only send the password if we're changing it.
                if (result.password) {
                    newUserValues.pass = {
                        existing: result.current_password,
                        value: result.password,
                    }
                }
                else {
                    delete newUserValues.pass;
                }

                let updatedUser = mergeEntityAttributes(this.props.user, newUserValues);
                if (typeof result.roles !== 'undefined') {
                    // Reset all the roles, then add the new roles in.
                    updatedUser.relationships.roles.data = result.roles.map(role => {
                        return {
                            id: role,
                            type: 'user_role--user_role',
                        }
                    })
                }

                // Persist it.
                this.setState({ isLoading: true });
                this.props.save(updatedUser)
                    .then(updateResult => {
                        this.setState({ isLoading: false }, () => {
                            if (typeof this.props.afterSave === 'function') {
                                this.props.afterSave();
                            }

                            // They didn't change their password.
                            if (typeof result.password === 'undefined' || this.props.currentUser.id !== updatedUser.id) {
                                message.success('User updated');
                            }
                            else {
                                // We don't need to call logout on the session because that was invalidated by the password
                                // change, we just clear the token and send them to the login page.
                                clearCurrentToken();
                                this.props.setIsLoggedIn(false);
                                message.success('User account updated. You must login again to confirm your new password');
                            }
                        });
                    })
                    .catch(error => this.setState({ isLoading: false }, () => message.warning(error)));
            })
            .catch(errors => {
                console.log(errors);
                message.warning('Please complete all fields');
            })
    }

    onArchive: Function
    onArchive() {
        let updatedUser = mergeEntityAttributes(this.props.user, {
            status: this.isArchived() ? 1 : 0,
        });
        this.props.save(updatedUser)
            .then(result => {
                message.success('User has been updated');

                if (typeof this.props.afterSave === 'function') {
                    this.props.afterSave();
                }
            })
    }

    isArchived: Function
    isArchived() {
        return this.props.user.attributes.status === false;
    }

    requiresCurrentPassword: Function
    requiresCurrentPassword(rule: any, value: any, callback: Function) {
        if (this.props.form.getFieldValue('password') && !value) {
            callback('You must provide your current password to change your password.');
            return;
        }

        callback();
    }

    handleRemoveImage: Function
    handleRemoveImage() {
        let updatedUser = JSON.parse(JSON.stringify(this.props.user))
        updatedUser.relationships.field_picture.data = [];

        this.setState({ isLoading: true });
        this.props.save(updatedUser)
            .then(result => {
                this.setState({ isLoading: false });
                message.success('Avatar has been removed');

                if (typeof this.props.afterSave === 'function') {
                    this.props.afterSave(true);
                }
            })
            .catch(error => this.setState({ isLoading: false }, () => message.warning(error)));
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let { user, currentUser } = this.props;

        return (
            <Spin spinning={this.state.isLoading}>
                <Form onSubmit={this.onSave}>
                    <Form.Item label="Avatar Image">
                        <div style={{ marginBottom: '24px' }} className="image-preview">
                            <RemovableImage
                                url={this.props.image && this.props.image.links.api_image_large ? this.props.image.links.api_image_large.href : null}
                                alt="User Avatar"
                                style={{ maxWidth: '200px' }}
                                handleRemove={this.handleRemoveImage}
                            />
                        </div>
                        <UserImageUpload
                            user={user}
                            afterSave={this.props.afterSave}
                        />
                    </Form.Item>
                    <Form.Item label="Full Name">
                        {getFieldDecorator('fullName', {
                            rules: [{ required: true, message: 'Please input your full name.' }],
                            initialValue: user.attributes.field_full_name,
                        })(
                            <Input name="fullName" />
                        )}
                    </Form.Item>

                    <Form.Item label="Position">
                        {getFieldDecorator('position', {
                            rules: [{ required: true, message: 'Please input your position.' }],
                            initialValue: user.attributes.field_position,
                        })(
                            <Input name="position" />
                        )}
                    </Form.Item>

                    <Form.Item label="Email">
                        {getFieldDecorator('email', {
                            rules: [{ type: 'email', required: true, message: 'Please input a valid email.' }],
                            initialValue: user.attributes.mail,
                        })(
                            <Input name="email" autoComplete="email" />
                        )}
                    </Form.Item>

                    <Divider />

                    {getConfig().sso_enabled === false ?
                        <>
                            <h2>Change Password</h2>

                            {currentUser.id === user.id ?
                                <Form.Item label="Current Password" help="Only required if you want to change your password">
                                    {getFieldDecorator('current_password', {
                                        rules: [{
                                            required: false, message: 'Please input your password!',
                                        }, {
                                            validator: this.requiresCurrentPassword,
                                        }],
                                    })(
                                        <Input name="currentPassword" type="password" autoComplete="current-password" />
                                    )}
                                </Form.Item>
                                : null}

                            <Form.Item label="New Password">
                                {getFieldDecorator('password', {
                                    rules: [{
                                        required: false, message: 'Please input your password!',
                                    }],
                                })(
                                    <Input name="newPassword" type="password" autoComplete="new-password" />
                                )}
                            </Form.Item>
                        </>
                        : null}

                    {/* They can't tweak their own roles. */}
                    {currentUser.id !== user.id && hasPermission(this.props.allPermissions, "administer permissions") ?
                        <div>
                            <Divider />
                            <h2>Roles</h2>

                            <Form.Item label="Change the user roles">
                                {getFieldDecorator('roles', {
                                    initialValue: user.relationships.roles.data.map(role => role.id),
                                })(
                                    <Checkbox.Group>
                                        {
                                            this.props.adminRoles.map(role => {
                                                return (
                                                    <Checkbox
                                                        key={role.id}
                                                        value={role.id}
                                                        className={tokenise(role.attributes.label)}
                                                    >{role.attributes.label}</Checkbox>
                                                );
                                            })
                                        }
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                        </div>
                        : null}
                    <br />

                    <FormActions>
                        {currentUser.id !== user.id && hasPermission(this.props.allPermissions, "administer permissions")
                            ? <Popconfirm
                                title={this.isArchived() ? "Unarchive this user?" : "Are you sure you want to archive this user?"}
                                onConfirm={this.onArchive}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="default">{this.isArchived() ? 'Archived' : 'Archive'}</Button>
                            </Popconfirm>
                            : null
                        }

                        <Button type="primary" htmlType="submit">Save</Button>
                    </FormActions>
                </Form>
            </Spin>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { user } = props;
    let includedRoles = [
        'innovation_manager',
        'organisation_user',
    ];
    const currentUser = getCurrentUser(state);
    return {
        adminRoles: typeof state.jsonApi['user_role--user_role'] !== 'undefined' ?
            Object.values(state.jsonApi['user_role--user_role']).filter((role: Object) => includedRoles.includes(role.attributes.drupal_internal__id))
            : [],
        allPermissions: getUserPermissions(state, currentUser),
        currentUser,
        image: getRelationship(state, 'field_picture', user || currentUser),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setIsLoggedIn: (...args) => dispatch(setIsLoggedIn(...args)),
        doLoadAdminRoles: () => dispatch(loadUserRoles()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'user_login' })(EditUserForm));
export { EditUserForm as _EditUserForm }