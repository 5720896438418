import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Box from '../Common/Box.js';
import PlaybookImage from '../Playbook/PlaybookImage';
import usePlaybook from '../../api/usePlaybook';
import debounce from 'debounce';
import get from '../../helpers/get.js';
import CompetitionRunPlaybookDescription from './CompetitionRunPlaybookDescription.js';

const Overview = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    margin-top: 32px;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`

const OverviewImageWrapper = styled.div`
    margin-right: 32px;
    overflow: hidden;
    border-radius: 4px;
    height: ${props => props.calculatedHeight + 'px'};
    max-height: 300px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @media (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 32px;
        width: 100%;
        height: 300px;
    }
`

const OverviewImage = styled(PlaybookImage)`
    width: 100%;
    height: 100%; 
    border-radius: 4px;
    object-fit: cover;

    @media (max-width: 600px) {
        width: 100%;
        height: auto;
    }
`

const PlaybookOverview = ({ playbookId }) => {
    const [height, setHeight] = useState(200);
    const textRef = useRef({ current: {} });
    const { playbook } = usePlaybook(playbookId);

    useEffect(() => {
        setHeight(textRef.current.clientHeight);

        const callback = debounce(() => {
            setHeight(textRef.current.clientHeight);
        }, 300);

        window.addEventListener('resize', callback)
        return () => {
            window.removeEventListener('resize', callback);
        }

    }, [textRef.current.clientHeight]);

    if (!playbook || get(playbook, 'attributes.description.processed', '').trim().length === 0) {
        return null;
    }

    return (
        <div>
            <Overview>
                <OverviewImageWrapper calculatedHeight={height}>
                    <OverviewImage
                        playbookId={playbookId}
                        fallbackHeight={250}
                        fallbackWidth={355}
                    />
                </OverviewImageWrapper>

                <div ref={textRef} style={{ width: '100%' }}>
                    <Box>
                        <CompetitionRunPlaybookDescription playbookId={playbookId} />
                    </Box>
                </div>

            </Overview>
        </div>
    )
}

export default PlaybookOverview;