// @flow

import { withToken, jsonApi } from "../actions";
import JsonApiRequestBuilder from "../helpers/JsonApiRequestBuilder";

export function createEntity(entityTypeId: string, attributes: Object, relationships: Object = {}) {
    let request = new JsonApiRequestBuilder(`${entityTypeId}--${entityTypeId}`);
    Object.keys(attributes).forEach(key => request.attribute(key, attributes[key]));
    Object.keys(relationships).forEach(key => request.relationship(key, relationships[key].type, relationships[key].id));

    return withToken(token => {
        return jsonApi()
            .post(`/jsonapi/${entityTypeId}/${entityTypeId}`, request.build(), {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function updateEntity(updatedEntity: Object) {
    return withToken(token => {
        return jsonApi()
            .patch(`${updatedEntity.links.self.href}`, { data: updatedEntity }, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function deleteEntity(entity: Object) {
    return withToken(token => {
        return jsonApi()
            .delete(`${entity.links.self.href}`, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}
