// @flow

import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown, Menu, Icon, Popconfirm, message } from 'antd';
import { deleteEntity } from '../../api/entity';
import { getEntityTypeId } from '../../helpers/getTaskWorkflowBundle';
import { extractErrorMessage } from '../../api';

type Props = {
    entity: Object,
    doDeleteEntity: Function,
    history: Object,
    editUrl: string,
}

const EntityActions = ({ entity, editUrl, doDeleteEntity, history }: Props) => {

    const handleDelete = useCallback(() => {
        doDeleteEntity(entity)
            .then(result => message.success('Deleted'))
            .catch(error => message.warning(extractErrorMessage(error)));
    }, [entity, doDeleteEntity]);

    const menu = (
        <Menu onClick={() => { }}>
            <Menu.Item key="2">
                <Icon type="delete" />
                <Popconfirm
                    title={"Are you sure you?"}
                    onConfirm={handleDelete}
                    okText="Yes"
                    cancelText="No"
                >
                    Delete
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown.Button onClick={() => history.push(editUrl)} overlay={menu}>
            Edit
        </Dropdown.Button>
    );
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        doDeleteEntity: entity => {
            return deleteEntity(entity)
                .then(result => {
                    dispatch({ type: 'JSONAPI_DELETE', entity_type_id: getEntityTypeId(entity.type), id: entity.id })
                    return Promise.resolve(result);
                })
                .catch(error => Promise.reject(error))
        },
    }
}

export default connect(null, mapDispatchToProps)(withRouter(EntityActions));
