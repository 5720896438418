// @flow

import React from 'react';
import styled from 'styled-components';
import Avatar from '../Avatar';
import TaskCheck from '../Task/TaskCheck';
import ViewDate from '../ViewDate';
import { Task, User } from '../../types';

const Container = styled.div`
    background: #FFF;
    border-radius: 4px;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1);
`

const Top = styled.div`
    border-bottom: 1px solid #EEE;
    display: flex;
    justify-content: space-between;
    padding: 20px;
`

const TopRight = styled.div`
    display: flex;
    align-items: center;

    > * {
        margin-right: 16px;
    }
`

const DateWrapper = styled.div`
    @media (max-width: 600px) {
        display: none;
    }
`

const Content = styled.div`
    padding: 20px;
`

type Props = {
    task: Task,
    taskOwner: User,
    fullText: string,
}

const TaskFullView = ({ task, taskOwner, fullText }: Props) => {
    return (
        <Container>
            <Top>
                <h2 style={{ fontSize: '14px', margin: '0' }}>{task.attributes.title}</h2>

                <TopRight>
                    <DateWrapper>
                        <ViewDate date={task.attributes.is_complete_timestamp} />
                    </DateWrapper>
                    <TaskCheck
                        task={task}
                        uiContext="Full View Inline"
                    />
                    <Avatar user={taskOwner} />
                </TopRight>
            </Top>

            <Content>
                {typeof fullText === 'string' ? <div dangerouslySetInnerHTML={{ __html: fullText }} /> : fullText}
            </Content>
        </Container>
    );
};

export default TaskFullView;
