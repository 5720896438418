import JsonApiRequestBuilder from "../helpers/JsonApiRequestBuilder";
import { withToken, jsonApi } from "../actions";

export function createHelpItem(title, description, url, is_published) {
    let request = new JsonApiRequestBuilder('help_item--help_item');
    request.attribute('title', title);
    request.attribute('description', description);
    request.attribute('url', url);
    request.attribute('is_published', is_published);

    return withToken(token => {
        return jsonApi()
            .post('/jsonapi/help_item/help_item', request.build(), {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function updateHelpItem(updatedEntity) {
    return withToken(token => {
        return jsonApi()
            .patch(`${updatedEntity.links.self.href}`, { data: updatedEntity }, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function deleteHelpItem(entity) {
    return withToken(token => {
        return jsonApi()
            .delete(`${entity.links.self.href}`, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}
