// @flow

import * as React from 'react';
import styled from 'styled-components';
import Task from './Task';

const TaskListWrapper = styled.div`
    .task-preview-wrapper {
        margin-bottom: 8px;
    }

    a {
        display: block;
    }
`

type Props = {
    tasks: Array<Task>,
    parentType: string,
    parentId: string,
    render?: Function,
    children?: React.Node|Function,
}

const TaskListPreview = ({ tasks, parentType, parentId, children }: Props) => {

    return (
        <TaskListWrapper>
            {tasks.map(typeof children === 'function' ? children : (task, i) => (
                <Task
                    key={i}
                    task={task}
                    isPreview={true}
                    parentId={parentId}
                />
            ))}
        </TaskListWrapper>
    );
};

export default TaskListPreview;
