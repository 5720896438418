import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Benton Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
    background: ${props => props.theme.lighterGrey} !important;
    height: 100%;
  }

  blockquote {
    font-size: 1.5em;
    width: 60%;
    border-left: 8px solid ${props => props.theme.primaryColor} !important;
    margin: 16px 0;
    padding: 1.2em 30px 1.2em 75px !important;
    position: relative;
    background: #EFEFEF;
    line-height: 1;

    &::before {
      font-family: Arial, Helvetica, sans-serif;
      content: "\\201C";
      color: #AAA;
      font-size: 4em;
      position: absolute;
      left: 20px;
      top: 5px;
    }

    &::after {
      content: "";
    }

    p {
      margin: 0;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.darkGrey};
  }

  p {
    color: ${props => props.theme.darkGrey};
  }

  a {
    color: ${props => props.theme.blueGrey};

    &:hover {
      color: ${props => props.theme.greyBlack};
    }
  }

  h2 {
    margin-bottom: 16px;
  }

  .default-color {
    color: ${props => props.theme.darkGrey} !important;
    > * {
      color: ${props => props.theme.darkGrey} !important;
    }
  }

  .ant-avatar-string {
    cursor: default;
  }

  .hoverable {
    &:hover, .ant-avatar-string {
      cursor: pointer;
    }
  }

  .link {
    color: ${props => props.theme.blueGrey};

    &:hover {
      cursor: pointer;
      color: ${props => props.theme.greyBlack};
      text-decoration: underline;
    }
  }

  .primary-color {
    color: ${props => props.theme.primaryColor};
  }

  .icon-large {
    font-size: 22px;
  }

  .icon-small {
    font-size: 11px;
  }

  .drop-over-downward,
  .drop-over-upward {
    border-top: 2px dotted blue;
  }

  .ck-editor__editable_inline {
    min-height: 300px;
  }

  .easyimage img {
    max-width: 100%;
  }

  .cke_top {
    position: sticky !important;
    top: 0 !important;
  }

  // Ant modal fix.
  .ant-modal-header {
    padding-right: 40px !important;
  }

  // Moving the help icon up 
  .slaask-button {
    bottom: 80px !important;
  }
`

export default GlobalStyles;
