// @flow

import React from 'react';
import styled from 'styled-components';
import ParticipantCountStat from './ParticipantCountStat';
import CountryCountStat from './CountryCountStat';
import SubmissionCountStat from './SubmissionCountStat';
import NewParticipantCountStat from './NewParticipantCountStat';
import { TaskWorkflow } from '../../types';

const StatsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
        margin-bottom: 16px;
    }
`

type Props = {
    competition: TaskWorkflow,
}

const CompetitionStatsBlock = ({ competition }: Props) => {
    return (
        <StatsWrapper>
            <ParticipantCountStat competition={competition} />
            <CountryCountStat competition={competition} />
            <SubmissionCountStat competition={competition} />
            <NewParticipantCountStat competition={competition} />
        </StatsWrapper>
    );
};

export default CompetitionStatsBlock;
