import React, { Component, useEffect } from 'react';
import getConfig from '../instance-config';

export function useSetPageTitle(title) {
    useEffect(() => {
        document.title = generateTitle(title, {});
    }, [title])
}

export default function setPageTitle(WrappedComponent, title) {
    return class extends Component {

        componentDidMount() {
            document.title = generateTitle(title, this.props);
        }

        componentDidUpdate() {
            document.title = generateTitle(title, this.props);
        }

        render() {
            return <WrappedComponent {...this.props} />
        }
    }
}

function generateTitle(title, props) {
    let titleString = typeof title === 'function' ? title(props) : title;
    return titleString + " | " + getConfig().siteName;
}
