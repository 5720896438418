const mergeTaskData = (task, newData) => {
    let updatedTask = JSON.parse(JSON.stringify(task))
    updatedTask.attributes.data = {
        ...updatedTask.attributes.data,
        ...newData,
    }
    return updatedTask;
}

export default mergeTaskData;
