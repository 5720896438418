import React from 'react';
import { SolveChallengeModal } from '../Challenge/SolveChallenge';
import usePlaybook from '../../api/usePlaybook';
import DropButton from '../Common/DropButton';
import { hasPermission } from '../../helpers/HasPermission';
import withPermissions from '../../hoc/withPermissions';

const canBuildOpportunity = challenge => {
    if (challenge.attributes.completed_tasks_count !== challenge.attributes.tasks_count) {
        return false;
    }

    if (challenge.attributes.title.trim().length === 0) {
        return false;
    }

    // Already been built.
    if (challenge.attributes.workflow_status !== 'draft') {
        return false;
    }

    return true;
}

const OpportunityActions = ({ challenge, menu, playbookId, permissions }) => {
    const { refresh } = usePlaybook(playbookId);

    return <DropButton
        type="primary"
        isDisabled={!canBuildOpportunity(challenge) || !hasPermission(permissions, 'forward incomplete task_workflow')}
        buttonText={<SolveChallengeModal
            challenge={challenge}
            afterComplete={refresh}
        >
            {(showModal) => (
                <span onClick={() => showModal(challenge)}>Build It</span>
            )}
        </SolveChallengeModal>}
        menu={menu}
    />
};

export default withPermissions(OpportunityActions);
export { canBuildOpportunity, OpportunityActions as _OpportunityActions }
