// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Box } from '../../components/Common';
import EditOrganisationForm from '../../Forms/EditOrganisationForm';
import { withRouter } from 'react-router-dom';
import useJsonApiGet from '../../api/useJsonApiGet';
import setPageTitle from '../../hoc/setPageTitle';
import { saveJsonApiResponse } from '../../api';
import { IMAGE_LARGE_CONSUMER_ID } from '../../consumers';
import ContentWrapper from '../../layouts/ContentWrapper';
import { get } from '../../helpers';

type Props = {
    match: Object,
    doSaveJsonApiResponse: Function,
}

const EditOrganisation = ({ match, doSaveJsonApiResponse }: Props) => {
    const { response, refresh } = useJsonApiGet(`/jsonapi/organisation/organisation/${match.params.id}?include=logo&consumerId=${IMAGE_LARGE_CONSUMER_ID}`, doSaveJsonApiResponse)
    const organisation = response.data ? response.data.data : null;
    const orgName = get(organisation, 'attributes.name');
    return (
        <ContentWrapper>
            {orgName ? <h1>{orgName}</h1> : null}
            <Box style={{ maxWidth: '800px' }}>
                {organisation ?
                    <EditOrganisationForm
                        organisation={organisation}
                        onAfterUpload={refresh}
                    />
                    : null}
            </Box>
        </ContentWrapper>
    );
};

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        doSaveJsonApiResponse: (response) => dispatch(saveJsonApiResponse(response)),
    }
}

export default setPageTitle(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditOrganisation)), 'Edit Organisation');
