import React, { useState } from 'react';
import { Progress } from 'antd';
import { message } from './Ant';

const ProgressWithTimer = ({ minLoadTime = 2000, hasStarted, actionCallback, afterComplete, loadingText, children }) => {
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const [isTriggered, setIsTriggered] = useState(false);

    let clearIntervalCallback = null;
    if (!hasStarted) {
        return children;
    }

    if (!isTriggered) {
        setIsTriggered(() => true);

        let timeStarted = new Date().getTime();
        setLoadingPercentage(0);
        let increment = minLoadTime / 100;
        clearIntervalCallback = setInterval(() => {
            if (loadingPercentage >= 100) {
                clearInterval(clearIntervalCallback);
            }
            else {
                setLoadingPercentage(loadingPercentage => loadingPercentage + 1);
            }
        }, increment);
        
        actionCallback()
            .then(result => {
                let timeWaited = new Date().getTime() - timeStarted;
                setTimeout(() => {
                    clearInterval(clearIntervalCallback);
                    afterComplete(result);
                }, minLoadTime - timeWaited);
            })
            .catch (error => {
                console.log(error);
                message.error(error);
            })
    }

    return (
        <div style={{ textAlign: 'center' }}>
            <h3 style={{ marginBottom: '24px' }}>{loadingText}</h3>
            <Progress type="circle" percent={loadingPercentage} />
        </div>
    );
};

export default ProgressWithTimer;
