import React from 'react';
import KanbanColumn from './KanbanColumn';
import KanbanMenu from './KanbanMenu';
import styled from 'styled-components';
import ReorderableList from './ReorderableList';

const ColoumnWrapper = styled.div`
    display: flex;
`

const KanbanBoard = ({ updateColumnWorkflows, columns, columnData }) => {
    return (
        <>
            <KanbanMenu columns={columns} />
            <ColoumnWrapper>
                {columns.map(column => (
                    <KanbanColumn
                        id={column.id}
                        key={column.id}
                        column={column}
                        top={column.placeholderTop}
                    >
                        {column.placeholder ? column.placeholder : null}

                        <ReorderableList
                            allWorkflows={columnData[column.id]}
                            column={column}
                        />
                    </KanbanColumn>
                ))}
            </ColoumnWrapper>
        </>
    )
};

export default KanbanBoard;
