function getPhaseFromWorkflow(workflow) {
    switch (workflow.attributes.workflow_status) {
        case 'draft':
            return 'opportunity';

        case 'build':
            return 'build';

        case 'ready_to_publish':
        case 'published':
            return 'live';
        case 'complete':
            return 'results';

        default:
            return 'opportunity'
    }
}

export {
    getPhaseFromWorkflow
}
