import React, { useState } from 'react';
import Ckeditor from './Ckeditor';
import { Input } from 'antd';

const CkeditorAdmin = ({ value, onChange, allowToggle = true }) => {
    const [isUsingEditor, setIsUsingEditor] = useState(true);

    return (
        <>
            {isUsingEditor ?
                <Ckeditor
                    data={value}
                    onChange={event => {
                        const html = event.editor.getData();
                        onChange(html);
                    }}
                />
                :
                <Input.TextArea
                    value={value}
                    rows={11}
                    onChange={e => onChange(e.target.value)}
                />
            }
            {allowToggle ?
                <div style={{ textAlign: 'right' }}>
                    <span className="link" onClick={() => setIsUsingEditor(e => !e)}>{isUsingEditor ? 'Edit Html' : 'Use Editor'}</span>
                </div>
                : null
            }
        </>
    )
};

export default CkeditorAdmin;
