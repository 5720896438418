import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

const Card = styled.div`
    width: 190px;
    text-align: center;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
`

const Image = styled.img`
    width: 150px;
    height: auto;
`
const ImageWrapper = styled.div`
    margin: 4px auto 24px auto;
    height: 104px;
    width: 104px;
    border-radius: 100%;
    overflow: hidden;
    background: #000;
    display: flex;
    align-items: center;
`
const ImagePlaceHolder = styled(ImageWrapper)`
    border: 1px solid #DDD;
    background: ${props => props.theme.lighterGrey};
    display: flex;
    align-items: center;
    justify-content: center;
`

const Name = styled.h3``



const UserCard = ({ name, details, url, imgSource }) => {
    return (
        <Card>    
            { imgSource ? <ImageWrapper>
                <Image src={imgSource} />
            </ImageWrapper>
                : <ImagePlaceHolder>
                    <Icon type="user" style={{ fontSize: '42px'}} />
                </ImagePlaceHolder>
            }
    
            <a href={url} target="_blank" rel="noopener noreferrer" style={{ display: 'block' }}>
                <Name>{name}</Name>
            </a>

            {details}
        </Card>
    );
}
export default UserCard;