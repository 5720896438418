export default function get(object, path, defaultValue = undefined) {
    if (typeof object === 'undefined' || object === null) {
        return defaultValue;
    }

    let parts = path.split('.');
    let value = object[parts[0]];
    for (let i = 1; i < parts.length; i++) {
        value = getSingleValue(value, parts[i]);

        if (value === undefined) {
            return defaultValue;
        }
    };
    return value;
}

function getSingleValue(object, key) {
    if (typeof object === 'undefined' || object === null || typeof object[key] === 'undefined') {
        return undefined;
    }
    return object[key];
}
