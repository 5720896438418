import React from 'react';
import styled from 'styled-components';
import CheckIcon from '../CheckIcon';
import TaskLink from '../Task/TaskLink';
import useJsonApiGet from '../../api/useJsonApiGet';
import { getRelationship } from '../../selectors';
import { Spin } from 'antd';
import TaskWorkflowLink from '../TaskWorkflow/TaskWorkflowLink';
import { textTrim } from '../../helpers';
import { getPhaseFromWorkflow } from '../../helpers/getPhase';

const Wrapper = styled.div`
    background: #FFF;
    padding: 8px;
    border-radius: 4px;
`

const WorkflowWrapper = styled.div`
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #D9D9D9;

    img {
        border-radius: 6px;
    }

    &:last-child {
        border: none;
    }
`

const WorkflowTitle = styled.h3`
    font-size: 14px;
    font-weight: bold;
`

const TaskMiniWrapper = styled.div`
    border: 1px solid #E8E8E8;
    border-radius: 6px;
    height: 52px;
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 4px;

    p {
        color: rgba(0,0,0,0.75);
        font-size: 13px;
        margin: 0 0 0 8px;
    }
`

const MoreTasksText = styled.div`
    font-weight: bold;
    text-align: center;
    margin-top: 8px;
    font-size: 13px;
`

const ImagePlaceholder = styled.div`
    height: 50px;
    width: 50px;
    background: #EEE;
    flex-shrink: 0;
    margin-right: 16px;
    border-radius: 6px;
`

const TaskMiniPreview = ({ task, playbookId, phase }) => {
    return (
        <TaskLink
            task={task}
            playbookId={playbookId}
            phase={phase}
            style={{ display: 'block' }}
        >
            <TaskMiniWrapper>
                <CheckIcon
                    color={'#BBB'}
                    wrapperStyles={{
                        height: '16px',
                        width: '16px',
                        flexBasis: '16px',
                        borderWidth: '1px',
                    }}
                    iconStyles={{
                        fontSize: '8px',
                    }}
                />
                <p>{textTrim(task.attributes.title, 90)}</p>
            </TaskMiniWrapper>
        </TaskLink>
    );
}

const UserTasksByWorkflow = ({ user, prefix = null, limit = 2 }) => {
    const { response, jsonapi } = useJsonApiGet(`/api/user-workflows-incomplete-tasks/${user.id}?_format=json`);
    const workflows = response.data.data || [];

    if (response.isFailed) {
        return null;
    }

    if (response.isLoading === false && workflows.length === 0) {
        return <div>
            {prefix}
            <p>You don't have any tasks yet</p>
        </div>
    }

    return (
        <Wrapper>
            <Spin spinning={response.isLoading}>
                {prefix}
                {workflows.map((workflow, i) => {
                    // We filter out tasks that don't exist because the server has only sent our
                    // own tasks that are incomplete.
                    const tasks = getRelationship(jsonapi, 'tasks', workflow)
                        .filter(t => !!t);

                    let imageUrl = '';
                    if (response.data.meta && typeof response.data.meta[`image_${workflow.id}`] !== 'undefined') {
                        // Try get it from the playbook.
                        imageUrl = response.data.meta[`image_${workflow.id}`];
                    }
                    return (
                        <WorkflowWrapper key={i}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {imageUrl.length ?
                                    <img
                                        src={imageUrl}
                                        style={{ width: '50px', marginRight: '16px' }}
                                        alt=""
                                    /> : <ImagePlaceholder />}

                                <TaskWorkflowLink workflow={workflow}>
                                    <WorkflowTitle>{workflow.attributes.title}</WorkflowTitle>
                                </TaskWorkflowLink>
                            </div>

                            <div style={{ margin: '16px 0' }}>
                                {tasks.slice(0, 2).map(task => <TaskMiniPreview
                                    task={task}
                                    playbookId={workflow.attributes.playbook_id}
                                    phase={getPhaseFromWorkflow(workflow)}
                                    key={task.id}
                                />)}
                                {tasks.length > limit ? <MoreTasksText>+{tasks.length - limit} more assigned tasks</MoreTasksText> : null}
                            </div>
                        </WorkflowWrapper>
                    )
                })}
            </Spin>
        </Wrapper>

    );


};

export default UserTasksByWorkflow;
