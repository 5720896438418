// @flow

import React from 'react';
import Box from '../Common/Box';
import styled from 'styled-components';
import UnearthedForum from 'headless-forum';

const EmbeddedForumWrapper = styled(Box)`
    padding-left: 0;
    padding-right: 0;
`

const InnerWrapper = styled.div`
    padding: 0 32px;

    @media (max-width: 600px) {
        padding: 0 16px;
    }
`

type Props = {
    remoteCompetitionId: string,
}

const EmbeddedForum = ({ remoteCompetitionId }: Props) => {
    return (
        <EmbeddedForumWrapper style={{ paddingLeft: '0', paddingRight: '0' }}>
            <h2 style={{ marginLeft: '32px' }}>Forum</h2>
            <div>
                <InnerWrapper>
                    <UnearthedForum remoteCompetitionId={remoteCompetitionId} />
                </InnerWrapper>
            </div>
        </EmbeddedForumWrapper>
    );
};

export default EmbeddedForum;
