import React from 'react';
import Tile from '../Presentation/Tile';
import { Skeleton, Tag, Progress } from 'antd';
import styled from 'styled-components';

const UserWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: -20px;
    padding: 0 8px;
`

const TaskWorkflowTileSkeleton = ({ title }) => {
    return (
        <Tile
            title={title}
            above_content={<UserWrapper>
                <Skeleton avatar active title={false} paragraph={false} />
            </UserWrapper>}
        >
            <Tag color="blue">Loading...</Tag>

            <div style={{ marginTop: '16px' }}>
                <Progress
                    percent={0}
                />
            </div>
        </Tile>
    );
};

export default TaskWorkflowTileSkeleton;