import { useState, useEffect } from 'react';
import updateWorkflowOrder from "../../api/updateWorkflowOrder";
import moveItem from "./moveItem";
import { http } from '../../actions';
import { get } from '../../helpers';

const usePinnedWorkflows = (columnId) => {
    const [workflows, setWorkflows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        http()
            .get('/api/unearthed/pinned-workflows/' + columnId)
            .then(result => {
                setWorkflows(get(result, 'data.data', []));
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            })
    }, [columnId]);

    return {
        isLoading,
        workflows,
        swapWorkflows: (moveIndex, targetIndex) => {
            let newWorkflows = moveItem(workflows, moveIndex, targetIndex);
            if (newWorkflows !== undefined) {
                setWorkflows(newWorkflows);

                updateWorkflowOrder(columnId, newWorkflows.map(w => w.id));
            }
        },
        replaceWorkflow: (index, newWorkflow) => {
            let newWorkflows = workflows.map(w => {
                return { ...w }
            });

            // Replace the workflow in the pinned list.
            newWorkflows.splice(index, 1, { ...newWorkflow });

            setWorkflows(newWorkflows);
            updateWorkflowOrder(columnId, newWorkflows.map(w => w.id));

        }
    }
}

export default usePinnedWorkflows;
