import normalize from 'json-api-normalizer';

export default function jsonApi(data = {}, action) {
    let newData = { ...data };
    switch (action.type) {
        case 'API_SUCCESS':
            // Do nothing when it's a token.
            if (action.key === 'TOKEN') {
                return data;
            }

            // We grab the existing data which is keyed by JSON API type and then for each
            // type, we save the existing entities and merge in the new entities by type
            // over the top.
            let normalized = normalize(action.payload, { camelizeKeys: false, camelizeTypeValues: false });
            Object.keys(normalized).forEach(key => {
                newData[key] = {
                    ...newData[key],
                    ...normalized[key],
                }
            })

            return newData;

        case 'JSONAPI_DELETE':
            let bundle = action.bundle;
            if (typeof bundle === 'undefined') {
                bundle = action.entity_type_id;
            }
            delete newData[action.entity_type_id + '--' + bundle][action.id];
            return newData;

        default:
            return data;
    }
}
