// @flow

import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { jsonApi } from '../../actions';
import { getRelationship } from '../../selectors';
import { FileNameFromFile } from '../File/FileName';
import UploadDropArea from '../UploadDropArea';
import { Task } from '../../types';
import { useDispatch } from 'react-redux';
import { Spin, message } from 'antd';
import { extractErrorMessage } from '../../api';
import useFileUpload from '../../api/useFileUpload';
import TaskAttachmentList from '../Task/TaskAttachmentList';

type Props = {
    task: Task,
}

const FileTask = ({ task }: Props) => {
    const dispatch = useDispatch();
    const [isLoadingTask, setIsLoadingTask] = useState(false);
    const { isUploadInProgress, handleUpload, uploadProgress } = useFileUpload(`/jsonapi/task/task/${task.id}/attachments`)

    const doHandleUpload = useCallback(async (files) => {
        handleUpload(files)
            .then(response => {
                setIsLoadingTask(true);
                jsonApi()
                    .get('/jsonapi/task/task/' + task.id + '?include=attachments')
                    .then(response => {
                        dispatch({
                            type: 'API_SUCCESS',
                            payload: response.data,
                        });
                        setIsLoadingTask(false);
                    })
                    .catch(error => {
                        setIsLoadingTask(false);
                        message.warn(extractErrorMessage(error));
                    })
            })
            .catch(error => {
                message.warn(extractErrorMessage(error));
                console.log('upload failures', error);
            })
    }, [task, dispatch, handleUpload]);

    return (
        <div>
            <UploadDropArea
                handleUpload={doHandleUpload}
                uploadInProgress={isUploadInProgress}
                uploadProgress={uploadProgress}
            />
            <br />
            <Spin spinning={isLoadingTask}>
                <TaskAttachmentList task={task} />
            </Spin>
        </div>
    )
}

FileTask.getSampleTask = () => {
    return {
        attributes: {
            data: {
                title: 'Sample Checkboxes',
            }
        }
    }
}

FileTask.isEmpty = task => task.relationships.attachments.data.length === 0;

const FileNameWrapper = styled.span`
    display: flex;
    flex-wrap: wrap;
    > * {
        &:after {
            content: ',';
        }
        &:last-child:after {
            content: '';
        }
    }
`
const FileTaskSummary = connect((state, props) => ({
    files: getRelationship(state, 'attachments', props.task)
}))(({ files, linkToFile }) => {
    if (files.length === 0) {
        return 'No files';
    }

    return (
        <FileNameWrapper>
            {files.map(file => <FileNameFromFile
                key={file.id}
                file={file}
                linkToFile={linkToFile}
            />)}
        </FileNameWrapper>
    )
})

FileTask.getPreviewValue = task => {
    if (FileTask.isEmpty(task)) {
        return 'No files';
    }
    return <FileTaskSummary task={task} linkToFile={false} />
}

FileTask.getFormattedValue = task => {
    if (FileTask.isEmpty(task)) {
        return 'No files';
    }
    return <TaskAttachmentList task={task} />
}

FileTask.displayName = 'File Upload';
export default FileTask;
