// @flow

import React, { useCallback, useState } from 'react';
import { Button, Drawer, message, TextArea } from '../Ant';
import { createNotification } from '../../api';
import SidebarLabel from '../Sidebar/SidebarLabel';
import Spacer from '../Common/Spacer';
import { User } from '../../types';

type Props = {
    selectedUsers: Array<User>,
    workflowId: String,
}

const SendUpdateButton = ({ selectedUsers, workflowId, ...props }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isShowingForm, setIsShowingForm] = useState(false);
    const [emailMessage, setMessage] = useState('Hi there, here is your status update');

    const sendUpdate = useCallback(() => {
        setIsLoading(true);
        let promises = [];
        selectedUsers.forEach(user => {
            promises.push(createNotification('email', user.id, emailMessage, {
                event: 'notification.workflow_update',
                workflowId: workflowId,
            }));
        })

        Promise.all(promises)
            .then(response => {
                setIsLoading(false);
                setIsShowingForm(false);
                message.success('Update sent to: ' + selectedUsers.map(u => u.attributes.display_name).join(', '));
            })
            .catch(error => {
                setIsLoading(false);
                message.error(error);
            })

    }, [selectedUsers, workflowId, emailMessage])

    const onMessageChange = useCallback(e => {
        setMessage(e.target.value);
    }, [])

    if (isShowingForm) {
        return <Drawer
            onClose={() => setIsShowingForm(false)}
            visible={true}
        >
            <h2>Send Update</h2>

            <Spacer>
                <SidebarLabel>Send a message</SidebarLabel>
                <TextArea value={emailMessage} onChange={onMessageChange} autoSize />
            </Spacer>

            <Button
                type="primary"
                onClick={sendUpdate}
                loading={isLoading}
            >
                Send update
            </Button>
        </Drawer>
    }

    return (
        <Button
            type="small"
            disabled={selectedUsers.length === 0 ? 'disabled' : ''}
            onClick={() => setIsShowingForm(true)}
            {...props}
        >Send update</Button>
    );
};

export default SendUpdateButton;
