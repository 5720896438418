import React from 'react';
import PasswordResetForm from '../Forms/PasswordResetForm';
import setPageTitle from '../hoc/setPageTitle';
import BasicPage from '../layouts/BasicPage';

const PasswordResetPage = ({ isLoggedIn }) => {
    return (
        <BasicPage>
            <h2>Reset your password</h2>
            <PasswordResetForm
                isLoggedIn={isLoggedIn}
            />
        </BasicPage>
    );
};

export default setPageTitle(PasswordResetPage, 'Reset Password');
