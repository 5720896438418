// @flow

import React from 'react';
import useJsonApiGet from '../../api/useJsonApiGet';
import { TaskWorkflow } from '../../types';
import ActivityList from './ActivityList';
import { getRelationship } from '../../selectors';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)

type Props = {
    taskWorkflow: TaskWorkflow,
    render: Function,
}

const TaskWorkflowActivityList = ({ taskWorkflow, render = null }: Props) => {
    const { response, jsonapi } = useJsonApiGet('/jsonapi/activity/activity?include=actor,object&filter[parent_uuid]=' + taskWorkflow.id);
    const activities = response.data.data || [];

    const activityList = <ActivityList
        items={activities.map(activity => {
            let user = getRelationship(jsonapi, 'actor', activity);
            let object = getRelationship(jsonapi, 'object', activity);

            const userName = user ? user.attributes.display_name : 'N/A';
            const objectName = object ? object.attributes.title || object.attributes.name || object.attributes.display_name : 'N/A';
            return {
                user: user,
                text: `${userName} ${activity.attributes.verb} ${objectName}`,
                timestamp: dayjs(activity.attributes.time).unix(),
            }
        })}
    />

    if (typeof render === 'function') {
        return render(activities, activityList);
    }

    return activityList;
};

export default TaskWorkflowActivityList;
