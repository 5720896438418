// @flow

import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import styled from 'styled-components';

const ImageGalleryWrapper = styled.div`
    max-width: 900px;

    .responsive-video {
        position: relative;
    }
    .responsive-video:after {
        content: '';
        display: block;
        padding-bottom: 56.25%;
    }

    .responsive-video iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
}

    .image-gallery-slide img {
        width: auto;
        max-width: 100%;
    }
`

const PlayButton = styled.div`
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 100px;
    background-color: ${props => props.theme.blueGrey};
    border-radius: 5px;

    &:hover: {
        background-color: ${props => props.theme.greyBlack};
    },
`
PlayButton.displayName = 'PlayButton';

const PlayButtonArrow = styled.span`
    content: '';
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0 auto;
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 1);
`

const CloseVideo = styled.span`
    content: '✖';
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 20px;
    z-index: 1,
    line-height: .7,
    display: block;
    color: #fff;
`

type Props = {
    videos: Array<Object>,
    images: Array<Object>,
}

type State = {
    showVideo: Object,
}

class MediaGallery extends Component<Props, State> {

    constructor() {
        super();
        this.state = {
            showVideo: {},
        }
    }

    render() {
        let items = [];

        this.props.videos.forEach(video => items.push({
            thumbnail: video.metadata.thumbnail_url,
            original: video.metadata.thumbnail_url,
            video: video,
            renderItem: this._renderVideo.bind(this)
        }));

        this.props.images.forEach(image => items.push(image));

        if (items.length === 0) {
            return <p>No media</p>
        }

        return (
            <ImageGalleryWrapper>
                <ImageGallery
                    items={items}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    slideInterval={10000}
                />
            </ImageGalleryWrapper>
        );
    }


    _renderVideo(item: Object) {
        return (
            <div className='image-gallery-image'>
                {
                    this.state.showVideo[item.video.value] ?
                        <div className='video-wrapper'>
                            <span
                                className='close-video hoverable'
                                onClick={this._toggleShowVideo.bind(this, item.video.value)}
                            >
                                <CloseVideo />
                            </span>
                            <div className="responsive-video" dangerouslySetInnerHTML={{ __html: item.video.metadata.autoplay_embed_code }} />
                        </div>
                        :
                        <span className="hoverable play-button-wrapper" onClick={this._toggleShowVideo.bind(this, item.video.value)}>
                            <PlayButton>
                                <PlayButtonArrow />
                            </PlayButton>
                            <img src={item.video.metadata.thumbnail_url} alt="The video thumbnail" />
                        </span>
                }
            </div>
        );
    }

    _toggleShowVideo(url: string) {
        this.setState({
            showVideo: {
                ...this.state.showVideo,
                [url]: !Boolean(this.state.showVideo[url]),
            },
        });
    }


}

export default MediaGallery;
