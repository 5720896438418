import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    height: 100px;
    width: 100px;
    overflow: hidden;
`
const ThumbnailCroppedImage = ({ src, alt, ...props }) => {
    return (
        <Wrapper>
            <img src={src} alt={alt} {...props} />
        </Wrapper>
    );
};

export default ThumbnailCroppedImage;
