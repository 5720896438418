// @flow

import React from 'react';
import Avatar from '../Avatar';
import styled from 'styled-components';
import { Divider } from 'antd';
import { User } from '../../types';
import UserTasksByWorkflow from './UserTasksByWorkflow';
import UserStatsBar from './UserStatsBar';
import Media from 'react-media';

const Wrapper = styled.div`
    max-width: 100%;
    width: 440px;
    background: #FFF;
    padding: 32px;
    flex-shrink: 0;
    word-break: break-word;

    @media (max-width: 960px) {
        padding: 24px;
        width: 380px;
    }

    @media (max-width: 600px) {
        padding: 8px;
        width: calc(100% - 16px);
        margin: 0 8px;
        background: transparent;
    }

`

const TopWrapper = styled.div`
    text-align: center;
`

const Name = styled.h2`
    margin: 32px 0 4px 0;
    font-size: 22px;
`

const Position = styled.h4`
    color: rgba(0,0,0,0.55);
    margin-bottom: 32px;
    font-size: 14px;
`

type Props = {
    user: User,
}

const VerticalProfile = ({ user }: Props) => {
    return (
        <Wrapper>
            <TopWrapper>
                <Media query="(min-width: 600px)">
                    <>
                        <Avatar
                            user={user}
                            size={128}
                        />
                        <Name>{user.attributes.display_name}</Name>
                        <Position>{user.attributes.field_position}</Position>
                    </>
                </Media>
            </TopWrapper>

            <Media query="(min-width: 600px)">
                <UserStatsBar user={user} />
            </Media>
            <Divider />

            <UserTasksByWorkflow
                prefix={<h3>Your tasks</h3>}
                user={user}
            />
    
        </Wrapper >
    );
};

export default VerticalProfile;
