import React from 'react';
import Spacer from '../components/Common/Spacer.js';
import EmbeddedForum from '../components/Competition/EmbeddedForum';
import get from '../helpers/get.js';
import usePlaybook from '../api/usePlaybook.js';
import PlaybookNav from '../components/Playbook/PlaybookNav.js';
import FullWidthContentBlock from '../components/FullWidthContentBlock.js';
import { GenericError } from '../components/Common/index.js';
import { Spin } from 'antd';
import CompetitionRunTabs from '../components/Competition/CompetitionRunTabs.js';
import { withRouter } from 'react-router-dom';
import { useSetPageTitle } from '../hoc/setPageTitle.js';
import PlaybookTitle from '../components/Playbook/PlaybookTitle.js';

const CompetitionForum = ({ match }) => {
    const playbookId = match.params.id;
    const { isLoading, isFailed, failedErrorMessage, workflows } = usePlaybook(playbookId);
    const competition = workflows.find(w => w.type === 'task_workflow--competition');
    const competitionRemoteId = get(competition, 'attributes.remote_uuid', null);
    useSetPageTitle(isLoading ? 'Loading...' : 'Forum');

    if (isFailed) {
        return (
            <div>
                <FullWidthContentBlock>
                    <br />
                    <br />
                    <br />
                    {isLoading ? <Spin spinning={true}></Spin> : <GenericError
                        title={failedErrorMessage}
                        message="This incident has been reported and will be investigated. If it persists please let us know."
                    />}
                </FullWidthContentBlock>
            </div>
        );
    }

    return (
        <Spin spinning={isLoading}>
            {get(competition, 'attributes.playbook_id', false) ?
                <PlaybookNav
                    playbookId={get(competition, 'attributes.playbook_id')}
                    title={<PlaybookTitle playbookId={playbookId} edit />}
                    mode={competition.attributes.workflow_status === 'complete' ? 'results' : 'live'}
                    tabs={<CompetitionRunTabs
                        competition={competition}
                        activeTab={"forum"}
                        playbookId={playbookId}
                        workflowStatus={competition.attributes.workflow_status}
                    />}
                />
                : null}

            <Spacer>
                <FullWidthContentBlock>
                    {['draft', 'build', 'ready_to_publish'].includes(get(competition, 'attributes.workflow_status')) ?
                        <h3>Competition not yet published</h3>
                        : <EmbeddedForum remoteCompetitionId={competitionRemoteId} />
                    }
                </FullWidthContentBlock>
            </Spacer>
        </Spin>
    )
}

export default withRouter(CompetitionForum);