import React from 'react';
import { Box } from '../components/Common';
import setPageTitle from '../hoc/setPageTitle';
import ContentWrapper from '../layouts/ContentWrapper';
import GlobalActivityFeed from '../components/Activity/GlobalActivityFeed';

const GlobalActivityPage = () => {
    return (
        <ContentWrapper>
            <Box>
                <GlobalActivityFeed />
            </Box>
        </ContentWrapper>
    );
};


export default setPageTitle(GlobalActivityPage, 'Activity');
