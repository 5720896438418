// @flow

import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Icon, Divider, Spin, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import { message } from '../Ant';
import { mergeEntityAttributes, htmlToText } from '../../helpers';
import { hasPermission } from '../../helpers/HasPermission';
import withPermissions from '../../hoc/withPermissions';
import ToggleEditor from './ToggleEditor';
import { User } from '../../types';
import CommentList from './CommentList';
import useComments from '../../api/useComments';

const CommentsWrapper = styled.div`
    margin-top: 40px;

    a {
        display: initial!important;
    }
`

const CommentSuffix = styled.p`
    text-align: right;

    span {
        margin-right: 1em;
        cursor: pointer;
    }
`

const Heading = styled.span`
    font-size: 14px;
    color: ${props => props.theme.darkGrey};
`

type Props = {
    commentType: string,
    parentType: string,
    id: string,
    currentUser: User,
    permissions: Array<string>,
}

const Comments = ({ commentType, parentType, id, currentUser, permissions }: Props) => {
    const { comments, refresh, createComment, updateComment, deleteComment, isFailed, isLoading } = useComments(commentType, id);
    const [commentText, setCommentText] = useState('');
    const [editComment, setEditComment] = useState(null);

    const resetInput = useCallback(() => {
        setEditComment(null);
        setCommentText('');
        refresh();
    }, [refresh]);

    const onCommentSubmit = useCallback((e) => {
        e.preventDefault();
        if (!htmlToText(commentText).trim() || (e && e.key && e.shiftKey)) {
            return;
        }
        if (editComment) {
            const updatedComment = mergeEntityAttributes(editComment, { comment_body: { value: commentText.trim() } });
            updateComment(commentType, updatedComment)
                .then(() => {
                    resetInput();
                    message.success('Comment has been edited.')
                })
                .catch(() => message.error('An error occurred and the comment could not be edited.'));
        } else {
            createComment(commentType, parentType, id, '', commentText.trim())
                .then(() => {
                    resetInput();
                    message.success('Comment has been created.')
                })
                .catch(() => message.error('An error occurred and the comment could not be created.'));
        }
    }, [commentText, commentType, editComment, id, parentType, resetInput, createComment, updateComment]);

    const onCommentDelete = useCallback((e, comment) => {
        e.preventDefault();
        deleteComment(commentType, comment)
            .then(result => {
                refresh();
                message.success('Comment has been removed.')
            })
            .catch(() => message.error('An error occurred and the comment could not be removed.'));

    }, [commentType, refresh, deleteComment]);

    const onCommentEdit = useCallback((e, comment) => {
        e.preventDefault();
        setEditComment(comment);
        setCommentText(comment.attributes.comment_body.value);
    }, []);

    const onCancelEdit = useCallback((e) => {
        e.preventDefault();
        resetInput();
    }, [resetInput]);

    return (
        <CommentsWrapper className="comments">
            <Divider orientation="left">
                <Heading>
                    {comments.length} Comment{comments.length !== 1 ? 's' : ''}
                </Heading>
            </Divider>
            <ToggleEditor
                forceWysiwyg={!!editComment}
                value={commentText}
                onChange={setCommentText}
                showCancel={!!editComment}
                onCancel={onCancelEdit}
                showSubmit={true}
                onSubmit={onCommentSubmit}
                submitText={editComment ? 'Save' : 'Send'}
                placeholder="Write a comment..."
            />
            <br />
            {isFailed ?
                <p>Failed to load comments, please refresh.</p>
                :
                <Spin spinning={isLoading}>
                    <CommentList
                        emptyText={""}
                        items={comments.map(comment => {
                            return {
                                user: comment.author,
                                timestamp: dayjs(comment.attributes.created).unix(),
                                text: <div dangerouslySetInnerHTML={{ __html: comment.attributes.comment_body.value }} className="comment-text"></div>,
                                actions: (comment.author && currentUser.id === comment.author.id) || hasPermission(permissions, 'administer comments') ?
                                    <CommentSuffix>
                                        <span onClick={(e) => onCommentEdit(e, comment)} title="Edit" className="comment-edit">
                                            <Icon type="edit" /> Edit
                                        </span>
                                        <Popconfirm
                                            title={"Are you sure you want to delete this comment?"}
                                            onConfirm={(e) => onCommentDelete(e, comment)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <span title="Delete" className="comment-delete">
                                                <Icon type="delete" /> Delete
                                            </span>
                                        </Popconfirm>
                                    </CommentSuffix>
                                    : null,
                            }
                        })}
                    />
                </Spin>
            }
        </CommentsWrapper>
    );
};

export default withPermissions(Comments);
