import React from 'react';
import styled from 'styled-components';
import { get } from '../../helpers';

const Teaser = styled.div`
    display: flex;
    align-items: flex-start;
    border-radius: 3px;
    border: 1px solid #E5E5E5;
    margin-bottom: 20px;

    @media (max-width: 1000px) {
        width: 100%;
    },

    ':hover': {
        cursor: pointer;
    }
`

const ImagePreview = styled.div`
    background: #EEE;
    flex: 0 0 102px;
    height: 102px;
`

const ImageWrapper = styled.div`
    border-right: 1px solid #F2F2F2;
    flex-shrink: 0;

    @media (max-width: 600px) {
        display: none;
    }
`

const Image = styled.img`
    width: 102px;
    height: 100px;
    display: block;
`

const InfoWrapper = styled.div`
    margin-left: 20px;
    padding: 14px 16px 14px 20px;
    margin-right: 20px;

    @media (max-width: 600px) {
        margin-left: 0;
    }
`

const SubmissionName = styled.h4`
    margin: 0 0 10px 0;
    font-size: 1em;
    font-weight: 600;
    color: #3E3E3E;

    @media (max-width: 600px) {
        font-zize: 1em;
    }
`

const TeamName = styled.span`
    font-size: 75%;
    color: #4C4E5D;
    margin-bottom: 0;
    display: block;
`

const SubmissionTeaser = ({ submission, team, images, onTeaserClick }) => {
    let imageUrl = images ? get(images[0], 'links.submission_teaser.href', '') : '';
    return (
        <Teaser onClick={onTeaserClick}>
            {imageUrl.length ?
                <ImageWrapper>
                    <Image src={imageUrl} />
                </ImageWrapper> :
                <ImagePreview />
            }
            <InfoWrapper>
                <SubmissionName>
                    <span dangerouslySetInnerHTML={{ __html: submission.attributes.field_title}}/> <TeamName>by <span dangerouslySetInnerHTML={{ __html: team.attributes.name}}/></TeamName>
                </SubmissionName>
            </InfoWrapper>
        </Teaser>
    );
};

export default SubmissionTeaser;
