export const mainMenuItems = [
    {
        path: '/',
        key: 'home',
        text: 'Home',
    },    
    {
        path: '/challenges',
        key: 'challenge',
        text: 'Challenges',
    },
];

export const userMenuItems = [
    {
        path: '/user/edit',
        key: 'user_edit',
        text: 'Edit Profile',
    }
]
