import React from 'react';
import getConfig from '../../instance-config';
import { Icon } from 'antd';


const CompetitionStatus = ({ workflowStatus }) => {
    return(
        <>
            { workflowStatus ? 
                    <>
                        <Icon
                            style={{ marginRight: '4px', color: getConfig().primaryColor }}
                            type="exclamation-circle"
                        />
                        <span>{ workflowStatus.replace(/_/g, ' ').toUpperCase() }</span>
                    </>
            : null }
        </>
    )
}

export default CompetitionStatus;