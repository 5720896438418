// @flow props

import React from 'react';
import { getRelationship } from '../../selectors';
import ActivityFeed from './ActivityFeed';
import { Activity } from '../../types';

type Props = {
    activities: Array<Activity>,
    jsonapi: Object,
}

const SignupActivity = ({ activities, jsonapi }: Props) => {
    if (activities.length === 0) {
        return <p>No signups yet</p>
    }

    return (
        <ActivityFeed
            items={activities.map(participation => SignupActivity.render(participation, jsonapi))}
        />
    );
};

SignupActivity.render = (participation, jsonapi) => {
    let innovator = getRelationship(jsonapi, 'innovator', participation);
    let user = getRelationship(jsonapi, 'uid', innovator);
    let competition = getRelationship(jsonapi, 'competition', participation);

    return {
        user: user,
        text: <span>Signed up for {competition.attributes.name}</span>,
        timestamp: participation.attributes.created,
    }
}

export default SignupActivity;
