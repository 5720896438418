import React, { useState } from 'react';
import { message, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { useCallback } from 'react';
import DropButton from '../Common/DropButton';
import { hasPermission } from '../../helpers/HasPermission';
import withPermissions from '../../hoc/withPermissions';
import useSaveResult from '../../api/useSaveResult';
import { mergeEntityAttributes } from '../../helpers';
import { updateEntity } from '../../api/entity';
import { createLinkFromTaskWorkflow } from '../TaskWorkflow/createLink';
import { extractErrorMessage } from '../../api';

const LiveActions = ({ competition, menu, history, permissions }) => {
    const [isLoading, setIsLoading] = useState(false);
    const saveResult = useSaveResult();

    const setComplete = useCallback(() => {
        setIsLoading(true);
        const updatedWorkflow = mergeEntityAttributes(competition, {
            workflow_status: 'complete'
        });

        updateEntity(updatedWorkflow)
            .then(result => {
                saveResult(result);
                setIsLoading(false);
                history.push(createLinkFromTaskWorkflow(competition, 'results'))
            })
            .catch(error => {
                message.warning(extractErrorMessage(error));
                setIsLoading(false);
            });
    }, [competition, saveResult, history]);

    return <Spin spinning={isLoading}>
        <DropButton
            isDisabled={!hasPermission(permissions, 'administer task_workflow') || competition.attributes.workflow_status !== 'published'}
            type="primary"
            buttonText={
                <div>
                    {competition.attributes.workflow_status !== 'complete' ?
                        <span onClick={setComplete}>
                            Mark Complete
                        </span>
                        : 'Complete'
                    }
                </div>
            }
            menu={menu}
        />
    </Spin>
};

export default withRouter(withPermissions(LiveActions));
export { LiveActions as _LiveActions }
