import styled from 'styled-components';

export default styled.div`
    padding: 0 calc((100vw - 1640px) / 2 );
    width: 100%;

    @media (max-width: 1672px) {
        padding: 0 16px;
    }
`
