// @flow

import React from 'react';
import { connect } from 'react-redux'
import ActivityFeed from './ActivityFeed';
import useJsonApiGet from '../../api/useJsonApiGet';
import { Button, Spin } from 'antd';
import { getRelationship } from '../../selectors';
import dayjs from 'dayjs';
import qs from 'qs';
import { saveJsonApiResponse } from '../../api';
import TaskWorkflowLink from '../TaskWorkflow/TaskWorkflowLink';
import PlaybookTitle from '../Playbook/PlaybookTitle';
import PlaybookLink from '../Playbook/PlaybookLink';

type Props = {
    query?: Object,
    render: Function,
    activities: Array<Object>,
    users: Object,
    objects: Object,
    parents: Object,
    doSaveJsonApiResponse: Function,
}

const GlobalActivityFeed = ({ query = {}, render = (children) => children, activities, users, objects, parents, doSaveJsonApiResponse }: Props) => {
    const queryArgs = {
        include: 'actor,object,parent',
        'sort[created][path]': 'time',
        'sort[created][direction]': 'DESC',
        'page[limit]': 50,
        ...query,
    }
    const queryString = qs.stringify(queryArgs);
    const { hasLink, requestLink, response } = useJsonApiGet(`/jsonapi/activity/activity?${queryString}`, doSaveJsonApiResponse);

    const buildActivityItems = (activities) => {
        let activityItems = [];
        activities.forEach(activity => {
            let user = users[activity.id];
            let object = objects[activity.id];
            let parent = parents[activity.id];

            if (!object || !parent) {
                return;
            }

            if (activity.attributes.activity_type === 'comment_added') {
                activityItems.push({
                    user: user,
                    text: `made a comment on ${parent.attributes.title}`,
                    timestamp: dayjs(activity.attributes.time).unix(),
                })
                return;
            }

            if (activity.attributes.activity_type === 'marked_complete') {
                activityItems.push({
                    user: user,
                    text: <TaskWorkflowLink
                        workflow={parent}
                        style={{ color: 'inherit' }}
                    >
                        {`${activity.attributes.verb} a task on ${parent.attributes.title}`}
                    </TaskWorkflowLink>,
                    timestamp: dayjs(activity.attributes.time).unix(),
                });
                return;
            }

            if (activity.attributes.activity_type === 'marked_incomplete') {
                activityItems.push({
                    user: user,
                    text: <PlaybookLink
                        playbookId={parent.id}
                        style={{ color: 'inherit' }}
                    >
                        {`marked a task incomplete on ${parent.attributes.title}`}
                    </PlaybookLink>,
                    timestamp: dayjs(activity.attributes.time).unix(),
                });
                return;
            }

            if (activity.attributes.activity_type === "added_collaborator") {
                activityItems.push({
                    user: user,
                    text: <PlaybookLink
                        playbookId={parent.id}
                        style={{ color: 'inherit' }}
                    >
                        {activity.attributes.verb} collaborator to <PlaybookTitle playbookId={parent.id} />
                    </PlaybookLink>,
                    timestamp: dayjs(activity.attributes.time).unix(),
                });
                return;
            }

            if (activity.attributes.activity_type === "removed_collaborator") {
                activityItems.push({
                    user: user,
                    text: <TaskWorkflowLink
                        workflow={object}
                        style={{ color: 'inherit' }}
                    >
                        {activity.attributes.verb} collaborator from <PlaybookTitle playbookId={parent.id} />
                    </TaskWorkflowLink>,
                    timestamp: dayjs(activity.attributes.time).unix(),
                });
                return;
            }

            if (activity.attributes.activity_type === "new_challenge") {
                activityItems.push({
                    user: user,
                    text: <TaskWorkflowLink
                        workflow={parent}
                        style={{ color: 'inherit' }}
                    >
                        {`${activity.attributes.verb} a new opportunity`}
                    </TaskWorkflowLink>,
                    timestamp: dayjs(activity.attributes.time).unix(),
                });
                return;
            }

            if (activity.attributes.activity_type === "new_competition") {
                // No longer required, in the feed via workflow_status_change.
                return;
            }

            if (activity.attributes.activity_type === "workflow_status_change") {
                activityItems.push({
                    user: user,
                    text: <TaskWorkflowLink
                        workflow={object}
                        style={{ color: 'inherit' }}
                    >
                        {activity.attributes.format
                            .replace('@actor', '')
                            .replace('@verb', activity.attributes.verb).trim()} for <PlaybookTitle playbookId={object.attributes.playbook_id} />
                    </TaskWorkflowLink>,
                    timestamp: dayjs(activity.attributes.time).unix(),
                })
                return;
            }

            activityItems.push({
                user: user,
                text: `${activity.attributes.verb} ${object.attributes.title || object.attributes.name || object.attributes.display_name}`,
                timestamp: dayjs(activity.attributes.time).unix(),
            })
        })
        return activityItems;
    }

    const activityItems = buildActivityItems(activities);
    if (activityItems.length === 0) {
        return null;
    }

    return render(
        <>
            <ActivityFeed
                items={activityItems}
            />

            {
                hasLink('next') ?
                    <Spin spinning={response.isLoading}>
                        <div style={{ textAlign: 'center', marginTop: '32px' }}>
                            <Button onClick={() => requestLink('next')}>Load More</Button>
                        </div>
                    </Spin>
                    : null
            }
        </>,
        activityItems
    );
};

const mapStateToProps = state => {
    const activities = typeof state.jsonApi['activity--activity'] !== 'undefined' ? Object.values(state.jsonApi['activity--activity']) : [];
    let users = {};
    let objects = {};
    let parents = {};

    activities.forEach((activity: Object) => {
        users[activity.id] = getRelationship(state, 'actor', activity);
        objects[activity.id] = getRelationship(state, 'object', activity);
        parents[activity.id] = getRelationship(state, 'parent', activity);
    })

    return {
        activities: typeof state.jsonApi['activity--activity'] !== 'undefined' ? Object.values(state.jsonApi['activity--activity']) : [],
        users: users,
        objects: objects,
        parents: parents,
    }
}

export default connect(mapStateToProps, {
    doSaveJsonApiResponse: saveJsonApiResponse
})(GlobalActivityFeed);
