// @flow

import { useEffect, useContext, useState } from "react";
import { ApiContext } from "./ApiContext";
import { extractErrorMessage } from ".";
import qs from 'qs';
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getComments, getUser } from '../selectors';
import useSaveResult from "./useSaveResult";
import JsonApiRequestBuilder from "../helpers/JsonApiRequestBuilder";
import { Comment } from '../types';

function useComments(commentType: string, entityId: string) {
    const saveResult = useSaveResult();
    const api = useContext(ApiContext);
    const [responseStatus, setResponseStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();

    const comments = useSelector(state => (Object.values(getComments(state, commentType)) || []).map((comment: any) => {
        comment.author = getUser(state, comment.relationships.uid.data.id);
        return comment;
    }));

    const performRequest = useCallback(() => {
        const query = {
            'filter[entity_id.id]': entityId,
            'sort[sort-changed][path]': 'changed',
            'sort[sort-changed][direction]': 'DESC',
            'include': 'uid',
        };
        let url = `/jsonapi/comment/${commentType}?${qs.stringify(query)}`;

        api
            .get(url)
            .then(result => {
                // Request was cancelled.
                if (!result) {
                    console.log('comments cancelled');
                    return;
                }

                saveResult(result);
                setResponseStatus('success');
            })
            .catch(error => {
                setResponseStatus('failed');
                setErrorMessage(extractErrorMessage(error));
            });

        return () => {
            api.cancel(url);
        }

    }, [api, commentType, entityId, saveResult]);

    useEffect(() => {
        return performRequest();
    }, [performRequest])

    return {
        isLoading: responseStatus === 'loading',
        isFailed: responseStatus === 'failed',
        errorMessage: errorMessage,
        comments,
        refresh: () => {
            api.clearCache();
            performRequest();
        },
        createComment: (commentType: string, parentType: string, entityId: string, subject: string, content: string) => {
            const request = new JsonApiRequestBuilder('comment--comment')
                .attribute('subject', subject)
                .attribute('entity_type', parentType.split('--')[0])
                .attribute('field_name', 'comments')
                .attribute('comment_body', {
                    'value': content,
                })
                .relationship('entity_id', parentType, entityId);

            return api
                .post(`/jsonapi/comment/${commentType}`, request.build())
                .then(response => {
                    console.log('refresh task');
                    api.get(`/jsonapi/task/task/${entityId}?`)
                        .then(taskResponse => saveResult(taskResponse));

                    saveResult(response);
                    return Promise.resolve(response);
                })
                .catch(error => {
                    setResponseStatus('failed');
                    setErrorMessage(extractErrorMessage(error));
                });
            ;
        },
        updateComment: (commentType: string, updatedComment: Comment) => {
            return api
                .patch(`/jsonapi/comment/${commentType}/${updatedComment.id}`, { data: updatedComment })
                .catch(error => {
                    setResponseStatus('failed');
                    setErrorMessage(extractErrorMessage(error));
                });
        },
        deleteComment: (commentType: string, comment: Comment) => {
            const id = typeof comment === 'string' ? comment : comment.id;
            return api
                .delete(`/jsonapi/comment/${commentType}/${id}`)
                .then(response => {
                    dispatch({ type: 'JSONAPI_DELETE', entity_type_id: 'comment', bundle: commentType, id: id })
                    return Promise.resolve(response);
                });
        },
    }
}

export default useComments;
