// @flow

import React, { useEffect, useState } from 'react';
import { StripedTable } from '../Ant';
import ReactCountryFlag from "react-country-flag";
import { http } from '../../actions';
import { TaskWorkflow } from '../../types';
import styled from 'styled-components';

const LeaderboardWrapper = styled.div`
    .priority-low {
        @media (max-width: 1200px) {
            display: none;
        }
    }
    .team-name {
        overflow-wrap: anywhere !important;
    }
`

type Props = {
    competition: TaskWorkflow
}

const columns = [{
    title: 'Position',
    key: 'position',
    dataIndex: 'position',
    className: 'priority-medium',
}, {
    title: 'Team',
    dataIndex: 'entity.label',
    key: 'team',
    className: 'team-name',
}, {
    title: 'Country',
    key: 'country',
    render: result => Object.keys(result.entity.flags).map((flag, i) => <ReactCountryFlag key={i} code={flag} />),
}, {
    title: 'Attempts',
    dataIndex: 'attempts',
    key: 'attempts',
    className: 'priority-low',
}, {
    title: 'Best Score',
    key: 'best_score',
    dataIndex: 'score',
    className: 'priority-low',
}, {
    title: 'Last Score',
    key: 'last_score',
    dataIndex: 'last_score',
    className: 'priority-low',
}];

const CompetitionLeaderboard = ({ competition }: Props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isFailedMessage, setIsFailedMessage] = useState(false)
    const [leaderboard, setLeaderboard] = useState([])

    useEffect(() => {
        loadLeaderboard(competition.attributes.remote_uuid)
            .then(result => {
                setIsLoading(false);
                setLeaderboard(result.data.leaderboard);
            })
            .catch(error => {
                console.log(error, error.response);
                setIsFailedMessage(error.response.data.message ? error.response.data.message : error.response.data.error);
            })
    }, [competition.attributes.remote_uuid])

    if (isFailedMessage) {
        return <p>Failed to load the leaderboard at this time: {isFailedMessage}</p>
    }
    return (
        <LeaderboardWrapper>
            <StripedTable
                loading={isLoading}
                columns={columns}
                rowKey={record => record.entity.id}
                dataSource={leaderboard}
                locale={{ emptyText: 'No submissions' }}
            />
        </LeaderboardWrapper>
    );
};

const loadLeaderboard = (competitionId: string) => {
    let url = '/api/leaderboard/' + competitionId + '?_format=json';
    return http().get('/api/unearthed/portal-proxy?u=' + encodeURIComponent(url));
}

export default CompetitionLeaderboard;
