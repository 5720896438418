import styled from 'styled-components';
import { Tabs as AntTabs } from 'antd';

const CardTypeTabs = styled(AntTabs)`

    .ant-tabs-nav {
        padding: 0 20px !important;
    }
    
    .ant-tabs-card-bar {
        display: none;
        &.ant-tabs-card-bar {
            display: block;
        }
    }

    .ant-tabs-nav-container {
        margin-bottom: -2px;
        max-width: 1640px;
        margin-left: auto;
        margin-right: auto;     
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        border: 2px solid ${props => props.theme.primaryColor} !important;
        background: #F5F6F5 !important;
        border-bottom: none !important;
        height: 40px !important;

        a {
            color: rgba(0, 0, 0, 0.65);
        }
    }

    .ant-tabs-tab {
        background: #FFFFFF !important;
        text-align: center;
        width: 159px;
        height: 38px !important;
        border-top: 2px solid #e8e8e8 !important;
        border-right: 2px solid #e8e8e8 !important;
        border-left: 2px solid #e8e8e8 !important;
        padding: 0px !important;

        &:hover {
            background: #F5F6F5 !important;
            font-weight: 500; 
        }

        a {
            color: rgba(0, 0, 0, 0.65);
            display: block;
        }
    }
    
    .ant-tabs-nav.ant-tabs-tab {
        border-bottom: none !important;
    }
    
    .ant-tabs-card-bar.ant-tabs-bar {
        border-bottom: 2px solid ${props => props.theme.primaryColor} !important;
        margin: 0 !important;
        width: 100vw;
    }

    .ant-tabs-nav .ant-tabs-tab:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`
export default CardTypeTabs;



