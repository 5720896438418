import styled from 'styled-components';
import { Button as AntButton } from 'antd';

const Button = styled(AntButton)`
    &&:not(.ant-btn-small) {
        height: 38px;
        font-weight: 500;
        font-size: 14px;
    }
`

Button.displayName = 'Button';

export default Button;
