import encodeObjectToQueryString from '../helpers/encodeObjectToQueryString';
import { IMAGE_LARGE_CONSUMER_ID } from '../consumers';

export function getTaskWorkflowUrl(type, id, extraQuery = {}) {
    let include = 'tasks,tasks.uid,tasks.attachments,image,uid.field_picture,collaborators.field_picture,tasks.uid.field_picture';
    if (typeof extraQuery.include !== 'undefined') {
        include += ',' + extraQuery.include;
        delete extraQuery.include;
    }
    let query = {
        include: include,
        'consumerId': IMAGE_LARGE_CONSUMER_ID,
        'sort[sort-created][path]': 'created',
        'sort[sort-created][direction]': 'DESC',
        ...extraQuery,
    };

    let queryString = encodeObjectToQueryString(query);

    return `/jsonapi/task_workflow/${type}/${id}?${queryString}`
}

export function loadWorkflowsUrl(type, extraQuery = {}) {
    let query = {
        include: 'task_template,image',
        'sort[sort-created][path]': 'changed',
        'sort[sort-created][direction]': 'DESC',
        'filter[is_archived]': '0',
        'filter[tasks.entity.is_archived]': '0',
        'consumerId': IMAGE_LARGE_CONSUMER_ID,
        ...extraQuery,
    };

    let queryString = encodeObjectToQueryString(query);
    return `/jsonapi/task_workflow/${type}?${queryString}`
}