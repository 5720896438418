// @flow

import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import getBaseUrl, { getBaseHost } from '../Unearthed/getBaseUrl';
import UserCard from '../Common/UserCard';


const Location = styled.h5``

type Props = {
    innovator: Object,
    image: Object,
}

const IconWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: auto;
    padding-top: 16px;

    i {
        font-size: 16px;
    }
`

const InnovatorCard = ({ innovator, image }: Props) => {
    return (
        <UserCard 
            imgSource={image ? getBaseHost() + image.attributes.uri.url : ''} 
            name={innovator.attributes.name} 
            url={getBaseUrl() + innovator.attributes.path.alias}
            details={innovator.attributes.country_label ? <Location>{innovator.attributes.country_label}</Location> : null}
        >

            <IconWrapper>
                <span title="Participations"><Icon type="calendar" /> {innovator.attributes.participation_count}</span>
                <span title="Submissions"><Icon type="file-pdf" /> {innovator.attributes.submission_count}</span>
                <span title="Awards"><Icon type="trophy" /> {innovator.attributes.prize_count}</span>
            </IconWrapper>
        </UserCard>
    );
};

export default InnovatorCard;
