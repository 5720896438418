// @flow

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Box from '../components/Common/Box';
import setPageTitle from '../hoc/setPageTitle';
import { http } from '../actions';
import { getBaseHost } from '../components/Unearthed/getBaseUrl';
import { Spin, Row, Col } from 'antd';
import normalize from 'json-api-normalizer';
import { getRelationship } from '../selectors';
import MediaGallery from '../components/MediaGallery';
import InnovatorCard from '../components/Innovator/InnovatorCard';
import styled from 'styled-components';
import { TaskWorkflow } from '../types';
import ContentWrapper from '../layouts/ContentWrapper';
import { get } from '../helpers';
import SubmissionNavLinks from '../components/TaskWorkflow/SubmissionNavLinks';

const TeamWrapper = styled.div`
    @media (max-width: 1200px) {
        margin-top: 16px;
    }
`

const TeamMembersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @media (max-width: 1200px) {
        justify-content: flex-start;
    }

    > div {
        margin: 0 0 8px 0;
        @media (max-width: 1200px) {
            margin-right: 8px;
        }
    }
`

const PrivateSubmission = styled.div`
    margin-top: 32px;

    label {
        display: block;
        font-weight: bold;
    }
    .webform-element {
        margin-bottom: 16px;
    }
`

const UnearthedSubmission = ({ match }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [submission: TaskWorkflow, setSubmission] = useState(null);
    const [responseData, setResponseData] = useState([]);
    const [webformHtml, setWebformHtml] = useState('');

    useEffect(() => {
        setIsLoading(true);
        loadSubmission(match.params.id, match.params.type)
            .then(response => {
                let normalized = { jsonApi: normalize(response.data, { camelizeKeys: false, camelizeTypeValues: false }) };
                setResponseData(normalized);
                setSubmission(response.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            });
    }, [match.params.id, match.params.type]);

    useEffect(() => {
        if (!submission) {
            return;
        }

        let innovatorUrl = `/submissions/${submission.id}/webform`;
        let url = '/api/unearthed/portal-proxy?u=' + encodeURIComponent(innovatorUrl);
        http()
            .get(url)
            .then(result => {
                if (result.status === 200) {
                    setWebformHtml(result.data.html);
                }
            })
            .catch(error => {
                console.log(error);
            })

    }, [submission])

    let team = getRelationship(responseData, 'field_team', submission);
    let images = getRelationship(responseData, 'field_images', submission) || [];
    let privateFiles = getRelationship(responseData, 'field_private_files', submission);
    let teamMembers = getRelationship(responseData, 'members', team);
    const privateVideos = get(submission, 'attributes.field_videos', [])
    const privateLinks = get(submission, 'attributes.field_private_links', [])
    const publicLinks = get(submission, 'attributes.field_links', [])

    return (
        <Spin spinning={isLoading}>
            <ContentWrapper>
                {isLoading || submission === null ? null :
                    <>
                        <SubmissionNavLinks
                            remoteTaskWorkflowUuid={submission.relationships.field_competition.data.id}
                            submission={submission}
                        />
                        <Row gutter={24}>
                            <Col sm={24} xl={15} xxl={16}>
                                <Box>
                                    <div>
                                        {/* Escaped by drupal. */}
                                        <h1 dangerouslySetInnerHTML={{ __html: submission.attributes.field_title }} />

                                        <MediaGallery
                                            images={images.map(image => {
                                                let teaserUrl = get(image, 'links.submission_teaser.href', '');
                                                let fullUrl = get(image, 'links.submission_media_carousel.href', '');
                                                if (teaserUrl.length) {
                                                    return {
                                                        thumbnail: teaserUrl,
                                                        original: fullUrl,
                                                    }
                                                }
                                                return null;
                                            }).filter(i => i !== null)}
                                            baseHost={getBaseHost()}
                                            videos={submission.attributes.field_videos ? submission.attributes.field_videos : []}
                                        />

                                        <h3 dangerouslySetInnerHTML={{ __html: submission.attributes.field_summary }} />
                                        <div dangerouslySetInnerHTML={{ __html: submission.attributes.field_full_description.processed }}></div>

                                        {webformHtml.length ?
                                            <PrivateSubmission>
                                                <h3>Private Submission</h3>
                                                <div dangerouslySetInnerHTML={{ __html: webformHtml }}></div>
                                            </PrivateSubmission>
                                            : null
                                        }
                                    </div>
                                </Box>
                            </Col>

                            <Col sm={24} xl={9} xxl={8}>
                                <TeamWrapper>
                                    <Box>
                                        <h2>{team.attributes.name}</h2>
                                        <TeamMembersWrapper>
                                            {teamMembers.sort((a, b) => getRelationship(responseData, 'image', a) ? -1 : 1).map(member => <InnovatorCard
                                                key={member.id}
                                                innovator={member}
                                                image={getRelationship(responseData, 'image', member)}
                                            />)}
                                        </TeamMembersWrapper>
                                    </Box>
                                </TeamWrapper>
                                <br />
                                <Box>
                                    {publicLinks.length !== 0 ?
                                        <div>
                                            <h2>Public Links</h2>
                                            <ul>
                                                {publicLinks.map((link, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <a href={link.uri} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: link.title }} />
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        : null}

                                    {privateVideos.length !== 0 ?
                                        <div>
                                            <h2>Private Videos</h2>
                                            <ul>
                                                {privateVideos.map((link, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <a href={link.value} target="_blank" rel="noopener noreferrer">{link.value}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        : null}

                                    {privateFiles && privateFiles.length > 0 ?
                                        <div>
                                            <h2>Private Files</h2>
                                            <ul>
                                                {privateFiles.map((file, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <a href={getBaseHost() + file.attributes.uri.url} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: file.attributes.filename }} />
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        : null}

                                    {privateLinks.length !== 0 ?
                                        <div>
                                            <h2>Private Links</h2>
                                            <ul>
                                                {privateLinks.map((link, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <a href={link.uri} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: link.title }} />
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        : null}
                                </Box>

                            </Col>
                        </Row>
                    </>
                }
            </ContentWrapper>
        </Spin>
    );
};

const loadSubmission = (id, type) => {
    let url = `/jsonapi/submission/${type}/${id}?include=field_images,field_private_files,field_prizes,field_team,field_team.members,field_team.members.image&consumerId=a4767485-cf86-4782-b836-7fafb59dde8b`;
    return http().get('/api/unearthed/portal-proxy?u=' + encodeURIComponent(url));
}

export default withRouter(setPageTitle(UnearthedSubmission, 'Viewing Submission'));
