// @flow

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { http } from '../../actions';
import { TaskWorkflow } from '../../types';

type Props = {
    competition: TaskWorkflow,
}

const ParticipantMap = ({ competition }: Props) => {
    const [loadedCountryData, setCountryData] = useState([]);
    const mapReference = useRef(null);

    useEffect(() => {
        let endpoint = '/api/participantStatistics/' + competition.attributes.remote_uuid;
        const source = axios.CancelToken.source();

        http()
            .get('/api/unearthed/portal-proxy?u=' + encodeURIComponent(endpoint), {
                cancelToken: source.token,
            })
            .then(response => {
                // Only update country data if there is data to update it with
                if(Object.keys(response.data).length > 0) {
                    setCountryData(response.data);
                }
            })
            .catch(error => {
                // Don't do anything if it was cancelled by the user.
                if (!axios.isCancel(error)) {
                    console.log(error);
                }
            });

        return () => {
            source.cancel();
        }

    }, [competition.attributes.remote_uuid])

    useEffect(() => {
        if (loadedCountryData.length === 0 || !mapReference.current) {
            return;
        }

        import('datamaps').then(datamap => {
            import('d3').then(d3 => {
                const map = new datamap.default({
                    element: mapReference.current,
                    responsive: true,
                    projection: 'mercator',
                    geographyConfig: {
                        popupTemplate: function (geo, data) {
                            // don't show tooltip if country don't present in dataset
                            if (!data) {
                                return;
                            }
                            return ['<div class="hoverinfo">',
                                '<strong>', geo.properties.name, '</strong>',
                                '<br>Count: <strong>', data.numberOfThings, '</strong>',
                                '</div>'].join('');
                        },
                        highlightOnHover: false
                    },
                    fills: { defaultFill: '#F5F5F5' },
                    data: formatData(d3.default, loadedCountryData),
                });
                map.legend();
            })
        });

    }, [loadedCountryData])

    return (
        <div style={{ height: '500px', maxWidth: '100%' }} ref={mapReference} />
    );
}

const formatData = (d3, countryData) => {
    var onlyValues = countryData.map(function (obj) {
        return obj.count;
    });
    var minValue = Math.min.apply(null, onlyValues),
        maxValue = Math.max.apply(null, onlyValues);

    var paletteScale = d3.scale.linear()
        .domain([minValue, maxValue])
        .range(["#EFEFFF", "#02386F"]);

    var data = {};
    for (var i = 0; i < countryData.length; i++) {
        data[countryData[i].country_three_letter] = {
            fillColor: paletteScale(countryData[i].count),
            numberOfThings: countryData[i].count
        }
    }

    return data;
}

export default ParticipantMap;
