// @flow

import React, { useCallback, useState } from 'react';
import { Task, User } from '../../types';
import { mergeTaskData } from '../../helpers';
import withAllUsers from '../../hoc/withAllUsers';
import { Select } from '../Ant';

type Props = {
    onChange: Function,
    task: Task,
    allUsers: Array<User>
}

const UserSelectionMultiTask = ({ task, allUsers, onChange }: Props) => {
    const [userSelectedValues, setUserSelectedValues] = useState(Array.isArray(task.attributes.data.values) ? task.attributes.data.values.map(u => u.id) : []);

    const onAutoCompleteChange = useCallback((selectedValues) => {
        setUserSelectedValues(selectedValues);

        onChange(mergeTaskData(task, {
            values: selectedValues.map(id => {
                let user = allUsers.find((u: User) => u.id === id);
                if (user) {
                    return {
                        id: id,
                        // @TODO, saving the name leaves us open to stale data, in the future but we could
                        // integrate against an endpoint for full name lookup in the future or even process
                        // it when tasks are loaded.
                        fullName: user.attributes.display_name,
                    }
                }
                return null;
            }).filter(user => !!user),
        }));
    }, [task, allUsers, onChange])

    return (
        <Select
            style={{ width: '100%' }}
            onChange={onAutoCompleteChange}
            value={userSelectedValues}
            mode={"multiple"}
            filterOption={(inputValue, option) => {
                return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }}
            placeholder="Select a collaborator"
        >
            {allUsers.map((u: any, i) =>
                <Select.Option key={u.id} value={u.id}>
                    {u.attributes.display_name || 'N/A'}
                </Select.Option>
            )}
        </Select>
    );
}


let ConnectedUserSelectionTask = withAllUsers(UserSelectionMultiTask);

ConnectedUserSelectionTask.getFormattedValue = (task: Task) => {
    if (ConnectedUserSelectionTask.isEmpty(task)) {
        return 'No users selected';
    }
    return task.attributes.data.values.map(u => u.fullName).join(', ');
}

ConnectedUserSelectionTask.getPreviewValue = (task: Task) => {
    return ConnectedUserSelectionTask.getFormattedValue(task);
}

ConnectedUserSelectionTask.isEmpty = task => !Array.isArray(task.attributes.data.values) || task.attributes.data.values.length === 0;


// Copy across static methods.
UserSelectionMultiTask.isEmpty = ConnectedUserSelectionTask.isEmpty;
UserSelectionMultiTask.displayName = 'User Selection Multi';
UserSelectionMultiTask.getFormattedValue = ConnectedUserSelectionTask.getFormattedValue;
UserSelectionMultiTask.getPreviewValue = ConnectedUserSelectionTask.getPreviewValue;

export default ConnectedUserSelectionTask;
export { UserSelectionMultiTask as _UserSelectionMultiTask }
