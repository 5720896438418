import React, { useState } from 'react';
import CollapsibleBoxTaskListTitle from '../Task/CollapsibleBoxTaskListTitle';
import CollapsibleBox from '../Common/CollapsibleBox';
import { useCallback } from 'react';
import usePlaybook from '../../api/usePlaybook';
import RemovableImage from '../RemovableImage';
import UploadDropArea from '../UploadDropArea';
import useFileUpload from '../../api/useFileUpload';
import { Spin, message } from 'antd';
import { extractErrorMessage } from '../../api';
import { get } from '../../helpers';

const PlaybookImageUpload = ({ playbookId, isOpen, onToggle, delta, close }) => {
    const { playbook, image, refresh, update, isLoading: isLoadingPlaybook } = usePlaybook(playbookId);
    const { handleUpload, isUploadInProgress, uploadProgress } = useFileUpload(`/jsonapi/playbook/playbook/${playbookId}/image`)
    const [isLoading, setIsLoading] = useState(false);

    const isComplete = useCallback(() => {
        return !!image;
    }, [image])

    const doHandleUpload = useCallback(files => {
        handleUpload(files)
            .then(result => {
                // Refresh the playbook and close the box.
                refresh(close)
            });
    }, [handleUpload, refresh, close]);

    const errorHandler = useCallback(error => {
        setIsLoading(false);
        console.log(error);
        message.error(extractErrorMessage(error));
    }, []);

    const handleRemoveImage = useCallback(() => {
        let updatedPlaybook = JSON.parse(JSON.stringify(playbook))
        updatedPlaybook.relationships.image.data = [];

        setIsLoading(true);
        update(updatedPlaybook)
            .then(response => {
                setIsLoading(false);
            })
            .catch(errorHandler);
    }, [playbook, errorHandler, update])

    return (
        <CollapsibleBox
            isOpen={isOpen()}
            onToggle={onToggle}
            className={'collapsible-task file'}
            title={<CollapsibleBoxTaskListTitle
                heading={'Add Main Image'}
                isComplete={isComplete()}
                count={delta}
            />}
        >
            <Spin spinning={isLoading || isLoadingPlaybook}>
                <div style={{ marginBottom: '24px' }} className="image-preview">
                    <RemovableImage
                        url={get(image, 'links.api_image_large.href', null)}
                        alt="Playbook"
                        style={{ maxWidth: '200px' }}
                        handleRemove={handleRemoveImage}
                    />
                </div>
                <UploadDropArea
                    handleUpload={doHandleUpload}
                    uploadInProgress={isUploadInProgress}
                    uploadProgress={uploadProgress}
                    uploadInstructions={"Supports single image upload. PNG or JPG supported under 2MB."}
                    options={{ multiple: false }}
                />
            </Spin>
        </CollapsibleBox>
    );
};

export default PlaybookImageUpload;
