import React from 'react';
import OpportunityGrid from '../Kanban/OpportunityGrid';
import DraggableTile from '../Kanban/DraggableTile';
import styled from 'styled-components';

const Wrapper = styled.div`
    background: #E5E5E5;
    padding: 32px 64px;

    @media (max-width: 960px) {
        padding: 16px 32px;
    }
`

const PinnedWorkflowList = ({ column, workflows, replaceWorkflow, swapWorkflows }) => {
    return <div className="drop-target-ref">
        <Wrapper>
            <OpportunityGrid>
                {workflows.map(workflow => {
                    workflow.isPinned = true;
                    workflow.isPinnedList = true;
                    return (
                        <DraggableTile
                            key={workflow.id}
                            id={workflow.id}
                            column={column.id}
                            workflow={workflow}
                            acceptType={`KanbanTile-${column.id}`}
                            findItem={id => {
                                let item = workflows.find(c => c.id === id);
                                let index = workflows.indexOf(item);
                                return { index, item };
                            }}
                            onHover={(draggedIndex, targetIndex) => {
                                swapWorkflows(draggedIndex, targetIndex);
                            }}
                            onDrop={(moveIndex, targetIndex, item) => {
                                replaceWorkflow(targetIndex, item.workflow);
                            }}
                        >
                            {column.render(workflow)}
                        </DraggableTile>
                    )
                })}
            </OpportunityGrid>
        </Wrapper>
    </div >
}

export default PinnedWorkflowList;
