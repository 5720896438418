import JsonApiRequestBuilder from "../helpers/JsonApiRequestBuilder";
import { jsonApi, withToken } from "../actions";
import { IMAGE_LARGE_CONSUMER_ID } from "../consumers";

let cache = {};
export function loadOrganisation(id) {
    if (!cache[id]) {
        cache[id] = jsonApi().get(`/jsonapi/organisation/organisation/${id}?include=logo&consumerId=${IMAGE_LARGE_CONSUMER_ID}`)
    }

    return cache[id];
}

export function createOrganisation(organisationName) {
    let request = new JsonApiRequestBuilder('organisation--organisation');
    request.attribute('name', organisationName);

    return withToken(token => {
        return jsonApi()
            .post('/jsonapi/organisation/organisation', request.build(), {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function updateOrganisation(updatedOrganisation) {
    return withToken(token => {
        return jsonApi()
            .patch(`/jsonapi/organisation/organisation/${updatedOrganisation.id}`, { data: updatedOrganisation }, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}
