import React from 'react';
import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

const StyledModal = styled(AntdModal)`
    && {
        background: rgba(244,244,244,1);

        .unearthed-modal-title {
            text-align: center;
            font-size: 28px;
            line-height: 39px;
            color: ${props => props.theme.greyBlack};
            margin-bottom: 32px;
        }

        .unearthed-modal-title-intro {
            font-size: 16px;
            line-height: 26px;
            color: ${props => props.theme.greyBlack};
            font-weight: 500;
            margin: 0 auto 64px auto;
            max-width: 768px;
            text-align: center;
        }

        .ant-modal-content {
            max-width: 95%;
            margin: 0 auto;
            background: none;
            box-shadow: none;
        }

        .ant-modal-header {
            background: none;
            box-shadow: none;
            border: none;
            max-width: 1000px;
            margin: 0 auto;
            padding: 16px 24px !important;
        }

        .ant-modal-body {
            max-width: 1000px;
            margin: 0 auto;
            padding: 0;
        }

        .ant-modal-footer {
            border: none;
            text-align: center;
            max-width: 1000px;
            margin: 0 auto;
            padding: 24px; 

            .ant-btn {
                height: 46px;
                padding: 0 45px;
                
                @media (max-width: 600px) {
                    padding: 0 25px;
                }
            }
        }

    }
`

const ModalTitle = ({ title, intro }) => (
    <div>
        <h2 className="unearthed-modal-title">{title}</h2>
        <div className="unearthed-modal-title-intro">{intro}</div>
    </div>
);

const maskStyle = {
    background: 'rgba(244,244,244,1)',
    zIndex: 99,
}

const Modal = ({ ...props }) => {
    return (
        <StyledModal
            {...props}
            title={<ModalTitle title={props.title} intro={props.titleIntro} />}
            maskStyle={maskStyle}
            maskClosable={false}
        />
    )
}

export default Modal;
