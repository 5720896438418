// @flow

import React, { useCallback, useState } from 'react';
import { Form, Input, Checkbox } from 'antd';
import { get, mergeEntityAttributes } from '../helpers';
import { createEntity, updateEntity, deleteEntity } from '../api/entity';
import EditEntityForm from './EditEntityForm';
import { Select } from '../components/Ant';
import CkeditorAdmin from '../components/CkeditorAdmin';

type Props = {
    form: Object,
    entity: Object,
    onAfterSave?: Function,
}

const EditContentBlockForm = ({ form, entity, onAfterSave }: Props) => {
    const [htmlContent, setHtmlContent] = useState(get(entity, 'attributes.content.value'));

    const handleDelete = useCallback(result => {
        return deleteEntity(entity)
            .then(result => Promise.resolve({ text: 'Content block deleted', result: result }));;
    }, [entity])

    const handleSave = useCallback(result => {
        const attributes = {
            ...result,
            content: htmlContent,
        }
        if (entity.id) {
            let updatedEntity = mergeEntityAttributes(entity, attributes);
            return updateEntity(updatedEntity)
                .then(result => Promise.resolve({ text: 'Content block updated', result: result }));
        }

        return createEntity('content_block', attributes)
            .then(result => {
                form.resetFields();
                return Promise.resolve({ text: 'Content block created', result: result })
            });
    }, [entity, form, htmlContent])

    return (
        <EditEntityForm
            form={form}
            handleSave={handleSave}
            handleDelete={handleDelete}
            onAfterSave={onAfterSave}
            showDelete={false}
        >
            <Form.Item label="Title">
                {form.getFieldDecorator('title', {
                    rules: [{ required: true, message: 'Please input the title.' }],
                    initialValue: entity.attributes.title,
                })(
                    <Input name="title" autoFocus />
                )}
            </Form.Item>

            <Form.Item label="Content">
                <CkeditorAdmin
                    value={htmlContent}
                    onChange={setHtmlContent}
                />
            </Form.Item>

            <Form.Item label="Placement">
                {form.getFieldDecorator('placement', {
                    initialValue: get(entity, 'attributes.placement') || '',
                })(
                    <Select style={{ maxWidth: '300px' }}>
                        <Select.Option value="">- Select -</Select.Option>
                        <Select.Option key={'home'} value={'homepage'}>Homepage</Select.Option>
                    </Select>
                )}
            </Form.Item>

            <Form.Item label="Order">
                {form.getFieldDecorator('weight', {
                    initialValue: get(entity, 'attributes.weight') || 0,
                })(
                    <Select style={{ maxWidth: '300px' }}>
                        <Select.Option value="">- Select -</Select.Option>
                        {[...Array(20).keys()].map(i => <Select.Option key={i} value={i}>{i}</Select.Option>)}

                    </Select>
                )}
            </Form.Item>

            <Form.Item>
                {form.getFieldDecorator('published', {
                    initialValue: !!entity.attributes.published,
                    valuePropName: 'checked'
                })(
                    <Checkbox
                        key="1"
                        name="published"
                        value="1"
                    >Is Published</Checkbox>
                )}
            </Form.Item>
        </EditEntityForm>
    );
};

export default Form.create({ name: 'edit_content_block' })(EditContentBlockForm);
