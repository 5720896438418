// @flow

import React, { useState, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { Form, Icon, message } from 'antd';
import FormActions from '../components/Form/FormActions';
import { Button, Input } from '../components/Ant';
import { login, extractErrorMessage } from '../api';

type Props = {
    form: Object,
    history: Object,
    match: Object,
    checkLogin: Function,
    isLoggedIn: bool,
}

const LoginForm = ({ form, history, checkLogin, match, isLoggedIn }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = useCallback(e => {
        e.preventDefault();

        form.validateFields()
            .then(result => {
                setIsLoading(true);
                login(result.email, result.password)
                    .then(response => {
                        setIsLoading(false);

                        // If we are on the login page, send them to the homepage.
                        if (match.path === '/user/login') {
                            history.push('/');
                        }
                        else {
                            // Otherwise, check if we are logged in which will re-render the current page.
                            checkLogin();
                        }
                    })
                    .catch(error => {
                        setIsLoading(false);
                        message.warning(extractErrorMessage(error));
                    })
            })
            .catch(error => message.warning('Please complete all fields'));

    }, [form, checkLogin, history, match])

    if (isLoggedIn) {
        return <p>You are already logged in, please <a href="/">click here</a>.</p>
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Item>
                {form.getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please enter your email address.' }],
                    initialValue: '',
                })(
                    <Input
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="email"
                        placeholder="Email"
                        autoComplete="username"
                        name="email"
                    />
                )}
            </Form.Item>
            <Form.Item>
                {form.getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please enter your password.' }],
                    initialValue: '',
                })(
                    <Input
                        prefix={<Icon type="password" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        name="password"
                    />
                )}
            </Form.Item>

            <FormActions>
                <Link to="/user/password">Reset password</Link>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    name="login"
                >Login</Button>
            </FormActions>
        </Form>
    );
};

export default withRouter(Form.create({ name: 'login_form' })(LoginForm));
