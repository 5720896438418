import { withToken, http } from './api';
import { IMAGE_LARGE_CONSUMER_ID } from '../consumers';

export function uploadFile(entityFieldUrl, file, uploadProgressCallback) {
    return withToken(token => {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onerror = e => {
                reject(e);
            }
            fileReader.onload = async (e) => {
                let uploadResult = await http()
                    .post(entityFieldUrl, e.target.result, {
                        headers: {
                            'Content-Type': 'application/octet-stream',
                            'Content-Disposition': 'file; filename="' + file.name + '"',
                            'X-CSRF-Token': token,
                            'X-Consumer-ID': IMAGE_LARGE_CONSUMER_ID,
                        },
                        onUploadProgress: function (progressEvent) {
                            if (typeof uploadProgressCallback === 'function') {
                                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                uploadProgressCallback(percentCompleted);
                            }
                        }
                    });
                resolve(uploadResult);
            };
            fileReader.readAsArrayBuffer(file);
        });
    })
}
