import React from 'react';
import PlaybookImage from '../Playbook/PlaybookImage';

const TaskWorkflowImage = ({ taskWorkflow, ...props }) => {
    return <PlaybookImage
        playbookId={taskWorkflow.attributes.playbook_id}
        {...props}
    />
};

export default TaskWorkflowImage;
