// @flow

import React from 'react';
import styled from 'styled-components';
import { TaskWorkflow } from '../../types';
import CollaboratorsTeaser from '../CollaboratorsTeaser';
import { textTrim } from '../../helpers';
import getConfig from '../../instance-config';
import { Icon } from 'antd'

const FloatingIcon = styled(Icon)`
    position: absolute;
    right: 16px;
    top: 16px;
    color: ${props => props.theme.darkGray};
`

const Wrapper = styled.div`
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px;
    height: 110px;
    width: 248px;
    max-width: 100%;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1);
    background: ${props => props.pinned && !props.pinnedList ? "#EEE" : "#FFF"};
    opacity: ${props => props.pinned && !props.pinnedList ? "0.4" : "1"};
    transition: box-shadow 150ms ease-in-out;
    word-break: break-word;

    &:hover {
        box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.3);
    }
`

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

type Props = {
    workflow: TaskWorkflow,
}

const KanbanOpportunityTile = ({ workflow }: Props) => {
    return (
        <Wrapper pinned={workflow.isPinned} pinnedList={workflow.isPinnedList} style={{ ...(workflow.ghost ? { opacity: 0.5 } : {}) }}>
            {workflow.isPinned ? <FloatingIcon type="pushpin"/> : null}
            <FlexWrapper>
                <div>
                    <div
                        style={{
                            color: getConfig().greyBlack,
                            fontWeight: 500,
                            maxWidth: workflow.isPinned ? 'calc(100% - 25px)' : ''
                        }}
                    >
                        {textTrim(workflow.attributes.title, 50) || 'Describe your opportunity...'}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <CollaboratorsTeaser
                        playbookId={workflow.attributes.playbook_id}
                        size="small"
                        hideOwner={false}
                    />
                </div>
            </FlexWrapper>
        </Wrapper>
    );
};

export default KanbanOpportunityTile