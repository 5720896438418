// @flow

import React from 'react';
import NewEntityModal from './NewEntityModal';
import EditContentBlockForm from '../../Forms/EditContentBlockForm';

type Props = {
    onAfterSave: Function,
    entity?: Object,
    buttonRender?: Function,
}

const CreateNewContentBlock = ({ onAfterSave, entity = {
    attributes: {
        title: '',
        published: 1,
    }
}, buttonRender = null }: Props) => {

    return <NewEntityModal
        entity={entity}
        title={"Create content"}
        EntityForm={EditContentBlockForm}
        onAfterSave={onAfterSave}
    />

};

export default CreateNewContentBlock;
