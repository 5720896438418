import { makeGetApiCall } from "./api";
import { getRequestKey } from "./challenges";
import encodeObjectToQueryString from "../helpers/encodeObjectToQueryString";
import { IMAGE_LARGE_CONSUMER_ID } from "../consumers";

export function loadTaskWorkflow(type, id, extraQuery = {}) {
    let include = 'tasks,tasks.uid,tasks.attachments,image,tasks.uid.field_picture';
    if (typeof extraQuery.include !== 'undefined') {
        include += ',' + extraQuery.include;
        delete extraQuery.include;
    }

    let query = {
        include: include,
        consumerId: IMAGE_LARGE_CONSUMER_ID,
        'sort[sort-created][path]': 'changed',
        'sort[sort-created][direction]': 'DESC',
        'filter[tasks.entity.is_archived]': '0',
        ...extraQuery,
    };

    let queryString = encodeObjectToQueryString(query);

    return function (dispatch, getState) {
        return makeGetApiCall(dispatch, getRequestKey(type), `/jsonapi/task_workflow/${type}/${id}?${queryString}`)
            .then(result => {
                if (result.type === 'API_FAILURE') {
                    return Promise.reject({
                        message: result.payload,
                        response: result.response,
                    });
                }
                return Promise.resolve(result);
            })
            .catch(error => Promise.reject(error));
    }
}
