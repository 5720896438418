import { IMAGE_LARGE_CONSUMER_ID } from '../consumers';
import encodeObjectToQueryString from '../helpers/encodeObjectToQueryString';
import { ApiContext } from './ApiContext';
import { useContext, useEffect, useState, useCallback } from 'react';
import { extractErrorMessage } from '.';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getTaskWorkflow, getTaskWorkflowTasks, getRelationship } from '../selectors';

const useTaskWorkflow = (type, id, extraQuery, isRemote = false) => {
    const api = useContext(ApiContext);
    const [status, setStatus] = useState('loading');
    const [workflowId, setWorkflowId] = useState(id);
    const [isFailedMessage, setIsFailedMessage] = useState('');

    const dispatch = useDispatch();
    const taskWorkflow = useSelector(state => getTaskWorkflow(state, type, workflowId), shallowEqual);
    const tasks = useSelector(state => getTaskWorkflowTasks(state, type, workflowId), shallowEqual)
    const owner = useSelector(state => getRelationship(state, 'uid', getTaskWorkflow(state, type, workflowId)), shallowEqual)
    const collaborators = useSelector(state => getRelationship(state, 'collaborators', getTaskWorkflow(state, type, workflowId)), shallowEqual)
    const image = useSelector(state => getRelationship(state, 'image', getTaskWorkflow(state, type, workflowId)), shallowEqual)
    const tags = useSelector(state => getRelationship(state, 'tags', getTaskWorkflow(state, type, workflowId)), shallowEqual)
    const taskTemplate = useSelector(state => getRelationship(state, 'task_template', getTaskWorkflow(state, type, workflowId)), shallowEqual)

    const saveResult = useCallback(result => {
        // We have to grab the ID like this because if we've done a query based on remote ID then we
        // need the local ID for the state query to work.
        setWorkflowId(Array.isArray(result.data.data) ? result.data.data[0].id : result.data.data.id);

        dispatch({
            type: 'API_SUCCESS',
            payload: result.data,
        })
    }, [dispatch]);

    const performRequest = useCallback(() => {
        setStatus('loading');
        setWorkflowId(id);

        const url = isRemote ? getTaskWorkflowFromRemoteUuidUrl(type, id) : getTaskWorkflowUrl(type, id, extraQuery || {});
        api.get(url)
            .then(result => {
                // If result is empty, do nothing, probably means the request was cancelled.
                if (!result) {
                    // We cannot set the status of the request here because it was cancelled.
                    return;
                }

                saveResult(result);
                setStatus('complete');
            })
            .catch(error => {
                setStatus('failed');
                setIsFailedMessage(extractErrorMessage(error));
            })

        return () => {
            api.cancel(url);
        }
    }, [type, id, extraQuery, api, saveResult, isRemote]);

    useEffect(() => {
        if (!id) {
            return;
        }

        return performRequest();
    }, [id, performRequest])

    return {
        // When you navigate between pages that both use this hook, the id will change
        // and because of our setWorkflowId() work around for remote Id's, there will be
        // a render where status === 'complete' but taskWorkflow is null because it has not yet
        // been loaded. We should split out a new hook for remote ID's to fix this.
        isLoading: status === 'loading' || !taskWorkflow,
        isFailed: status === 'failed',
        failedErrorMessage: isFailedMessage,
        taskWorkflow: taskWorkflow,
        tasks: tasks,
        owner: owner,
        image: image,
        tags: tags,
        taskTemplate: taskTemplate,
        collaborators: collaborators,
        clearCache: () => {
            api.clearCache();
        },

        update: (workflow) => {
            ['drupal_internal__id', 'changed'].forEach(field => delete workflow.attributes[field]);

            return api.patch(workflow.links.self.href, { data: workflow })
                .then(response => saveResult(response));
        },
        refresh: () => {
            api.clearCache();
            performRequest();
        }
    }
};

const defaultInclude = [
    'tasks',
    'tasks.uid',
    'tasks.attachments',
    'image',
    'tasks.uid.field_picture',
];
const defaultQuery = {
    'consumerId': IMAGE_LARGE_CONSUMER_ID,
    'sort[sort-created][path]': 'created',
    'sort[sort-created][direction]': 'DESC',
}

export function getTaskWorkflowUrl(type, id, extraQuery = {}) {
    let include = defaultInclude.join(',');
    if (typeof extraQuery.include !== 'undefined') {
        include += ',' + extraQuery.include;
        delete extraQuery.include;
    }
    let query = {
        include: include,
        ...defaultQuery,
        ...extraQuery,
    };

    let queryString = encodeObjectToQueryString(query);
    return `/jsonapi/task_workflow/${type}/${id}?${queryString}`
}

function getTaskWorkflowFromRemoteUuidUrl(type, remoteUuid) {
    let query = {
        include: defaultInclude,
        'filter[remote_uuid]': remoteUuid,
        ...defaultQuery,
    };

    let queryString = encodeObjectToQueryString(query);
    return `/jsonapi/task_workflow/${type}?${queryString}`
}

export default useTaskWorkflow;
