import { useState, useContext, useEffect } from "react";
import { ApiContext } from "./ApiContext";

function useKanbanColumnMetadata(columnId) {
    const [metadata, setMetadata] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const api = useContext(ApiContext);

    useEffect(() => {
        setIsLoading(true);
        api.get(`/api/unearthed/kanban-column-metadata/${columnId}`)
            .then(response => {
                setMetadata(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            })
    }, [api, columnId]);

    return {
        metadata,
        isLoading,
    }
}

export default useKanbanColumnMetadata;
