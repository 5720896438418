import React, { useState } from 'react';
import CollapsibleBox from '../Common/CollapsibleBox';
import { Select, Button, Spin } from 'antd';
import CollapsibleBoxTaskListTitle from '../Task/CollapsibleBoxTaskListTitle';
import { Spacer } from '../Common';
import { useCallback } from 'react';
import useOrganisations from '../../api/useOrganisations';

const OrganisationSelect = ({ isOpen, onToggle, isComplete, heading, delta, selectedOrgId, onSave, isLoading }) => {
    let { organisations } = useOrganisations();
    const [selectedOrg, setSelectedOrg] = useState(null);

    const handleClick = useCallback(() => {
        onSave(selectedOrg);
    }, [selectedOrg, onSave])

    return (
        <Spin spinning={isLoading}>
            <CollapsibleBox
                isOpen={isOpen()}
                onToggle={onToggle}
                title={<CollapsibleBoxTaskListTitle
                    heading="Organisation"
                    isComplete={!!selectedOrgId}
                    count={delta}
                />}
            >
                {isLoading ? null :
                    <Select
                        defaultValue={selectedOrgId}
                        style={{ width: '200px', maxWidth: '300px' }}
                        onChange={setSelectedOrg}
                    >
                        <Select.Option value="">- Select -</Select.Option>
                        {organisations.map(organisation => <Select.Option key={organisation.id} value={organisation.id}>{organisation.attributes.name}</Select.Option>)}
                    </Select>
                }

                <Spacer size="small" style={{ overflow: 'hidden' }}>
                    <Button
                        style={{ float: 'right' }}
                        type="primary"
                        onClick={handleClick}
                    >
                        {isComplete ? 'Save Changes' : 'Done'}
                    </Button>
                </Spacer>
            </CollapsibleBox>
        </Spin>
    );
};

export default OrganisationSelect;