// @flow

import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';

const IconWrapper = styled.div`
    border: 2px solid #000;
    border-radius: 100%;
    height: ${props => props.height ? props.height : 25}px;
    width: ${props => props.width ? props.width : 25}px;
    flex-basis: ${props => props.width ? props.width : 25}px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        font-size: 11px;
        color: #000;
    }
`

type Props = {
    color: string,
    iconStyles?: Object,
    wrapperStyles?: Object,
}

const CheckIcon = ({ color = '#BBB', wrapperStyles = {}, iconStyles = {}, iconOverride, ...props }: Props) => {
    return (
        <IconWrapper style={{ borderColor: color, ...wrapperStyles }} {...props}>
            {iconOverride ?
                iconOverride :
                <Icon type="check" style={{ color: color, ...iconStyles }} />
            }

        </IconWrapper>
    );
};

export default CheckIcon;
