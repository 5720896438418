import styled from 'styled-components';
import { Layout } from 'antd';
const { Content } = Layout;

export default styled(Content)`
    margin: 16px auto 16px auto;
    width: 100%;
    max-width: 1640px;
    padding: 16px;

    @media (max-width: 600px) {
        margin: 0;
        padding: 8px;
    }
`
