import React from 'react';
import { connect } from 'react-redux';
import useJsonApiGet from '../api/useJsonApiGet';
import { Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import setPageTitle from '../hoc/setPageTitle';
import TaskWorkflowTile from '../components/TaskWorkflow/TaskWorkflowTile';
import { saveJsonApiResponse } from '../api';
import TaskListPreview from '../components/Task/TaskListPreview';
import { getTaskWorkflowTasks, getRelationship, getPlaybookWorkflows } from '../selectors';
import TaskPreview from '../components/Task/TaskPreview';
import qs from 'qs';
import GenericError from '../components/Common/GenericError';
import { IMAGE_LARGE_CONSUMER_ID } from '../consumers';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    max-width: 375px;
    margin: 100px auto;
`

const WrapperInner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

const WorkflowStatusPage = ({ match, location, saveResponse, ...props }) => {
    const query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
    });
    const workflowTypeId = match.params.workflowTypeId;
    const { response, jsonapi } = useJsonApiGet(`/api/unearthed/jwt-entity?token=${query.token}&consumerId=${IMAGE_LARGE_CONSUMER_ID}`, saveResponse);
    const playbook = response.data.data || {};

    if (response.isFailed) {
        return <div style={{ marginTop: '60px' }}>
            <GenericError
                title="Something went wrong"
                message={response.errorMessage}
                hideRefresh={true}
            />
        </div>
    }

    const workflow = response.isLoading ? null : getPlaybookWorkflows(jsonapi, playbook.id).find(workflow => workflow.type === `task_workflow--${workflowTypeId}`);
    return (
        <Wrapper>
            <Spin spinning={response.isLoading} style={{ width: '100%' }}>
                {!response.isLoading ?
                    <WrapperInner>
                        <TaskWorkflowTile
                            taskWorkflow={workflow}
                        />
                        <br />
                        <TaskListPreview
                            parentType={workflowTypeId}
                            parentId={workflow.id}
                            tasks={getTaskWorkflowTasks(jsonapi, workflowTypeId, workflow.id).filter(c => !c.attributes.is_complete)}
                        >
                            {task => (
                                <TaskPreview
                                    key={task.id}
                                    task={task}
                                    parentId={workflow.id}
                                    previewText={''}
                                    user={getRelationship(jsonapi, 'uid', task)}
                                    isLinked={false}
                                />
                            )}
                        </TaskListPreview>
                    </WrapperInner>
                    : null
                }
            </Spin>
        </Wrapper>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        saveResponse: response => dispatch(saveJsonApiResponse(response))
    }
}

export default connect(null, mapDispatchToProps)(setPageTitle(withRouter(WorkflowStatusPage), 'Status Update'));
