// @flow

import React, { Component } from 'react';
import { Input, Button, Icon } from 'antd';
import { mergeTaskData } from '../../helpers';
import { Task } from '../../types';

type Props = {
    onChange: Function,
    task: Task,
}

type State = {
    count: number,
    values: Array<string>,
}

class TextfieldMultiTask extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        let values = Array.isArray(props.task.attributes.data.values) ? props.task.attributes.data.values : [];
        this.state = {
            count: values.length + 1,
            // Initialise with the values plus an extra for the additional text box.
            values: values.concat(''),
        }
        this.onChange = this.onChange.bind(this);
        this.remove = this.remove.bind(this);
    }

    static isEmpty(task: Task) {
        return typeof task.attributes.data.values === 'undefined' || task.attributes.data.values.length === 0
    }

    remove: Function
    remove(i: number) {
        let newValues = this.state.values.filter((value, index) => index !== i);
        this.setState({ values: newValues, count: this.state.count - 1 });

        this.props.onChange(mergeTaskData(this.props.task, { values: newValues }));

    }

    onChange: Function
    onChange(el: Object, i: number) {
        let newValues = this.state.values;
        newValues[i] = el.target.value;
        this.setState({
            values: newValues,
        })
        this.props.onChange(mergeTaskData(this.props.task, { values: newValues }));
    }

    render() {
        let inputs = [];
        for (let i = 0; i < this.state.count; i++) {
            inputs.push(<Input
                key={i}
                value={this.state.values[i]}
                onChange={e => this.onChange(e, i)}
                type={"text"}
                style={{ marginBottom: '16px' }}
                addonAfter={<Icon type="close" className="hoverable" onClick={() => this.remove(i)} />}
            />)
        }

        return (
            <div>
                {inputs}
                <Button onClick={() => this.setState({ count: this.state.count + 1 })}>Add more</Button>
            </div>
        );
    }

    static getFormattedValue = (task: Task) => {
        return TextfieldMultiTask.getPreviewValue(task);
    }

    static getPreviewValue = (task: Task) => {
        if (!Array.isArray(task.attributes.data.values)) {
            return '';
        }

        return task.attributes.data.values.join(', ');
    }

}

TextfieldMultiTask.displayName = 'Textfield Multiple';

export default TextfieldMultiTask;
