import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useSaveResult = result => {
    const dispatch = useDispatch();

    const handleSave = useCallback(result => {
        dispatch({
            type: 'API_SUCCESS',
            payload: result.data,
        })
    }, [dispatch]);

    return handleSave
}

export default useSaveResult;
