// @flow

import * as React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';
import Image from './Image';

const CloseIcon = styled(Icon)`
    position: absolute;
    top: -40px;
    right: 0;
    font-size: 22px;
    cursor: pointer;
`

type Props = {
    url: ?string,
    alt: string,
    handleRemove: Function,
    style?: Object,
}

const RemovableImage = ({ url, alt, handleRemove, style = {} }: Props) => {
    if (!url) {
        return null;
    }

    return (
        <div style={{ position: 'relative', ...style }}>
            <CloseIcon
                type="close-circle"
                onClick={handleRemove}
            />
            <Image
                url={url}
                alt={alt}
                style={style}
            />
        </div>
    );
};

export default RemovableImage;
