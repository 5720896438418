// @flow

import React from 'react';
import usePlaybook from '../../api/usePlaybook';
import TaskWorkflowLink from '../TaskWorkflow/TaskWorkflowLink';

type Props = {
    playbookId: string,
    children: any,
}

const PlaybookLink = ({ playbookId, children, ...props }: Props) => {
    const { workflows, isLoading } = usePlaybook(playbookId);

    if (workflows.length === 0 || isLoading === true) {
        return null;
    }

    return (
        <TaskWorkflowLink
            workflow={workflows[0]}
            {...props}
        >
            {children}
        </TaskWorkflowLink>
    );
};

export default PlaybookLink;
