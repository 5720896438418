// @flow

import React from 'react';
import { Icon } from 'antd';
import { message } from './Ant';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Media from 'react-media';

const iconStyle = {
    fontSize: '22px',
}

type Props = {
    text: string,
    children?: any,
}

const ShareToClipboard = ({ text, children }: Props) => {
    return (
        <CopyToClipboard
            text={text}
            onCopy={() => message.success('Link copied to clipboard')}
        >
            {children ? children : <div style={{ display: 'flex' }} className="hoverable">
                <Icon
                    style={iconStyle}
                    type="link"
                />
                <Media query="(min-width: 600px)">
                    <span style={{ marginLeft: '10px' }}>Share</span>
                </Media>
            </div>}
        </CopyToClipboard>
    )
}

export default ShareToClipboard;
