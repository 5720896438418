import qs from 'qs';
import { useEffect, useState, useContext } from "react";
import { extractErrorMessage } from '.';
import { ApiContext } from './ApiContext';
import { useCallback } from 'react';
import { get } from '../helpers';
import { IMAGE_LARGE_CONSUMER_ID } from '../consumers';

const usePlaybookUpdates = (playbookId, extra) => {
    const api = useContext(ApiContext);
    const [updates, setUpdates] = useState([]);
    const [responseStatus, setResponseStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    const performRequest = useCallback(() => {
        setResponseStatus('loading');
        const query = {
            'sort[date][path]': 'date',
            'sort[date][direction]': 'DESC',
            'filter[playbook][path]': 'playbook.id',
            'filter[playbook][value]': playbookId,
            include: 'uid,image,attachments',
            consumerId: IMAGE_LARGE_CONSUMER_ID,
            ...(typeof extra === 'object' ? extra : {}),
        };

        let url = `/jsonapi/playbook_update/playbook_update?${qs.stringify(query)}`;
        api
            .get(url)
            .then(result => {
                // Request was cancelled.
                if (!result) {
                    console.log('request cancelled');
                    return;
                }
                setResponseStatus('success');

                // Populate the authors and images onto the entity, this could be done via the store
                // which has helpers for relationships.
                result.data.data.forEach(update => {
                    update.author = result.data.included.find(obj => obj.id === get(update, 'relationships.uid.data.id'))
                    update.image = result.data.included.find(obj => obj.id === get(update, 'relationships.image.data.id'))
                    update.attachments = result.data.included
                        .filter(obj => get(update, 'relationships.attachments.data', [])
                            .map(attachment => attachment.id)
                            .includes(obj.id)
                        );
                })

                setUpdates(result.data.data);
            })
            .catch(error => {
                setResponseStatus('failed');
                setErrorMessage(extractErrorMessage(error));
            });

        return () => {
            api.cancel(url);
        }
    }, [api, extra, playbookId]);

    useEffect(() => {
        return performRequest();
    }, [performRequest])

    return {
        isLoading: responseStatus === 'loading',
        isFailed: responseStatus === 'failed',
        errorMessage: errorMessage,
        updates: updates,
        refresh: () => {
            api.clearCache();
            return performRequest();
        },
    }
}

export default usePlaybookUpdates;
