// @flow

import React, { useState } from 'react';
import { Modal, Button, message } from '../Ant';
import { TaskWorkflow } from '../../types';
import ProgressWithTimer from '../ProgressWithTimer'
import mergeEntityAttributes from '../../helpers/mergeEntityAttributes'
import { withRouter } from 'react-router-dom';

type Props = {
    competition: TaskWorkflow,
    update: Function,
    children?: Function,
    history: Function,
}

const LaunchCompetitionPublishModal = ({ competition, update, children, history }: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [overrideCompetition, setCompetition] = useState(null);

    const handleSubmit = () => {
        const comp = overrideCompetition || competition
        return update(mergeEntityAttributes(comp, {
            workflow_status: 'ready_to_publish',
        }))
            .then(result => {
                return comp
            })
            .catch(error => {
                message.warning(error);
                return null
            });
    }

    
    return (
        <div>
            {typeof children === 'function' ?
                children((overrideComp) => {
                    setIsVisible(true);
                    // Workaround to support kanban, may need a re-think.
                    if (overrideComp) {
                        setCompetition(overrideComp);
                    }
                })
                : <Button className="launch-competition" type="primary" onClick={() => setIsVisible(true)}>Go Live</Button>
            }

            <Modal
                title={'Ready to Go Live!'}
                titleIntro={'Congratulations! You have built your challenge. Ready for the community to solve it?'}
                visible={isVisible}
                onCancel={() => setIsVisible(false)}
                width={'100%'}
                footer={isLoading ? null : [
                    <Button key="cancel" onClick={() => setIsVisible(false)}>Cancel</Button>,
                    <Button type="primary" key="create" onClick={() => setIsLoading(true)}>Go Live</Button>,
                ]}
                className="launch-competition-modal"
            >
                {(overrideCompetition || competition) && isLoading ?
                    <ProgressWithTimer
                        hasStarted={true}
                        actionCallback={handleSubmit}
                        afterComplete={competition => {
                            if (competition) {
                                message.success('Your competition has been sent to Unearthed');
                                history.push(`/challenge/${competition.attributes.playbook_id}/live`);
                            }
                        }}
                    >progress</ProgressWithTimer>
                    : null}
            </Modal>
        </div>
    );
};

export default withRouter(LaunchCompetitionPublishModal);