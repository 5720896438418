import React from 'react';
import LaunchCompetitionPublishModal from '../Competition/LaunchCompetitionPublishModal';
import usePlaybook from '../../api/usePlaybook';
import DropButton from '../Common/DropButton';
import { hasPermission } from '../../helpers/HasPermission';
import withPermissions from '../../hoc/withPermissions';

const canGoLive = competition => {
    if (competition.attributes.completed_tasks_count !== competition.attributes.tasks_count) {
        return false;
    }

    if (competition.attributes.title.trim().length === 0) {
        return false;
    }

    // Already live
    if (competition.attributes.workflow_status !== 'build') {
        return false;
    }

    return true;
};

const BuildActions = ({ competition, menu, playbookId, permissions }) => {
    const { update } = usePlaybook(playbookId);

    return <DropButton
        type="primary"
        isDisabled={!canGoLive(competition) || !hasPermission(permissions, 'publish competition')}
        buttonText={
            <LaunchCompetitionPublishModal
                update={update}
            >
                {showModal => (
                    <span onClick={() => showModal(competition)}>Go Live</span>
                )}
            </LaunchCompetitionPublishModal>
        }
        menu={menu}
    />
};

export default withPermissions(BuildActions);
export { canGoLive }
