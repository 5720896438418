import React from 'react';
import Tile from '../Presentation/Tile';
import styled from 'styled-components';
import Avatar from '../Avatar';
import withCurrentUser from '../../hoc/withCurrentUser';
import { Tag, Icon } from 'antd';
import CreateNewChallenge from '../Modals/CreateNewChallenge';

const UserWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: -20px;
    padding: 0 8px;
`

const Wrapper = styled.div`
    max-width: 300px;
    position: relative;
`

const IconWrapper = styled.div`
    visibility: hidden;
    opacity: 0;
    position: absolute;
    // Hide of the card plus padding.
    top: 108px;
    left: 50%;
    margin-left: -55px;
    margin-top: -21px;
    display: flex;
    flex-direction: column;
`

const Overlay = styled.div`
    width: 229px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    border-radius: 10px;
    transition: background 150ms ease-in;

    &:hover {
        background: rgba(0,0,0,0.2);

        ${IconWrapper} {
            visibility: visible;
            transition: opacity 150ms ease-in;
            opacity: 1;
        }
    }

    @media (max-width: 1025px) {
        width: 132px;
    }
`

const EmptyCard = ({ currentUser }) => {
    return (
        <CreateNewChallenge>
            {openModal => (
                <Wrapper onClick={openModal}>
                    <Overlay>
                        <IconWrapper>
                            <Icon type="plus-circle" className="icon-large" />
                            <span>Create Challenge</span>
                        </IconWrapper>
                    </Overlay>
                    <Tile
                        size="large"
                        title={"My First Challenge"}
                        above_content={<UserWrapper>
                            <Avatar
                                user={currentUser}
                                size={'large'}
                            />

                        </UserWrapper>}
                    >
                        <Tag color="blue">Get Involved</Tag>
                    </Tile>
                </Wrapper>
            )}
        </CreateNewChallenge>
    )
}

export default withCurrentUser(EmptyCard);