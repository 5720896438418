// @flow

import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { duplicateTaskTemplate } from '../../actions';
import { Modal, Input, message } from '../Ant';
import Spacer from '../Common/Spacer';
import { Form, Icon } from 'antd';

type Props = {
    template: Object,
    doDuplicateTaskTemplate: Function,
    history: Object,
}

const DuplicateTaskTemplate = ({ template, doDuplicateTaskTemplate, history }: Props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newName, setNewName] = useState('');

    const onClick = useCallback(() => {
        if (newName.trim().length === 0) {
            message.warning('Please name your new task workflow');
            return;
        }

        doDuplicateTaskTemplate(template, newName)
            .then(response => {
                message.success('Task workflow has been duplicated');
                setNewName('');
                setIsModalVisible(false);
                history.push('/admin/task-templates/edit/' + response.payload.data.data.id);
            })
            .catch(error => {
                message.warning('Unable to duplicate template');
                console.log(error);
            })
    }, [newName, history, template, doDuplicateTaskTemplate]);

    return (
        <div>
            <span className='duplicate' onClick={() => setIsModalVisible(!isModalVisible)}><Icon type="file-add" style={{ marginRight: '8px' }} />Duplicate</span>
            <Modal
                title={"Duplicate task workflow '" + template.attributes.label + "'"}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={onClick}
            >
                <Spacer>
                    <Form.Item required={true} label="Name the duplicated template">
                        <Input name="newName" onChange={e => setNewName(e.target.value)} />
                    </Form.Item>
                </Spacer>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        doDuplicateTaskTemplate: (...args) => dispatch(duplicateTaskTemplate(...args)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DuplicateTaskTemplate));
export { DuplicateTaskTemplate as _DuplicateTaskTemplate }
