import React from 'react';
import { connect } from 'react-redux';
import ContentWrapper from '../../layouts/ContentWrapper';
import setPageTitle from '../../hoc/setPageTitle';
import { Box } from '../../components/Common';
import { Table } from 'antd';
import PublishAction from '../../components/TaskTemplate/PublishAction';
import useJsonApiGet from '../../api/useJsonApiGet';
import CreateNewContentBlock from '../../components/Modals/CreateNewContentBlock';
import { updateEntity } from '../../api/entity';
import { saveJsonApiResponse } from '../../api';
import EntityActions from '../../components/Entity/EntityActions';

const ManageContent = ({ contentBlocks, doUpdateEntity, doSaveJsonApiResponse }) => {
    const { response, refresh } = useJsonApiGet('/jsonapi/content_block/content_block?sort[title][path]=title&sort[title][direction]=ASC', doSaveJsonApiResponse);

    const columns = [{
        title: 'Title',
        dataIndex: 'attributes.title',
        key: 'title',
    }, {
        title: 'Placement',
        dataIndex: 'attributes.placement',
        key: 'placement',
    }, {
        title: 'Status',
        render: record => <PublishAction publishAttribute="published" entity={record} update={doUpdateEntity} />,
    },
    {
        title: '',
        render: record => <EntityActions
            entity={record}
            editUrl={'/admin/content/edit/' + record.id}
        />
    }];

    return (
        <ContentWrapper>
            <h1>Manage Content</h1>

            <Box>
                <div style={{ textAlign: 'right', marginBottom: '16px' }}>
                    <CreateNewContentBlock
                        onAfterSave={refresh}
                    />
                </div>

                <Table
                    dataSource={contentBlocks}
                    loading={response.isLoading}
                    rowKey="id"
                    columns={columns}
                    pagination={{
                        pageSize: 50,
                    }}
                />
            </Box>
        </ContentWrapper>
    );
};

const mapStateToProps = state => {
    return {
        contentBlocks: typeof state.jsonApi['content_block--content_block'] !== 'undefined' ? Object.values(state.jsonApi['content_block--content_block']) : [],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doSaveJsonApiResponse: response => dispatch(saveJsonApiResponse(response)),
        doUpdateEntity: entity => updateEntity(entity)
            .then(result => dispatch({ type: 'API_SUCCESS', payload: result.data })),
    }
}

export default setPageTitle(connect(mapStateToProps, mapDispatchToProps)(ManageContent), 'Manage Content');
