import React from 'react';
import { Icon } from 'antd';

const FileIcon = ({ fileName }) => {
    const getExtension = (length) => {
        return fileName.substring(fileName.length - length).toLowerCase();
    };

    let iconMap = {
        jpg: 'file-jpg',
        jpeg: 'file-jpg',
        gif: 'file-image',
        png: 'file-image',
        pdf: 'file-pdf',
        xls: 'file-excel',
        ppt: 'file-ppt',
        zip: 'file-zip',
        doc: 'file-word',
        docx: 'file-word',
    }
    let icon = 'file-unknown';
    if (typeof iconMap[getExtension(3)] !== 'undefined') {
        icon = iconMap[getExtension(3)];
    }
    else if (typeof iconMap[getExtension(4)] !== 'undefined') {
        icon = iconMap[getExtension(4)];
    }

    return (
        <Icon type={icon} />
    );
};

const FileIconFromFile = ({ file }) => (
    <FileIcon fileName={file.attributes.filename} />
);

export default FileIcon;
export { FileIconFromFile }
