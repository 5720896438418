import { useCallback, useState } from "react";

const useManagedOpenState = () => {
    const [openStateMap, setOpenStateMap] = useState({});

    const isOpen = useCallback(taskId => {
        return !!openStateMap[taskId];
    }, [openStateMap]);

    const open = useCallback(taskId => {
        // Nothing to do if already open.
        if (isOpen(taskId)) {
            return;
        }

        let newOpenTaskMap = { ...openStateMap };
        newOpenTaskMap[taskId] = true;
        setOpenStateMap(newOpenTaskMap);
    }, [openStateMap, isOpen]);

    const close = useCallback(taskId => {
        // Nothing to do if already closed.
        if (!isOpen(taskId)) {
            return;
        }

        let newOpenStateMap = { ...openStateMap };
        newOpenStateMap[taskId] = false;
        setOpenStateMap(newOpenStateMap);
    }, [openStateMap, isOpen])

    const toggle = useCallback(taskId => {
        isOpen(taskId) ? close(taskId) : open(taskId);
    }, [isOpen, close, open])

    return {
        open,
        close,
        toggle,
        isOpen,
    }
}

export default useManagedOpenState;
