import React from 'react';
import styled from 'styled-components';
import UserMenu from './Menu/UserMenu';
import Media from "react-media";
import MobileMenu from './Menu/MobileMenu';
import HelpMenu from './Menu/HelpMenu';
import theme from '../themes';
import SearchBar from '../components/Search/SearchBar';

const HeaderWrapper = styled.div`
    background: #FFF;
    border-bottom: 1px solid #e8e8e8;
`

const HeaderWrapperInner = styled.div`
    max-width: 100%;
    margin: 0 0 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    height: 50px;

    @media (max-width: 600px) {
        margin-left: 0;
    }
`

const HeaderRight = styled.div`
    display: flex;
    align-items: center;

    > * {
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }
`

const Header = () => {
    return (
        <HeaderWrapper>
            <HeaderWrapperInner>
                <Media query="(max-width: 759px)">
                    <a href="/" style={{ display: 'block' }}>
                        <img
                            alt="Logo"
                            style={{
                                width: 'auto',
                                height: '27px',
                            }}
                            src={theme.logo}
                        />
                    </a>
                </Media>

                <SearchBar />

                <HeaderRight>
                    <Media query="(max-width: 759px)">
                        <>
                            <HelpMenu />
                            <MobileMenu />
                        </>
                    </Media>

                    <Media key={1} query="(min-width: 760px)">
                        <>
                            <HelpMenu />

                            <UserMenu />
                        </>
                    </Media>
                </HeaderRight>

            </HeaderWrapperInner>
        </HeaderWrapper >
    );
};

export default Header;
