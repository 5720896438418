// @flow

import React, { useCallback } from 'react';
import styled from 'styled-components';
import Avatar from '../Avatar';
import { User } from '../../types';
import relativeTime from 'dayjs/plugin/relativeTime'

import dayjs from 'dayjs';
dayjs.extend(relativeTime)

const ActivityItem = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid ${props => props.theme.lightGrey};

    &:last-child {
        border-bottom: none;
    }

    p {
        margin: 0 0 0 16px;
        line-height: 16px;
        font-size: 12px;
    }
`

type ActivtyItem = {
    user: User,
    text: string,
    content: string,
    timestamp: Number,
}

type Props = {
    items: Array<ActivtyItem>,
    avatarTooltip?: boolean,
}

type ItemGroup = {
    items: Array<ActivtyItem>,
    weight: number,
    format: string,
}

const ActivityList = ({ items, avatarTooltip = true }: Props) => {
    const getItemsGrouped = useCallback(() => {
        let itemsGrouped = {};
        items.forEach(item => {
            let format = dayjs.unix(item.timestamp).fromNow();
            if (typeof itemsGrouped[format] === 'undefined') {
                itemsGrouped[format] = {
                    format: format,
                    weight: item.timestamp,
                    items: [],
                };
            }
            itemsGrouped[format].items.push(item);
        })
        return itemsGrouped;
    }, [items])

    const getSortedAndGroupedItems = useCallback(() => {
        const itemsGrouped = getItemsGrouped();
        const itemsGroupedArray: Array<ItemGroup> = Object.keys(itemsGrouped).map(k => itemsGrouped[k]);
        itemsGroupedArray.sort((a: Object, b: Object) => {
            return b.weight - a.weight;
        });
        return itemsGroupedArray;
    }, [getItemsGrouped])

    return (
        <div>
            {getSortedAndGroupedItems().map((itemGroup, i) => (
                <div key={i}>
                    <h5>{itemGroup.format}</h5>
                    {
                        itemGroup.items.map((item, j) => <ActivityItem key={j}>
                            <Avatar user={item.user} tooltip={avatarTooltip} style={{ flexShrink: '0' }} />
                            {item.content || <p>{item.text}</p>}
                        </ActivityItem>)
                    }
                </div>
            ))}
        </div>
    );
};

export default ActivityList;
