// @flow

import React from 'react';
import { Row, Col } from 'antd';
import TaskWorkflowTile from './TaskWorkflowTile';
import { TaskWorkflow } from '../../types';
import styled from 'styled-components';
import TaskWorkflowLink from './TaskWorkflowLink';
import TaskWorkflowTileSkeleton from './TaskWorkflowTileSkeleton';

type Props = {
    workflows: Array<TaskWorkflow>,
    colProps?: Object,
}

const ColWithSupportFiveColumns = styled(Col)`

    @media (min-width: 1600px) {
        &&.ant-col-xxl-4 {
            width: 20%;
        }
    }
`

const StyledRow = styled(Row)`
    @media (max-width: 400px) {
        justify-content: center;
    }
`

const TaskWorkflowTileList = ({ workflows, colProps = {} }: Props) => {
    return (
        <StyledRow gutter={16} type="flex" className="workflow-tile-list">
            {workflows.map(taskWorkflow => {
                return (
                    <ColWithSupportFiveColumns
                        sm={12}
                        md={8}
                        xl={6}
                        xxl={4}
                        key={taskWorkflow.id}
                        style={{ marginBottom: '16px' }}
                        {...colProps}
                    >
                        {taskWorkflow.isLoading ?
                            <TaskWorkflowTileSkeleton
                                key={taskWorkflow.id}
                                title={taskWorkflow.attributes.title}
                            />
                            :
                            <TaskWorkflowLink
                                style={{ display: 'block', height: '100%' }}
                                workflow={taskWorkflow}
                            >
                                <TaskWorkflowTile
                                    size='large'
                                    taskWorkflow={taskWorkflow}
                                />
                            </TaskWorkflowLink>
                        }
                    </ColWithSupportFiveColumns>
                )
            })}
        </StyledRow>
    )
}

export default TaskWorkflowTileList;
