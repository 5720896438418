// @flow

import JsonApiRequestBuilder from "../helpers/JsonApiRequestBuilder";
import { jsonApi, withToken } from "../actions";

export function createTag(tagName: string, categoryType: string, categoryId: string) {
    let request = new JsonApiRequestBuilder('tag--' + categoryType);
    request
        .attribute('name', tagName)
        .relationship('type', 'tag_category--tag_category', categoryId);

    return withToken(token => {
        return jsonApi()
            .post('/jsonapi/tag/' + categoryType, request.build(), {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function updateTag(updatedTag: Object) {
    return withToken(token => {
        return jsonApi()
            .patch(`${updatedTag.links.self.href}`, { data: updatedTag }, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function deleteTag(tag: Object) {
    return withToken(token => {
        return jsonApi()
            .delete(`${tag.links.self.href}`, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function createCategory(categoryName: string, enabledWorkflowTypeIds: Array<string>) {
    let request = new JsonApiRequestBuilder('tag_category--tag_category');
    request
        .attribute('id', categoryName.toLowerCase().replace(/ /g, "_"))
        .attribute('name', categoryName)
        .attribute('enabled_workflow_types', enabledWorkflowTypeIds);

    return withToken(token => {
        return jsonApi()
            .post('/jsonapi/tag_category/tag_category', request.build(), {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function updateCategory(updatedCategory: Object) {
    return withToken(token => {
        return jsonApi()
            .patch(`/jsonapi/tag_category/tag_category/${updatedCategory.id}`, { data: updatedCategory }, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function deleteCategory(category: Object) {
    return withToken(token => {
        return jsonApi()
            .delete(`${category.links.self.href}`, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}
