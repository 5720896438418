import React from 'react';
import styled from 'styled-components';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { getTaskWorkflow } from '../../selectors'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { textTrim } from '../../helpers';

const BreadcrumbWrapper = styled.div`
    border-bottom: 1px solid #D9D9D9;
    padding: 16px 0;
    height: 72px;
    
    @media (max-width: 759px) {
        height: auto;
    }
`

const BreadcrumbStyled = styled(AntdBreadcrumb)`

    && {
        max-width: 1640px;
        margin: 0 auto;

        .ant-breadcrumb-link {
            border: 1px solid #D9D9D9;
            border-radius: 4px;
            padding: 8px 16px;
            display: inline-block;

            a {
                font-weight: 500;
                color: ${props => props.theme.blueGrey};
            }

            &:hover {
                cursor: pointer;
            }

        }
        // Hide everything but the last link on mobile.
        @media (max-width: 759px) {

            span {
                display: none;
            }

            span:last-child {
                display: block;
            }
        }

        .ant-breadcrumb-separator {
            margin: 0 16px;
            color: ${props => props.theme.greyBlack};
        }

        & > span:last-child .ant-breadcrumb-link {
            border: none !important;
            padding: 8px 0;

            a {
                color: ${props => props.theme.blueGrey};

                &:focus,
                &:hover {
                    cursor: text;
                    text-decoration: none;
                }
            }
        }
    }
`

const TaskWorkflowBreadcrumb = connect((state, ownProps) => {
    let challenge = getTaskWorkflow(state, ownProps.match.params.parentType, ownProps.match.params.id);
    return {
        title: challenge ? textTrim(challenge.attributes.title, 100) : 'Loading',
    }
})(({ title }) => title);

const routes = [
    { path: "/", breadcrumb: null },
    { path: "/challenge", breadcrumb: 'Challenges' },
    { path: "/competition", breadcrumb: 'Competitions' },
    { path: "/user", breadcrumb: null },
    { path: "/user/edit/:id", breadcrumb: 'Edit User Profile' },
    { path: "/user/my-tasks", breadcrumb: 'My Tasks' },
    { path: "/new", breadcrumb: null },
    
    { path: "/admin", breadcrumb: 'Admin' },
    { path: "/admin/user-management", breadcrumb: 'Manage Users' },
    { path: "/admin/task-templates", breadcrumb: 'Task Workflow' },
    { path: "/admin/organisations", breadcrumb: 'Organisations' },
    { path: "/admin/organisations/edit", breadcrumb: null },
    { path: "/admin/organisations/edit/:id", breadcrumb: 'Edit Organisation' },
    { path: "/admin/task-templates/edit", breadcrumb: null },
    { path: "/admin/task-templates/edit/:id", breadcrumb: 'Manage Task Workflow' },
    { path: "/admin/user/new", breadcrumb: 'Create New User' },
    { path: "/admin/tags", breadcrumb: 'Tags' },

    { path: "/submission/:type/:id", breadcrumb: null },
    { path: "/submission", breadcrumb: null },

    { path: "/:parentType/:id", breadcrumb: null },
    { path: "/:parentType/:id/build", breadcrumb: TaskWorkflowBreadcrumb },
    { path: "/:parentType/:id/view", breadcrumb: TaskWorkflowBreadcrumb },
    { path: "/:parentType/:id/build/task", breadcrumb: null },
    { path: "/:parentType/:id/build/task/:taskId", breadcrumb: null },
    { path: "/dashboard", breadcrumb: 'Dashboard' },

];

const Breadcrumb = ({ breadcrumbs, ...props }) => {
    return (
        <BreadcrumbWrapper>
            <BreadcrumbStyled separator={">"} {...props}>
                {breadcrumbs.map((item, i) => (
                    <AntdBreadcrumb.Item key={item.key}>
                        <Link to={item.key}>
                            {item}
                        </Link>
                    </AntdBreadcrumb.Item>)
                )}
            </BreadcrumbStyled>
        </BreadcrumbWrapper>
    );
};

export default withBreadcrumbs(routes)(Breadcrumb);
