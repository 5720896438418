import { http, getBaseUrl, jsonApi, withToken } from '../actions/api'
import axios from 'axios';
import { clearCurrentToken, refreshToken } from '../helpers/jwt';
import { getLoginInfo, saveLoginInfo } from '../helpers/loginInfo';
import JsonApiRequestBuilder from '../helpers/JsonApiRequestBuilder';

export function createNotification(type, recipientId, message, data = {}) {
    let request = new JsonApiRequestBuilder('notification--notification')
        .attribute('label', `New ${type} notification for ${recipientId}`)
        .attribute('type', type)
        .attribute('notification', message)
        .attribute('data', JSON.stringify(data))
        .relationship('recipient', 'user--user', recipientId);

    return withToken(token => jsonApi()
        .post('/jsonapi/notification/notification', request.build(), {
            headers: {
                'X-CSRF-Token': token,
            }
        })
        .catch(errorHandler)
    );
}

export function logout() {
    return http()
        .post('/user/logout?_format=json&token=' + getLoginInfo().logout_token, {}, {
            withCredentials: true,
        })
        .then(response => {
            clearCurrentToken();
            return Promise.resolve();
        })
        .catch(error => {
            // If we got a 403, they've logged out directly in Drupal so succeed.
            if (error.response && error.response.status === 403) {
                clearCurrentToken();
                return Promise.resolve(error);
            }
            return Promise.reject(error);
        });
}

export function login(email, password) {
    return axios.post('/user/login?_format=json', {
        mail: email,
        pass: password,
    }, {
            baseURL: getBaseUrl(),
            withCredentials: true,
        })
        .then(response => {
            saveLoginInfo(response.data);
            return refreshToken()
                .then(refreshResponse => Promise.resolve())
                .catch(error => {
                    let errorMessage = error.response ? error.response.data.message : error;
                    return Promise.reject(errorMessage);
                });
        })
}

export const errorHandler = error => {
    return Promise.reject(extractErrorMessage(error));
}

export const extractErrorMessage = error => {
    let errorMessage = '';

    if (typeof error === 'string') {
        errorMessage = error;
    }
    else if (error.response) {
        if (error.response.status === 403) {
            errorMessage = 'Access denied, your session might have expired, please refresh.';
        }
        else if (typeof error.response.data === 'string') {
            errorMessage = error.response.data;
        }
        else if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
        }
        else if (Array.isArray(error.response.data.errors)) {
            errorMessage = error.response.data.errors.map(error => error.title).join(', ');
        }
    }

    if (errorMessage === '') {
        console.log('Unrecognised error', error);
        return 'Something unexpected happened';
    }

    return errorMessage;
}

export const saveJsonApiResponse = response => {
    return {
        type: 'API_SUCCESS',
        payload: response.data,
    }
}
