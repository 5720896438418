// @flow props

import React from 'react';
import ActivityFeed from './ActivityFeed';
import { getRelationship } from '../../selectors';
import { Activity, ForumQuestion } from '../../types';

type Props = {
    activities: Array<Activity>,
    jsonapi: Object,
}

const ForumActivity = ({ activities, jsonapi }: Props) => {
    if (activities.length === 0) {
        return <p>No questions yet</p>
    }

    return (
        <ActivityFeed
            items={activities.map(question => ForumActivity.render(question, jsonapi))}
        />
    );
};

ForumActivity.render = (question: ForumQuestion, jsonapi: Object) => {
    let user = getRelationship(jsonapi, 'uid', question);

    return {
        user: user,
        text: <span>Posted a question in the forum.</span>,
        timestamp: question.attributes.created,
    }
}

export default ForumActivity;
