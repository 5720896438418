import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import setPageTitle from '../hoc/setPageTitle';

const Wrapper = styled.div`
    background: #F5F5F5;
    height: 100%;
`

const Middle = styled.div`
    margin-top: 100px;
`

const LoadingPage = ({ children }) => {
    return (
        <Wrapper>
            <Middle>
                <Spin>
                    {children}
                </Spin>
            </Middle>
        </Wrapper>
    );
};

export default setPageTitle(LoadingPage, 'Loading...');
