import { withToken, http } from "../actions"

export default (columnId, workflowIds) => {
    withToken(token => {
        http()
            .patch(`/api/unearthed/pinned-workflows/${columnId}?_format=json`, {
                columnId: columnId,
                workflowIds: workflowIds,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': token,
                }
            })
    })
}
