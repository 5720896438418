// @flow

import React, { useState, useCallback } from 'react';
import { Box } from '../../components/Common';
import { Icon, Spin, Tag, Input, List, message } from 'antd';
import CreateNewCategory from '../../components/Modals/CreateNewCategory';
import setPageTitle from '../../hoc/setPageTitle';
import useJsonApiGet from '../../api/useJsonApiGet';
import { createTag, updateTag, deleteTag } from '../../api/tags';
import TagCategoryEditModal from '../../components/Tag/TagCategoryEditModal';
import { mergeEntityAttributes, tokenise } from '../../helpers';
import ContentWrapper from '../../layouts/ContentWrapper';

const CategoryTag = ({ category, tag, refreshTags }) => {
    const [isUpdatingTag, setIsUpdatingTag] = useState(false);
    const [isInputVisible, setIsInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState(tag.attributes.name);

    const handleUpdateTag = useCallback(() => {
        setIsUpdatingTag(true);
        const updatedTag = mergeEntityAttributes(tag, {
            name: inputValue
        });
        updateTag(updatedTag)
            .then(result => {
                setIsUpdatingTag(false);
                refreshTags();
                setIsInputVisible(false);
            })
            .catch(error => {
                console.log(error);
            });
    }, [inputValue, tag, refreshTags]);

    const handleDelete = useCallback(() => {
        deleteTag(tag)
            .then(result => {
                message.success('Tag deleted');
                refreshTags();
            })
            .catch(error => {
                console.log(error);
            })
    }, [refreshTags, tag])


    if (isInputVisible) {
        return (
            <Spin spinning={isUpdatingTag}>
                <div style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                    <Input
                        type="text"
                        size="small"
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        onPressEnter={handleUpdateTag}
                        autoFocus
                    />
                    <Icon
                        style={{ marginLeft: '4px', cursor: 'pointer' }}
                        type="delete"
                        onClick={handleDelete}
                    />
                </div>
            </Spin >
        )
    }
    return (
        <Tag
            onClick={() => setIsInputVisible(true)}
            style={{ cursor: 'pointer' }}
        >
            {tag.attributes.name}
        </Tag>
    )
}

const Category = ({ category, refreshCategories }) => {
    const { response, refresh } = useJsonApiGet(`/jsonapi/tag/${category.attributes.drupal_internal__id}`)
    const tags = response.data.data || [];

    const [isCreatingNewTag, setIsCreatingNewTag] = useState(false);
    const [isInputVisible, setIsInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const handleCreateTag = useCallback(() => {
        setIsCreatingNewTag(true);
        createTag(inputValue, category.attributes.drupal_internal__id, category.id)
            .then(result => {
                setIsCreatingNewTag(false);
                refresh();
                setIsInputVisible(false);
                setInputValue('');
            })
            .catch(error => {
                console.log(error);
            });
    }, [inputValue, category, refresh]);

    return (
        <List.Item>
            <div className={'category-' + tokenise(category.attributes.name)}>
                <TagCategoryEditModal
                    category={category}
                    onAfterSave={refreshCategories}
                >
                    <h3>{category.attributes.name}</h3>
                </TagCategoryEditModal>

                <Spin spinning={response.isLoading || isCreatingNewTag}>
                    <div style={{ marginTop: '16px', display: 'flex' }}>
                        {tags.map(tag => <CategoryTag
                            key={tag.id}
                            tag={tag}
                            category={category}
                            refreshTags={refresh}
                        />)}

                        {isInputVisible ?
                            <Input
                                type="text"
                                size="small"
                                style={{ width: '78px', marginRight: '8px' }}
                                value={inputValue}
                                onChange={e => setInputValue(e.target.value)}
                                onPressEnter={handleCreateTag}
                                className="ant-tag-new-input"
                                autoFocus
                            />
                            :
                            <Tag
                                onClick={() => setIsInputVisible(true)}
                                style={{ background: '#fff', borderStyle: 'dashed' }}
                                className="ant-tag-new"
                            >
                                <Icon type="plus" /> New Tag
                            </Tag>
                        }
                    </div>
                </Spin>
            </div>
        </List.Item >
    );
};

const ManageTags = () => {
    const { response, refresh } = useJsonApiGet('/jsonapi/tag_category/tag_category?sort[name][path]=name&sort[name][direction]=ASC')
    const categories = response.data.data || [];

    return (
        <Spin spinning={response.isLoading}>
            <ContentWrapper className="tags-management">
                <h1>Manage Tags</h1>
                <Box>
                    <div style={{ textAlign: 'right', marginBottom: '16px' }}>
                        <CreateNewCategory onAfterSave={refresh} />
                    </div>
                    {categories.length === 0 && !response.isLoading ? <p>There are no tag categories</p> : null}
                    <List>
                        {categories.map(category => <Category
                            key={category.id}
                            category={category}
                            refreshCategories={refresh}
                        />)}
                    </List>
                </Box>
            </ContentWrapper>
        </Spin>
    );
};

export default setPageTitle(ManageTags, 'Manage Tags');
