import React, { useState, useCallback } from 'react';
import { Box } from '.';
import styled from 'styled-components';
import { Icon } from 'antd';
import Spacer from './Spacer';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`

const CollapsibleBox = ({ title, children, isOpen, onToggle, extraActions, ...props }) => {
    const [isOpenState, setIsOpen] = useState(null);

    if (typeof isOpen === 'boolean' && typeof onToggle !== 'function') {
        console.error('You must provide onToggle for a controlled component');
    }

    const checkIsOpen = useCallback(() => {
        // The state is being controlled externally.
        if (typeof isOpen === 'boolean') {
            return isOpen;
        }

        return isOpenState;
    }, [isOpen, isOpenState])

    const toggle = useCallback(() => {
        if (typeof onToggle === 'function') {
            onToggle();
        }
        else {
            setIsOpen(checkIsOpen() ? false : true);
        }
    }, [onToggle, checkIsOpen]);

    return (
        <Box {...props}>
            <Header onClick={toggle}>
                {title}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {extraActions}
                    <Icon type="down" style={{ marginLeft: '8px' }} />
                </div>
            </Header>
            {checkIsOpen() ? <Spacer size="small" style={{ marginBottom: 0 }}>
                {children}
            </Spacer> : null}
        </Box>
    );
};

export default CollapsibleBox;
