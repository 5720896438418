import React from 'react';
import { Drawer as AntDrawer } from 'antd';
import styled from 'styled-components';

const DrawerWrapper = styled(AntDrawer)`
    .ant-drawer-content-wrapper {
        max-width: 100%;
    }
`

const DrawerInner = styled.div`
    padding: 36px;

    .ant-select {
        width: 100%;
    }

    h2 {
        font-size: 18px;
        margin-bottom: 48px;
        padding-bottom: 32px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }

    @media (max-width: 600px) {
        padding: 0px;
    }
`

const Drawer = ({ children, ...props }) => {
    return (
        <DrawerWrapper
            title={null}
            placement="right"
            width={480}
            {...props}
        >
            <DrawerInner>
                {children}
            </DrawerInner>
        </DrawerWrapper>
    );
};

export default Drawer;
