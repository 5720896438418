// @flow

import React, { useCallback, useState } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { Modal } from '../Ant';
import styled from 'styled-components';
import Task from './Task';
import Box from '../Common/Box';
import { Row, Col, Button, message, Spin } from 'antd';
import CollaboratorsTeaser from '../CollaboratorsTeaser';
import SidebarItem from '../Sidebar/SidebarItem';
import SidebarLabel from '../Sidebar/SidebarLabel';
import { updateTask } from '../../actions';
import { extractErrorMessage } from '../../api';
import FormActions from '../Form/FormActions';
import Comments from '../Comments/Comments';
import { mergeEntityAttributes } from '../../helpers';
import { TaskWorkflow, Task as TaskType } from '../../types';
import DateSelect from '../DateSelect';

const Sidebar = styled.div`
    padding: 32px;

    @media (max-width: 600px) {
        padding: 16px;
    }
`

const ChildrenWrapper = styled.div`
    &:hover {
        cursor: pointer;
    }
`

type Props = {
    children: Node,
    workflow: TaskWorkflow,
    task: TaskType,
    saveTask: Function,
    onAfterSave: Function,
}

const CompleteSingleTaskModal = ({ children, workflow, task, saveTask, onAfterSave }: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [draftTask, updateDraftTask] = useState(task);

    const onClick = useCallback(() => {
        setIsVisible(true);
    }, [])

    const save = useCallback(() => {
        setIsLoading(true);
        const updatedTask = mergeEntityAttributes(draftTask, {
            is_complete: true,
        });
        saveTask(updatedTask)
            .then(result => {
                message.success('Task saved');
                setIsLoading(false);
                setIsVisible(false);
                onAfterSave();
            })
            .catch(error => {
                console.log(error);
                message.warning(extractErrorMessage(error));
            });

    }, [draftTask, saveTask, onAfterSave])

    return (
        <div>
            <ChildrenWrapper
                onClick={onClick}
            >
                {children}
            </ChildrenWrapper>
            <Modal
                title={workflow.attributes.title}
                onCancel={() => setIsVisible(false)}
                visible={isVisible}
                width="100%"
                footer={[]}
            >

                <Spin spinning={isLoading}>
                    <Row gutter={8}>
                        <Col span={18}>
                            <Box>
                                <Task
                                    task={draftTask}
                                    parentId={workflow.id}
                                    onChange={updateDraftTask}
                                />

                                <FormActions>
                                    <Button type="primary" key="create" onClick={save}>Save</Button>
                                </FormActions>

                                <Comments id={task.id} commentType="task_comments" parentType="task--task" />
                            </Box>
                        </Col>
                        <Col span={6}>
                            <Sidebar>
                                <SidebarItem>
                                    <CollaboratorsTeaser
                                        playbookId={workflow.attributes.playbook_id}
                                    />
                                </SidebarItem>

                                <SidebarItem>
                                    <SidebarLabel>Due Date</SidebarLabel>
                                    <DateSelect
                                        selectedDate={draftTask.attributes.due_date}
                                        isEditable={true}
                                        onChange={isoString => {
                                            const updatedDraftTask = mergeEntityAttributes(task, {
                                                due_date: isoString,
                                            })
                                            updateDraftTask(updatedDraftTask);
                                            return Promise.resolve();
                                        }}
                                    />
                                </SidebarItem>
                            </Sidebar>
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        </div>

    );
};

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        saveTask: task => dispatch(updateTask(task)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteSingleTaskModal);