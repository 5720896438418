import styled from 'styled-components';

const TwoCol = styled.div`
    display: flex;

    > div {
        width: 49%;

        &:first-child {
            margin-right: 2%;
        }

    }

    @media (max-width: 950px) {
        flex-direction: column;

        > div {
            width: 100%
            &:first-child {
                margin-right: 0;
            }
        }
    }
`
export default TwoCol;