// @flow

import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Button, Spin, Divider, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { message } from '../components/Ant';
import { withRouter } from 'react-router-dom';
import { User, Role } from '../types';
import { mergeEntityAttributes, tokenise } from '../helpers';
import { loadUserRoles } from '../actions';
import { getUserPermissions, getCurrentUser } from '../selectors';

type Props = {
    user: User,
    form: Object,
    save: Function,
    history: Object,
    doLoadAdminRoles: Function,
    adminRoles: Array<Role>,
}

const UserRegisterForm = ({ user, form, history, save, doLoadAdminRoles, adminRoles }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        doLoadAdminRoles();
    }, [doLoadAdminRoles])

    const { getFieldDecorator } = form;
    const onSave = useCallback(e => {
        e.preventDefault();

        form.validateFields()
            .then(result => {
                // Merge new values into the user object and save it.
                let newUser = mergeEntityAttributes(user, {
                    field_full_name: result.fullName,
                    mail: result.email,
                    field_position: result.position,
                    pass: { value: result.password },
                    name: result.email,
                    status: 1,
                });

                newUser.relationships = {}
                if (typeof result.roles !== 'undefined') {
                    newUser.relationships.roles = {
                        data: result.roles.map(role => {
                            return {
                                id: role,
                                type: 'user_role--user_role',
                            }
                        })
                    }
                }

                setIsLoading(true);
                save(newUser)
                    .then(updateResult => {
                        setIsLoading(false);
                        message.success('User created');
                        history.push('/admin/user-management');
                    })
                    .catch(error => {
                        setIsLoading(false);
                        // A work around for the fact that there can be many errors but we only normally care
                        //a bout the email already taken one so make it nicer.
                        if (error.indexOf('is already taken') !== -1) {
                            error = 'This email address has already been taken';
                        }
                        message.warning(error)
                    });
            })
            .catch(errors => {
                console.log(errors);
                message.warning('Please complete all fields');
            })
    }, [form, history, save, user])

    return (
        <Spin spinning={isLoading}>
            <Form onSubmit={onSave}>
                <Form.Item label="Full Name">
                    {getFieldDecorator('fullName', {
                        rules: [{ required: true, message: 'Please input your full name.' }],
                        initialValue: user.attributes.display_name,
                    })(
                        <Input name="fullName" />
                    )}
                </Form.Item>

                <Form.Item label="Position">
                    {getFieldDecorator('position', {
                        rules: [{ required: true, message: 'Please input your position.' }],
                        initialValue: user.attributes.field_position,
                    })(
                        <Input name="position" />
                    )}
                </Form.Item>

                <Form.Item label="Email">
                    {getFieldDecorator('email', {
                        rules: [{ type: 'email', required: true, message: 'Please input a valid email.' }],
                        initialValue: user.attributes.mail,
                    })(
                        <Input name="email" autoComplete="email" />
                    )}
                </Form.Item>

                <Form.Item label="Password">
                    {getFieldDecorator('password', {
                        rules: [{
                            required: true, message: 'Please input your password!',
                        }],
                    })(
                        <Input name="password" type="password" autoComplete="new-password" />
                    )}
                </Form.Item>

                <div>
                    <Divider />
                    <h2>Roles</h2>

                    <Form.Item label="Change the user roles">
                        {getFieldDecorator('roles', {
                            initialValue: [],
                        })(
                            <Checkbox.Group>
                                {
                                    adminRoles.map(role => {
                                        return (
                                            <Checkbox
                                                key={role.id}
                                                value={role.id}
                                                className={tokenise(role.attributes.label)}
                                            >{role.attributes.label}</Checkbox>
                                        );
                                    })
                                }
                            </Checkbox.Group>
                        )}
                    </Form.Item>
                </div>

                <Button type="primary" htmlType="submit">Create User</Button>
            </Form>
        </Spin>
    )
}

const mapStateToProps = state => {
    let includedRoles = [
        'innovation_manager',
        'organisation_user',
    ];
    return {
        adminRoles: typeof state.jsonApi['user_role--user_role'] !== 'undefined' ?
            Object.values(state.jsonApi['user_role--user_role']).filter((role: Object) => includedRoles.includes(role.attributes.drupal_internal__id))
            : [],
        allPermissions: getUserPermissions(state, getCurrentUser(state)),
        currentUser: getCurrentUser(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doLoadAdminRoles: () => dispatch(loadUserRoles()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'user_register' })(UserRegisterForm)));