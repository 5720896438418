import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 164px;
    height: 230px;
    border: 1px dashed #737373;
    background: ${props => props.canDrop ? '#EEE' : 'transparent'};
    border-radius: 10px;

    @media (max-width: 1025px) {
        width: 132px;
        height: 184px;
    }
`

const EmptyTileDropTarget = ({ id, onDrop, acceptType, handleCanDrop, isValid, invalidDrop = null }) => {
    const [{ isSupportedType, canDrop, valid }, drop] = useDrop({
        accept: acceptType,
        collect: monitor => {
            return {
                canDrop: monitor.canDrop(),
                isSupportedType: monitor.getItem() !== null && monitor.getItem().type === acceptType,
                isOver: monitor.isOver(),
                valid: monitor.getItem() !== null && isValid(monitor.getItem())
            };
        },
        canDrop: handleCanDrop,
        drop: onDrop,
    })

    return (
        <Wrapper
            canDrop={canDrop}
            ref={drop}
            className={"empty-tile-drop-target-" + id}
        >
            {isSupportedType === true && canDrop === true && valid === false ? invalidDrop : null}
        </Wrapper>
    );
}

export default EmptyTileDropTarget;
