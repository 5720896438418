// @flow

import React from 'react';
import VerticalProfile from '../components/User/VerticalProfile';
import withCurrentUser from '../hoc/withCurrentUser';
import styled from 'styled-components';
import PlaybookList from '../components/Playbook/PlaybookList';
import TaskWorkflowTileList from '../components/TaskWorkflow/TaskWorkflowTileList';
import setPageTitle from '../hoc/setPageTitle';
import { IMAGE_LARGE_CONSUMER_ID } from '../consumers';
import GlobalActivityFeed from '../components/Activity/GlobalActivityFeed';
import { Box } from '../components/Common';
import { Spin, Button, Icon } from 'antd';
import WelcomeToCrowd from '../components/WelcomeToCrowd';
import Dismissible from '../components/Util/Dismissible';
import EmptyCard from '../components/TaskWorkflow/EmptyCard';
import { HasPermission } from '../helpers';
import ArrowLink from '../components/Common/ArrowLink';
import CreateNewChallenge from '../components/Modals/CreateNewChallenge';

const SmallBox = styled(Box)`

    @media (max-width: 600px) {
        padding: 8px;
    }
`

const Wrapper = styled.div`
    display: flex;

    @media (max-width: 800px) {
        flex-direction: column-reverse;
        align-items: center;
    }

    @media (max-width: 575px) {
        .workflow-tile-list {
            justify-content: center;
        }
    }
`

const HeadingWrapper = styled.div`
    margin-top: 56px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;

    h2 {
        margin: 0;
    }

    // Could still this a generic component if we use again?
    a {
        color: ${props => props.theme.blueGrey};
        font-weight: bold;
        display: block;
        margin-left: 32px;
    }
`

const LeftWrapper = styled.div`
    padding: 56px;
    width: 100%;

    @media (max-width: 1200px) {
        padding: 16px;
    }

    @media (max-width: 800px) {
        padding: 8px;
    }
`
const HeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    h1 {
        margin-bottom: 0px;
        font-size: 30px;
        font-weight: bold;
    }

    @media (max-width: 632px) {

        h1 {
            font-size: 27px;
        }
    }

    @media (max-width: 599px) {
        display: none;
    }
`

const HeaderContentMobile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
        margin-top: 32px;
        text-align: center;
    }

    div  {
        align-self: center;
    }

    @media (min-width: 600px) {
        display: none;
    }
`

const Home = ({ currentUser }) => {
    return (
        <Wrapper className="home">
            <LeftWrapper>
                <HeaderContent>
                    <h1>Good Day {currentUser.attributes.display_name} 🙂</h1>
                    <CreateNewChallenge>
                        {onClick => (
                            <Button
                                style={{ fontWeight: 'bold' }}
                                onClick={onClick}
                                className="create-challenge">
                                New Challenge
                                <Icon style={{ fontSize: '14px' }} className="primary-color" type="plus" />
                            </Button>
                        )}
                    </CreateNewChallenge>
                </HeaderContent>
                <HasPermission permission="view content_block">

                    <WelcomeToCrowd>
                        {(children, contentHash) => {
                            return <Dismissible name="welcome_crowd" hash={contentHash}>
                                {children}
                            </Dismissible>
                        }}
                    </WelcomeToCrowd>
                </HasPermission>

                <HeadingWrapper>
                    <h2>Your Challenges</h2>
                    <ArrowLink url={`/challenges`}>
                        View All
                    </ArrowLink>
                </HeadingWrapper>

                <PlaybookList
                    type={'challenge'}
                    filters={{
                        'page[limit]': 4,
                        'filter[collaborators.id]': currentUser.id,
                        'filter[workflows.tasks.parent_type]': 'task_workflow--challenge',
                        'filter[is_archived]': 0,
                        'sort[sort-created][path]': 'workflows.created',
                        'sort[sort-created][direction]': 'DESC',
                        consumerId: IMAGE_LARGE_CONSUMER_ID,
                    }}
                    sort={{}}
                >
                    {(response, workflows, owners, templates, organisations, tags, pager) => {
                        if (response.isLoading) {
                            return <Spin spinning={true}></Spin>
                        }

                        if (workflows.length === 0) {
                            return <div>
                                <EmptyCard />
                            </div>
                        }

                        return (
                            <TaskWorkflowTileList
                                workflows={workflows}
                                colProps={{
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                    xl: 8,
                                    xxl: 6,
                                }}
                            />
                        );
                    }}
                </PlaybookList>


                <GlobalActivityFeed
                    query={{ 'page[limit]': 10 }}
                    render={(children, activities) => {
                        if (activities.length === 0) {
                            return null;
                        }
                        return (
                            <div style={{ margin: '32px 8px 0 8px' }}>
                                <SmallBox>

                                    <h2>Recent Activity</h2>
                                    {children}
                                </SmallBox>
                            </div>
                        )
                    }}
                />
            </LeftWrapper>

            <HeaderContentMobile>
                <h1>Good Day {currentUser.attributes.display_name} 🙂</h1>
                    <CreateNewChallenge>
                        {onClick => (
                            <Button
                                style={{ fontWeight: 'bold' }}
                                onClick={onClick}
                                className="create-challenge">
                                New Challenge
                            </Button>
                        )}
                    </CreateNewChallenge>
                <VerticalProfile
                    user={currentUser}
                />
            </HeaderContentMobile>
        </Wrapper>
    );
};

export default withCurrentUser(setPageTitle(Home, 'Home'));
