// @flow

import React, { useState, useCallback } from 'react';
import { Button, Modal } from '../Ant';

type Props = {
    onAfterSave?: Function,
    entity: Object,
    title: string,
    EntityForm: Object,
    buttonRender?: Function,
}

const NewEntityModal = ({ onAfterSave, entity, title, EntityForm, buttonRender = null }: Props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const onClick = useCallback(() => {
        setIsModalVisible(!isModalVisible)
    }, [isModalVisible]);

    return (
        <div>
            {buttonRender ? buttonRender(onClick) : <Button type="primary" icon={"plus"} onClick={onClick}>New</Button>}

            <Modal
                title={title}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >

                <EntityForm
                    entity={entity}
                    onAfterSave={() => {
                        if (typeof onAfterSave === 'function') {
                            onAfterSave();
                        }
                        setIsModalVisible(false)
                    }}
                />
            </Modal>
        </div>
    );
};

export default NewEntityModal;
