import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getTaskTemplate } from '../../selectors';
import { get } from '../../helpers';
import { saveJsonApiResponse } from '../../api';
import { loadTemplate } from '../../api/taskTemplates';

const TaskTemplateLabel = ({ workflow, children, taskTemplateId, doSaveJsonApiResponse, taskTemplate }) => {
    useEffect(() => {
        // // No need to load anything.
        if (taskTemplate) {
            return;
        }

        loadTemplate(taskTemplateId)
            .then(doSaveJsonApiResponse)
            .catch(error => console.log(error));

    }, [taskTemplate, taskTemplateId, doSaveJsonApiResponse])

    if (!taskTemplate) {
        return null;
    }

    return children(taskTemplate.attributes.label);
};

const mapStateToProps = (state, props) => {
    const taskTemplateId = get(props.workflow, 'relationships.task_template.data.id');
    return {
        taskTemplateId: taskTemplateId,
        taskTemplate: taskTemplateId ? getTaskTemplate(state, taskTemplateId) : null,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doSaveJsonApiResponse: args => dispatch(saveJsonApiResponse(args)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskTemplateLabel)
