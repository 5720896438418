// @flow

import React from 'react';
import Avatar from './Avatar';
import styled from 'styled-components';
import usePlaybook from '../api/usePlaybook';

const AvatarContainer = styled.div`
    display: flex;
    align-items: center;
    > span {
        margin-right: -6px;
    }
`

const ExtraCollaborators = styled.div`
    margin-left: 8px;
    font-weight: bold;
    font-size: 12px;
    z-index: 10;
`

type Props = {
    playbookId: string,
    size?: string,
    hideOwner?: boolean,
}

const displayCount = 4;
const CollaboratorsTeaser = ({ playbookId, size = 'default', hideOwner = false }: Props) => {
    const { collaborators, owner, isLoading } = usePlaybook(playbookId)

    if (isLoading) {
        return null;
    }

    return (
        <AvatarContainer>
            {hideOwner ? null : <Avatar key={owner.id} user={owner} size={size} nameSuffix=" (Owner)" style={{ zIndex: displayCount + 1 }} />}
            {
                collaborators.slice(0, displayCount).filter(c => c.id !== owner.id)
                    .map((collaborator, i) => <Avatar key={collaborator.id} user={collaborator} style={{ zIndex: displayCount - i }} />)
            }
            {collaborators.length > displayCount ? <ExtraCollaborators>{`+${collaborators.length - displayCount}`}</ExtraCollaborators> : null}
        </AvatarContainer>
    );
};

export default CollaboratorsTeaser;
