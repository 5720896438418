import { useEffect, useContext, useState, useCallback } from "react";
import { ApiContext } from "./ApiContext";
import { extractErrorMessage } from ".";
import qs from 'qs';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getRelationship, getUser } from "../selectors";
import { IMAGE_LARGE_CONSUMER_ID } from "../consumers";

function useUser(id) {
    const api = useContext(ApiContext);
    const [responseStatus, setResponseStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();
    
    const saveResult = useCallback(result => {
        dispatch({
            type: 'API_SUCCESS',
            payload: result.data,
        })
    }, [dispatch]);

    useEffect(() => {
        if (!id) {
            return;
        }

        const query = {
            'include': 'roles,field_picture',
            'filter[anon][condition][path]': 'uid',
            'filter[anon][condition][value]': '0',
            'filter[anon][condition][operator]': '<>',
            'consumerId': IMAGE_LARGE_CONSUMER_ID,
        };
        let url = `/jsonapi/user/user/${id}?${qs.stringify(query)}`;
        api
            .get(url)
            .then(result => {
                // Request was cancelled.
                if (!result) {
                    console.log('load user cancelled');
                    return;
                }
                saveResult(result);
                setResponseStatus('success');
            })
            .catch(error => {
                setResponseStatus('failed');
                setErrorMessage(extractErrorMessage(error));
            });

        return () => {
            api.cancel(url);
        }

    }, [id, api, saveResult]);

    const user = useSelector(state => getUser(state, id), shallowEqual) || null;
    const picture = useSelector(state => getRelationship(state, 'field_picture', user), shallowEqual) || null;

    return {
        isLoading: responseStatus === 'loading',
        isFailed: responseStatus === 'failed',
        errorMessage: errorMessage,
        user: user,
        picture: picture,
    }
}

export default useUser;
