import React from 'react';
import CKEditor from 'ckeditor4-react';
import { getBaseUrl } from '../actions';

// Enables file uploads by adding the credentials to CKEditor ajax requests.
(function () {
    var origOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function () {
        if (arguments[1].indexOf('ck_token') !== -1) {
            this.withCredentials = true;
        }
        origOpen.apply(this, arguments);
    };
})();

const Ckeditor = ({ config = {}, ...props }) => {
    CKEditor.editorUrl = getBaseUrl() + '/ckeditor/ckeditor.js';

    return <CKEditor
        type="classic"
        config={{
            cloudServices_tokenUrl: getBaseUrl() + '/util/ck_token',
            ...config,
        }}
        {...props}
    />
};

export default Ckeditor;
