import React from 'react';
import { useDrag } from 'react-dnd';

/**
 * This item makes a workflow tile draggable, something else will handle
 * the drop and we don't know what.
 */
const PinnableItem = ({ id, children, workflow, column }) => {
    const [{ opacity }, drag] = useDrag({
        item: { type: `KanbanTile-${column}`, id, column, workflow },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
        }),
    })

    return (
        <div
            ref={drag}
            style={{ opacity: opacity, transform: 'translate(0, 0)' }}
            className="pinnable-item"
        >
            {children}
        </div>
    );
};

export default PinnableItem;
