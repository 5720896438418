import React from 'react';
import { connect } from 'react-redux';
import Box from '../../components/Common/Box';
import UserRegisterForm from '../../Forms/UserRegisterForm';
import { createUser } from '../../actions';
import ContentWrapper from '../../layouts/ContentWrapper';
import setPageTitle from '../../hoc/setPageTitle';

const NewUser = ({ save }) => {
    return (
        <ContentWrapper>
            <Box style={{ maxWidth: '800px' }}>
                <UserRegisterForm
                    user={{
                        type: 'user--user',
                        attributes: {
                            name: '',
                        }
                    }}
                    save={save}
                />
            </Box>
        </ContentWrapper>
    );
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        save: user => dispatch(createUser(user)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setPageTitle(NewUser, 'Create new user'));
