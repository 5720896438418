import React from 'react';
import { GenericError } from '../components/Common';
import { Layout } from 'antd';
import styled from 'styled-components';
import setPageTitle from '../hoc/setPageTitle';

const { Content } = Layout;

const ContentStyled = styled(Content)`
    margin: 16px auto 16px auto;
    width: 100%;
    max-width: 1640px;
    padding: 16px;

    @media (max-width: 600px) {
        padding: 8px;
    }
`

const PageNotFound404 = () => {
    return (
        <Layout style={{ background: 'rgb(245, 246, 245)' }}>
            <ContentStyled>
                <GenericError
                    title={'Page Not Found'}
                    message="The page you are looking for does not exist or you do not have access."
                    hideRefresh={true}
                />
            </ContentStyled>
        </Layout>

    );
};

export default setPageTitle(PageNotFound404, 'Page Not Found');
