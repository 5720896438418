// @flow

import * as React from 'react';
import styled from 'styled-components';

import Avatar from '../Avatar';
import { User } from '../../types';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

import dayjs from 'dayjs';
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

const Item = styled.div`
    padding: 16px 0;
    border-bottom: 1px solid ${props => props.theme.lightGrey};
    display: flex;
    align-items: flex-start;
    position: relative;
    &:last-child {
        border-bottom: none;
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
`

const TopWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`

const Name = styled.div`
    font-size: 14px;
    margin-left: 16px;
    font-weight: 500;
    color: ${props => props.theme.darkGrey};
`

const TextWrapper = styled.div`
    /* With of the image plus margin on name. */
    margin-left: 46px;
    color: ${props => props.theme.greyBlack};
    font-size: 13px;
`

const TimeAgo = styled.span`
    margin-left: auto
    padding-top: 7px;
`

const Content = styled.div`
    max-width: calc(100% - 120px);
    overflow-wrap: break-word;

    @media (max-width: 600px) {
        max-width: 100%;
    }
`

const Actions = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 11px;
    opacity: 0;

    ${Item}:hover & {
        opacity: 1;
    }

    @media (max-width: 600px) {
        position: static;
        opacity: 1;
    }
`

type ItemType = {
    user: User,
    text: React.Node,
    timestamp: number,
    actions?: React.Node,
}

type Props = {
    items: Array<ItemType>,
    emptyText?: string,
}

const CommentList = ({ items, emptyText = 'No comments' }: Props) => {
    return (
        <div>
            {items.length === 0 && emptyText.length ? <p>{emptyText}</p> : null}
            {items.map((item, i) => (
                <Item key={i} className="comment-item">
                    <Content>
                        <TopWrapper>
                            <Avatar size="default" user={item.user} />
                            {item.user ? <Name>{item.user.attributes.field_full_name || item.user.attributes.display_name}</Name> : null}
                        </TopWrapper>
                        <TextWrapper>
                            {item.text}
                        </TextWrapper>
                    </Content>
                    {item.timestamp ? <TimeAgo>{dayjs.unix(item.timestamp).fromNow()}</TimeAgo> : null}
                    {item.actions ? <Actions className="comment-actions">{item.actions}</Actions> : null}
                </Item>
            ))}
        </div>
    );
};

export default CommentList;
