// @flow

import React from 'react';
import styled from 'styled-components';
import arrow from './arrow.png';
import arrowSmall from './arrowSmall.png'
import { Link } from 'react-router-dom';
import { Icon } from 'antd';

const MenuWrapper = styled.div`
    display: flex;

    @media (max-width: 1025px) {
        margin-bottom: 16px;
    }
`

const Title = styled.h4`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
`

const ColumnMenuItem = styled.div`
    height: 61px;
    background-image: ${props => `url(${arrow})`};
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 32px;
    flex-shrink: 0;
    opacity: ${props => props.isDisabled ? 0.5 : 1};
    transition: 0.2s ease-in-out;

    &:hover {
        transform: translateX(2px);
        opacity: 1;
    }

    // These props are duplicated on KanbanColumn.
    width: ${props => props.cssWidth ? props.cssWidth + 'px' : '370px'};
    margin-right: 32px;
    &:last-child {
        margin-right: 0;
    }

    @media (max-width: 1025px) {
        background-size: 170px;
        margin-right: 8px;
        width: ${props => props.cssWidth ? '177px' : '288px'};
        height: 32px;
        margin-bottom: 0;
    }
`

const FullHeightLink = styled(Link)`
    display: block;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

type Props = {
    columns: Array<Object>,
    activeColumn: string,
}

const KanbanMenu = ({ columns, activeColumn = '' }: Props) => {
    return (
        <MenuWrapper>
            {columns.map(column => (
                <ColumnMenuItem
                    key={column.id}
                    cssWidth={column.width}
                    isDisabled={activeColumn.length && column.id !== activeColumn}
                >
                    <FullHeightLink
                        to={activeColumn === column.id ? '/challenges' : `/challenges/${column.id}`}
                        className={"column-menu-" + column.id}
                    >
                        <Title>
                            <Icon
                                type={column.icon}
                                style={{ marginRight: '4px', fontSize: '18px' }}
                            /> {column.title}
                        </Title>
                    </FullHeightLink>
                </ColumnMenuItem>
            ))}
        </MenuWrapper>
    )
};

const MobileWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: 600px) {
        margin-bottom: 16px;
    }

    ${ColumnMenuItem} {
        margin-right: 0;
        width: 140px;
        background-size: 140px;
    }
`

const Arrow = styled.div`
  border: solid #E8E8E8;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 8px;
`

const LeftArrow = styled(Arrow)`
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
`
const RightArrow = styled(Arrow)`
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`

const KanbanMenuSingleColumn = ({ columns, activeColumn = '' }: Props) => {
    const activeColumnIndex = columns.findIndex(c => c.id === activeColumn);
    const column = columns[activeColumnIndex];
    const previousColumn = columns[activeColumnIndex - 1];
    const nextColumn = columns[activeColumnIndex + 1];

    return (
        <MobileWrapper>
            {previousColumn ?
                <Link to={`/challenges/${previousColumn.id}`}>
                    <LeftArrow />
                </Link>
                : <span />
            }
            <ColumnMenuItem
                key={column.id}
                cssWidth={column.width}
            >
                <FullHeightLink
                    to={'/challenges'}
                    className={"column-menu-" + column.id}
                >
                    <Title>
                        <Icon
                            type={column.icon}
                            style={{ marginRight: '4px', fontSize: '18px' }}
                        /> {column.title}
                    </Title>
                </FullHeightLink>
            </ColumnMenuItem>

            {nextColumn ?
                <Link to={`/challenges/${nextColumn.id}`}>
                    <RightArrow />
                </Link>
                : <span />
            }
        </MobileWrapper>
    )
}

const MenuWrapperResponsive = styled(MenuWrapper)`
    @media (max-width: 670px) {
        flex-direction: column;
        align-items: center;
    }
`

const ColumnMenuItemResponsive = styled.div`
    background-image: ${props => `url(${arrowSmall})`};
    background-repeat: no-repeat;
    background-position: center center;
    flex-shrink: 0;
    opacity: ${props => props.isDisabled ? 0.5 : 1};

    width: 150px
    background-size: 150px;
    margin-right: 32px;
    padding: 8px 0;
    &:last-child {
        margin-right: 0;
    }

    @media (max-width: 1000px) {
        width: 130px
        background-size: 130px;
        margin-right: 8px;
    }

    @media (max-width: 820px) {
        display: ${props => props.isDisabled ? 'none' : 'block'}
    }
`

const StagedMenuResponsive = ({ columns, activeColumn }: Props) => {

    return (
        <MenuWrapperResponsive>
            {columns.map(column => (
                <ColumnMenuItemResponsive
                    key={column.id}
                    cssWidth={column.width}
                    isDisabled={activeColumn.length && column.id !== activeColumn}
                >
                    <Title>
                        <Icon
                            type={column.icon}
                            style={{ marginRight: '4px', fontSize: '18px' }}
                        /> {column.title}
                    </Title>
                </ColumnMenuItemResponsive>
            ))}
        </MenuWrapperResponsive>
    );
}

export default KanbanMenu;
export { KanbanMenuSingleColumn, StagedMenuResponsive }
