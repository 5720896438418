import React from 'react';
import usePlaybook from '../../api/usePlaybook';
import styled from 'styled-components';
import FittedImage from 'react-fitted-image';

const ImagePlaceholder = styled.div`
    background: #F5F5F5;
    height: ${props => props.fallbackHeight + 'px'};
    width: ${props => props.fallbackWidth + 'px'};
`

const PlaybookImage = ({ playbookId, fallbackWidth, fallbackHeight, style, ...props }) => {
    const { image: playbookImage } = usePlaybook(playbookId);

    if (playbookImage && playbookImage.links.api_image_large) {
        return (
            <FittedImage
                src={playbookImage.links.api_image_large.href}
                alt="Playbook"
                fit="cover"
                style={style}
                {...props}
            />
        );
    }

    return (
        <ImagePlaceholder
            fallbackHeight={fallbackHeight}
            fallbackWidth={fallbackWidth}
        />
    );
}

export default PlaybookImage;
