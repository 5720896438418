import React from 'react';
import { get } from '../../helpers';
import Avatar from '../Avatar';
import useUser from '../../api/useUser';

const TaskOwnerAvatar = ({ task }) => {
    let uid = get(task, 'relationships.uid.data.id');
    const { user } = useUser(uid);

    if (!user) {
        return null;
    }

    return <Avatar
        user={user}
        size="small"
    />
};

export default TaskOwnerAvatar;
