import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Tabs } from 'antd';
import CardTypeTabs from '../Ant/CardTypeTabs'

const { TabPane } = Tabs;

const CompetitionRunTabs = ({ playbookId, workflowStatus, activeTab }) => {
    const overviewPhase = workflowStatus === 'complete' ? 'results' : 'live';
    return (
        <CardTypeTabs type="card" activeKey={activeTab}>
            <TabPane tab={<Link to={`/challenge/${playbookId}/${overviewPhase}`}>Overview</Link>} key="overview"></TabPane>
            <TabPane tab={<Link to={`/challenge/${playbookId}/forum`}>Forum</Link>} key="forum"></TabPane>
            <TabPane tab={<Link to={`/challenge/${playbookId}/submissions`}>Submissions</Link>} key="submissions"></TabPane>
            <TabPane tab={<Link to={`/challenge/${playbookId}/updates`}>Updates</Link>} key="updates"></TabPane>
        </CardTypeTabs>
    );
};

export default withRouter(CompetitionRunTabs);