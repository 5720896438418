const mergeEntityAttributes = (entity, newAttributes) => {
    let updatedEntity = JSON.parse(JSON.stringify(entity))
    updatedEntity.attributes = {
        ...updatedEntity.attributes,
        ...newAttributes,
    }
    return updatedEntity;
}

export default mergeEntityAttributes;
