// @flow

import React from 'react';
import { Tabs, Collapse } from 'antd';
import SignupActivity from './SignupActivity';
import SubmissionActivity from './SubmissionActivity';
import ForumActivity from './ForumActivity';
import useJsonApiGet from '../../api/useJsonApiGet';
import styled from 'styled-components';
import ActivityFeedRenderer from './ActivityFeedRenderer'

const { TabPane } = Tabs;
const { Panel } = Collapse;

const StyledTabs = styled(Tabs)`
    @media (max-width: 400px) {
        .ant-tabs-nav-wrap {
            width: 220px;
        }
    }
`
const ClearPanel = styled(Panel)`
    background: unset;
    border: none !important;
    overflow: hidden;

    .ant-collapse-content-box {
        padding: 0 !important;
    }
    .ant-collapse-header {
        text-align: right;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 15px 0 !important;

        .anticon {
            position: relative !important;
        }
        .ant-collapse-arrow {
            left: 0 !important;
            top: 1px !important;
            transform: none !important;
            margin-left: 1em !important;
        }
    }
`

type Props = {
    remoteCompetitionId: string,
}

const ActivityTabs = ({ remoteCompetitionId }: Props) => {
    const { response, jsonapi } = useJsonApiGet(`/api/unearthed/activity-stream/${remoteCompetitionId}`)
    let activities = response.data.data || [];

    return (
        <>
            <StyledTabs
                tabPosition="top"
                defaultActiveKey="all"
            >
                <TabPane tab="All" key="all">
                    <ActivityFeedRenderer activities={activities} jsonapi={jsonapi} limit={5}/>
                    
                        <Collapse bordered={false} accordion>
                            <ClearPanel header="View More" key="1">
                                <ActivityFeedRenderer activities={activities} jsonapi={jsonapi} offset={6}/>
                            </ClearPanel>
                        </Collapse>
                </TabPane>

                <TabPane tab="Sign ups" key="participants">
                    <SignupActivity
                        activities={activities.filter(a => a.type === 'competition_participation--competition_participation')}
                        jsonapi={jsonapi}
                    />
                </TabPane>

                <TabPane tab="Submissions" key="submissions">
                    <SubmissionActivity
                        activities={activities.filter(a => ['submission--data_science', 'submission--hackathon'].includes(a.type))}
                        jsonapi={jsonapi}
                    />
                </TabPane>

                <TabPane tab="Forum Posts" key="forum">
                    <ForumActivity
                        activities={activities.filter(a => a.type === 'forum_question--forum_question')}
                        jsonapi={jsonapi}
                    />
                </TabPane>
            </StyledTabs>
        </>
    );
};

export default ActivityTabs;
