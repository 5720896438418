// @flow

import React, { useState } from 'react';
import { Button, Modal } from '../Ant';
import EditTaskTemplateForm from '../../Forms/EditTaskTemplateForm';

type Props = {
    addTaskTemplate: Function,
    afterSave?: Function,
}

const CreateNewTaskTemplate = ({ addTaskTemplate, afterSave }: Props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <div>
            <Button type="primary" onClick={() => setIsModalVisible(!isModalVisible)}>Create task workflow</Button>
            <Modal
                title="Create task workflow"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <EditTaskTemplateForm
                    template={{
                        type: 'task_template--task_template',
                        attributes: {
                            label: '',
                            description: '',
                        }
                    }}
                    save={addTaskTemplate}
                    afterSave={afterSave}
                />
            </Modal>
        </div>
    );
};

export default CreateNewTaskTemplate;
