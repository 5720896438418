export default function (task) {
    // We save all changes to localStorage to try and avoid losing anything
    // although we don't have a way to recover this just yet.
    try {
        let localTask = { ...task };
        localTask.time = Date.now() / 1000;
        let tasks = JSON.parse(localStorage.getItem('tasks')) || [];
        const existingTaskIndex = tasks.findIndex(t => t.id === task.id);
        if (existingTaskIndex !== -1) {
            // The task already exists so just update it.
            tasks[existingTaskIndex] = localTask;
        }
        else {
            // It doesn't exist, make sure we don't go above 10 tasks in local storage.
            if (tasks.length >= 10) {
                // Sort the tasks, from oldest to newest.
                tasks = tasks.sort((a, b) => a.time - b.time);
                // Remove the oldest item from the beginning.
                tasks.shift();
            }
            tasks.push(localTask);
        }
        localStorage.setItem('tasks', JSON.stringify(tasks));
    }
    catch (e) { console.log(e); }
}
