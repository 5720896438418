import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import KanbanBoard from '../components/Kanban/KanbanBoard';
import { useSetPageTitle } from '../hoc/setPageTitle';
import columnDefinitions from '../components/Kanban/columnDefinitions';
import { get } from '../helpers';
import Padding from '../components/Common/Padding';
import { ApiContext } from '../api/ApiContext';

const KanbanOverviewWrapper = styled.div`
    width: 1750px;
    margin: 0 auto;
    max-width: 100%;
    overflow: auto;

    @media (max-width: 1630px) {
        overflow: scroll;
    }
`

const KanbanOverview = () => {
    useSetPageTitle('Kanban');
    const [isLoading, setIsLoading] = useState(true);
    const [columnData, setColumnData] = useState({})
    const api = useContext(ApiContext);

    useEffect(() => {
        let promises = [];
        let cancelUrls = [];

        columnDefinitions.forEach(column => {
            let url = '/api/unearthed/pinned-workflows/' + column.id;
            cancelUrls.push(url);
            promises.push(
                api.get(url)
            );
        })

        Promise.all(promises)
            .then(results => {
                let newColData = {};
                results.forEach((result, i) => {
                    newColData[columnDefinitions[i].id] = get(result, 'data.data', []);
                })

                setColumnData(newColData);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            })

            // Cancel all requests.
            return () => {
                cancelUrls.forEach(url => api.cancel(url));
            }
    }, [api]);

    if (isLoading) {
        return <Spin spinning={true}></Spin>
    }

    return (
        <KanbanOverviewWrapper>
            <Padding horizontal>
                <KanbanBoard
                    columns={columnDefinitions.sort((a, b) => a.weight - b.weight)}
                    columnData={columnData}
                />
            </Padding>
        </KanbanOverviewWrapper>
    );
};

export default KanbanOverview;
