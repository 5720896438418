import { CheckboxesTask, FileTask, MoneyTask, NumberTask, SelectTask, TextareaTask, TextfieldTask, UserSelectionTask, UserSelectionMultiTask, TextfieldMultiTask } from '../components/TaskTypes';

export function getTaskTypeComponentList() {
    return {
        user_selection: UserSelectionTask,
        user_selection_multi: UserSelectionMultiTask,
        text: TextareaTask,
        textfield: TextfieldTask,
        textfield_multi: TextfieldMultiTask,
        money: MoneyTask,
        number: NumberTask,
        selectbox: SelectTask,
        select: SelectTask,
        checkboxes: CheckboxesTask,
        file: FileTask,
    };
}

export default function getTaskTypeComponent(task) {
    return getTaskTypeComponentList()[task.attributes.data.type];
}
