import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { Home, PhaseEditPage, EditUser, ViewChallenge, LoadingPage,CompetitionForum, ManageOrganisations, EditOrganisation, ManageTaskTemplates, ManageTags, ManageUsers, EditTaskTemplate, MyAssignedTasks, CompetitionRun, LoginPage, PasswordResetPage, SetNewPassword, ManageMetrics, TaskWorkflowMetrics, GlobalActivityPage, WorkflowCompleteTasksInline, ManageHelp, ManageContent, PlaybookUpdate, CompetitionSubmissions, KanbanOverview, KanbanColumnList } from './pages';
import GlobalStyles from './global-styles/GlobalStyles';
import GlobalButtonStyles from './global-styles/GlobalButtonStyles';
import { loadCurrentUser, setIsLoggedIn, jsonApi } from './actions';
import NewUser from './pages/Admin/NewUser';
import { refreshToken } from './helpers/jwt';
import withTracker from './withTracker';
import UnearthedSubmission from './pages/UnearthedSubmission';
import GenericError from './components/Common/GenericError';
import NeedsRefreshIndicator from './components/NeedsRefreshIndicator';
import { initialiseGa } from './helpers/trackEvent';
import requiresAuth from './hoc/requiresAuth';
import WorkflowStatusPage from './pages/WorkflowStatusPage';
import PageNotFound404 from './pages/PageNotFound404';
import { AppLayout } from './layouts';
import EditContentBlock from './pages/Admin/EditContentBlock';
import { BackendApi, ApiContext, InnovatorApiContext } from './api/ApiContext';
import axios from 'axios';
import getBaseUrl from './components/Unearthed/getBaseUrl';

let gaInit = initialiseGa();

const routeInfo = [
  { path: "/", component: Home, activeMenuItem: 'home' },
  { path: "/activity", component: GlobalActivityPage },
  { path: "/challenges", component: KanbanOverview, activeMenuItem: 'challenges' },
  { path: "/challenges/:columnId", component: KanbanColumnList, activeMenuItem: 'challenges' },

  { path: "/challenge/:id/live", component: CompetitionRun, activeMenuItem: 'challenge' },
  { path: "/challenge/:id/results", component: CompetitionRun, activeMenuItem: 'challenge' },
  { path: "/challenge/:id/forum", component: CompetitionForum, activeMenuItem: 'challenge' },
  { path: "/challenge/:id/submissions", component: CompetitionSubmissions, activeMenuItem: 'challenge' },
  { path: "/challenge/:id/updates", component: PlaybookUpdate, activeMenuItem: 'challenge' },
  { path: "/challenge/:id/preview", component: ViewChallenge, activeMenuItem: 'challenge' },

  // Opportunity or Build
  { path: "/challenge/:id/:phase", component: PhaseEditPage, activeMenuItem: 'challenge' },

  { path: "/:parentType/:id/metrics", component: TaskWorkflowMetrics },
  { path: "/challenge/:id/updates/:activeUpdateId", component: PlaybookUpdate, activeMenuItem: 'competition' },

  { path: "/user/login", component: LoginPage, isAnonymous: true },
  { path: "/user/password", component: PasswordResetPage, isAnonymous: true },
  { path: "/user/set-new-password", component: SetNewPassword, isAnonymous: true },

  { path: "/user/edit", component: EditUser },
  { path: "/user/edit/:id", component: EditUser },
  { path: "/user/my-tasks", component: MyAssignedTasks },
  { path: "/submission/:type/:id", component: UnearthedSubmission },

  { path: "/:workflowTypeId/:workflowId/status", component: WorkflowStatusPage, isAnonymous: true },
  { path: "/:type/:playbookId/complete-tasks", component: WorkflowCompleteTasksInline },

  // Admin routes.
  { path: "/admin/task-templates", component: ManageTaskTemplates, requiredRoles: ['innovation_manager'], activeMenuItem: "task_workflows" },
  { path: "/admin/task-templates/edit/:id", component: EditTaskTemplate, requiredRoles: ['innovation_manager'], activeMenuItem: "task_workflows" },
  { path: "/admin/user-management", component: ManageUsers, requiredRoles: ['innovation_manager'], activeMenuItem: "user_management" },
  { path: "/admin/user/new", component: NewUser, requiredRoles: ['innovation_manager'], activeMenuItem: "user_management" },
  { path: "/admin/organisations", component: ManageOrganisations, requiredRoles: ['innovation_manager'], activeMenuItem: "organisations" },
  { path: "/admin/organisations/edit/:id", component: EditOrganisation, requiredRoles: ['innovation_manager'], activeMenuItem: "organisations" },
  { path: "/admin/tags", component: ManageTags, requiredRoles: ['innovation_manager'], activeMenuItem: "tags" },
  { path: "/admin/metrics", component: ManageMetrics, requiredRoles: ['innovation_manager'], activeMenuItem: "metrics" },
  { path: "/admin/help", component: ManageHelp, requiredRoles: ['innovation_manager'], activeMenuItem: "help" },
  { path: "/admin/content", component: ManageContent, requiredRoles: ['innovation_manager'], activeMenuItem: "content" },
  { path: "/admin/content/edit/:id", component: EditContentBlock, requiredRoles: ['innovation_manager'], activeMenuItem: "content" },
];

// Temporary workaround so that our actions can clear the cache of the API
// wrapped up in context.
let globalBackendApi = null;
class App extends React.Component {

  constructor() {
    super();
    this.state = {
      isLoading: true,
      isFailed: false,
      roles: [],
      currentUser: null,
    }
    this.checkLogin = this.checkLogin.bind(this);
    this.backendApi = new BackendApi(() => jsonApi());
    globalBackendApi = this.backendApi;

    const innovatorPortalClient = axios.create({
      baseURL: getBaseUrl(),
    });
    this.innovatorApi = new BackendApi(() => innovatorPortalClient);
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin() {
    refreshToken()
      .then(() => this.handleLoadingCurrentUser())
      .catch(error => {
        // Show the login page.
        this.setState({ isLoading: false })
      });
  }

  handleLoadingCurrentUser() {
    return this.props.doLoadCurrentUser()
      .then(userResponse => {
        this.setState({
          isLoading: false,
          currentUser: userResponse.payload.data,
          roles: typeof userResponse.payload.included === 'undefined' ? [] : userResponse.payload.included.filter(role => role.type === 'user_role--user_role')
        }, () => this.props.setIsLoggedIn(true))
      })
  }

  checkRouteAuth(requiredRoles) {
    let hasCorrectRoles = true;
    let allUserRoles = this.state.roles.map(role => role.attributes.drupal_internal__id);

    requiredRoles.forEach(role => {
      if (!allUserRoles.includes(role)) {
        hasCorrectRoles = false;
      }
    })

    return hasCorrectRoles;
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingPage>
        <GlobalStyles />
        <GlobalButtonStyles />

      </LoadingPage>
    }

    if (this.state.isFailed) {
      return (
        <div style={{ marginTop: '64px' }}>
          <GenericError title="The Challenge Platform is currently under maintenance" message="Please try again shortly or contact Unearthed (dev@unearthed.solutions) if this persists." />
        </div>
      );
    }

    return (
      <Router basename={'/'}>
        <div>
          <ApiContext.Provider value={this.backendApi}>
            <InnovatorApiContext.Provider value={this.innovatorApi}>

              <GlobalStyles />
              <GlobalButtonStyles />
              <NeedsRefreshIndicator />

              <Switch>
                {routeInfo.map((route, i) => <Route key="app_route" exact path={route.path} render={({ location, match }) => {
                  let WrappedComponent = gaInit ? withTracker(route.component) : route.component;
                  const isAnonymous = route.isAnonymous || false;
                  const AuthedComponent = requiresAuth(WrappedComponent, routeInfo, this.checkRouteAuth(route.requiredRoles || []), this.props.isLoggedIn, isAnonymous, this.checkLogin, location)

                  if (isAnonymous === true || this.props.isLoggedIn !== true) {
                    return AuthedComponent
                  }

                  return <AppLayout activeMenuItem={typeof route.activeMenuItem === 'function' ? route.activeMenuItem(match) : route.activeMenuItem}>
                    {AuthedComponent}
                  </AppLayout>
                }} />)}
                <Route component={PageNotFound404} />
              </Switch>
            </InnovatorApiContext.Provider>
          </ApiContext.Provider>
        </div>
      </Router>
    )
  }

}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.appState.isLoggedIn,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    doLoadCurrentUser: () => dispatch(loadCurrentUser()),
    setIsLoggedIn: (...args) => dispatch(setIsLoggedIn(...args)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
export { globalBackendApi }
