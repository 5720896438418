// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import { User } from '../types';
import { updateUser, loadUser, loadCurrentUser } from '../actions';
import Box from '../components/Common/Box';
import EditUserForm from '../Forms/EditUserForm';
import setPageTitle from '../hoc/setPageTitle';
import { hasPermission } from '../helpers/HasPermission';
import { getUserPermissions, getCurrentUser } from '../selectors';
import ContentWrapper from '../layouts/ContentWrapper';

type Props = {
    currentUser: User,
    editUser: User,
    form: Object,
    save: Function,
    loadUser: Function,
    loadCurrentUser: Function,
    history: Object,
    permissions: Array<string>,
}

type State = {}

class EditUser extends Component<Props, State> {
    componentDidMount() {
        this.props.loadUser()
    }

    render() {
        let { editUser, save, currentUser } = this.props;
        if (!editUser) {
            return <Spin spinning={true}>...</Spin>
        }

        return (
            <ContentWrapper>
                <Box style={{ maxWidth: '800px' }}>
                    <EditUserForm
                        user={editUser}
                        save={save}
                        afterSave={(postImageChange = false) => {
                            if (editUser.id === currentUser.id) {
                                this.props.loadCurrentUser();
                            }
                            if (!postImageChange && hasPermission(this.props.permissions, 'administer users')) {
                                this.props.history.push('/admin/user-management');
                            }
                        }}
                    />
                </Box>
            </ContentWrapper>
        );
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        // We only have to load the user if it's in the url otherwise we use the current user.
        // which is already loaded.
        loadUser: () => props.match.params.id ? dispatch(loadUser(props.match.params.id)) : null,
        loadCurrentUser: user => dispatch(loadCurrentUser()),
        save: user => dispatch(updateUser(user)),
    }
}

const mapStateToProps = (state, props) => {
    const currentUser = getCurrentUser(state);
    // If no id in the router use the current user.
    let editUser = null;
    if (!props.match.params.id) {
        editUser = currentUser;
    }
    else {
        editUser = typeof state.jsonApi['user--user'] === 'undefined'
            ? null
            : state.jsonApi['user--user'][props.match.params.id];
    }

    return {
        editUser: editUser,
        currentUser,
        permissions: getUserPermissions(state, currentUser),
    }
}

export default setPageTitle(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditUser)), 'Edit Profile');
