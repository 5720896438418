// @flow

import React from 'react';
import NumericalStat from '../NumericalStat';
import useCompetitionMetadata from './useCompetitionMetadata';
import { Spin } from 'antd';
import { TaskWorkflow } from '../../types';

type Props = {
    competition: TaskWorkflow,
}

const NewParticipantCountStat = ({ competition }: Props) => {
    const [isLoading, metadata] = useCompetitionMetadata(competition.attributes.remote_uuid);

    return <Spin spinning={isLoading}>
        <NumericalStat
            text={"New This Week"}
            icon="usergroup-add"
            value={metadata.newParticipantCount}
        />
    </Spin>
};

export default NewParticipantCountStat;
