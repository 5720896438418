import React, { useState, useCallback } from 'react';
import { DatePicker } from './Ant';
import { Icon, message, Spin } from 'antd';
import dayjs from 'dayjs';
import { extractErrorMessage } from '../api';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';

const DateWrapper = styled.div`
    padding: 8px;
    border-radius: 3px;
    display: flex;
    align-items: center;

    &:hover {
        cursor: ${props => props.isEditable ? 'pointer' : 'inherit'};
        background: ${props => props.isEditable ? '#EEE' : 'inherit'};
    }

    @media (max-width: 600px) {
        .date-prefix,
        .date-text {
            display: none;
        }
    }
`
DateWrapper.displayName = 'DateWrapper';

type LanguageOptions = {
    emptyText: ?string,
    selectText: ?string,
    prefix: ?string,
}

type Props = {
    selectedDate: string,
    onChange: Function,
    isEditable: bool,
    language: LanguageOptions,
}

const DateSelect = ({ isEditable, onChange, selectedDate = null, language = {} }: Props) => {
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const lang = {
        prefix: '',
        emptyText: 'None',
        selectText: 'Select',
        ...language,
    };

    const handleOutsideClick = useCallback(e => {
        if (e.srcElement.className.indexOf('ant-calendar') === -1) {
            setIsEditingDate(false)
        }
    }, [])

    if (isEditingDate) {
        return (
            <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                <DatePicker
                    defaultValue={selectedDate}
                    open={true}
                    onChange={isoString => {
                        setIsEditingDate(false);
                        setIsLoading(true);
                        onChange(isoString)
                            .then(result => {
                                setIsEditingDate(false);
                                setIsLoading(false);
                            })
                            .catch(error => {
                                setIsLoading(false);
                                message.warning(extractErrorMessage(error));
                            })
                    }}
                />
            </OutsideClickHandler>
        )
    }

    return (
        <DateWrapper isEditable={isEditable} onClick={() => {
            if (isEditable) {
                setIsEditingDate(true);
            }
        }}>
            <Icon type="calendar" style={{ marginRight: '8px' }} />
            <Spin spinning={isLoading}>
                {selectedDate ?
                    <span>{lang.prefix ? <span className="date-prefix">{lang.prefix} </span> : null}{dayjs(selectedDate).format('DD-MMM-YY')}</span> :
                    <span className="date-text">{isEditable ? lang.selectText : lang.emptyText}</span>
                }
            </Spin>
        </DateWrapper>
    );
};

export default DateSelect;
