import getHash from '../../helpers/getHash'

const findTaskId = (tasks, getHardCodedTaskId) => {
    let taskId
    let isIdHash = false
    const hash = getHash()
    
    let hashTask = tasks.find(t => t.id === hash)
    if (hashTask) {
        taskId = hash 
        isIdHash = true          
    } else {
        const result = getHardCodedTaskId()
        if (result) {
            taskId = result
        } else {
            // Find next incomplete task
            const t = tasks.find(t => !t.attributes.is_complete);
            if (t) {
                taskId = t.id
            }
        }
    }
    return [taskId, isIdHash]
    
}

export default findTaskId



