import { IMAGE_LARGE_CONSUMER_ID } from '../consumers';
import { useContext, useState, useEffect, useCallback } from 'react';
import { ApiContext } from './ApiContext';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getPlaybook, getRelationship } from '../selectors';
import { extractErrorMessage } from '.';

const usePlaybook = (playbookId) => {
    const api = useContext(ApiContext);
    const [status, setStatus] = useState('loading');
    const [isFailedMessage, setIsFailedMessage] = useState('');

    const dispatch = useDispatch();
    const playbook = useSelector(state => getPlaybook(state, playbookId), shallowEqual);
    const image = useSelector(state => getRelationship(state, 'image', playbook), shallowEqual);
    const workflows = useSelector(state => getRelationship(state, 'workflows', playbook), shallowEqual) || [];
    const organisation = useSelector(state => getRelationship(state, 'organisation', playbook), shallowEqual) || [];
    const collaborators = useSelector(state => getRelationship(state, 'collaborators', playbook), shallowEqual) || [];
    const owner = useSelector(state => getRelationship(state, 'uid', playbook), shallowEqual);

    const saveResult = useCallback(result => {
        dispatch({
            type: 'API_SUCCESS',
            payload: result.data,
        })
    }, [dispatch]);

    const performRequest = useCallback((onComplete) => {
        setStatus('loading');
        let include = [
            'image',
            'organisation',
            'organisation.logo',
            // Workflows.
            'workflows',
            'workflows.task_workflow_type',
            'workflows.tags',
            'collaborators',
            'uid',
            'uid.field_picture',
            'collaborators.field_picture',
        ].join(',');
        const url = `/jsonapi/playbook/playbook/${playbookId}?include=${include}&consumerId=${IMAGE_LARGE_CONSUMER_ID}`;
        api.get(url)
            .then(result => {
                // If result is empty, do nothing, probably means the request was cancelled.
                if (!result) {
                    // We cannot set the status of the request here because it was cancelled.
                    return;
                }
                
                saveResult(result);
                setStatus('complete');

                // Mainly when you refresh, you might need to know when it's finished.
                if (typeof onComplete === 'function') {
                    onComplete();
                }
            })
            .catch(error => {
                setStatus('failed');
                setIsFailedMessage(extractErrorMessage(error));
            })

        return () => {
            api.cancel(url);
        }
    }, [api, playbookId, saveResult])

    useEffect(() => {
        if (!playbookId) {
            return;
        }

        return performRequest();
    }, [performRequest, playbookId])

    return {
        isLoading: status === 'loading',
        isFailed: status === 'failed',
        failedErrorMessage: isFailedMessage,
        playbook: playbook,
        workflows: workflows,
        image: image,
        organisation: organisation,
        collaborators: collaborators,
        owner: owner,
        refresh: (onComplete) => {
            api.clearCache();
            return performRequest(onComplete);
        },
        clearCache: () => {
            api.clearCache();
        },

        update: (workflow) => {
            setStatus('loading');
            ['drupal_internal__id', 'changed'].forEach(field => delete workflow.attributes[field]);

            return api.patch(workflow.links.self.href, { data: workflow })
                .then(response => {
                    setStatus('complete');
                    return saveResult(response);
                });
        }
    }
};

export default usePlaybook;
