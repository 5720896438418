import React from 'react';
import TagSelectionEdit from '../Tag/TagSelectionEdit';
import SidebarItem from '../Sidebar/SidebarItem';
import SidebarLabel from '../Sidebar/SidebarLabel';
import TaskWorkflowActivityList from '../Activity/TaskWorkflowActivityList';

const PhaseSidebar = ({ taskWorkflow, preview, update }) => {
    return (
        <div>
            {preview}

            <TagSelectionEdit
                entity={taskWorkflow}
                update={update}
                defaultSelectedTagIds={taskWorkflow.relationships.tags.data.map(data => data.id)}
            >
                {(categoryTitle, tags) => (
                    <SidebarItem>
                        <SidebarLabel>{categoryTitle}</SidebarLabel>
                        {tags}
                    </SidebarItem>
                )}
            </TagSelectionEdit>

            <TaskWorkflowActivityList
                render={(activities, activitylist) => {
                    if (activities.length === 0) {
                        return null;
                    }

                    return (
                        <SidebarItem>
                            <SidebarLabel>Activity</SidebarLabel>
                            {activitylist}
                        </SidebarItem>
                    )
                }}
                taskWorkflow={taskWorkflow}
            />
        </div>
    );
};

export default PhaseSidebar;
