// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import TaskCard from '../components/Task/TaskCard';
import { loadUserTasks } from '../actions';
import { getCurrentUser, getMyIncompleteTasks, getMyTaskWorkflows } from '../selectors';
import { User, TaskWorkflow, TaskType } from '../types';
import { Spin } from 'antd';
import setPageTitle from '../hoc/setPageTitle';
import CreateNewChallenge from '../components/Modals/CreateNewChallenge';
import { Modal } from '../components/Ant';
import EditTaskInline from '../components/Task/EditTaskInline';

const BoardActions = styled.div`
    margin-bottom: 24px;
    text-align: right;
`

const Board = styled.div`
    max-width: 100%;
    display: flex;

    @media (max-width: 760px) {
        flex-direction: column;

        > div {
            margin-right: 0;
        }
    }

    > div {
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    }

`
const Column = styled.div`
    background-color: rgba(0,0,0,0.04);
    padding: 5px;
    max-width: 780px;
    flex-shrink: 0;
    width: 47%;

    @media (max-width: 760px) {
        width: 100%;
    }

    a {
        display: block;
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`
Column.displayName = 'Column';

const Title = styled.h2`
    padding: 20px;
    margin: 0;
    font-size: 15px;

    span {
        font-size: 17px;
        color: rgba(0,0,0,0.55);
    }
`

type Props = {
    challengeTasks: Array<TaskType>,
    competitionTasks: Array<TaskType>,
    loadUserTasks: Function,
    currentUser: User,
    taskWorkflows: Array<TaskWorkflow>,
}

type TaskListProps = {
    taskWorkflows: Array<TaskWorkflow>,
    tasks: Array<TaskType>,
    currentUser: User,
}

const TaskList = ({ tasks, taskWorkflows, currentUser }: TaskListProps) => {
    const [modalActiveTask, setModalActiveTask] = useState(null);
    const [modalActiveTaskWorkflow, setModalActiveTaskWorkflow] = useState(null);

    if (modalActiveTask) {
        return <Modal
            title={'Edit Task'}
            titleIntro={modalActiveTaskWorkflow !== null ? modalActiveTaskWorkflow.attributes.title : ''}
            visible={modalActiveTask !== null}
            onCancel={() => setModalActiveTask(null)}
            width={'100%'}
            footer={[]}
        >
            <EditTaskInline
                task={modalActiveTask}
                currentUser={currentUser}
            />
        </Modal>
    }

    return (tasks.map(task => {
        let taskWorkflow = taskWorkflows.find(taskWorkflow => taskWorkflow.relationships.tasks.data.find(t => t.id === task.id));
        if (typeof taskWorkflow === 'undefined') {
            return <div>Error, failed to find task workflow for task {task.id}</div>
        }

        return (
            <div
                onClick={() => {
                    setModalActiveTask(task);
                    setModalActiveTaskWorkflow(taskWorkflow);
                }}
                className="hoverable"
                key={task.id}
                style={{ marginBottom: '5px' }}
            >
                <TaskCard
                    task={task}
                    taskWorkflow={taskWorkflow}
                />
            </div>
        )
    }))
}

const MyAssignedTasks = ({ challengeTasks, competitionTasks, taskWorkflows, loadUserTasks, currentUser }: Props) => {
    const [isLoadingChallenge, setIsLoadingChallenge] = useState(true);
    const [isLoadingCompetition, setIsLoadingCompetition] = useState(true);

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        loadUserTasks(currentUser.id, 'challenge')
            .then(response => setIsLoadingChallenge(false));

        loadUserTasks(currentUser.id, 'competition')
            .then(response => setIsLoadingCompetition(false));
    }, [currentUser, loadUserTasks])

    return (
        <>
            <BoardActions>
                <CreateNewChallenge />
            </BoardActions>
            <Board>
                <Column key="challenge">
                    <Spin spinning={isLoadingChallenge}>
                        <Title>My Challenge Tasks <span>{parseInt(challengeTasks.length)}</span></Title>
                        <TaskList tasks={challengeTasks} taskWorkflows={taskWorkflows} currentUser={currentUser} />
                    </Spin>
                </Column>

                <Column key="competition">
                    <Spin spinning={isLoadingCompetition}>
                        <Title>My Competition Tasks <span>{parseInt(competitionTasks.length)}</span></Title>
                        <TaskList tasks={competitionTasks} taskWorkflows={taskWorkflows} currentUser={currentUser} />
                    </Spin>
                </Column>
            </Board>
        </>
    );
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        loadUserTasks: (...args) => dispatch(loadUserTasks(...args)),
    }
}

const mapStateToProps = (state, props) => {
    let tasks = getMyIncompleteTasks(state);
    let challenges = getMyTaskWorkflows(state, 'challenge');
    let competitions = getMyTaskWorkflows(state, 'competition');

    return {
        taskWorkflows: challenges.concat(competitions),
        challengeTasks: tasks.filter(task => task.attributes.parent_type === 'task_workflow--challenge'),
        competitionTasks: tasks.filter(task => task.attributes.parent_type === 'task_workflow--competition'),
        currentUser: getCurrentUser(state),
    }
}

export default setPageTitle(connect(mapStateToProps, mapDispatchToProps)(MyAssignedTasks), 'My Assigned Tasks');
export { MyAssignedTasks };
