// @flow

import React, { useCallback, useState } from 'react';
import { Form, Input, Button, Spin, Divider } from 'antd';
import { connect } from 'react-redux';
import { message, TextArea } from '../components/Ant';
import { User } from '../types';
import { mergeEntityAttributes } from '../helpers';
import { createUser } from '../actions';

type Props = {
    user: User,
    form: Object,
    save: Function,
    onAfterSave: Function,
    onCancel: Function,
    defaultMessage: string,
}

const UserRegisterFormSimple = ({ user, form, save, onAfterSave, onCancel, defaultMessage }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const { getFieldDecorator } = form;
    const onSave = useCallback(e => {
        e.preventDefault();

        form.validateFields()
            .then(result => {
                // Merge new values into the user object and save it.
                let newUser = mergeEntityAttributes(user, {
                    field_full_name: result.fullName,
                    mail: result.email,
                    name: result.email,
                    status: 1,
                });

                newUser.relationships = {}
                if (typeof result.roles !== 'undefined') {
                    newUser.relationships.roles = {
                        data: result.roles.map(role => {
                            return {
                                id: role,
                                type: 'user_role--user_role',
                            }
                        })
                    }
                }

                setIsLoading(true);
                save(newUser)
                    .then(updateResult => {
                        message.success('User created and invite sent');
                        form.resetFields();
                        setIsLoading(false);
                        onAfterSave({
                            userId: updateResult.data.id,
                            message: result.message,
                        });
                    })
                    .catch(error => {
                        setIsLoading(false);
                        // A work around for the fact that there can be many errors but we only normally care
                        //a bout the email already taken one so make it nicer.
                        if (error.indexOf('is already taken') !== -1) {
                            error = 'This email address has already been taken';
                        }
                        message.warning(error)
                    });
            })
            .catch(errors => {
                console.log(errors);
                message.warning('Please complete all fields');
            })
    }, [form, save, user, onAfterSave])

    return (
        <Spin spinning={isLoading}>
            <Form onSubmit={onSave}>
                <Form.Item label="Full Name">
                    {getFieldDecorator('fullName', {
                        rules: [{ required: true, message: 'Please input your full name.' }],
                        initialValue: user.attributes.display_name,
                    })(
                        <Input name="fullName" />
                    )}
                </Form.Item>

                <Form.Item label="Email">
                    {getFieldDecorator('email', {
                        rules: [{ type: 'email', required: true, message: 'Please input a valid email.' }],
                        initialValue: user.attributes.mail,
                    })(
                        <Input name="email" autoComplete="email" />
                    )}
                </Form.Item>

                <Form.Item label="Message">
                    {getFieldDecorator('message', {
                        initialValue: defaultMessage,
                    })(
                        <TextArea name="message" />
                    )}
                </Form.Item>

                <Divider />

                <Button style={{ marginRight: '8px' }} onClick={onCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit">Create User and Send Invite</Button>
            </Form>
        </Spin>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        save: user => dispatch(createUser(user)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'user_register_simple' })(UserRegisterFormSimple));
