// @flow

import React, { useState } from 'react';
import type { Node } from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';
import { Modal } from '../Ant';
import EditTagCategoryForm from '../../Forms/EditTagCategoryForm';

const Wrapper = styled.span`
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    > * {
        margin: 0;
    }
`

type Props = {
    category: Object,
    onAfterSave: Function,
    children: Node,
}

const TagCategoryEditModal = ({ category, onAfterSave, children }: Props) => {
    const [isHovering, setIsHovering] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Wrapper
                onClick={() => setIsModalVisible(true)}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                {children}{isHovering ? <Icon className="icon-large" type="edit" /> : null}
            </Wrapper>
            <Modal
                title={'Edit Category'}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                width={'100%'}
                footer={[]}
            >
                <EditTagCategoryForm
                    category={category}
                    onAfterSave={(closeModal) => {
                        onAfterSave();
                        if (closeModal) {
                            setIsModalVisible(false)
                        }
                    }}
                />
            </Modal>
        </>
    );
};

export default TagCategoryEditModal;
