import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

const LayoutSiderStyled = styled(Layout.Sider)`
    border-radius: 4px;
    padding: 32px;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1);
`

const LayoutSider = ({ children, ...props }) => {
    return (
        <LayoutSiderStyled
            theme={"light"}
            collapsible={false}
            // collapsedWidth={0}
            {...props}
        >
            {children}
        </LayoutSiderStyled>
    );
};

export default LayoutSider;