// @flow

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadTaskTemplates, addTaskTemplate, updateTaskTemplate } from '../../actions';
import { message, Table } from '../../components/Ant';
import Box from '../../components/Common/Box';
import CreateNewTaskTemplate from '../../components/Modals/CreateNewTaskTemplate';
import setPageTitle from '../../hoc/setPageTitle';
import TaskTemplateActions from '../../components/TaskTemplate/TaskTemplateActions';
import PublishAction from '../../components/TaskTemplate/PublishAction';
import ContentWrapper from '../../layouts/ContentWrapper';

type Props = {
    loadTaskTemplates: Function,
    history: Object,
    doAddTaskTemplate: Function,
    doUpdateTaskTemplate: Function,
    dataSource: Array<Object>,
}

const ManageTaskTemplates = ({ dataSource, loadTaskTemplates, doAddTaskTemplate, doUpdateTaskTemplate, history }: Props) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadTaskTemplates()
            .then(result => setIsLoading(false))
            .catch(error => {
                console.log(error);
                message.warning(error);
                setIsLoading(false);
            })
    }, [loadTaskTemplates]);

    const columns = [{
        title: 'Label',
        dataIndex: 'attributes.label',
        key: 'label',
    }, {
        title: 'Type',
        dataIndex: 'attributes.task_workflow_type',
        key: 'task_workflow_type',
    }, {
        title: 'Status',
        render: record => <PublishAction publishAttribute="published" entity={record} update={doUpdateTaskTemplate} />,
    },
    {
        title: '',
        render: record => <TaskTemplateActions template={record} />
    }];

    return (
        <ContentWrapper>
            <h1>Task Workflows</h1>
            <Box>
                <div style={{ textAlign: 'right', marginBottom: '16px' }}>
                    <CreateNewTaskTemplate
                        addTaskTemplate={doAddTaskTemplate}
                        afterSave={(newTaskTemplate) => {
                            history.push('/admin/task-templates/edit/' + newTaskTemplate.payload.data.id);
                        }}
                    />
                </div>

                <Table
                    loading={isLoading}
                    columns={columns}
                    rowKey="id"
                    dataSource={dataSource}
                    locale={{ emptyText: 'No task workflows' }}
                />
            </Box>
        </ContentWrapper>
    );
}

const mapStateToProps = state => {
    return {
        dataSource: typeof state.jsonApi['task_template--task_template'] === 'undefined' ? [] : Object.values(state.jsonApi['task_template--task_template']),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadTaskTemplates: () => {
            return dispatch(loadTaskTemplates())
                .then(result => {
                    if (result.type === 'API_SUCCESS') {
                        return Promise.resolve(result.payload);
                    }
                    return Promise.reject(result.payload);
                })
                .catch(error => Promise.reject(error));
        },
        doAddTaskTemplate: template => dispatch(addTaskTemplate(template)),
        doUpdateTaskTemplate: template => dispatch(updateTaskTemplate(template)),
    }
}

export default setPageTitle(withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageTaskTemplates)), 'Manage Workflow Templates');
