import React, { useCallback, useState } from 'react';
import { Menu } from 'antd';
import PlaybookEditableDescription from '../Playbook/PlaybookEditableDescription';
import usePlaybook from '../../api/usePlaybook';
import { HasPermission, get } from '../../helpers';
import EllipsisMenu from '../Common/EllipsisMenu';
import styled from 'styled-components';

const EditWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

const CompetitionRunPlaybookDescription = ({ playbookId }) => {
    const [isEditing, setIsEditing] = useState(false);
    const { playbook } = usePlaybook(playbookId);

    const buildMenu = useCallback(() => {
        return <Menu onClick={e => e.domEvent.stopPropagation()}>
            <Menu.Item key="edit" onClick={() => setIsEditing(v => !v)}>
                Edit
            </Menu.Item>
        </Menu>
    }, []);

    return (
        isEditing
            ? <PlaybookEditableDescription
                playbookId={playbookId}
                onAfterSave={() => setIsEditing(false)}
            />
            : <EditWrapper>
                <div dangerouslySetInnerHTML={{ __html: get(playbook, 'attributes.description.processed') }} />
                <HasPermission permission="administer task_workflow">
                    <EllipsisMenu menu={buildMenu} />
                </HasPermission>
            </EditWrapper>
    );
};

export default CompetitionRunPlaybookDescription;