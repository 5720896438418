import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 600px) {
        justify-content: center;
    }

    > div {
        margin-right: 8px;
        margin-bottom: 8px;

        &:last-child {
            margin-right: 0;
        }
    }

`

const OpportunityGrid = ({ children }) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};

export default OpportunityGrid;
