// @flow

import React from 'react';

function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

type Props = {
    size: number,
}

const FileSize = ({ size }: Props) => {
    return (
        <span>{bytesToSize(size)}</span>
    );
};

type FromFileProps = {
    file: Object,
}

const FileSizeFromFile = ({ file }: FromFileProps) => (
    <FileSize size={file.attributes.filesize} />
)

export default FileSize;
export { FileSizeFromFile }
