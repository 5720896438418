import { makeGetApiCall, makePatchApiCall, makePostApiCall } from './api';
import { errorHandler } from '../api/index';
import { IMAGE_LARGE_CONSUMER_ID } from '../consumers';
import qs from 'qs';

export function loadUsers(filters = {}) {
    return function (dispatch, getState) {
        let filtersQuery = {
            'include': 'field_picture',
            'consumerId': IMAGE_LARGE_CONSUMER_ID,
            'filter[anon][condition][path]': 'uid',
            'filter[anon][condition][value]': '0',
            'filter[anon][condition][operator]': '<>',
            'sort[name][path]': 'name',
            'sort[name][direction]': 'ASC',
            ...filters,
        };
        let filterString = qs.stringify(filtersQuery);

        return makeGetApiCall(dispatch, 'LOAD_USERS', '/jsonapi/user/user?' + filterString);
    }
}

export function loadUser(userId) {
    return function (dispatch, getState) {
        return makeGetApiCall(dispatch, 'LOAD_USER', '/jsonapi/user/user/' + userId + '?filter[anon][condition][path]=uid&filter[anon][condition][value]=0&filter[anon][condition][operator]=<>&include=roles,field_picture&consumerId=' + IMAGE_LARGE_CONSUMER_ID);
    }
}

export function loadCurrentUser() {
    return function (dispatch, getState) {
        return makeGetApiCall(dispatch, 'LOAD_META', '/jsonapi')
            .then(response => {
                if (typeof response.payload.meta === 'undefined') {
                    return Promise.reject(response.payload);
                }

                return makeGetApiCall(dispatch, 'LOAD_CURRENT_USER', response.payload.meta.links.me.href + '?include=roles,field_picture&consumerId=' + IMAGE_LARGE_CONSUMER_ID)
                    .then(success => {
                        // Full name should be a required field but super admin doesn't have one locally when
                        // you rebuild the site so this is a little workaround.
                        success.payload.data.attributes.display_name = success.payload.data.attributes.display_name || 'N/A';
                        dispatch(setCurrentUser(success.payload.data));

                        return success;
                    });
            });
    }
}

export function setCurrentUser(user) {
    return {
        type: 'CURRENT_USER',
        user: user,
    }
}

export function setIsLoggedIn(bool) {
    return {
        type: 'IS_LOGGED_IN',
        result: bool,
    }
}

// Generic API call handling, consider moving this to api.js and using for all API calls
// that shouldn't send success/failure to the store.
const handle = (dispatch, apiCall) => {
    return apiCall
        .then(result => {
            if (result.type !== 'API_SUCCESS') {
                // Extract errors from the response if possible.
                if (typeof result.response !== 'undefined' && Array.isArray(result.response.data.errors)) {
                    return Promise.reject(result.response.data.errors.map(error => error.detail).join(', '));
                }
                return Promise.reject(result.payload);
            }

            // Update the entity in the store
            dispatch(result);
            return Promise.resolve(result.payload);
        })
        .catch(errorHandler)
}

export function updateUser(user) {
    return function (dispatch, getState) {
        let requestData = {
            data: user,
        }

        delete requestData.data.attributes.changed;

        return handle(
            dispatch,
            makePatchApiCall(dispatch, 'UPDATE_USER', '/jsonapi/user/user/' + user.id + '?include=roles,field_picture&consumerId=' + IMAGE_LARGE_CONSUMER_ID, requestData)
        );
    }
}

export function createUser(user) {
    return function (dispatch, getState) {
        let requestData = {
            data: user,
        }

        return handle(
            dispatch,
            makePostApiCall(dispatch, 'CREATE_USER', '/jsonapi/user/user', requestData)
        );
    }
}

export function loadUserRoles() {
    return function (dispatch, getState) {
        return handle(
            dispatch,
            makeGetApiCall(dispatch, 'LOAD_ROLES', '/jsonapi/user_role/user_role')
        );
    }
}
