import React from 'react';
import styled from 'styled-components';
import { Box } from '../components/Common';
import theme from '../themes';

const Container = styled.div`
    background: #F5F5F5;
    height: 100%;
`

const PageWrapper = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding: 100px 20px 0 20px;

    h2 {
        text-align: center;
    }
`

const Logo = styled.div`
    text-align: center;
    margin-bottom: 24px;
`

const BasicPage = ({ children }) => {
    return (
        <Container>
            <PageWrapper>
                <Logo>
                    <img alt="Logo" style={theme.logoStyles} src={theme.logo} />
                </Logo>
                <Box>
                    {children}
                </Box>
            </PageWrapper>
        </Container>
    );
};

export default BasicPage;
