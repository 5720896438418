import { useEffect, useContext, useState, useCallback } from "react";
import { ApiContext } from "./ApiContext";
import { extractErrorMessage } from ".";
import qs from 'qs';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getTaskTemplate, getRelationship } from "../selectors";

function useTaskTemplate(id) {
    const api = useContext(ApiContext);
    const [responseStatus, setResponseStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();
    
    const saveResult = useCallback(result => {
        dispatch({
            type: 'API_SUCCESS',
            payload: result.data,
        })
    }, [dispatch]);

    useEffect(() => {
        const query = {
            'include': 'default_tasks',
        };
        let url = `/jsonapi/task_template/task_template/${id}?${qs.stringify(query)}`;
        api
            .get(url)
            .then(result => {
                // Request was cancelled.
                if (!result) {
                    console.log('task templates cancelled');
                    return;
                }
                saveResult(result);
                setResponseStatus('success');
            })
            .catch(error => {
                setResponseStatus('failed');
                setErrorMessage(extractErrorMessage(error));
            });

        return () => {
            api.cancel(url);
        }

    }, [id, api, saveResult]);

    const template = useSelector(state => getTaskTemplate(state, id), shallowEqual);
    const tasks = useSelector(state => getRelationship(state, 'default_tasks', template), shallowEqual) || [];

    return {
        isLoading: responseStatus === 'loading',
        isFailed: responseStatus === 'failed',
        errorMessage: errorMessage,
        template: template,
        // Filter out any potential undefined tasks which can happen when the template
        // was loaded previously without the tasks.
        tasks: tasks.filter(t => !!t),
    }
}

export default useTaskTemplate;
