// @flow props

import React from 'react';
import ActivityFeed from './ActivityFeed';
import { getRelationship } from '../../selectors';
import { Activity } from '../../types';

type Props = {
    activities: Array<Activity>,
    jsonapi: Object,
}

const SubmissionActivity = ({ activities, jsonapi }: Props) => {
    if (activities.length === 0) {
        return <p>No submissions yet</p>
    }

    return (
        <ActivityFeed
            items={activities.map(submission => SubmissionActivity.render(submission, jsonapi))}
        />
    );
};

SubmissionActivity.render = (submission, jsonapi) => {
    let user = getRelationship(jsonapi, 'uid', submission);
    let team = getRelationship(jsonapi, 'field_team', submission);

    return {
        user: user,
        text: <span>Updated submission for {team.attributes.name}</span>,
        timestamp: submission.attributes.changed,
    }
}

export default SubmissionActivity;
