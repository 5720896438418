import React, { useCallback, useState } from 'react';
import { Radio, Spin } from 'antd';
import { mergeEntityAttributes } from '../../helpers';

const PublishAction = ({ entity, update, publishAttribute = 'is_published' }) => {
    const [isLoading, setIsLoading] = useState(false);

    const publish = useCallback(() => {
        const updatedEntity = mergeEntityAttributes(entity, {
            [publishAttribute]: true,
        })

        setIsLoading(true);
        update(updatedEntity)
            .then(result => setIsLoading(false));
    }, [entity, update, publishAttribute])

    const unpublish = useCallback(() => {
        const updatedEntity = mergeEntityAttributes(entity, {
            [publishAttribute]: false,
        })

        setIsLoading(true);
        update(updatedEntity)
            .then(result => setIsLoading(false));;
    }, [entity, update, publishAttribute])

    return (
        <Spin spinning={isLoading}>
            <Radio.Group value={!!entity.attributes[publishAttribute] ? '1' : '0'}>
                <Radio.Button
                    value="1"
                    onClick={publish}
                    className="publish"
                >Published</Radio.Button>
                <Radio.Button
                    value="0"
                    onClick={unpublish}
                >Draft</Radio.Button>
            </Radio.Group>
        </Spin>
    );
};

export default PublishAction;
