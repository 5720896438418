// @flow

import React, { Component } from 'react';
import { Select, Input, message } from '../components/Ant';
import { Form, Spin, Button, Row, Col, Popconfirm } from 'antd';
import { mergeEntityAttributes } from '../helpers';
import styled from 'styled-components';

const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

type Props = {
    template: Object,
    form: Object,
    save: Function,
    afterSave?: Function,
    delete: Function,
    afterDelete?: Function,
}

type State = {
    isLoading: boolean,
}

class EditTaskTemplateForm extends Component<Props, State> {

    constructor() {
        super();
        this.state = {
            isLoading: false,
        }
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    onDelete: Function
    onDelete(e) {
        this.setState({ isLoading: true })
        this.props.delete(this.props.template)
            .then(result => {
                message.success('Workflow deleted');
                if (this.props.afterDelete) {
                    this.props.afterDelete();
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ isLoading: false })
                message.warning('Failed to delete task workflow');
            });
    }

    onSave: Function
    onSave(e) {
        e.preventDefault();

        this.props.form.validateFields()
            .then(result => {
                let updatedTemplate = mergeEntityAttributes(this.props.template, {
                    label: result.label,
                    task_workflow_type: result.task_workflow_type,
                    description: result.task_template_description,
                });

                this.setState({ isLoading: true });
                this.props.save(updatedTemplate)
                    .then(saveResult => {
                        message.success(this.props.template.id ? 'Updated task workflow' : 'Created task workflow');
                        this.setState({ isLoading: false });

                        if (this.props.afterSave) {
                            this.props.afterSave(saveResult);
                        }
                    })
                    .catch(error => {
                        message.warning('Template could not be saved');
                        this.setState({ isLoading: false });
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
                message.warning('Please complete all fields');
            })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { template } = this.props;

        return (
            <Spin spinning={this.state.isLoading}>
                <Form onSubmit={this.onSave} className={template.id ? "existing-workflow-template-form" : "new-workflow-template-form"}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label="Title">
                                {getFieldDecorator('label', {
                                    rules: [{ required: true, message: 'Please input the template name.' }],
                                    initialValue: template.attributes.label,
                                })(
                                    <Input name="label" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="Workflow Type">
                                {getFieldDecorator('task_workflow_type', {
                                    rules: [{ required: true, message: 'Please select the task workflow type.' }],
                                    initialValue: template.attributes.task_workflow_type,
                                })(
                                    <Select>
                                        <Select.Option name="challenge" value="challenge">Challenge</Select.Option>
                                        <Select.Option name="competition" value="competition">Competition</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label="Description">
                        {getFieldDecorator('task_template_description', {
                            rules: [{ required: true, message: 'Please enter the task workflow description.' }],
                            initialValue: template.attributes.description,
                        })(
                            <Input name="description" />
                        )}
                    </Form.Item>

                    <ActionContainer>
                        <Button type="primary" htmlType="submit">{this.props.template.id ? "Save" : "Create"}</Button>
                        {this.props.template.id ?
                            <Popconfirm
                                title={"Are you sure you want to delete this template?"}
                                onConfirm={this.onDelete}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="ghost">Delete</Button>
                            </Popconfirm> :
                            null
                        }
                    </ActionContainer>
                </Form>
            </Spin>
        );
    }
}

export default Form.create({ name: 'edit_task_template' })(EditTaskTemplateForm)
