// @flow

import React from 'react';
import { StyledComponent } from 'styled-components';
import Task from '../Task/Task';
import { Task as TaskType, User } from '../../types';
import { Spacer } from '../Common';

type Props = {
    tasks: Array<TaskType>,
    owner: User,
}

const TaskViewList = ({ tasks, owner }: Props) => {
    return (
        tasks.map((task: TaskType) => {
            return (
                <Spacer size="small" key={task.id}>
                    <Task
                        task={task}
                        taskOwner={owner}
                        isFullView={true}
                    />
                </Spacer>
            )
        }): Array<StyledComponent>
    );
};

export default TaskViewList;
