import React, { useState } from 'react';
import FullWidthContentBlock from '../FullWidthContentBlock';
import { Row, Col } from 'antd';
import TaskList from '../Task/TaskList';
import TaskLibrary, { NewTaskDropTarget } from '../Task/TaskLibrary';
import { Spacer } from '../Common';
import { useDispatch } from 'react-redux';
import { addNewTaskToTaskWorkflow } from '../../actions';
import PhaseSidebar from './PhaseSidebar';
import { HasPermission } from '../../helpers';

const PhasePageLayout = ({ preview, phase, taskWorkflow, updateTaskWorkflow, refreshTaskWorkflow, updateWorkflowFromDraft, tasks, countOffset, isOpen, toggle, additionalTasks = null }) => {
    const dispatch = useDispatch();
    const [isAddingNewTask, setIsAddingNewTask] = useState(false);

    return (
        <>
            <FullWidthContentBlock>
                <Row gutter={16} justify="space-between" type="flex">
                    <Col md={15}>
                        {additionalTasks}

                        <TaskList
                            tasks={tasks}
                            activeTask={{}}
                            parentId={taskWorkflow.id}
                            refreshTaskWorkflow={refreshTaskWorkflow}
                            countOffset={countOffset}
                            isOpen={isOpen}
                            onToggle={toggle}
                        />

                        <HasPermission permission={['create task', 'administer task']}>
                            <Spacer>
                                <NewTaskDropTarget
                                    isLoading={isAddingNewTask}
                                    onDrop={(item, monitor) => {
                                        setIsAddingNewTask(true);
                                        let newTask = {
                                            type: 'task--task',
                                            attributes: {
                                                title: item.task.attributes.title,
                                                data: item.task.attributes.data,
                                                help: item.task.attributes.help,
                                                parent_type: taskWorkflow.type,
                                            }
                                        }
                                        dispatch(addNewTaskToTaskWorkflow(taskWorkflow, newTask))
                                            .then(result => setIsAddingNewTask(false));
                                    }}
                                />
                            </Spacer>

                            <Spacer size="small">
                                <h2>Task Library</h2>
                                <TaskLibrary
                                    taskTemplateId={taskWorkflow.relationships.task_template.data.id}
                                />
                            </Spacer>
                        </HasPermission>

                    </Col>
                    <Col
                        md={9}
                    >
                        <div
                            style={{
                                // display: 'flex',
                                // justifyContent: 'flex-end',
                                position: 'sticky',
                                top: 0,
                                maxWidth: '300px',
                                float: 'right',
                            }}
                        >
                            <PhaseSidebar
                                taskWorkflow={taskWorkflow}
                                preview={preview}
                                update={updateTaskWorkflow}
                            />
                        </div>
                    </Col>
                </Row>
            </FullWidthContentBlock>
        </>
    );
};

export default PhasePageLayout;
