// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRelationship, getUserPermissions } from '../../selectors';
import { Task as TaskType } from '../../types';
import TaskPreview from './TaskPreview';
import TaskFullView from '../TaskView/TaskFullView';
import { textTrim } from '../../helpers';
import { getCurrentUser } from '../../selectors';
import { updateTask } from '../../actions';
import getTaskComponent from '../../helpers/getTaskComponent';
import { hasPermission } from '../../helpers/HasPermission';
import { Spin, message } from 'antd';
import saveTaskToLocalStorage from './saveTaskToLocalStorage';
import { Button } from '../Ant';
import { Spacer } from '../Common';

type Props = {
    isPreview: boolean,
    isFullView: boolean,
    previewSize: string,
    task: TaskType,
    taskOwner: Object,
    onChange: Function,
    parentId: string,
    saveTask: Function,
    showComments: boolean,
    canAdministerTasks: boolean,
    isAutoSaveEnabled: boolean,
    onSave: Function,
}

type State = {
    isAutoSaving: bool,
}

const AutoSaveTaskIndicator = ({ isSaving }) => {
    if (isSaving) {
        return <div style={{ position: 'absolute', top: '0', right: '0' }}>
            <Spin size="small" /> <span style={{ marginLeft: '4px' }}>Saving</span>
        </div>;
    }
    return null;
}

const AUTOSAVE_INTERVAL = 3000;

class Task extends Component<Props, State> {
    autosaveInterval;

    static defaultProps = {
        isAutoSaveEnabled: false,
    }

    constructor() {
        super();
        this.state = {
            isAutoSaving: false,
        }
        this.onChange = this.onChange.bind(this);
        this.autosave = this.autosave.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange: Function
    onChange(...args) {
        this.props.onChange(...args);
        this.autosave(...args);
    }

    autosave: Function
    autosave(task) {
        clearInterval(this.autosaveInterval);
        this.autosaveInterval = setInterval(() => {
            this.setState({ isAutoSaving: true });
            clearInterval(this.autosaveInterval);

            saveTaskToLocalStorage(task);

            this.props.onSave(true)
                .then(result => this.setState({ isAutoSaving: false }))
                .catch(error => this.setState({ isAutoSaving: false }));
        }, AUTOSAVE_INTERVAL);
    }

    onSubmit() {
        let task = this.props.task;

        // Validate the task is not empty.
        if (task.attributes.data.is_required === '1') {
            let Component = getTaskComponent(task);
            if (Component.isEmpty(task)) {
                message.warn('Task is not complete');
                return;
            }
        }
        this.props.onSave(false);
    }

    submitRender() {
        if (typeof this.props.onSave === "function") { 
            return (
                <Spacer size="small" style={{ overflow: 'hidden' }}>
                    <Button
                        className="task-done"
                        onClick={this.onSubmit}
                        style={{ float: 'right' }}
                    >
                        Done
                    </Button>
                </Spacer>
            )
        }
    }

    render() {
        let task = this.props.task;
        let Component = getTaskComponent(task);

        if (!Component) {
            return <div style={{ padding: '20px' }}>
                Invalid Task Type {this.props.task.attributes.data.type}
            </div>;
        }

        if (task.attributes.is_archived) {
            return <p>This task has been archived</p>
        }

        if (this.props.isPreview) {
            let previewText = this.getTaskPreviewValue(Component, task);
            return <TaskPreview
                task={task}
                parentId={this.props.parentId}
                previewText={previewText ? textTrim(previewText, 300) : 'Please assign a value'}
                user={this.props.taskOwner}
                size={this.props.previewSize}
                saveTask={this.props.saveTask}
                isLinked={true}
            />
        }

        if (this.props.isFullView) {
            let fullText = this.getTaskFormattedValue(Component, task);

            return <TaskFullView
                task={task}
                taskOwner={this.props.taskOwner}
                fullText={fullText || 'N/A'}
            />
        }

        return (
            <div
                key={task.id}
                className={`task-input ${task.attributes.data.type}`}
                style={{ position: 'relative' }}
            >
                <AutoSaveTaskIndicator isSaving={this.state.isAutoSaving} />

                {task.attributes.help ?
                    <p style={{ color: "#737373", fontStyle: 'italic' }}>{task.attributes.help}</p>
                    : null
                }

                <Component
                    task={task}
                    taskOwner={this.props.taskOwner}
                    size={this.props.previewSize}
                    onChange={this.props.isAutoSaveEnabled ? this.onChange : this.props.onChange}
                />

                {this.submitRender()}
            </div>
        );
    }

    getTaskFormattedValue(Component, task) {
        let renderFormattedValue = typeof Component.getFormattedValue === 'function' ?
            Component.getFormattedValue
            : (taskToRender: TaskType) => taskToRender.attributes.data.value;
        return renderFormattedValue(task);
    }

    getTaskPreviewValue(Component, task) {
        let renderPreviewValue = typeof Component.getPreviewValue === 'function' ?
            Component.getPreviewValue
            : (taskToRender: TaskType) => taskToRender.attributes.data.value;
        return renderPreviewValue(task);
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        taskOwner: getRelationship(state, 'uid', ownProps.task),
        canAdministerTasks: hasPermission(getUserPermissions(state, getCurrentUser(state)), 'administer task'),
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        saveTask: (task, parentId) => dispatch(updateTask(task, parentId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Task);
