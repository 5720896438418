// @flow

import React, { Component } from 'react';
import { Icon, AutoComplete, Spin } from 'antd';
import { Button, Drawer, message } from '../Ant';
import Avatar from '../Avatar';
import styled from 'styled-components';
import SidebarLabel from '../Sidebar/SidebarLabel';
import UserList from '../UserList';
import withAllUsers from '../../hoc/withAllUsers';
import { trackEvent } from '../../helpers/trackEvent';
import SendUpdateButton from './SendUpdateButton';
import getTaskWorkflowBundle from '../../helpers/getTaskWorkflowBundle';
import { isUserCollaboratorOrHasPermission } from '../../helpers';
import withPermissions from '../../hoc/withPermissions';
import { TaskWorkflow, User } from '../../types'

const ManageUsersHeading = styled.h4`
    font-size: 14px;
    color: ${props => props.theme.primaryColor};
    margin-bottom: 12px;
`

const Spacer = styled.div`
    margin-top: 48px;
    margin-bottom: 48px;
`

type State = {
    visible: bool,
    collaborators: Array<Object>,
    userSearchValue: string,
    isLoading: bool,
    selectedUsers: Array<string>,
    searchedStrings: Array<string>,
}

type Props = {
    title: string,
    user: User,
    loadUsers: Function,
    onSave: Function,
    allUsers: Array<User>,
    collaborators: Array<User>,
    taskWorkflow: TaskWorkflow,
    toggleRender: Function,
    uiContext: string,
    permissions: Array<string>,
    currentUser: User,
}

class ManageChallengeCollaborators extends Component<Props, State> {

    static defaultProps = {
        toggleRender: (props: Props) => {
            if (props.user) {
                return (
                    <div>
                        <Avatar
                            user={props.user}
                        />
                        <span style={{ marginLeft: '10px' }}>Assigned</span>
                    </div>
                );
            }

            return (
                <div>
                    <Icon
                        className="icon-large"
                        type="user-add"
                    />
                    <span style={{ marginLeft: '10px' }}>Assign</span>
                </div>
            );
        }
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            visible: false,
            collaborators: props.collaborators || [],
            userSearchValue: '',
            isLoading: false,
            selectedUsers: [],
            searchedStrings: [],
        }
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onAutoCompleteChange = this.onAutoCompleteChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
        this.onRemoveCollaborator = this.onRemoveCollaborator.bind(this);
        this.applyMakeOwner = this.applyMakeOwner.bind(this);
        this.hasAccess = this.hasAccess.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    toggleDrawer: Function
    toggleDrawer() {
        if (!this.hasAccess()) {
            return;
        }

        this.setState({ visible: !this.state.visible })
    }

    hasAccess: Function
    hasAccess() {
        const { currentUser, taskWorkflow, permissions } = this.props;
        return isUserCollaboratorOrHasPermission(currentUser, taskWorkflow, permissions, 'administer users');
    }

    onClose: Function
    onClose() {
        // We reset the state because closing is like a "cancel" effect and we don't want
        // any changes to still be there next time they open the drawer.
        this.setState({
            visible: false,
            collaborators: this.props.collaborators,
            selectedUsers: [],
        });
    }

    onAutoCompleteChange: Function
    onAutoCompleteChange(selectedValue: string) {
        let selectedUser = this.props.allUsers.find(u => u.id === selectedValue);

        // If we didn't receive an ID matching a user then it's probably the user
        // filtering so we only update the user search value.
        if (!selectedUser) {
            this.setState({ userSearchValue: selectedValue })
            return;
        }

        // Otherwise, they have selected a user so now we update the collaborators.
        if (!this.state.collaborators.find(u => u.id === selectedUser.id)) {
            this.setState({
                collaborators: this.state.collaborators.concat(selectedUser),
                userSearchValue: '',
            })
        }
    }

    onSearchTimer: any
    onSearch: Function
    onSearch(searchString: string) {
        // Too short, just ignore it.
        if (searchString.length < 3) {
            return;
        }

        const DEBOUNCE_TIME = 400;
        clearTimeout(this.onSearchTimer);

        // Load some more users if we haven't already dont this search before.
        if (!this.state.searchedStrings.includes(searchString)) {
            // Debounce the searches.
            this.onSearchTimer = setTimeout(() => {
                this.props.loadUsers({
                    'filter[mail][condition][path]': 'field_full_name',
                    'filter[mail][condition][value]': searchString,
                    'filter[mail][condition][operator]': 'CONTAINS',
                });
                // Save it so we don't do the same query again.
                this.setState({ searchedStrings: this.state.searchedStrings.concat(searchString) })
            }, DEBOUNCE_TIME);
        }
    }

    onRemoveCollaborator: Function
    onRemoveCollaborator(id: string) {
        this.setState({
            collaborators: this.state.collaborators.filter(user => user.id !== id)
        })
    }

    onUserSelected: Function
    onUserSelected(isChecked: bool, userUuid: string) {
        if (isChecked) {
            this.setState({ selectedUsers: this.state.selectedUsers.concat(userUuid) })
        }
        else {
            this.setState({ selectedUsers: this.state.selectedUsers.filter(id => id !== userUuid) });
        }
    }

    applyMakeOwner: Function
    applyMakeOwner() {
        this.setState({ isLoading: true });
        let updatedTaskWorkflow = this.props.taskWorkflow;
        updatedTaskWorkflow.relationships.uid = {
            data: {
                id: this.state.selectedUsers[0],
                type: 'user--user',
            }
        };

        this.props.onSave(updatedTaskWorkflow)
            .then((response) => {
                this.setState({ isLoading: false });
            })
            .catch(error => {
                message.error(error);
                this.setState({ isLoading: false });
            })
    }

    onSave: Function
    onSave() {
        this.setState({ isLoading: true });
        let updatedTaskWorkflow = this.props.taskWorkflow;
        updatedTaskWorkflow.relationships.collaborators = {
            data: this.state.collaborators.map(collaborator => {
                return {
                    id: collaborator.id,
                    type: 'user--user',
                }
            })
        }

        // We must calculate the action that was taken before we save and the
        // props get updated.
        let event = this.getRelevantEvent(updatedTaskWorkflow);
        this.props.onSave(updatedTaskWorkflow)
            .then((response) => {
                this.saveEvent(event);
                this.setState({ visible: false, isLoading: false });
            })
            .catch(error => {
                message.error(error);
                this.setState({ isLoading: false });
            })
    }

    getRelevantEvent(updatedTaskWorkflow: TaskWorkflow) {
        if (this.state.collaborators.length > this.props.collaborators.length) {
            return [getTaskWorkflowBundle(updatedTaskWorkflow.type), 'Added new collaborator', this.props.uiContext]
        }
        else if (this.state.collaborators.length < this.props.collaborators.length) {
            return [getTaskWorkflowBundle(updatedTaskWorkflow.type), 'Removed collaborator', this.props.uiContext]
        }
    }

    saveEvent(event: Object) {
        if (event) {
            trackEvent(...event);
        }
    }

    getOwnerId: Function
    getOwnerId() {
        if (!this.props.taskWorkflow.relationships.uid) {
            return null;
        }

        return this.props.taskWorkflow.relationships.uid.data.id;
    }

    render() {
        let { title } = this.props;
        if (this.props.toggleRender(this.props, this.hasAccess()) === undefined ) {
            return null
        }
        return (
            <div>
                <div
                    style={{ display: 'flex' }}
                    className={this.hasAccess() ? "hoverable add-collaborator" : ""}
                    onClick={this.toggleDrawer}
                >
                    {this.props.toggleRender(this.props, this.hasAccess())}
                </div>

                <Drawer
                    onClose={this.onClose}
                    visible={this.state.visible}
                >
                    <ManageUsersHeading>Manage Users</ManageUsersHeading>
                    <h2>{title}</h2>

                    <SidebarLabel>Add User</SidebarLabel>

                    <AutoComplete
                        dataSource={
                            this.props.allUsers
                                .filter(u => typeof u.attributes.status === 'undefined' || u.attributes.status === true)
                                .map((u: any) => {
                                    return {
                                        value: u.id,
                                        text: u.attributes.display_name || 'N/A',
                                    }
                                })
                        }
                        style={{ width: '100%' }}
                        onChange={this.onAutoCompleteChange}
                        onSearch={this.onSearch}
                        value={this.state.userSearchValue}
                        filterOption={(inputValue, option) => {
                            return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        placeholder="Select a collaborator"
                        className="collaborators-select"
                    />

                    <Spacer>
                        <UserList
                            collaborators={this.state.collaborators}
                            ownerId={this.getOwnerId()}
                            taskWorkflow={this.props.taskWorkflow}
                            onUserSelected={this.onUserSelected}
                            onRemoveCollaborator={this.onRemoveCollaborator}
                        />
                    </Spacer>

                    <Spin spinning={this.state.isLoading}>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button
                                type="primary"
                                onClick={this.onSave}
                                className="save-collaborators"
                            >Save</Button>

                            <div>
                                <SendUpdateButton
                                    selectedUsers={this.props.allUsers.filter(u => this.state.selectedUsers.includes(u.id))}
                                    workflowId={this.props.taskWorkflow.id}
                                    style={{ marginRight: '4px' }}
                                />
                                <Button
                                    type="small"
                                    disabled={this.state.selectedUsers.length === 1 && this.state.selectedUsers[0] !== this.getOwnerId() ? '' : 'disabled'}
                                    onClick={this.applyMakeOwner}
                                >Make owner</Button>
                            </div>
                        </div>
                    </Spin>
                </Drawer>
            </div>
        )
    }
}

export default withPermissions(withAllUsers(ManageChallengeCollaborators));
export { ManageChallengeCollaborators as _ManageChallengeCollaborators }
