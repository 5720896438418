import React, { useCallback } from 'react';
import { get } from '../../helpers';
import usePlaybook from '../../api/usePlaybook';
import CollapsibleBox from '../Common/CollapsibleBox';
import CollapsibleBoxTaskListTitle from '../Task/CollapsibleBoxTaskListTitle';
import PlaybookEditableDescription from './PlaybookEditableDescription';

const DescriptionInput = ({ playbookId, isOpen, onToggle, delta, open }) => {
    const { playbook } = usePlaybook(playbookId);

    const isComplete = useCallback(() => {
        return !!get(playbook, 'attributes.description.value');
    }, [playbook])

    return (
        <CollapsibleBox
            isOpen={isOpen()}
            onToggle={onToggle}
            className="collapsible-task text task-input"
            title={<CollapsibleBoxTaskListTitle
                heading={'Challenge Overview'}
                isComplete={isComplete()}
                count={delta}
            />}
        >
            <PlaybookEditableDescription
                playbookId={playbookId}
                onAfterSave={onToggle}
            />
        </CollapsibleBox>
    );
};

export default DescriptionInput;
