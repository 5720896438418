// @flow

import React from 'react';
import CollaboratorsTeaser from '../CollaboratorsTeaser';
import { TaskWorkflow } from '../../types';
import styled from 'styled-components';
import Avatar from '../Avatar';
import Tile from '../Presentation/Tile';
import TaskWorkflowImage from './TaskWorkflowImage';
import usePlaybook from '../../api/usePlaybook';
import { textTrim } from '../../helpers';
import OrganisationLogo from '../Organisation/OrganisationLogo';
import AlignRight from '../Common/AlignRight';

const UserWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${props => props.cssSize === 'default' ? '-12px' : '-16px'};
    padding: 0 8px;
`

type Props = {
    taskWorkflow: TaskWorkflow,
}

const InReviewWrapper = styled.div`
    position: absolute;
    height: 30px;
    width: 100%;
    top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    background: #FFF;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1);
    color: ${props => props.theme.greyBlack};
`
const InReviewBadge = () => {
    return <InReviewWrapper>In Review</InReviewWrapper>
}

const TaskWorkflowTile = ({ taskWorkflow: workflow, size = 'default', logosize = 'small' }: Props) => {
    const { owner, organisation } = usePlaybook(workflow.attributes.playbook_id);
    return (
        <Tile
            pinned={workflow.isPinned}
            pinnedList={workflow.isPinnedList}
            size={size}
            image={<>
                <TaskWorkflowImage
                    taskWorkflow={workflow}
                    fallbackHeight={250}
                    fallbackWidth={355}
                />
                {workflow.attributes.workflow_status === 'ready_to_publish' ? <InReviewBadge /> : null}
           </>}
            title={textTrim(workflow.attributes.title, 50) || 'Describe the opportunity...'}
            above_content={<UserWrapper cssSize={size}>
                {owner ?
                    <Avatar
                        user={owner}
                        size={size === 'default' ? 'small' : 'default'}
                    />
                    : <Avatar user={{
                        attributes: { display_name: '' }
                    }} color={'#EEE'} size={size === 'default' ? 'small' : 'default'} />}

                <CollaboratorsTeaser
                    hideOwner={true}
                    playbookId={workflow.attributes.playbook_id}
                    size="small"
                />
            </UserWrapper>}
        >
                <AlignRight>
                    <OrganisationLogo
                        organisation={organisation}
                        logoSize="small"
                    />
                </AlignRight>
        </Tile>
    );
};

export default TaskWorkflowTile;
