import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { Tag } from 'antd';

const FlexCenter = styled.div`
    display: flex;
    align-items: center;

    > div {
        margin-right: 8px;
        &:last-child {
            margin-right: 0;
        }
    }
`

const AlgoliaCurrentRefinements = ({ items, refine, createUrl }) => {
    return (
        <FlexCenter>
            {items.map((item, i) => <div key={item.label}>
                <Tag closable onClose={() => refine(item.value)}>{item.label}</Tag>
            </div>)}
        </FlexCenter>
    );
};

export default connectCurrentRefinements(AlgoliaCurrentRefinements);
