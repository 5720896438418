// @flow

import React, { useState, useCallback } from 'react';
import { Table } from './Ant';
import qs from 'qs';
import useJsonApiGet from '../api/useJsonApiGet';
import { get } from '../helpers';

type Props = {
    url: string,
    query: Object,
    columns: Object,
    rowKey: string,
    pageSize?: number,
}

const JsonApiTable = ({ url, query, columns, rowKey, pageSize = 50, ...props }: Props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const getUrl = useCallback(() => {
        let queryString = qs.stringify({
            ...query,
            'page[limit]': pageSize,
        });

        return '/jsonapi/user/user?' + queryString;

    }, [query, pageSize]);

    const { response, requestLink } = useJsonApiGet(getUrl());
    const data = response.data.data || [];
    const included = get(response, 'data.included', []);

    // Make the included data available to every row.
    data.forEach(data => {
        data.included = included;
    })

    const links = response.data.links || {}
    const hasNextPage = links.next;

    const onChange = useCallback((page, pageSize) => {
        if (page > currentPage) {
            requestLink('next');
            setCurrentPage(currentPage => currentPage + 1);
        }
        else if (page < currentPage) {
            requestLink('prev');
            setCurrentPage(currentPage => currentPage - 1);
        }
    }, [currentPage, requestLink])

    return (
        <Table
            dataSource={data}
            loading={response.isLoading}
            rowKey={rowKey}
            columns={columns}
            pagination={{
                pageSize: pageSize,
                total: hasNextPage ? pageSize * currentPage + 1 : pageSize * currentPage,
                current: currentPage,
                onChange: onChange,
            }}
            {...props}
        />
    );
};

export default JsonApiTable;
