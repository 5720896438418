import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import { Pagination } from 'antd';

const AlgoliaPagination = connectPagination(({ currentRefinement, createURL, nbPages, refine, hitsPerPage, ...props }) => {
    return (
        <Pagination
            current={currentRefinement}
            onChange={(page, pageSize) => {
                refine(page);
                createURL(page);
                window.scrollTo(0, 0);
            }}
            total={nbPages * hitsPerPage}
            pageSize={hitsPerPage}
        />
    );
});

export default AlgoliaPagination;
