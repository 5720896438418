import { useCallback } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import useJsonApiGet from '../../api/useJsonApiGet';
import { saveJsonApiResponse } from '../../api';
import { getTaskWorkflows, getRelationship, getTaskTemplates } from '../../selectors';
import { IMAGE_LARGE_CONSUMER_ID } from '../../consumers';

const pageSize = 50;
const PlaybookList = ({ type, filters = {}, sort = {}, taskTemplates, doSaveJsonApi, children, includeExtra = '' }) => {
    let queryString = qs.stringify({
        include: [
            'image',
            'workflows',
            'workflows.uid',
            'workflows.tags',
            'workflows.collaborators',
            'workflows.task_template',
            'workflows.task_workflow_type',
            'workflows.uid.field_picture',
            'workflows.collaborators.field_picture',
            'organisation',
            'organisation.logo',
        ].join(',') + includeExtra,
        'consumerId': IMAGE_LARGE_CONSUMER_ID,
        'sort[sort-created][path]': 'workflows.created',
        'sort[sort-created][direction]': 'DESC',
        'page[limit]': pageSize,
        ...filters,
        ...sort,
    });

    const { response, jsonapi, requestLink, hasLink } = useJsonApiGet('/jsonapi/playbook/playbook?' + queryString, doSaveJsonApi);
    const playbooks = response.data.data || [];
    const workflows = response.isLoading ? [] : getTaskWorkflows(jsonapi, type)

    const getOwners = useCallback(() => {
        if (response.isLoading) {
            return [];
        }

        let owners = {};
        workflows.forEach(workflow => {
            owners[workflow.id] = getRelationship(jsonapi, 'uid', workflow);
        });
        return owners;
    }, [workflows, jsonapi, response])

    const getTags = useCallback(() => {
        if (response.isLoading) {
            return {};
        }
        let tags = {};
        workflows.forEach(workflow => {
            tags[workflow.id] = getRelationship(jsonapi, 'tags', workflow);
        });
        return tags;
    }, [workflows, jsonapi, response])

    const getOrganisations = useCallback(() => {
        if (response.isLoading) {
            return {};
        }

        const organisations = {};
        playbooks.forEach(playbook => {
            getRelationship(jsonapi, 'workflows', playbook)
                .forEach(workflow => {
                    let organisation = getRelationship(jsonapi, 'organisation', playbook);
                    if (organisation !== null) {
                        organisations[workflow.id] = organisation;
                    }
                })
        })
        return organisations;
    }, [jsonapi, response, playbooks])

    return children(response, workflows, getOwners(), taskTemplates, getOrganisations(), getTags(), {
        requestLink,
        hasLink,
        pageSize
    });
}

const mapStateToProps = (state) => {
    return {
        taskTemplates: getTaskTemplates(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        doSaveJsonApi: response => dispatch(saveJsonApiResponse(response))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaybookList);
