import { useEffect, useState, useContext } from 'react';
import getBaseUrl from '../Unearthed/getBaseUrl';
import { InnovatorApiContext } from '../../api/ApiContext';
import { extractErrorMessage } from '../../api';

const useCompetitionMetadata = (competitionId) => {
    const api = useContext(InnovatorApiContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [failedErrorMessage, setIsFailedErrorMessage] = useState('');
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        if (!competitionId) {
            return;
        }

        setIsLoading(true);
        const url = getBaseUrl() + '/api/competition/' + competitionId + '/metadata';
        api.get(url)
            .then(response => {
                if (response && response.data) {
                    setMetadata(response.data);
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setIsFailed(true);
                setIsFailedErrorMessage(extractErrorMessage(error));
                console.log('useCompetitionMetadata failed', error);
            });

        return () => {
            api.cancel(url);
        }
    }, [competitionId, api])

    return [isLoading, metadata, isFailed, failedErrorMessage,];
}

export default useCompetitionMetadata;
