// @flow

import React, { useState, useCallback } from 'react';
import { Button, Modal } from '../Ant';
import EditHelpItemForm from '../../Forms/EditHelpItemForm';

type Props = {
    onAfterSave: Function,
    helpItem?: Object,
    buttonRender?: Function,
}

const CreateNewHelpItem = ({ onAfterSave, helpItem = {
    attributes: {
        title: '',
        is_published: 1,
    }
}, buttonRender = null }: Props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const onClick = useCallback(() => {
        setIsModalVisible(!isModalVisible)
    }, [isModalVisible]);

    return (
        <div>
            {buttonRender ? buttonRender(onClick) : <Button type="primary" onClick={onClick}>Create help</Button>}

            <Modal
                title={helpItem.id ? "Edit help" : "Create help"}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <EditHelpItemForm
                    helpItem={helpItem}
                    onAfterSave={() => {
                        onAfterSave();
                        setIsModalVisible(false)
                    }}
                />
            </Modal>
        </div>
    );
};

export default CreateNewHelpItem;
