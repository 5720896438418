import React from 'react';
import CheckIcon from '../CheckIcon';
import styled from 'styled-components';

const TitleWrapper = styled.div`
    display: flex;
`

const CollapsibleBoxTaskListTitle = ({ isComplete, heading, count }) => {
    return (
        <TitleWrapper>
            <CheckIcon
                color={isComplete ? '#26e82f' : '#BBB'}
                iconOverride={isComplete ? null : <span style={{ fontSize: '11px' }}>{count}</span>}
            />
            <h3 style={{ marginLeft: '8px' }}>{heading}</h3>
        </TitleWrapper>
    );
}

export default CollapsibleBoxTaskListTitle;
