import React from 'react';
import BasicPage from '../layouts/BasicPage';
import setPageTitle from '../hoc/setPageTitle';
import SetNewPasswordForm from '../Forms/SetNewPasswordForm';
import qs from 'qs';

const SetNewPassword = () => {
    const query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
    });

    return (
        <BasicPage>
            <h2 style={{ marginBottom: '16px' }}>{query.title ? query.title : 'Set your new password'}</h2>
            <p style={{ textAlign: 'center' }}>Set your secure password below</p>
            <SetNewPasswordForm />
        </BasicPage>
    );
};

export default setPageTitle(SetNewPassword, 'Set new password');
