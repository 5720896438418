import React from 'react';
import SubmissionList from '../components/Competition/SubmissionList';
import { withRouter } from 'react-router-dom';
import { Box, Spacer } from '../components/Common';
import { TwoCol7525 } from '../layouts';
import { useSetPageTitle } from '../hoc/setPageTitle';
import PlaybookNav from '../components/Playbook/PlaybookNav';
import usePlaybook from '../api/usePlaybook';
import CompetitionRunTabs from '../components/Competition/CompetitionRunTabs';
import PlaybookTitle from '../components/Playbook/PlaybookTitle';
import { Spin } from 'antd';

const CompetitionSubmissions = ({ match }) => {
    const playbookId = match.params.id;
    const { isLoading, workflows } = usePlaybook(playbookId);
    const competition = workflows.find(w => w.type === 'task_workflow--competition');
    useSetPageTitle(isLoading ? 'Loading...' : 'Submissions');

    return (
        <Spin spinning={isLoading}>
            {competition ?
                <PlaybookNav
                    playbookId={playbookId}
                    title={<PlaybookTitle playbookId={playbookId} edit />}
                    mode={competition.attributes.workflow_status === 'complete' ? 'results' : 'live'}
                    tabs={<CompetitionRunTabs
                        competition={competition}
                        activeTab={"submissions"}
                        playbookId={playbookId}
                        workflowStatus={competition.attributes.workflow_status}
                    />}
                />
                : null}

            <Spacer>
                <TwoCol7525
                    isLoading={isLoading}
                    left={isLoading ? null : <Box>
                        <SubmissionList
                            competition={competition}
                        >{(submissions, submissionList, isLoading) => {
                            return (
                                <>
                                    {isLoading ? null : <h2>{submissions.length} Submissions</h2>}
                                    {submissionList}
                                </>
                            );
                        }}
                        </SubmissionList>
                    </Box>}
                />
            </Spacer>
        </Spin>
    );
};

export default withRouter(CompetitionSubmissions);
