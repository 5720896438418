// @flow

import React from 'react';
import type { Node } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Task } from '../../types';

type Props = {
    task: Task,
    children: Node,
    phase: string,
    playbookId: string,
    style?: Object,
}

const TaskLink = ({ task, phase, children, playbookId, style = {} }: Props) => {
    return (
        <Link to={`/challenge/${playbookId}/${phase}#${task.id}`} style={style}>
            {children}
        </Link>
    )
}

export default TaskLink;
