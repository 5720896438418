import React, { useState, useCallback } from 'react';
import { Button, message, Form, Input, Checkbox, Spin } from 'antd';
import { extractErrorMessage } from '../api';
import FormActions from '../components/Form/FormActions';
import { mergeEntityAttributes } from '../helpers';
import { updateHelpItem, createHelpItem } from '../api/helpItems';

const EditHelpItemForm = ({ form, helpItem, onAfterSave }) => {
    const [isLoading, setIsLoading] = useState(false);

    const errorHandler = useCallback(error => {
        setIsLoading(false);
        console.log(error);
        message.error(extractErrorMessage(error));
    }, []);

    const successHandler = useCallback((text, result) => {
        setIsLoading(false);
        message.success(text);
        form.resetFields();

        if (typeof onAfterSave === 'function') {
            onAfterSave(result.data.data);
        }
    }, [onAfterSave, form])

    const onSave = useCallback(e => {
        e.preventDefault();

        form.validateFields()
            .then(result => {
                setIsLoading(true);

                if (helpItem.id) {
                    let updatedHelpItem = mergeEntityAttributes(helpItem, {
                        title: result.title,
                        description: result.description,
                        url: result.url,
                        is_published: result.is_published,
                    });

                    updateHelpItem(updatedHelpItem)
                        .then(response => successHandler('Help item updated', response))
                        .catch(errorHandler);
                }
                else {
                    createHelpItem(result.title, result.description, result.url, result.is_published)
                        .then(response => successHandler('Help item created', response))
                        .catch(errorHandler);
                }
            })

    }, [helpItem, form, errorHandler, successHandler]);

    return (
        <Spin spinning={isLoading}>
            <Form onSubmit={onSave}>
                <Form.Item label="Title">
                    {form.getFieldDecorator('title', {
                        rules: [{ required: true, message: 'Please input the title.' }],
                        initialValue: helpItem.attributes.title,
                    })(
                        <Input name="title" autoFocus />
                    )}
                </Form.Item>

                <Form.Item label="Description">
                    {form.getFieldDecorator('description', {
                        rules: [
                            { required: true, message: 'Please input the description.' },
                            { max: 100, message: 'Maximum 100 characters' },
                        ],
                        initialValue: helpItem.attributes.description,
                    })(
                        <Input name="description" />
                    )}
                </Form.Item>

                <Form.Item label="URL" extra="The URL is optional.">
                    {form.getFieldDecorator('url', {
                        rules: [
                            { type: 'url', required: false, message: 'Must be a valid URL such as http://www.google.com' },
                        ],
                        initialValue: helpItem.attributes.url,
                    })(
                        <Input name="url" />
                    )}
                </Form.Item>

                <Form.Item>
                    {form.getFieldDecorator('is_published', {
                        initialValue: !!helpItem.attributes.is_published,
                        valuePropName: 'checked'
                    })(
                        <Checkbox
                            key="1"
                            name="is_published"
                        >Is Published</Checkbox>
                    )}
                </Form.Item>

                <FormActions>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >{helpItem.id ? "Save" : "Create"}</Button>
                </FormActions>
            </Form>
        </Spin>
    );
};

export default Form.create({ name: 'edit_help_item' })(EditHelpItemForm);
