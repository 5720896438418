// @flow

import React from 'react';
import { Icon, Tooltip } from 'antd';
import styled from 'styled-components';

const CountWrapper = styled.div`
    display: flex;
`

type Props = {
    count: number,
}

const CommentCount = ({ count, ...props }: Props) => {
    if (!count) {
        return <span></span>;
    }

    return (
        <CountWrapper {...props}>
            <Tooltip title={`${count} Comment${count !== 1 ? 's' : ''}`}>
                <Icon type="message" />
            </Tooltip>
        </CountWrapper>
    );
}

export default CommentCount;
