import React, { useContext } from 'react';
import EmptyTileDropTarget from './EmptyTileDropTarget';
import LaunchCompetitionPublishModal from '../Competition/LaunchCompetitionPublishModal';
import { useCallback } from 'react';
import { ApiContext } from '../../api/ApiContext';
import { HasPermission } from '../../helpers';
import { canGoLive } from '../Phase/BuildActions';
import styled from 'styled-components';
import { message } from 'antd';

const InvalidWrapper = styled.div`
    background: #fdc5c5;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const EmptyTileDropTargetLiveColumn = () => {
    const api = useContext(ApiContext);

    const update = useCallback(workflow => {
        return api.patch(workflow.links.self.href, { data: workflow });
    }, [api]);

    return (
        <HasPermission
            permission={'publish competition'}
        >
            <LaunchCompetitionPublishModal
                update={update}
            >
                {showModal => (
                    <EmptyTileDropTarget
                        id={'live'}
                        onDrop={(item, monitor) => {
                            if (!canGoLive(item.workflow)) {
                                message.warn('Unable to Go Live until all tasks are complete');
                                return;
                            }
                            // Pass the challenge we're creating a competition for.
                            showModal(item.workflow);
                        }}
                        isValid={(item) => canGoLive(item.workflow)}
                        acceptType={`KanbanTile-build`}
                        handleCanDrop={(item, monitor) => item.column === 'build'}// && canGoLive(item.workflow)}
                        invalidDrop={<InvalidWrapper>
                            <p>Cannot go live until all tasks are complete.</p>
                        </InvalidWrapper>}
                    />
                )}
            </LaunchCompetitionPublishModal>
        </HasPermission>
    );
};

export default EmptyTileDropTargetLiveColumn;