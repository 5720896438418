import React, { useCallback } from 'react';
import Task from './Task';
import CollapsibleBox from '../Common/CollapsibleBox';
import { Spacer } from '../Common';
import { useDispatch, useSelector } from 'react-redux';
import { updateTask, updateDraftTask } from '../../actions';
import { getDraftTasksData } from '../../selectors';
import { Spin, Menu } from 'antd';
import { useState } from 'react';
import CollapsibleBoxTaskListTitle from './CollapsibleBoxTaskListTitle';
import EllipsisMenu from '../Common/EllipsisMenu';
import ShareToClipboard from '../ShareToClipboard';
import ArchiveTask from './ArchiveTask';
import TaskCommentDrawer from './TaskCommentDrawer';
import { tokenise, get } from '../../helpers';
import AssignUserToTask from './AssignUserToTask';
import CommentCount from '../Comments/CommentCount';
import TaskOwnerAvatar from './TaskOwnerAvatar';

const TaskList = ({ tasks, activeTask, parentId, refreshTaskWorkflow, isOpen, onToggle, countOffset = 1 }) => {
    const dispatch = useDispatch();
    const [isLoadingMap, updateIsLoadingMap] = useState({});
    const draftTaskData = useSelector(state => getDraftTasksData(state, parentId));

    const setIsLoading = useCallback((taskId, value) => {
        let newIsLoadingMap = { ...isLoadingMap };
        newIsLoadingMap[taskId] = value;
        updateIsLoadingMap(newIsLoadingMap);
    }, [isLoadingMap])

    const isLoading = useCallback(taskId => {
        return !!isLoadingMap[taskId];
    }, [isLoadingMap]);

    const handleUpdateDraftTask = useCallback(task => {
        dispatch(updateDraftTask(parentId, task.id, task.attributes.data));

        // Draft tasks need to be fed back into the task components somehow?
    }, [parentId, dispatch]);

    const getTaskWithDraft = useCallback((task) => {
        let draftTask = draftTaskData.find(draft => draft.id === task.id);
        let updatedTask = { ...task }

        if (draftTask) {
            updatedTask.attributes.data = draftTask.data;
        }
        return updatedTask;
    }, [draftTaskData])

    const buildMenu = useCallback((task) => {
        // Gets link to be copied to the clipboard
        function getShareLink() {
            let href = window.location.href;
            const str = href.split('#');
            // changes hash to task
            str[1] = '#' + task.id
            // joins the hash with the href
            // uses slice to ensure no trailing hashs
            const newHref = str.slice(0, 2).join('')
            return newHref
        }

        return (
            <Menu onClick={e => e.domEvent.stopPropagation()}>
                <Menu.Item key="share">
                    <ShareToClipboard text={getShareLink()}>
                        <span>share</span>
                    </ShareToClipboard>
                </Menu.Item>
                <Menu.Item key="assign">
                    <AssignUserToTask
                        task={task}
                        onSave={(updatedTask) => {
                            dispatch(updateTask(updatedTask, parentId))
                        }}
                    />
                </Menu.Item>
                <Menu.Item key="comments">
                    <TaskCommentDrawer task={task} />
                </Menu.Item>
                <Menu.Item key="archive">
                    <ArchiveTask
                        task={task}
                        afterSave={refreshTaskWorkflow}
                    >{(isArchived) => isArchived ? 'unarchive' : 'archive'}</ArchiveTask>
                </Menu.Item>
            </Menu>
        );
    }, [refreshTaskWorkflow, dispatch, parentId])

    return (
        <div>
            {tasks.map((t, delta) => (
                <Spacer
                    size="small"
                    key={t.id}
                >
                    <CollapsibleBox
                        id={t.id}
                        isOpen={isOpen(t.id)}
                        onToggle={() => onToggle(t.id)}
                        style={{ paddingBottom: '16px' }}
                        className={`collapsible-task ${tokenise(t.attributes.data.type)}`}
                        title={<CollapsibleBoxTaskListTitle
                            isComplete={t.attributes.is_complete}
                            heading={t.attributes.title}
                            count={delta + countOffset + 1}
                        />}
                        extraActions={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: '8px' }}>
                                    <TaskOwnerAvatar task={t} />
                                </div>

                                {get(t, 'attributes.comments.comment_count') ?
                                    <div style={{ marginRight: '8px' }}>
                                        <TaskCommentDrawer task={t}>
                                            {(open => <div onClick={open} className="hoverable">
                                                <CommentCount
                                                    count={get(t, 'attributes.comments.comment_count')}
                                                />
                                            </div>)}
                                        </TaskCommentDrawer>

                                    </div> : null}

                                <EllipsisMenu
                                    menu={buildMenu(t)}
                                />
                            </div>
                        }
                    >
                        <Spin spinning={isLoading(t.id)}>
                            <Task
                                task={getTaskWithDraft(t)}
                                isPreview={false}
                                previewSize={"small"}
                                parentId={parentId}
                                onChange={handleUpdateDraftTask}
                                // @TODO, look at enabling this?
                                // isAutoSaveEnabled={true}
                                onSave={(isAutoSave) => {
                                    let updatedTask = getTaskWithDraft(t);

                                    if (!isAutoSave) {
                                        updatedTask.attributes.is_complete = true;
                                        setIsLoading(t.id, true);
                                    }

                                    return dispatch(updateTask(updatedTask, parentId))
                                        .then(result => {
                                            onToggle(t.id);
                                            setIsLoading(t.id, false);
                                            return result;
                                        });
                                }}
                            />
                        </Spin>
                    </CollapsibleBox>
                </Spacer>
            ))}
        </div>
    )
}

export default TaskList;
