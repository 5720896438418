// @flow

import React from 'react';
import { Select } from '../Ant';
import { mergeTaskData } from '../../helpers';
import { Task } from '../../types';

type Props = {
    task: Task,
    onChange: Function,
}

const SelectTask = ({ task, onChange }: Props) => {

    const handleOnChange = selectedValue => {
        onChange(mergeTaskData(task, { value: selectedValue }));
    }

    let selectWidth = 100;
    if (Array.isArray(task.attributes.data.options)) {
        selectWidth = (task.attributes.data.options.reduce((accumulator, currentValue) => accumulator > currentValue ? accumulator : currentValue).length * 7);
    }

    return (
        <Select
            onChange={handleOnChange}
            value={typeof task.attributes.data.value === 'undefined' || task.attributes.data.value.length === 0 ? "" : task.attributes.data.value}
            // Zane made me do it.
            style={{ width: (selectWidth + 100) + 'px'}}
        >
            <Select.Option value="">- Select -</Select.Option>
            {Array.isArray(task.attributes.data.options) ? task.attributes.data.options.map((option, i) => <Select.Option key={i} value={option}>{option}</Select.Option>) : null}
        </Select>
    )
}

SelectTask.getFormattedValue = task => {
    if (typeof task.attributes.data.value !== 'string' || task.attributes.data.value.length === 0) {
        return 'None selected';
    }
    return task.attributes.data.value;
}

SelectTask.getPreviewValue = task => {
    return SelectTask.getFormattedValue(task);
}

SelectTask.isEmpty = task => task.attributes.data.value.length === 0;

SelectTask.displayName = 'Selectbox';

export default SelectTask;
