import React from 'react';
import Task from './Task';
import { Row, Col } from 'antd';
import { Button, message } from '../Ant';

const EditTaskInline = ({ taskWorkflow, activeTaskId, tasks, owner, collaborators, currentUser }) => {
    return (
        <Row gutter={40}>
            <h1 style={{ textAlign: 'center', marginBottom: '32px' }}>Complete Task</h1>
            <Col md={18}>
                <div style={{ marginTop: '32px' }}>
                    <Task
                        key={activeTaskId}
                        isPreview={false}
                        task={tasks.find(task => task.id === activeTaskId)}
                        onChange={e => console.log('changed', e)}
                    />
                    <div style={{ marginTop: '8px', textAlign: 'right' }}>
                        <Button type="primary" key="create" onClick={() => message.success('Save not yet implemented')}>Save</Button>
                    </div>
                </div>
            </Col>

            <Col md={6}>
              ...
            </Col>
        </Row>
    );
};

export default EditTaskInline;
