// @flow

import React from 'react';
import NumberTask from './NumberTask';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
});

type Props = {

}

const MoneyTask = (props: Props) => <div style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ marginRight: '4px' }}>$</span> <NumberTask {...props} placeholder={"10000"} />
</div>

MoneyTask.getFormattedValue = task => {
    return MoneyTask.getPreviewValue(task);
}

MoneyTask.getPreviewValue = task => {
    if (task.attributes.data.value === undefined) {
        return formatter.format(0);
    }
    return formatter.format(task.attributes.data.value);
}

MoneyTask.getSampleTask = () => {
    return {
        attributes: {
            data: {
                title: 'Sample Task',
                value: 250000,
            }
        }
    }
}

MoneyTask.isEmpty = task => task.attributes.data.value.length === 0;

MoneyTask.displayName = 'Money';

export default MoneyTask;
