import React from 'react';
import Table from './Table';
import styled from 'styled-components';

const StyledTable = styled(Table)`
    .odd {
        background: #F7F7F7;
    }
`

const StripedTable = ({ ...props }) => {
    return (
        <StyledTable
            {...props}
            rowClassName={(record, index) => index % 2 === 0 ? 'odd' : 'even'}
        />
    );
};

export default StripedTable;
