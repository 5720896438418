import React, { useState, useCallback } from 'react';
import { Icon } from 'antd';
import AttachmentList from './File/AttachmentList';
import ThumbnailCroppedImage from './Common/ThumbnailCroppedImage';
import UploadDropArea from './UploadDropArea';
import styled from 'styled-components';

const PreviewWrapper = styled.div`

`

type Props = {
    onDrop: Function,
    isUploadInProgress: bool,
    uploadProgress: number,
    filesToPreview?: Array<Object>,
    previewStyle?: Object,
    preview?: string,
}

const DelayedUploadDropArea = ({ onDrop, isUploadInProgress, uploadProgress, filesToPreview = [], previewStyle = {}, preview = 'image', ...props }: Props) => {
    const [files, setFiles] = useState([]);

    const handleUpload = useCallback((droppedFiles) => {
        const allFiles = files.concat(droppedFiles);
        // We ignore uploads for new entities and handle them after creation.
        setFiles(allFiles)
        onDrop(allFiles);
    }, [onDrop, files]);

    return (
        <>
            <UploadDropArea
                handleUpload={handleUpload}
                uploadInProgress={isUploadInProgress}
                uploadProgress={uploadProgress}
                {...props}
            />

            {preview === 'image' ?
                <PreviewWrapper style={previewStyle}>
                    {filesToPreview.map(file => {
                        return <ThumbnailCroppedImage
                            key={file.name}
                            src={URL.createObjectURL(file)}
                            alt="preview"
                            style={{ maxWidth: '100%', border: '1px solid #EEE' }}
                        />
                    })}
                </PreviewWrapper>

                : null}

            {filesToPreview.length && preview === 'list' ?
                <PreviewWrapper style={previewStyle}>
                    <AttachmentList
                        style={previewStyle}
                        attachments={filesToPreview.map(file => {
                            // Files are in the format that comes from the browser and
                            // we map them to something generic for AttachmentList.
                            return {
                                fileName: file.name,
                                size: file.size,
                                fileUrl: URL.createObjectURL(file),
                                date: file.lastModified,
                            }
                        })}
                        isLoading={false}
                        getItemActions={item => [
                            <Icon
                                type="close-circle"
                                className="hoverable"
                                onClick={() => {
                                    const newFileList = filesToPreview.filter(a => a.name !== item.fileName);
                                    setFiles(newFileList);
                                    onDrop(newFileList);
                                }}
                            />
                        ]}
                    />
                </PreviewWrapper>
                : null}
        </>
    );
}

export default DelayedUploadDropArea;
