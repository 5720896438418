// @flow

import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Form, Icon, Button } from 'antd';
import { Input, message } from '../components/Ant';
import FormActions from '../components/Form/FormActions';
import { http } from '../actions';
import { extractErrorMessage } from '../api';

type Props = {
    form: Object,
    isLoggedIn: bool,
}

const PasswordResetForm = ({ form, isLoggedIn }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = useCallback(e => {
        e.preventDefault();

        form.validateFields()
            .then(result => {
                setIsLoading(true);

                http()
                    .post('/user/password-reset?_format=json', {
                        mail: result.email,
                    })
                    .then(response => {
                        setIsLoading(false);
                        message.success('Please check your email for a password reset link');
                    })
                    .catch(error => {
                        setIsLoading(false);
                        message.warning(extractErrorMessage(error));
                    })
            })
    }, [form])

    if (isLoggedIn) {
        return <p>You are already logged in, please <a href="/">click here</a>.</p>
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Item>
                {form.getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please enter your email address.' }],
                    initialValue: '',
                })(
                    <Input
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="email"
                        placeholder="Email"
                        autoComplete="username"
                    />
                )}
            </Form.Item>

            <FormActions>
                <Link to="/user/login">Login</Link>
                <Button
                    type="primary"
                    loading={isLoading}
                    htmlType="submit"
                >Reset Password</Button>
            </FormActions>
        </Form>
    );
};

export default Form.create({ name: 'password_reset' })(PasswordResetForm);
