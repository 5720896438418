import React from 'react';
import useJsonApiGet from '../../api/useJsonApiGet';
import { Spin, Tag } from 'antd';
import { Box } from '../../components/Common';
import MetricTypeEditModal from '../../components/Modals/MetricTypeEditModal';
import CreateNewMetricType from '../../components/Modals/CreateNewMetricType';
import setPageTitle from '../../hoc/setPageTitle';
import ContentWrapper from '../../layouts/ContentWrapper';

const MetricType = ({ metricType, refreshMetricTypes }) => {
    return (
        <MetricTypeEditModal
            metricType={metricType}
            onAfterSave={refreshMetricTypes}
        >
            <Tag>{metricType.attributes.name} ({metricType.attributes.unit})</Tag>
        </MetricTypeEditModal>
    );
};

const ManageMetrics = () => {
    const { response, refresh } = useJsonApiGet('/jsonapi/metric_type/metric_type?sort[name][path]=name&sort[name][direction]=ASC')
    const metricsTypes = response.data.data || [];

    return (
        <Spin spinning={response.isLoading}>
            <ContentWrapper>
                <h1>Manage Metrics</h1>
                <Box>
                    <div style={{ textAlign: 'right', marginBottom: '16px' }}>
                        <CreateNewMetricType
                            onAfterSave={refresh}
                        />
                    </div>
                    {metricsTypes.length === 0 && !response.isLoading ? <p>There are no metrics</p> : null}
                    <div className="manage-metrics" style={{ display: 'flex' }}>
                        {metricsTypes.map(metricType => <MetricType
                            key={metricType.id}
                            metricType={metricType}
                            refreshMetricTypes={refresh}
                        />)}
                    </div>
                </Box>
            </ContentWrapper>
        </Spin>
    );
};

export default setPageTitle(ManageMetrics, 'Manage Metrics');
