export default function (filters) {
    let filterString = '';

    filters.forEach((filter, j) => {
        filterString += ` AND `;
        if (Array.isArray(filter.value)) {
            filter.value.forEach((v, i) => {
                if (i === 0) {
                    filterString += '(';
                }
                if (i === filter.value.length - 1) {
                    filterString += `${filter.name}:${v})`
                }
                else {
                    filterString += `${filter.name}:${v} OR `
                }
            })
        }
        else {
            filterString += `${filter.name}:${filter.value}`;
        }
    })

    return `${filterString}`.trim();
}
