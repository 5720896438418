// @flow

import JsonApiRequestBuilder from "../helpers/JsonApiRequestBuilder";
import { jsonApi, withToken } from "../actions";
import { getTaskWorkflowBundle } from "../helpers";
import { TaskWorkflow } from '../types';

export function createMetric(metricValue: number, taskWorkflow: TaskWorkflow, metricTypeId: string) {
    let request = new JsonApiRequestBuilder('metric--metric');
    request
        .attribute('value', metricValue)
        .relationship('metric_type', 'metric_type--metric_type', metricTypeId)
        .relationship('workflow', 'task_workflow--' + getTaskWorkflowBundle(taskWorkflow.type), taskWorkflow.id);

    return withToken(token => {
        return jsonApi()
            .post('/jsonapi/metric/metric', request.build(), {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function updateMetric(updatedMetric: Object) {
    return withToken(token => {
        return jsonApi()
            .patch(`${updatedMetric.links.self.href}`, { data: updatedMetric }, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function deleteMetric(metric: Object) {
    return withToken(token => {
        return jsonApi()
            .delete(`${metric.links.self.href}`, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function createMetricType(metricTypeName: string, unit: string) {
    let request = new JsonApiRequestBuilder('metric_type--metric_type');
    request
        .attribute('name', metricTypeName)
        .attribute('unit', unit);

    return withToken(token => {
        return jsonApi()
            .post('/jsonapi/metric_type/metric_type', request.build(), {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function updateMetricType(updatedMetricType: Object) {
    return withToken(token => {
        return jsonApi()
            .patch(`${updatedMetricType.links.self.href}`, { data: updatedMetricType }, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}

export function deleteMetricType(metricType: Object) {
    return withToken(token => {
        return jsonApi()
            .delete(`${metricType.links.self.href}`, {
                headers: {
                    'X-CSRF-Token': token,
                }
            })
    })
}
