import React from 'react';
import { Box } from './Common';
import { Row, Col } from 'antd';
import useJsonApiGet from '../api/useJsonApiGet';
import qs from 'qs';
import md5 from 'md5';

const WelcomeToCrowd = ({ children }) => {
    const query = qs.stringify({
        'filter[placement]': 'homepage',
        'filter[published]': '1',
        'sort[weight][path]': 'weight',
        'sort[weight][direction]': 'ASC',
        'page[limit]': 3,
    })
    const { response } = useJsonApiGet(`/jsonapi/content_block/content_block?${query}`);
    const blocks = response.data.data || [];
    let contentHash = '';

    const element = <Box leftBorder>
        <Row gutter={16}>
            {blocks.map(block => {
                contentHash += md5(block.attributes.title + block.attributes.content.value) + '-';
                return <Col key={block.id} lg={8}>
                    <h3>{block.attributes.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: block.attributes.content.value }} />
                </Col>
            })}
        </Row>
    </Box>

    if (typeof children === 'function') {
        return children(element, contentHash);
    }
    return element;
};

export default WelcomeToCrowd;