// @flow

import React, { Component } from 'react';
import Avatar from '../Avatar';
import { AutoComplete, Divider, Button, Input } from 'antd';
import { Drawer, message, TextArea, } from '../Ant';
import styled from 'styled-components';
import SidebarLabel from '../Sidebar/SidebarLabel';
import withAllUsers from '../../hoc/withAllUsers';
import { User } from '../../types';
import Spacer from '../Common/Spacer';
import { createNotification, extractErrorMessage } from '../../api';
import withCurrentUser from '../../hoc/withCurrentUser';
import UserRegisterFormSimple from '../../Forms/UserRegisterFormSimple';
import { jsonApi } from '../../actions';
import { get } from '../../helpers';

const ManageUsersHeading = styled.h4`
    font-size: 14px;
    color: ${props => props.theme.primaryColor};
    margin-bottom: 12px;
`

type State = {
    visible: bool,
    userSearchValue: string,
    isLoading: bool,
    taskOwnerId: string,
    message: string,
    isAddUserOpen: bool,
    searchedStrings: Array<string>,
    currentTaskOwner: Object,
}

type Props = {
    currentUser: User,
    allUsers: Array<Object>,
    task: Object,
    onSave: Function,
    loadUsers: Function,
}

class AssignUserToTask extends Component<Props, State> {

    static defaultProps = {
        message: '',
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            visible: false,
            isLoading: false,
            userSearchValue: '',
            taskOwnerId: '',
            message: '',
            isAddUserOpen: false,
            searchedStrings: [],
            currentTaskOwner: null,
        }
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.onAutoCompleteChange = this.onAutoCompleteChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.getMessage = this.getMessage.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
        this.shouldShowMessage = this.shouldShowMessage.bind(this);
        this.shouldShowAddUserButton = this.shouldShowAddUserButton.bind(this);
        this.renderAutoCompleteOptions = this.renderAutoCompleteOptions.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.getCurrentTaskOwnerId = this.getCurrentTaskOwnerId.bind(this);
        this.getCurrentTaskOwner = this.getCurrentTaskOwner.bind(this);
        this.loadTaskOwner = this.loadTaskOwner.bind(this);
    }

    componentDidMount() {
        this.loadTaskOwner();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.task !== prevProps.task) {
            this.loadTaskOwner();
        }
    }

    loadTaskOwner: Function
    loadTaskOwner() {
        let uid = this.getCurrentTaskOwnerId();
        this.setState({ currentTaskOwner: null });

        if (uid) {
            this.setState({ isLoading: true });
            jsonApi()
                .get(`/jsonapi/user/user/${uid}`)
                .then(result => {
                    this.setState({
                        isLoading: false,
                        currentTaskOwner: result.data.data,
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    console.log(error);
                })
        }
    }

    getCurrentTaskOwnerId: Function
    getCurrentTaskOwnerId() {
        return get(this.props.task, 'relationships.uid.data.id', null);
    }

    getCurrentTaskOwner: Function
    getCurrentTaskOwner() {
        return this.state.currentTaskOwner;
    }

    toggleDrawer: Function
    toggleDrawer() {
        this.setState({
            visible: !this.state.visible,
            userSearchValue: '',
            taskOwnerId: this.getCurrentTaskOwnerId() === null ? '' : this.getCurrentTaskOwnerId(),
        })
    }

    onAutoCompleteChange: Function
    onAutoCompleteChange(selectedValue: string) {
        let selectedUser = this.props.allUsers.find(u => u.id === selectedValue);

        // If we didn't receive an ID matching a user then it's probably the user
        // filtering so we only update the user search value.
        if (!selectedUser) {
            this.setState({
                userSearchValue: selectedValue,
                taskOwnerId: '',
            })
            return;
        }

        this.setState({
            userSearchValue: selectedUser.attributes.display_name,
            taskOwnerId: selectedUser.id,
        })
    }

    onSave: Function
    onSave(isNewUser: bool = false) {
        this.setState({ isLoading: true });

        let updatedTask = this.props.task;
        let user = this.state.taskOwnerId ? {
            id: this.state.taskOwnerId,
            type: 'user--user',
        } : {};
        updatedTask.relationships.uid = { data: user };

        Promise.all([
            this.props.onSave(updatedTask),
            this.state.taskOwnerId !== '' ? createNotification('email', this.state.taskOwnerId, this.getMessage(), {
                event: isNewUser ? 'notification.new_task_owner_new_user' : 'notification.new_task_owner',
                taskId: this.props.task.id,
            }) : null,
        ])
            .then(responses => {
                this.setState({
                    visible: false,
                    isLoading: false,
                    isAddUserOpen: false,
                    message: '',
                });
            })
            .catch(error => {
                message.error(extractErrorMessage(error));
                this.setState({ isLoading: false });
            })
    }

    getMessage: Function
    getMessage() {
        if (this.state.message === '') {
            if (this.state.taskOwnerId === '') {
                return 'Hi, could you please investigate this task for me';
            }
            let selectedUser = this.props.allUsers.find(u => u.id === this.state.taskOwnerId);
            if (!selectedUser) {
                return 'Hi, could you please investigate this task for me';
            }

            return `Hi ${selectedUser.attributes.display_name}, could you please investigate this task for me`;
        }
        return this.state.message;
    }

    onMessageChange: Function
    onMessageChange(e: Object) {
        this.setState({
            message: e.target.value,
        })
    }

    shouldShowMessage: Function
    shouldShowMessage() {
        // If we don't have a selected task owner then don't show the message.
        if (this.state.taskOwnerId === '') {
            return false;
        }

        // If we have a task owner and it's the same as what's selected don't show.
        if (this.getCurrentTaskOwnerId() === this.state.taskOwnerId) {
            return false;
        }

        // Don't show if we're assigning to ourself.
        if (this.props.currentUser && this.props.currentUser.id === this.state.taskOwnerId) {
            return false;
        }

        return true;
    }

    shouldShowAddUserButton: Function
    shouldShowAddUserButton() {
        let found = false;
        this.props.allUsers.forEach(user => {
            if (user.attributes.display_name.toUpperCase().indexOf(this.state.userSearchValue.toUpperCase()) !== -1) {
                found = true;
            }
        });

        return !found && !this.state.isAddUserOpen;
    }

    renderAutoCompleteOptions: Function
    renderAutoCompleteOptions(): Array<Object> {
        return this.props.allUsers
            .filter(u => typeof u.attributes.status === 'undefined' || u.attributes.status === true)
            .map((u: any, i) => {
                return ({
                    value: u.id,
                    text: u.attributes.display_name || 'N/A',
                })
            })
    }

    onSearchTimer: any
    onSearch: Function
    onSearch(searchString: string) {
        // Too short, just ignore it.
        if (searchString.length < 3) {
            return;
        }

        const DEBOUNCE_TIME = 400;
        clearTimeout(this.onSearchTimer);

        // Load some more users if we haven't already dont this search before.
        if (!this.state.searchedStrings.includes(searchString)) {
            // Debounce the searches.
            this.onSearchTimer = setTimeout(() => {
                this.props.loadUsers({
                    'filter[mail][condition][path]': 'name',
                    'filter[mail][condition][value]': searchString,
                    'filter[mail][condition][operator]': 'CONTAINS',
                });
                // Save it so we don't do the same query again.
                this.setState({ searchedStrings: this.state.searchedStrings.concat(searchString) })
            }, DEBOUNCE_TIME);
        }
    }

    render() {
        return (
            <div>
                <div
                    style={{ display: 'flex' }}
                    className="hoverable add-user"
                    onClick={this.toggleDrawer}
                >
                    <span>{this.getCurrentTaskOwnerId() ? 'assigned' : 'assign'}</span>
                </div>
                <Drawer
                    onClose={this.toggleDrawer}
                    visible={this.state.visible}
                >
                    <ManageUsersHeading>Update Task Owner</ManageUsersHeading>
                    <h2>{this.props.task.attributes.title}</h2>

                    {!this.state.isAddUserOpen ?
                        <div>
                            {this.getCurrentTaskOwner() ? <div style={{ marginBottom: '32px' }}>
                                <h4>Current Task Owner</h4>
                                <Avatar user={this.getCurrentTaskOwner()} /> <span style={{ marginLeft: '8px' }}>{this.getCurrentTaskOwner().attributes.display_name}</span>
                            </div> : null}

                            <AutoComplete
                                style={{ width: '100%' }}
                                onChange={this.onAutoCompleteChange}
                                onSearch={this.onSearch}
                                value={this.state.userSearchValue}
                                filterOption={(inputValue, option) => {
                                    return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                placeholder="Select a new task owner"
                                dataSource={this.renderAutoCompleteOptions()}
                            >
                                <Input
                                    suffix={
                                        <Button
                                            className="search-btn"
                                            style={{
                                                marginRight: -12,
                                                ...(this.shouldShowAddUserButton() ? {} : { display: 'none' })
                                            }}
                                            onClick={() => this.setState({ isAddUserOpen: true })}
                                        >
                                            Add User
                                        </Button>
                                    }
                                />
                            </AutoComplete>

                            {this.props.currentUser && this.getCurrentTaskOwnerId() !== this.props.currentUser.id ?
                                <div style={{ textAlign: 'right' }}>
                                    <span className="hoverable" onClick={() => this.onAutoCompleteChange(this.props.currentUser.id)}>Assign to me</span>
                                </div>
                                : null}

                            {this.shouldShowMessage() ?
                                <Spacer>
                                    <SidebarLabel>Send a message</SidebarLabel>
                                    <TextArea value={this.getMessage()} onChange={this.onMessageChange} />
                                </Spacer>
                                : null}

                            <Divider />

                            <Button
                                onClick={e => {
                                    e.preventDefault();
                                    this.onSave();
                                }}
                                loading={this.state.isLoading}
                                disabled={this.shouldShowAddUserButton()}
                            >Save</Button>
                        </div> : null}

                    {this.state.isAddUserOpen ?
                        <UserRegisterFormSimple
                            user={{
                                type: 'user--user',
                                attributes: {
                                    display_name: this.state.userSearchValue,
                                }
                            }}
                            onAfterSave={result => {
                                this.setState({
                                    message: result.message,
                                    taskOwnerId: result.userId,
                                });
                                this.onSave(true);
                            }}
                            defaultMessage={this.getMessage()}
                            onCancel={() => this.setState({ isAddUserOpen: false })}
                        />
                        : null}

                </Drawer>
            </div>
        )
    }
}

export default withCurrentUser(withAllUsers(AssignUserToTask));
export { AssignUserToTask as _AssignUserToTask }
