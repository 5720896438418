import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { loadUsers } from '../actions';

const withAllUsers = function (WrappedComponent, filters = {}) {
    return class extends Component {

        componentDidMount() {
            this.props.loadUsers(filters);
        }

        render() {
            return <WrappedComponent {...this.props} />
        }
    }
}

const mapStateToProps = state => {
    return {
        allUsers: typeof state.jsonApi['user--user'] === 'undefined' ? [] : Object.values(state.jsonApi['user--user']),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadUsers: (filters) => dispatch(loadUsers(filters)),
    }
}

const ComponentWithAllUsers = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withAllUsers
)

export default ComponentWithAllUsers;
