// @flow

import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { updateTask } from '../../actions';
import { getRelationship } from '../../selectors';
import { AttachmentListFromFiles } from '../File/AttachmentList';
import { Task } from '../../types';
import { Popconfirm, Icon } from 'antd';
import { useState } from 'react';

type Props = {
    attachments: Array<Object>,
    updateTask: Function,
    task: Task,
}

const RemoveFile = ({ file, removeFile }) => {
    return (
        <Popconfirm
            title={"Are you sure you want to delete this file?"}
            onConfirm={e => removeFile(file)}
            okText="Yes"
            cancelText="No"
        >
            <Icon type="close-circle" className="hoverable" />
        </Popconfirm>
    )
}

const TaskAttachmentList = ({ attachments, updateTask, task }: Props) => {
    const [isLoadingFileId, setIsLoadingFileId] = useState('');

    const removeFile = useCallback((file) => {
        let updatedTask = JSON.parse(JSON.stringify(task))
        updatedTask.relationships.attachments.data = updatedTask.relationships.attachments.data.filter(attachment => attachment.id !== file.id);

        setIsLoadingFileId(file.id);
        updateTask(updatedTask)
            .then(response => {
                setIsLoadingFileId('');
            })
            .catch(error => {
                setIsLoadingFileId('');
                console.log(error);
            });
    }, [task, updateTask]);

    return (
        <AttachmentListFromFiles
            isLoading={isLoadingFileId !== ''}
            attachments={attachments}
            updateEntity={updateTask}
            entity={task}
            getItemActions={item => {
                return [
                    <RemoveFile file={item} removeFile={removeFile} />
                ];
            }}
        />
    );
};

const mapStateToProps = (state, props) => {
    return {
        attachments: getRelationship(state, 'attachments', props.task) || [],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateTask: (...args) => dispatch(updateTask(...args)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskAttachmentList);
